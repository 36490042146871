import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import ConversationPreview from './ConversationPreview';
import ConversationWindow from './ConversationWindow';

const TextMessageContext = React.createContext();
import { toast } from 'react-toastify';
import Utils from '../../Utils';

export default class TextMessagesConversationsModal extends React.Component {
    static propTypes = {
        company: PropTypes.object.isRequired,
        pusher_key: PropTypes.string.isRequired,
        unread_count: PropTypes.number.isRequired,
        current_user: PropTypes.object.isRequired,
        environment: PropTypes.string.isRequired
    }

    constructor(props) {
        super(props);

        this.baseState = {
            unreadCount: props.unread_count,
            loading: false,
            loadingMore: false,
            appendingTexts: false,
            conversations: [],
            conversationPages: null,
            currentConversationPage: 1,
            selectedConversation: null,
            conversationMessages: [],
            currentConversationMessagesPages: 1,
            currentConversationMessagesPage: 1,
            replyText: "",
            sendingText: false,
            incomingConversationId: null,
            expandedImage: null,
            conversationTypes: ["customers", "route-techs", "repair-techs", "admins"]
        }

        this.state = this.baseState
    }

    componentDidMount() {
        this.updateUnreadIcon();

        $("a.toggle-text-message-conversation").on("click", (e) => {
            e.preventDefault();
            this.fetchConversations();
            $(this.modalEl).modal("show");
        });

        $("#text-message-conversation-modal").on("show.bs.modal", (e) => {
            $(e.target).addClass("center-screen-modal");
        });

        $("#text-message-conversation-modal").on("hide.bs.modal", (e) => {
            $(e.target).removeClass("center-screen-modal");

            this.setState({ selectedConversation: null, currentConversationPage: 1, conversationPages: null, currentConversationMessagesPage: 1, currentConversationMessagesPages: null });
        });

        if (!window.pusherChannel) {
            const pusher = new Pusher(this.props.pusher_key, {
                cluster: "us2",
                forceTLS: true
            });

            window.pusherChannel = pusher.subscribe(`admin-alerts-${this.props.environment}-${this.props.company.channel_id}`);
        }

        window.pusherChannel.bind("text-message-received", (data) => {
            if ($(this.modalEl).hasClass("in")) {
                this.setState({ conversationPages: null, currentConversationPage: 1, currentConversationMessagesPages: null, currentConversationMessagesPage: 1 }, () => {
                    if (this.state.selectedConversation && data.conversation_id === this.state.selectedConversation.id) {
                        this.fetchTextMessages(false, false, false);
                        this.fetchConversations(false);
                    } else {
                        this.handleIncomingAlert(data, "text-message-received");
                    }
                })
            } else {
                if (this.props.company.sms_number !== data.from) {
                    this.handleIncomingAlert(data, "text-message-received");

                    this.setState({ unreadCount: this.state.unreadCount + 1 });
                }
            }

            if (this.props.current_user.settings.text_message_sound) {
                const sound = new Audio("https://s3.amazonaws.com/superswimbros.dev/text-sound.mp3");
                sound.play();
            }
        });

        window.pusherChannel.bind("quick-repair-created", (data) => {
            this.handleIncomingAlert(data, "quick-repair-created");
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.unreadCount !== this.state.unreadCount) {
            this.updateUnreadIcon();
        }
    }

    handleIncomingAlert = (data, type) => {
        if (data.conversation_type === "route-tech-repair-tech") {
            if (type === "quick-repair-created") {
                if (!this.state.conversationTypes.includes("route-techs")) {
                    return false
                }
            } else if (!this.state.conversationTypes.includes("route-techs") && !this.state.conversationTypes.includes("repair-techs")) {
                return false
            }
        } else if (!this.state.conversationTypes.includes(`${data.conversation_type}s`)) {
            return false;
        }

        const Msg = ({ closeToast }) => {
            let iconClass;
            switch(type) {
                case 'text-message-received':
                    iconClass = "fa-envelope";
                    break;
                case 'quick-repair-created':
                    iconClass = "fa-wrench";
                    break;
            }

            return(
                <div onClick={() => { this.handleMessageSelect(data.conversation_id) }}>
                    <div className="display-flex">
                        <div className="margin-10-right">
                            <i style={{ fontSize: "1.75em" }} className={`fa ${iconClass}`}></i>
                        </div>
                        <h4 className="margin-5-top">
                            { data.from }
                        </h4>
                    </div>
                    <div>
                        { data.content }
                    </div>
                </div>
            )
        }

        if (type === "quick-repair-created" && data.from === this.props.current_user.name) return;

        if ($(this.modalEl).hasClass("in")) {
            if (this.state.selectedConversation) {
                this.fetchTextMessages(false);
                this.fetchConversations(false);
            } else {
                this.fetchConversations(false);
            }
        }

        switch(type) {
            case 'text-message-received':
                toast.success(Msg, {
                    position: toast.POSITION.TOP_RIGHT
                });
                break;
            case 'quick-repair-created':
                toast.info(Msg, {
                    position: toast.POSITION.TOP_RIGHT
                });
                break;
        }
    };

    findUnreadCount = (conversations = this.state.conversations) => {
        let unreadCount = 0;
        conversations.forEach(conversation => {
            if (this.canDisplayConversation(conversation)) {
                unreadCount += conversation.number_of_unread_messages;
            }
        });

        return unreadCount;
    };

    handleMessageSelect = (id) => {
        this.setState({ incomingConversationId: id }, () => {
            this.fetchConversations(true, () => {
                const conversation = this.state.conversations.find(c => c.id === this.state.incomingConversationId);

                this.selectConversation(conversation);
                this.launchModal();
            });
        });
    };

    launchModal = () => {
        if (!$(this.modalEl).hasClass("in")) {
            $(this.modalEl).modal("show");
        }
    }

    updateUnreadIcon = () => {
        const unread = $(".unread-text-message-wrapper .unread");

        if (this.state.unreadCount > 0) {
            let value = this.state.unreadCount;
            value = value >= 99 ? 99 : value;

            unread.find(".unread-value").text(value);
            unread.removeClass("display-none");
        } else {
            if (!unread[0].classList.contains("display-none")) {
                unread.addClass("display-none");
            }
        }
    };

    displayedContact = (conversation) => {
        if (conversation && conversation.user_name) {
            return Utils.truncate(conversation.user_name, 20);
        } else if (conversation && conversation.account_names.length > 0) {
            return Utils.truncate(conversation.account_names.join(", "), 20);
        } else {
            return Utils.formattedPhone(conversation.reply_to_number);
        }
    };

    fetchConversations = (loading = true, callback, append = false) => {
        this.setState({ loading });
        const page = this.state.currentConversationPage;

        $.ajax({
            method: "GET",
            url: "/conversations.json",
            data: { page }
        }).done(res => {
            const conversationPages = res.total_pages;
            const currentConversationPage = res.current_page;

            let conversations;
            if (!append) {
                conversations = [...res.conversations];
            } else {
                conversations = [...this.state.conversations, ...res.conversations];
            }

            let selectedConversation = null;
            if (this.state.selectedConversation) {
                selectedConversation = conversations.find(conversation => conversation.id === this.state.selectedConversation.id);
            }

            this.setState({ conversations, conversationPages, currentConversationPage, selectedConversation, loading: false, loadingMore: false }, () => {
                if (callback) callback();
            });
        })
    };

    loadMoreConversations = () => {
        let { currentConversationPage } = this.state;
        currentConversationPage += 1;

        this.setState({ currentConversationPage, loadingMore: true }, () => {
            this.fetchConversations(false, undefined, true);
        });
    };

    selectConversation = (conversation) => {
        this.setState({ selectedConversation: null, currentConversationMessagesPage: 1, currentConversationMessagesPages: 1 }, () => {
            this.setState({ selectedConversation: conversation });
        })
    };

    fetchTextMessages = (fetchingMessages = true, appendingTexts = false, scrollLast = false) => {
        let { currentConversationMessagesPage } = this.state;

        if (appendingTexts) {
            currentConversationMessagesPage += 1;
        }

        this.setState({ fetchingMessages, appendingTexts, currentConversationMessagesPage });

        const page = currentConversationMessagesPage;

        $.ajax({
            method: "GET",
            url: `/conversations/${this.state.selectedConversation.id}/text_messages.json`,
            data: { page }
        }).done(res => {
            let conversationMessages;
            const newMessages = [...res.messages].reverse();

            if (appendingTexts) {
                conversationMessages = [...newMessages, ...this.state.conversationMessages];
            } else {
                conversationMessages = [...newMessages];
            }

            const currentConversationMessagesPages = res.total_pages;

            this.setState({ conversationMessages, currentConversationMessagesPages, fetchingMessages: false, appendingTexts: false }, () => {
                if (!appendingTexts) {
                    if (scrollLast) {
                        this.scrollLastTextIntoView();
                    }
                    this.markReadMessages();
                }
            })
        });
    };

    markReadMessages = () => {
        const unreadMessages = this.state.conversationMessages.filter(message => !message.read);

        if (unreadMessages.length > 0 || this.state.selectedConversation.number_of_unread_messages > 0) {
            $.ajax({
                method: "PUT",
                url: `/conversations/${this.state.selectedConversation.id}.json`,
                data: {
                    conversation: {
                        mark_all_as_read: true
                    }
                }
            }).done(() => {
                const conversations = [...this.state.conversations].map(convo => {
                    if (convo.id === this.state.selectedConversation.id) {
                        return { ...convo, number_of_unread_messages: 0, last_message: { ...convo.last_message, read: true } }
                    } else {
                        return { ...convo };
                    }
                });

                this.setState({ conversations, unreadCount: this.findUnreadCount(conversations) });
            });
        }
    };

    scrollLastTextIntoView = () => {
        setTimeout(() => {
            const el = $(".conversation .text-message-wrapper:last-child")[0];
            if (el) el.scrollIntoView();
        });
    };

    setReplyText = (text) => {
        this.setState({ replyText: text });
    };

    setExpandedImage = (expandedImage) => {
        this.setState({ expandedImage });
    };

    sendText = () => {
        if (this.state.replyText.length > 0) {
            this.setState({ sendingText: true });

            $.ajax({
                method: "POST",
                url: `/conversations/${this.state.selectedConversation.id}/text_messages.json`,
                data: {
                    text_message: {
                        to_number: this.state.selectedConversation.reply_to_number,
                        content: this.state.replyText
                    }
                }
            }).done(() => {
                const newMessage = {
                    content: this.state.replyText,
                    from_number: this.props.company.sms_number,
                    to_number: this.state.selectedConversation.reply_to_number,
                    read: true,
                };

                const conversationMessages = [...this.state.conversationMessages, newMessage];

                let conversations = [...this.state.conversations.filter(c => c.id !== this.state.selectedConversation.id)]
                const conversation = { ...this.state.selectedConversation, last_message: newMessage, updated_at: moment.utc().format("YYYY-MM-DDTHH:mm:ss") };
                conversations.push(conversation);

                conversations = conversations.sort((left, right) => (moment(right.updated_at, "YYYY-MM-DD HH:mm:ss").diff(moment(left.updated_at, "YYYY-MM-DD HH:mm:ss"))));

                this.setState({ conversationMessages, conversations, sendingText: false, replyText: "" });

                this.scrollLastTextIntoView();

                toast.success("Successfully enqueued Text Message for delivery", {
                    position: toast.POSITION.TOP_CENTER
                });
            }).fail(() => {

            });
        }
    };

    conversationType = (conversation) => {
        if (conversation.account_names.length > 0) {
            return "customer";
        } else if (conversation.user_roles && conversation.user_roles.length > 0) {
            if (conversation.user_roles.includes("route_tech") && conversation.user_roles.includes("repair_tech")) {
                return "route-tech-repair-tech"
            } else if (conversation.user_roles.includes("route_tech")) {
                return "route-tech"
            } else if (conversation.user_roles.includes("repair_tech")) {
                return "repair-tech"
            } else if (conversation.user_roles.includes("admin")) {
                return "admin"
            }
        } else {
            return "customer"
        }
    };

    conversationTypes = () => {
        // unique conversation types based on all conversations loaded
        const types = [];
        this.state.conversations.forEach(conversation => {
            const type = this.conversationType(conversation);
            if (type === "route-tech-repair-tech") {
                types.push("route-techs", "repair-techs")
            } else {
                types.push(`${type}s`);
            }
        });

        return types.filter((value, index, array) => array.indexOf(value) === index).sort().reverse();
    };

    categoryButtons = () => {
        return this.conversationTypes().map((type, index) => {
            return(
                <button
                    key={index}
                    className={`btn ${type}`}
                    onClick={() => this.toggleConversationType(type)}
                >
                    <input
                        type="checkbox"
                        className="margin-5-right"
                        checked={this.state.conversationTypes.includes(type)}
                        onChange={() => this.toggleConversationType(type)}
                    />
                    { Utils.humanize(type) }
                </button>
            )
        })
    };

    toggleConversationType = (type) => {
        if (this.state.conversationTypes.includes(type)) {
            const conversationTypes = this.state.conversationTypes.filter(_type => _type !== type);
            this.setState({ conversationTypes });
        } else {
            const conversationTypes = [...this.state.conversationTypes, type];
            this.setState({ conversationTypes });
        }
    };

    canDisplayConversation = (conversation) => {
        if (this.conversationType(conversation) === "route-tech-repair-tech" && (this.state.conversationTypes.includes("route-techs") || this.state.conversationTypes.includes("repair-techs"))) {
            return true
        } else {
            return this.state.conversationTypes.includes(`${this.conversationType(conversation)}s`);
        }
    };

    render() {
        return(
            <TextMessageContext.Provider
                value={{
                    ...this.state,
                    company: this.props.company,
                    displayedContact: this.displayedContact,
                    selectConversation: this.selectConversation,
                    setReplyText: this.setReplyText,
                    setExpandedImage: this.setExpandedImage,
                    sendText: this.sendText,
                    scrollLastTextIntoView: this.scrollLastTextIntoView,
                    markReadMessages: this.markReadMessages,
                    conversationType: this.conversationType
                }}
            >
                <div
                    id="text-message-conversation-modal"
                    className="modal fade"
                    ref={(e) => this.modalEl = e}
                >
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <h5 className="modal-title">
                                    Text Message Conversations
                                </h5>
                            </div>
                            <div className="modal-body no-padding">
                                { (this.state.conversations.length > 0 || this.state.loading) &&
                                    <div className="text-message-conversation-wrapper">
                                        { this.state.loading &&
                                            <div className="display-flex flex-column align-items-center width-100 justify-content-center">
                                                <h4 className="text-center">
                                                    Loading Conversations...
                                                </h4>
                                                <div>
                                                    <i className="fa fa-spinner fa-pulse fa-3x fa-fw align-self-center flex-1"></i>
                                                </div>
                                            </div>
                                        }
                                        { !this.state.loading && this.state.conversations.length > 0 &&
                                            <Fragment>
                                                <div className="flex-1 conversation-list">
                                                    <h5 className="text-center">
                                                        Conversation Types:
                                                    </h5>
                                                    <div className="category-buttons">
                                                        { this.categoryButtons() }
                                                    </div>
                                                    <hr/>
                                                    {
                                                        this.state.conversations.map((conversation, index) => {
                                                            if (this.canDisplayConversation(conversation)) {
                                                                return(
                                                                    <ConversationPreview
                                                                        key={index}
                                                                        conversation={conversation}
                                                                    />
                                                                )
                                                            }
                                                        })
                                                    }
                                                    { this.state.currentConversationPage < this.state.conversationPages &&
                                                        <div className="display-flex load-more-conversations-wrapper">
                                                            { this.state.loadingMore &&
                                                                <i className="fa fa-spinner fa-pulse fa-3x fa-fw align-self-center flex-1"></i>
                                                            }
                                                            { !this.state.loadingMore &&
                                                                <button className="btn btn-default" onClick={this.loadMoreConversations}>
                                                                    Load More Conversations
                                                                </button>
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                                <div className="conversation-window">
                                                    { !this.state.selectedConversation &&
                                                        <div className="display-flex height-100 width-100  justify-content-center align-items-center padding-10">
                                                            <h4 className="text-center">
                                                                Select a Conversation
                                                            </h4>
                                                        </div>
                                                    }
                                                    { this.state.selectedConversation &&
                                                        <ConversationWindow
                                                            conversation={this.state.selectedConversation}
                                                            fetchTextMessages={this.fetchTextMessages}
                                                            scrollLastTextIntoView={this.scrollLastTextIntoView}
                                                        />
                                                    }
                                                </div>
                                            </Fragment>
                                        }
                                    </div>
                                }
                                { !this.state.loading && this.state.conversations.length < 1 &&
                                    <div className="display-flex justify-content-center align-items-center height-100">
                                        <h4 className="text-center">
                                            No Conversations to display
                                        </h4>
                                    </div>
                                }
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-default" data-dismiss="modal">
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </TextMessageContext.Provider>
        )
    }
}

export const TextMessageContextConsumer = TextMessageContext.Consumer;
