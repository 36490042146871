import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Utils from '../../../Utils';

export default class UserModal extends React.Component {
    static propTypes = {
        type: PropTypes.string.isRequired,
        techs: PropTypes.array.isRequired,
        callback: PropTypes.func.isRequired
    }

    constructor(props) {
        super(props);

        this.baseState = {
            name: ""
        }

        this.state = this.baseState;
    }

    componentDidMount() {
        $(this.modalEl).on("show.bs.modal", () => {
            $(this.modalEl).toggleClass("center-screen-modal");
        });

        $(this.modalEl).on("hide.bs.modal", () => {
            $(this.modalEl).toggleClass("center-screen-modal");
            this.setState({ ...this.baseState });
        });
    }

    showModal = () => {
        $(this.modalEl).modal("show");
    };

    hideModal = () => {
        $(this.modalEl).modal("hide");
    };

    techs = () => {
        return this.props.techs.filter(t => t.roles.includes(this.props.type));
    };

    filteredTechs = () => {
        if (this.state.name.length > 0) {
            return this.techs().filter(t => t.name.toLowerCase().includes(this.state.name));
        }

        return this.techs();
    };

    render() {
        return(
            <div
                id="user-zone-modal"
                className="modal fade"
                ref={(e) => this.modalEl = e}
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button className="close" type="button" data-dismiss="modal">
                                <span>
                                    &times;
                                </span>
                            </button>
                            <h4 className="modal-title">
                                { `Select ${Utils.humanize(this.props.type)} to add to selected Zone` }
                            </h4>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <input
                                    value={this.state.name}
                                    type="text"
                                    className="form-control"
                                    placeholder="Search for Tech by Name"
                                    onChange={(e) => this.setState({ name: e.target.value })}
                                />
                            </div>
                            { this.filteredTechs().length > 0 &&
                                <div className="tech-list">
                                    { this.filteredTechs().length > 0 &&
                                        <Fragment>
                                            {
                                                this.filteredTechs().map((tech, index) => (
                                                    <div className="panel panel-default margin-10-bottom" key={index}>
                                                        <div className="panel-body">
                                                            <div className="display-flex justify-content-space-between">
                                                                <div className="display-flex align-items-center">
                                                                    { tech.name }
                                                                </div>
                                                                <button className="btn btn-success" onClick={() => this.props.callback(tech, this.props.type, this.hideModal)}>
                                                                    Select
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </Fragment>
                                    }
                                </div>
                            }
                            { this.filteredTechs().length < 1 &&
                                <h4 className="text-center">
                                    No { `${Utils.humanize(this.props.type)}'s` } found
                                </h4>
                            }
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-default" data-dismiss="modal">
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
