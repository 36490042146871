import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import AqsChart from '@shared/components/Chart';
import UserAppointments from './UserAppointments';
import ServiceStopShow from '../../ServiceStops/Show';
import RepairItemTicket from './RepairItemTicket';
import RepairTicket from './RepairTicket';

import Utils from '../../../Utils';

export default class AppointmentsReport extends React.Component {
    static propTypes = {
        timezone: PropTypes.string.isRequired,
        isNotAdmin: PropTypes.bool.isRequired,
        defaultTasks: PropTypes.array.isRequired,
        company: PropTypes.object.isRequired,
        companySettings: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);

        this.baseState = {
            start: moment().format("MM/DD/YYYY"),
            end: moment().format("MM/DD/YYYY"),
            loading: true,
            appointments: [],
            types: ["service_stop", "repair", "repair_item", "other"],
            filteredTechs: [],
            loadingAppointment: null,
            selectedAppointment: {}
        }

        this.state = this.baseState;
    }

    componentDidMount() {
        $(".datetime-picker").datetimepicker({
            ignoreReadonly: true,
            allowInputToggle: true,
            format: "MM/DD/YYYY"
        });

        $(".datetime-picker").on("dp.change", (e) => {
            this.setState({ [e.target.name]: e.target.value });
        });

        this.fetchAppointments();
    }

    showDTPicker = (e) => {
        $($(e.target).parent().find("input")).data("DateTimePicker").show();
    };

    fetchAppointments = () => {
        this.setState({ loading: true });

        $.ajax({
            method: "GET",
            url: "/appointments.json",
            data: {
                q: {
                    start_gteq: moment(this.state.start, "MM/DD/YYYY").startOf("day").utc().format("YYYY-MM-DD HH:mm"),
                    end_lteq: moment(this.state.end, "MM/DD/YYYY").endOf("day").utc().format("YYYY-MM-DD HH:mm"),
                    type_in: this.state.types
                }
            }
        }).done(appointments => {
            this.setState({ loading: false, appointments, selectedAppointment: {} }, () => {
                this.setState({ filteredTechs: this.uniqueUsers() });
            });
        });
    };

    uniqueUsers = () => {
        const ids = [];
        return this.state.appointments.map(appt => {
            if (!ids.includes(appt.user_id)) {
                ids.push(appt.user_id);
                return appt.user
            }
        }).filter(Boolean);
    };

    userAppointments = (userId) => {
        return this.state.appointments.filter(appt => appt.user_id === userId);
    };

    toggleType = (type) => {
        let types;
        if (this.state.types.includes(type)) {
            if (type === "repair") {
                types = types = this.state.types.filter(_type => _type !== type && _type !== "repair_item");
            }
            types = this.state.types.filter(_type => _type !== type);
        } else {
            if (type === "repair") {
                types = [...this.state.types, type, "repair_item"];
            }
        }

        this.setState({ types });
    };

    completedAppointmentCount = (which) => {
        return this.state.appointments.filter(appt => appt.completed).length
    }

    filterTechs = (term) => {
        const filteredTechs = this.uniqueUsers().filter(user => {
            return user.name.toLowerCase().search(term.toLowerCase()) !== -1
        });

        this.setState({ filteredTechs })
    };

    selectAppointment = (appointment) => {
        if (appointment.type === "service_stop" && appointment.service_stop_id) {
            this.setState({ loadingAppointment: "Service Stop", selectedAppointment: appointment });

            $.ajax({
                method: "GET",
                url: `/accounts/${appointment.account_id}/service_stops/${appointment.service_stop_id}.json`
            }).done(serviceStop => {
                this.setState({ selectedAppointment: { ...this.state.selectedAppointment, serviceStop }, loadingAppointment: null });
            });
        } else if (appointment.type === "repair_item") {
            this.setState({ loadingAppointment: "Repair", selectedAppointment: appointment })

            $.ajax({
                method: "GET",
                url: `/accounts/${appointment.account.id}/repairs/${appointment.repair_item.repair.id}/repair_items/${appointment.repair_item_id}.json`
            }).done(repairItem => {
                this.setState({ selectedAppointment: { ...this.state.selectedAppointment, repairItem }, loadingAppointment: null });
            });
        } else if (appointment.type === "repair") {
            this.setState({ loadingAppointment: "Repair", selectedAppointment: appointment })

            $.ajax({
                method: "GET",
                url: `/accounts/${appointment.repair.account_id}/repairs/${appointment.repair.id}.json`
            }).done(repair => {
                this.setState({ selectedAppointment: { ...this.state.selectedAppointment, repair }, loadingAppointment: null });
            })
        }
    };

    render() {
        return(
            <div className="appointment-report-wrapper">
                <div className="row">
                    <div className="col-md-4">
                        <h4 className="no-margin-top">
                            Dates:
                        </h4>
                    </div>
                </div>
                <div className="row">
                    <div className="date-time-selection col-md-6 col-lg-5">
                        <div className="form-group display-flex margin-10-right">
                            <label className="margin-5-right no-margin-bottom">
                                Between:
                            </label>
                            <div className="datetime-picker-wrapper">
                                <input
                                    name="start"
                                    type="text"
                                    className="form-control datetime-picker"
                                    readOnly
                                    value={this.state.start}
                                />
                                <span className="glyphicon glyphicon-chevron-down" onClick={this.showDTPicker}></span>
                            </div>
                        </div>
                        <div className="form-group display-flex margin-10-right">
                            <label className="margin-5-right no-margin-bottom">
                                And:
                            </label>
                            <div className="datetime-picker-wrapper">
                                <input
                                    name="end"
                                    type="text"
                                    className="form-control datetime-picker with-chevron"
                                    readOnly
                                    value={this.state.end}
                                />
                                <span className="glyphicon glyphicon-chevron-down" onClick={this.showDTPicker}></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <h4 className="no-margin-top">
                            Types:
                        </h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 display-flex">
                        {
                            ["service_stop", "repair", "other"].map((type, index) => {
                                return(
                                    <div className="form-group margin-10-right no-margin-bottom" key={index}>
                                        <label htmlFor={`${type}-appt`} className="margin-5-right">
                                            { Utils.humanize(type) }:
                                        </label>
                                        <input
                                            type="checkbox"
                                            id={`${type}-appt`}
                                            checked={this.state.types.includes(type)}
                                            onChange={(e) => this.toggleType(type)}
                                        />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <button className="btn btn-success margin-10-top" onClick={this.fetchAppointments}>
                            Show Appointments
                        </button>
                    </div>
                </div>

                <hr/>

                { !this.state.loading && this.state.appointments.length > 0 &&
                    <Fragment>
                        <div className="dispaly-flex justify-content-center align-items-center">
                            <div className="top-doughnut-chart-wrapper">
                                <AqsChart
                                    chartType="doughnut"
                                    canvasWidth={300}
                                    canvasHeight={300}
                                    displayLegend={false}
                                    chartData={[this.completedAppointmentCount(), (this.state.appointments.length - this.completedAppointmentCount())]}
                                    dataLabels={["complete", "incomplete"]}
                                    backgroundColors={["rgba(0, 255, 113, 0.2)", "rgba(255, 99, 132, 0.2)"]}
                                    borderColors={["rgba(0, 255, 113, 1)", "rgba(255,99,132,1)"]}
                                />
                                <div className="chart-stats">
                                    <div className="top-label">
                                        Appointments
                                    </div>
                                    <h2 className="margin-10">
                                        { this.completedAppointmentCount() }
                                        /
                                        { this.state.appointments.length }
                                    </h2>
                                    <div className="bottom-label">
                                        Completed
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr/>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group display-flex col-md-3 col-sm-6 no-padding">
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Search Technician's name..."
                                        onChange={(e) => this.filterTechs(e.target.value) }
                                    />
                                </div>
                            </div>
                        </div>

                        { this.state.filteredTechs.length > 0 &&
                            <div className="display-flex overflow-x-scroll">
                                {
                                    this.state.filteredTechs.map((user, index) => {
                                        return(
                                            <UserAppointments
                                                key={index}
                                                user={user}
                                                appointments={this.userAppointments(user.id)}
                                                timezone={this.props.timezone}
                                                selectAppointment={this.selectAppointment}
                                                selectedAppointment={this.state.selectedAppointment}
                                            />
                                        )
                                    })
                                }
                            </div>
                        }
                        { this.state.filteredTechs.length < 1 &&
                            <h4 className="text-center">
                                No Techs found
                            </h4>
                        }
                    </Fragment>
                }

                { this.state.loading &&
                    <div className="display-flex flex-column justify-content-center align-items-center">
                        <h4 className="text-center">
                            Loading Appointments
                        </h4>
                        <i className="fa fa-spinner fa-pulse fa-3x fa-fw align-self-center flex-1"></i>
                    </div>
                }
                { !this.state.loading && this.state.appointments.length < 1 &&
                    <h4 className="text-center">
                        No Appointments Found
                    </h4>
                }

                { this.state.loadingAppointment &&
                    <Fragment>
                        <hr/>
                        <div className="display-flex flex-column justify-content-center align-items-center">
                            <h4 className="text-center">
                                Loading { this.state.loadingAppointment }
                            </h4>
                            <i className="fa fa-spinner fa-pulse fa-3x fa-fw align-self-center flex-1"></i>
                        </div>
                    </Fragment>
                }

                { this.state.selectedAppointment && !this.state.loadingAppointment &&
                    <Fragment>

                        <hr/>

                        { this.state.selectedAppointment.type === "service_stop" &&
                            <ServiceStopShow
                                service_stop={this.state.selectedAppointment.serviceStop}
                                display_links={false}
                                chemical_readings={this.state.selectedAppointment.serviceStop.chemical_readings}
                                addable_chemicals={this.state.selectedAppointment.serviceStop.addable_chemicals}
                                company_settings={this.props.companySettings}
                                company={this.props.company}
                                current_user={this.props.currentUser}
                                is_not_admin={this.props.isNotAdmin}
                                default_tasks={this.props.defaultTasks}
                                tasks_completed={this.state.selectedAppointment.account.tasks_completed}
                            />
                        }
                        { this.state.selectedAppointment.type === "repair_item" &&
                            <RepairItemTicket
                                repairItem={this.state.selectedAppointment.repairItem}
                                timezone={this.props.timezone}
                            />
                        }
                        { this.state.selectedAppointment.type === "repair" &&
                            <RepairTicket
                                appointment={this.state.selectedAppointment}
                                timezone={this.props.timezone}
                            />
                        }
                    </Fragment>
                }
            </div>
        )
    }
}
