import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

export default class CreateExternalCustomer extends React.Component {
    static propTypes = {
        account: PropTypes.object.isRequired,
        integration: PropTypes.string.isRequired
    }

    constructor(props) {
        super(props);

        this.baseState = {
            loading: null,
            completed: null,
            error: null
        }

        this.state = this.baseState;
    }

    createCustomer = () => {
        this.setState({ loading: true });

        $.ajax({
            url: `/accounts/${this.props.account.id}/create_external_customer.json`,
            method: "POST"
        }).done(() => {
            this.setState({ completed: true, loading: false });
        }).fail(() => {
            this.setState({ error: true, loading: false })
        });
    };

    render() {
        return(
            <div>
                { !this.state.loading && !this.state.completed && !this.state.error &&
                    <button className="qbo-button btn btn-success" onClick={this.createCustomer}>
                        <img
                            src="https://s3.amazonaws.com/superswimbros.dev/site_images/qbicon.png"
                            alt="Quickbooks Online"
                            className="qbo-icon margin-5-right"
                        />
                        Create customer in Quickbooks
                    </button>
                }
                { this.state.loading && !this.state.completed && !this.state.error &&
                    <div className="display-flex">
                        <i className="fa fa-spinner fa-pulse fa-3x fa-fw align-self-center flex-1"></i>
                    </div>
                }
                { !this.state.loading && this.state.completed &&
                    <Fragment>
                        <div className="checkmark-animation margin-20-bottom">
                            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/><path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/></svg>
                        </div>
                        <h4 className="text-center">
                            Customer successfully created in Quickbooks
                        </h4>
                    </Fragment>
                }
                { !this.state.loading && this.state.error &&
                    <h4 className="text-center">
                        <span className="glyphicon glyphicon-remove margin-10-right"></span>
                        Unable to save customer in Quickbooks
                    </h4>
                }
            </div>
        )
    }
}
