import React from 'react';
import PropTypes from 'prop-types';
import { HashRouter as Router, Route, NavLink } from 'react-router-dom';

import CompanyList from './CompanyList';
import UserList from './UserList';

export default class Dashboard extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return(
            <Router>
                <div>
                    <div className="row page-header no-margin no-padding">
                        <div className="col-md-12 no-padding margin-20-top margin-20-bottom">
                            <NavLink
                                to="/companies"
                                className="btn btn-default margin-10-right"
                                activeClassName="btn btn-info"
                            >
                                Companies
                            </NavLink>
                            <NavLink
                                to="/users"
                                className="btn btn-default margin-10-right"
                                activeClassName="btn btn-info"
                            >
                                Users
                            </NavLink>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 margin-20-top">
                            <Route exact path="/companies" component={CompanyList} />
                            <Route exact path="/users" component={UserList} />
                        </div>
                    </div>
                </div>
            </Router>
        )
    }
}
