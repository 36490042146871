import React from 'react';
import PropTypes from 'prop-types';

export default class Step4 extends React.Component {
    static propTypes = {
        getMasterState: PropTypes.func.isRequired,
        updateMasterState: PropTypes.func.isRequired,
        active: PropTypes.bool.isRequired,
        notes: PropTypes.string.isRequired
    }

    constructor(props) {
        super(props);

        this.baseState = {
            notes: props.notes,
            active: props.active,
            home_warranty: props.home_warranty,
            pre_approved_budget: props.pre_approved_budget
        }

        this.state = this.baseState;
    }

    componentWillReceiveProps(props) {
        this.setState({ active: props.active, notes: props.notes, home_warranty: props.home_warranty, pre_approved_budget: props.pre_approved_budget });
    }

    render() {
        return(
            <div className="row margin-20-top margin-20-bottom">
                <div className="input-row">
                    <div className="col-sm-4 input-field">
                        <span className="field-label">
                            Account Active:
                        </span>
                    </div>
                    <div className="col-sm-8 input-field">
                        <div className="field-description">
                            Is this account active with your company?
                        </div>
                        <div className="margin-10-top">
                            <button
                                className={`btn ${this.state.active ? "btn-primary" : "btn-default"} margin-10-right`}
                                onClick={() => this.props.updateMasterState({ active: true })}
                            >
                                Yes
                            </button>
                            <button
                                className={`btn ${this.state.active ? "btn-default" : "btn-primary"}`}
                                onClick={() => this.props.updateMasterState({ active: false }) }
                            >
                                No
                            </button>
                        </div>
                    </div>
                </div>
                <div className="input-row">
                    <div className="col-sm-4 input-field">
                        <span className="field-label">
                            Home Warranty:
                        </span>
                    </div>
                    <div className="col-sm-8 input-field">
                        <div className="field-description">
                            Does this customer have a Home Warranty? (this is useful to note when determining who the customer is most likely to have do their repairs)
                        </div>
                        <div className="margin-10-top">
                            <button
                                className={`btn ${this.state.home_warranty ? "btn-primary" : "btn-default"} margin-10-right`}
                                onClick={() => this.props.updateMasterState({ home_warranty: true })}
                            >
                                Yes
                            </button>
                            <button
                                className={`btn ${this.state.home_warranty ? "btn-default" : "btn-primary"}`}
                                onClick={() => this.props.updateMasterState({ home_warranty: false }) }
                            >
                                No
                            </button>
                        </div>
                    </div>
                </div>
                <div className="input-row">
                    <div className="col-sm-4 input-field">
                        <span className="field-label">
                            Pre-Approved Budget:
                        </span>
                    </div>
                    <div className="col-sm-8 input-field">
                        <div className="field-description">
                            What Pre-Approved Budget (if any) for Service Items and Repairs does this Customer have?
                        </div>
                        <div className="margin-10-top">
                            <div className="form-group no-margin no-padding col-md-4">
                                <div className="input-group">
                                    <div className="input-group-addon">
                                        <span className="glyphicon glyphicon-usd"></span>
                                    </div>
                                    <input
                                        type="number"
                                        min="1"
                                        className="form-control"
                                        onChange={(e) => this.props.updateMasterState({ pre_approved_budget: e.target.value }) }
                                        value={this.state.pre_approved_budget || ""}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="input-row">
                    <div className="col-sm-4 input-field">
                        <span className="field-label">
                            Notes:
                        </span>
                    </div>
                    <div className="col-sm-8 input-field">
                        <div className="field-description margin-20-top margin-10-bottom">
                            List any notes you would like to keep on this account (e.g. gate codes, special instructors when servicing the pool, etc.)
                        </div>
                        <div className="form-group">
                            <textarea
                                className="form-control"
                                value={this.state.notes}
                                onChange={(e) => this.props.updateMasterState({ notes: e.target.value })}
                            >
                            </textarea>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
