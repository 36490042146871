import Timeout from '@main/Timeout';
import { toast } from 'react-toastify';

export default {
    saveRepairTicket(repairItem, accountId) {
        return new Promise((resolve, reject) => {
            const url = `/accounts/${accountId}/repairs/${repairItem.repair_id}/repair_items/${repairItem.id}.json`;

            const images = repairItem.images;

            $.ajax({
                method: "PUT",
                url,
                contentType: "application/json",
                data: JSON.stringify({ repair_item: repairItem, from_route: true }),
                timeout: Timeout.RESOURCE,
                success(savedRepairItem) {
                    resolve({ ...savedRepairItem, images });
                },
                error(jqXHR, textStatus, errorThrown) {
                    if (textStatus === "error" && (jqXHR.responseJSON || {}).errors) {
                        toast.error(`Unable to save Repair ${jqXHR.responseJSON.errors}`, {
                            position: tost.POSITION.TOP_CENTER
                        })
                    }

                    reject({ ...repairItem, timeout: true });
                }
            })
        });
    }
}
