import PropTypes from 'prop-types';
import React from 'react';

import DefaultTaxRates from './DefaultTaxRates';

export default class AddInvoiceIntegrations extends React.Component {
    static propTypes = {
        company: PropTypes.object.isRequired,
        stripe_url: PropTypes.string.isRequired,
        freshbooks_url: PropTypes.string.isRequired,
        quickbooks_url: PropTypes.string.isRequired,
        company_settings: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);

        this.state = {
            integration: props.company.invoice_integration
        }
    }

    oauthWindow = (provider) => {
        let path = this.props[`${provider}_url`];
        const encodedState = btoa(JSON.stringify({ company_id: this.props.company.id, provider: provider }))

        path += `&state=${encodedState}`;

        this.oauthWindowInstance = window.open(path, `Authorize ${provider} to integrate with AquaSuite`, 'location=0,status=0,width=800,height=800');

        this.oauthWindowInterval = setInterval(() => {
            $.ajax({
                method: "GET",
                url: "/company"
            }).done((res) => {
                const integration = res["invoice_integration"];
                if (integration !== this.state.integration || this.oauthWindowInstance.closed) clearInterval(this.oauthWindowInterval);

                this.setState({ integration })
            });
        }, 3000);
    };

    totalCcRate = () => {
        const aquaBiller = this.props.company.aquabiller_rate * 100
        const stripeCcRate = this.props.company.stripe_cc_rate * 100;

        return `${(aquaBiller + stripeCcRate).toFixed(2)}%`;
    };

    render() {
        return(
            <div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="panel panel-default">
                            <div className="panel-body display-flex flex-column justify-content-center align-items-center">
                                <img
                                    src="https://s3.amazonaws.com/superswimbros.dev/aquabiller.png"
                                    className="aquabiller-img display-block"
                                    alt="Enable AquaBiller"
                                />

                                <div className="width-100">
                                    <hr/>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="display-flex align-items-center flex-column">
                                            <div className="display-flex biller-detail-section">
                                                <div>
                                                    <i className="fa fa-check-circle color-green font-2em margin-10-right"></i>
                                                </div>
                                                <div className="display-flex align-items-center">
                                                    <h5 className="no-margin text-center">
                                                        Accept Credit Cards
                                                    </h5>
                                                </div>
                                            </div>
                                            <div className="display-flex align-items-center">
                                                <div>
                                                    <i className="fa fa-check-circle color-green font-2em margin-5-right"></i>
                                                </div>
                                                <div className="display-flex align-items-center">
                                                    <h5 className="no-margin text-center">
                                                        { this.totalCcRate() } + $.30 Transaction Fee
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="display-flex align-items-center flex-column">
                                            <div className="display-flex biller-detail-section">
                                                <div>
                                                    <i className="fa fa-check-circle color-green font-2em margin-10-right"></i>
                                                </div>
                                                <div className="display-flex align-items-center">
                                                    <h5 className="no-margin text-center">
                                                        Unlock <a href="http://help.useaquasuite.com/en/articles/3256628-income-expense-report" target="_blank">Income/Expense Report</a>
                                                    </h5>
                                                </div>
                                            </div>
                                            <div className="display-flex align-items-center">
                                                <div>
                                                    <i className="fa fa-check-circle color-green font-2em margin-5-right"></i>
                                                </div>
                                                <div className="display-flex align-items-center">
                                                    <h5 className="no-margin text-center">
                                                        Send Invoices via Email and Text Message
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="width-100 text-center margin-20-top">
                                    <small>
                                        Credit Card transaction rates may vary based on Credit Card Company and/or rate(s) with Credit Card Processor - for a complete breakdown of fee schedule contact Support
                                    </small>
                                </div>

                                <div className="width-100">
                                    <hr/>
                                </div>

                                { this.state.integration !== "stripe" &&
                                    <img
                                        className="stripe-connect cursor-pointer"
                                        src="https://s3.amazonaws.com/superswimbros.dev/stripe-connect-button.png"
                                        alt="Connect With Stripe"
                                        onClick={() => this.oauthWindow("stripe")}
                                    />
                                }

                                { this.state.integration == "stripe" &&
                                    <div className="width-100 display-flex justify-content-center flex-column">
                                        <h3 className="no-margin-top text-center">
                                            <span className="label label-success">
                                                <i className="fa fa-check margin-5-right"></i>
                                                Connected
                                            </span>
                                        </h3>
                                        <div className="width-100">
                                            <hr/>
                                        </div>
                                        <div className="display-flex justify-content-center flex-column">
                                            <DefaultTaxRates settings={this.props.company_settings} />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <h4
                    className="cursor-pointer"
                    data-toggle="collapse"
                    href="#other-integrations"
                    onClick={(e) => $(this.chevronEl).toggleClass("rotate-right")}
                >
                    <span className="glyphicon ease-transition glyphicon-chevron-down rotate-right" ref={(e) => this.chevronEl = e}></span>
                    Other Integrations:
                </h4>
                <div className="row collapse" id="other-integrations">
                    <div className="col-xs-12">
                        <div className="row">
                            <div className="col-md-4 display-flex flex-column align-items-center">
                                <img
                                    onClick={(e) => { this.oauthWindow("freshbooks") }}
                                    className="integration img-responsive"
                                    src="https://s3.amazonaws.com/superswimbros.dev/site_images/freshbooks-integration.png"
                                    alt="Enable Freshbooks Invoice Integration"
                                />
                                { this.state.integration == "freshbooks"  &&
                                    <div className="margin-10-top">
                                        <h4 className="no-margin-top">
                                            <span className="label label-success">
                                                <i className="fa fa-check margin-5-right"></i>
                                                Connected
                                            </span>
                                        </h4>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="row margin-30-top">
                            <div className="col-md-4 display-flex flex-column align-items-center">
                                <img
                                    onClick={(e) => { this.oauthWindow("quickbooks") }}
                                    className="integration img-responsive"
                                    src="https://s3.amazonaws.com/superswimbros.dev/site_images/intuit-integration.png"
                                    alt="Enable Quickbooks Online Invoice Integration"
                                />
                                { this.state.integration == "quickbooks"  &&
                                    <div className="margin-10-top">
                                        <h4 className="no-margin-top">
                                            <span className="label label-success">
                                                <i className="fa fa-check margin-5-right"></i>
                                                Connected
                                            </span>
                                        </h4>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
