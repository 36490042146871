import React, { Fragment, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { toast } from 'react-toastify';

const RouteTechStartingPoint = (props) => {
    const geocodeClient = require('@mapbox/mapbox-sdk/services/geocoding');
    const geocodeService = geocodeClient({ accessToken: props.mapboxApiKey });

    const [editing, setEditing] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [geocodeQuery, setGeocodeQuery] = useState(false);
    const [geocoding, setGeocoding] = useState(false);
    const [geocodeResults, setGeocodeResults] = useState([]);

    const geocode = (e) => {
        setGeocoding(false);
        setGeocodeQuery(e.target.value.toString());

        setGeocoding(true);
        setGeocodeResults([]);

        try {
            geocodeService.forwardGeocode({
                query: geocodeQuery
            }).send().then(res => {
                if (res.statusCode === 200) {
                    setGeocodeResults(res.body.features);
                } else {
                    toast.error("Unable to find location...", {
                        position: toast.POSITION.TOP_CENTER
                    })

                    setGeocodeResults([]);
                }

                setGeocoding(false);
            });
        } catch (e) {
            if (e.message !== "query must be a string.") {
                throw(e);
            }
        }
    };

    const updateStartingPoint = (e, result) => {
        e.preventDefault();

        const startingPoint = { location: result.place_name, longitude: result.center[0], latitude: result.center[1] };

        setUpdating(true);
        $.ajax({
            method: "PUT",
            url: `/users/${props.tech.id}.json`,
            data: { user: { [`${props.dayName}_route_starting_point`]: { ...startingPoint } }, non_invite: true }
        }).done(() => {
            toast.success("Successfully updated Tech's Starting Point", {
                position: toast.POSITION.TOP_CENTER
            });

            setEditing(false);
            if (props.updateCallback) props.updateCallback(props.tech.id, props.dayName, startingPoint);
        }).fail(() => {
            toast.error("Unable to update Tech's Starting Point...", {
                position: toast.POSITION.TOP_CENTER
            });
        }).always(() => {
            setUpdating(false);
        })
    };

    const toggle = () => {
        props.toggleCallback(props.tech, props.dayName, props.startingPoint);
    };

    return(
        <div className="panel panel-default margin-10-bottom">
            <div className="panel-body width-100">
                <h4 className="color-gray text-center no-margin-top">
                    Starting Point:
                </h4>
                <div className="display-flex justify-content-space-between width-100">
                    { !editing &&
                        <Fragment>
                            <h5 className="no-margin-bottom margin-10-right">
                                { props.startingPoint.location }
                            </h5>
                            { props.updateCallback &&
                                <div className="margin-10-top">
                                    <button className="btn btn-default" onClick={() => setEditing(true)}>
                                        <i className="fa fa-pencil"></i>
                                    </button>
                                </div>
                            }
                        </Fragment>
                    }
                    { editing &&
                        <Fragment>
                            <div className="form-group width-100 margin-10-right no-margin-bottom margin-10-top">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter address"
                                    onChange={geocode}
                                />
                            </div>
                            <div className="starting-point-buttons display-flex margin-10-top">
                                <button className="btn btn-default" onClick={() => setEditing(false)}>
                                    <i className="fa fa-times"></i>
                                </button>
                            </div>
                        </Fragment>
                    }
                </div>
                { (!editing && props.toggleCallback) &&
                    <div className="display-flex justify-content-center margin-10-top">
                        <input
                            type="checkbox"
                            id={`${props.tech.id}-${props.dayName}-starting-point`}
                            onChange={toggle}
                            checked={(props.startingPointMarker && props.startingPointMarker.aqsUserId === props.tech.id && props.startingPointMarker.aqsDayName === props.dayName) || false}
                        />
                        <label className="margin-5-left" htmlFor={`${props.tech.id}-${props.dayName}-starting-point`}>
                            Show on Map
                        </label>
                    </div>
                }
                { editing &&
                    <div className="margin-20-top">
                        { !updating &&
                            <Fragment>
                                { geocodeQuery.length > 0 ?
                                    <Fragment>
                                        <h5 className="text-center">
                                            Select Starting Point:
                                        </h5>
                                        <div className="geocode-results-wrapper">
                                            {
                                                geocodeResults.map((result, index) => (
                                                    <a
                                                        key={index}
                                                        href="#"
                                                        className="geocode-result"
                                                        onClick={(e) => updateStartingPoint(e, result)}
                                                    >
                                                        { result.place_name }
                                                    </a>
                                                ))
                                            }
                                        </div>
                                    </Fragment>
                                :
                                    <Fragment>
                                        <h5 className="text-center">
                                            Search for a valid location
                                        </h5>
                                    </Fragment>
                                }
                            </Fragment>
                        }
                        { updating &&
                            <div className="display-flex flex-column align-items-center">
                                <h5 className="text-center no-margin-top">
                                    Updating Starting Point...
                                </h5>
                                <i className="fa fa-spinner fa-pulse fa-2x fa-fw align-self-center flex-1"></i>
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    )
};

RouteTechStartingPoint.propTypes = {
    tech: PropTypes.object.isRequired,
    startingPoint: PropTypes.object.isRequired,
    dayName: PropTypes.string.isRequired,
    mapboxApiKey: PropTypes.string.isRequired,
    updateCallback: PropTypes.func,
    toggleCallback: PropTypes.func,
    startingPointMarker: PropTypes.object
};

export default RouteTechStartingPoint;
