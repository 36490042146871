import React from 'react';
import PropTypes from 'prop-types';

import Utils from 'Utils';
import { toast } from 'react-toastify';

export default class Payout extends React.Component {
    static propTypes = {
        timezone: PropTypes.string.isRequired
    }

    constructor(props) {
        super(props);

        this.baseState = {
            loading: false,
            amount: null,
            date: null,
        }

        this.state = this.baseState;
    }

    componentDidMount() {
        this.setState({ loading: true });

        $.ajax({
            method: "GET",
            url: "/reports/income/payout"
        }).done((res) => {
            if (res) {
                this.setState({ amount: (res.amount / 100), date: res.arrival_date })
            }
        }).fail(() => {
            toast.error("Unable to fetch Payout info, please try again later...", {
                position: toast.POSITION.TOP_CENTER
            })
        }).always(() => {
            this.setState({ loading: false });
        });
    }

    render() {
        return(
            <div className="payout-wrapper display-flex justify-content-space-around">
                <div>
                    <h4 className="text-center no-margin">
                        Next Payout Amount:
                    </h4>
                    <h2 className="text-center color-gray">
                        { this.state.loading &&
                            <div className="display-flex flex-column">
                                <i className="fa fa-spinner fa-pulse fa-2x fa-fw align-self-center flex-1"></i>
                            </div>
                        }
                        { !this.state.loading && Utils.floatToCurrency(parseFloat(this.state.amount || 0.0)) }
                    </h2>
                </div>
                <div>
                    <h4 className="text-center">
                        Next Estimated Payout Arrival Date:
                    </h4>
                    <h2 className="text-center color-gray">
                        { this.state.loading &&
                            <div className="display-flex flex-column">
                                <i className="fa fa-spinner fa-pulse fa-2x fa-fw align-self-center flex-1"></i>
                            </div>
                        }
                        { !this.state.loading && !this.state.date && "--" }
                        { !this.state.loading && this.state.date && moment.unix(this.state.date).utc().tz(this.props.timezone).format("MM/DD/YYYY") }
                    </h2>
                </div>
            </div>
        )
    }
}
