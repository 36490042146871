import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import InvoiceAction from './InvoiceAction';

import { toast } from 'react-toastify';
import Utils from '../../Utils';

export default class InvoiceIndex extends React.Component {
    static propTypes = {
        integration: PropTypes.string,
        timezone: PropTypes.string.isRequired,
        company: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            start: moment().subtract(30, "days").format("MM/DD/YYYY"),
            end: moment().format("MM/DD/YYYY"),
            invoices: [],
            selectedInvoices: [],
            allSelected: false,
            selectedStatus: "draft",
            invoiceAction: "",
            filters: {}
        }
    }

    componentDidMount() {
        $(".datetime-picker").datetimepicker({
            format: "MM/DD/YYYY",
            ignoreReadonly: true,
            allowInputToggle: true,
            showClose: true,
            widgetPositioning: {
                horizontal: "left",
                vertical: "bottom"
            },
            icons: {
                time: "glyphicon dt-picker-icon-time",
                close: "glyphicon dt-picker-icon-close",
                date: "glyphicon dt-picker-icon-date"
            }
        });

        $(".datetime-picker").on("dp.change", (e) => {
            const date = moment(e.target.value, "MM/DD/YYYY").format("MM/DD/YYYY");
            const name = e.target.name;

            if (this.state[name] !== date) {
                this.setState({ [name]: date }, () => {
                    this.fetchInvoices();
                });
            }
        });

        this.startInput.value = this.state.start;
        this.endInput.value = this.state.end;

        this.fetchInvoices();
    }

    fetchInvoices = () => {
        this.setState({ loading: true });

        $.ajax("/invoices.json", {
            method: "GET",
            data: {
                q: {
                    created_at_gteq: moment(this.state.start, "MM/DD/YYYY").startOf("day").utc().format(),
                    created_at_lteq: moment(this.state.end, "MM/DD/YYYY").endOf("day").utc().format()
                }
            }
        }).done((invoices) => {
            this.setState({ invoices, loading: false });
        });
    };

    filterInvoices = (status) => {
        this.setState({ selectedInvoices: [], selectedStatus: status, allSelected: false }, () => {
            setTimeout(() => {
                $('[data-toggle="tooltip"]').tooltip();
            });
        });
    };

    searchInvoices = (e) => {
        let term;
        if (e) {
            term = e.target.value;
        }

        let displayableInvoices;
        if (term && term.length > 0) {
            displayableInvoices = this.filteredInvoices().filter(invoice => {
                return invoice.account.contact_name.toLowerCase().search(term.toLowerCase()) !== -1;
            });
        }

        if (this.state.filters.automaticPayments) {
            displayableInvoices = (displayableInvoices || this.filteredInvoices()).filter(invoice => {
                return this.state.filters.automaticPayments === "enabled" ? invoice.account.automatic_payments : !invoice.account.automatic_payments;
            });
        }

        if (this.state.filters.invoiceDelivery) {
            displayableInvoices = (displayableInvoices || this.filteredInvoices()).filter(invoice => {
                return invoice.account.invoice_delivery === this.state.filters.invoiceDelivery;
            });
        }

        this.setState({ displayableInvoices, selectedInvoices: [], allSelected: false });
    };

    resetFilters = () => {
        this.setState({ filters: {} }, () => {
            this.searchInvoices();
        });
    };

    grandTotal = (invoices) => {
        let total = 0;

        if (invoices.length === 1) {
            return invoices[0].total_amount
        } else if (invoices.length > 1) {
            const amounts = invoices.map(i => i.total_amount);
            total = amounts.reduce((a, b) => a + b);
        }

        return total;
    };

    filteredInvoices = (filter = this.state.selectedStatus) => {
        if (this.state.invoices) {
            const now = moment.tz(this.props.timezone);

            if (filter === "past_due") {
                return this.state.invoices.filter(invoice => {
                    const dueDate = moment(invoice.due_date, "MM-DD-YYYY").tz(this.props.timezone);
                    return invoice.status === "billed" && now > dueDate;
                });
            } else if (filter === "billed") {
                return this.state.invoices.filter(invoice => {
                    const dueDate = moment(invoice.due_date, "MM-DD-YYYY").tz(this.props.timezone);
                    return invoice.status === "billed" && now <= dueDate;
                });
            } else {
                return this.state.invoices.filter(invoice => invoice.status === filter);
            }
        } else {
            return [];
        }
    };

    renderFilters = () => {
        const statuses = ["draft", "billed", "past_due", "partial_payment", "paid", "processing", "failed_to_process"];
        return(
            <div className="status-wrapper display-flex overflow-x-scroll">
                { statuses.map((status, index) => {
                    const klass = this.state.selectedStatus === status ? "status active" : "status"
                    return(
                        <div
                            key={index}
                            className={`${klass}`}
                            onClick={() => this.filterInvoices(status)}
                        >
                            <h4 className="text-center">
                                { Utils.humanize(status) }
                            </h4>
                            <h3>
                                { this.filteredInvoices(status).length }
                            </h3>
                            <h5 className="text-center">
                                { Utils.floatToCurrency(this.grandTotal(this.filteredInvoices(status))) }
                            </h5>
                        </div>
                    )
                }) }
            </div>
        )
    };

    renderInvoiceRows = () => {
        const invoices = this.state.displayableInvoices || this.filteredInvoices();

        return invoices.map((invoice, index) => {
            let typeClass;
            if (invoice.type === "service_stops") {
                typeClass = "label-primary";
            } else if (invoice.type === "repairs") {
                typeClass = "label-warning"
            }

            return(
                <tr key={index}>
                    <td>
                        <input
                            type="checkbox"
                            onChange={() => { this.setSelected(false, invoice.id) }}
                            checked={this.state.selectedInvoices.includes(invoice.id)}
                        />
                    </td>
                    <td>
                        <a href={`/invoices/${invoice.id}`}>
                            { invoice.invoice_number }
                        </a>
                    </td>
                    <td>
                        { invoice.date_created }
                    </td>
                    <td>
                        { invoice.due_date }
                    </td>
                    <td>
                        <a href={`/accounts/${invoice.account.id}`}>
                            { invoice.account.contact_name }
                        </a>
                    </td>
                    <td>
                        <span className={`label ${typeClass}`}>
                            { Utils.humanize(invoice.type) }
                        </span>
                    </td>
                    <td>
                        { Utils.floatToCurrency(invoice.total_amount) }
                    </td>
                    <td>
                        { Utils.humanize(invoice.status) }
                    </td>
                    { (this.state.selectedStatus === "billed" || this.state.selectedStatus === "past_due") &&
                        <Fragment>
                            <td>
                                { invoice.delivered &&
                                    Object.keys(invoice.delivered).map((key, index) => {
                                        return(
                                            <span key={index} className="label label-default">
                                                { Utils.humanize(key) }
                                            </span>
                                        )
                                    }).reduce((prev, curr) => [prev, " / ", curr])
                                }
                                { !invoice.delivered &&
                                    "--"
                                }
                            </td>
                            <td>
                                { invoice.delivered &&
                                    Object.keys(invoice.delivered).map((key, index) => {
                                        if (invoice.delivered[key].error_message) {
                                            return(
                                                <i
                                                    key={index}
                                                    className="fa fa-exclamation-triangle color-warning cursor-pointer"
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title={`${Utils.humanize(key)}:  ${invoice.delivered[key].error_message}`}
                                                ></i>
                                            )
                                        } else if (invoice.delivered[key].date) {
                                            return(
                                                <span key={index} className="label label-success">
                                                    { moment(invoice.delivered[key].date).tz(this.props.timezone).format("MM-DD-YYYY hh:mm A") }
                                                </span>
                                            )
                                        } else {
                                            return "--"
                                        }
                                    }).reduce((prev, curr) => [prev, " / ", curr])
                                }
                                { !invoice.delivered &&
                                    "--"
                                }
                            </td>
                        </Fragment>
                    }
                    { this.props.integration === "stripe" &&
                        <Fragment>
                            <td>
                                { invoice.account.invoice_delivery && Utils.humanize(invoice.account.invoice_delivery) }
                                { !invoice.account.invoice_delivery && "--" }
                            </td>
                            <td>
                                { invoice.account.automatic_payments &&
                                    <span className="glyphicon glyphicon-ok"></span>
                                }
                                { !invoice.account.automatic_payments &&
                                    <span className="glyphicon glyphicon-remove"></span>
                                }
                            </td>
                        </Fragment>
                    }
                </tr>
            )
        });
    };

    noInvoicesToShow = () => {
        if (this.state.displayableInvoices) {
            return this.state.displayableInvoices.length === 0
        } else {
            return this.filteredInvoices(this.state.selectselectedStatus).length === 0
        }
    };

    setSelected = (all = false, id) => {
        let selectedInvoices = [], allSelected = false;

        if (all) {
            if (this.state.displayableInvoices && this.state.displayableInvoices.length > 0) {
                if (this.state.displayableInvoices.length !== this.state.selectedInvoices.length) {
                    selectedInvoices = this.state.displayableInvoices.map(i => i.id);
                    allSelected = true;
                }
            } else {
                if (this.state.selectedInvoices.length !== this.filteredInvoices().length) {
                    selectedInvoices = this.filteredInvoices().map(i => i.id);
                    allSelected = true;
                }
            }
        } else {
            if (this.state.selectedInvoices.find(i => i == id)) {
                selectedInvoices = this.state.selectedInvoices.filter(_id => _id !== id)
            } else {
                selectedInvoices = [...this.state.selectedInvoices, id]
            }
        }

        this.setState({ selectedInvoices, allSelected });
    };

    openInvoiceAction = (e, invoiceAction) => {
        e.preventDefault();

        this.setState({ invoiceAction }, () => {
            $("#invoice-action-modal").modal("show");
        });
    };

    updateInvoices = (_invoices) => {
        const ids = _invoices.map(i => i.id);
        const otherInvoices = this.state.invoices.filter(i => !ids.includes(i.id));
        const invoices = _invoices.concat(otherInvoices);

        this.setState({ invoices, displayableInvoices: null })
    };

    destroyInvoices = (ids) => {
        const invoices = this.state.invoices.filter(i => !ids.includes(i.id));

        this.setState({ invoices, selectedInvoices: [] });
    };

    canDeliverInvoices = () => {
        if (this.props.integration === "stripe") {
            return true
        } else {
            return (this.state.selectedStatus === "billed" || this.state.selectedStatus === "past_due") && (this.props.integration)
        }
    };

    canProcessAutoPayments = () => {
        return this.state.selectedInvoices.every(id => {
            const invoice = this.state.invoices.find(i => i.id === id);
            return invoice.account.automatic_payments;
        });
    };

    processAutoPayments = (e) => {
        e.preventDefault();

        if (this.canProcessAutoPayments()) {
            this.setState({ invoiceAction: "process" }, () => {
                $("#invoice-action-modal").modal("show");
            });
        } else {
            toast.error("Not all selected Invoices have Automatic Payments Enabled", {
                position: toast.POSITION.TOP_CENTER
            });
        }
    };

    render() {
        return(
            <div className="invoice-index-wrapper">
                <div className="row page-header no-margin-top">
                    <div className="col-md-12">
                        <h1>
                            Invoices
                        </h1>
                    </div>
                </div>
                <div className="row page-header no-margin-top">
                    <div className="col-md-12">
                        <h4 className="no-margin-top">
                            <i className="fa fa-filter margin-5-right"></i>
                            Date Created:
                        </h4>
                    </div>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-3 col-sm-4">
                                <div className="form-group">
                                    <label>Start:</label>
                                    <input
                                        name="start"
                                        ref={(input) => this.startInput = input}
                                        type="text"
                                        readOnly={true}
                                        className="form-control datetime-picker"
                                    />
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-4">
                                <div className="form-group">
                                    <label>End:</label>
                                    <input
                                        name="end"
                                        ref={(input) => this.endInput = input}
                                        type="text"
                                        readOnly={true}
                                        className="form-control datetime-picker"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row page-header no-margin-top">
                    <div className="col-md-12">
                        { this.renderFilters() }
                    </div>
                </div>
                <div className="row margin-10-bottom">
                    <div className="col-xs-7 col-md-4">
                        <div className="form-group search no-margin-bottom margin-8-top position-relative">
                            <input
                                type="search"
                                className="form-control"
                                placeholder="Contact Name"
                                onChange={(e) => this.searchInvoices(e)}
                            />
                            <i className="fa fa-search"></i>
                        </div>
                        { this.props.integration === "stripe" &&
                            <Fragment>
                                <div className="row margin-10-top">
                                    <div className="col-md-12">
                                        <h4
                                            className="cursor-pointer"
                                            data-toggle="collapse"
                                            href="#filter"
                                            onClick={(e) => $(this.filterChevronEl).toggleClass("rotate-right")}
                                        >
                                            <span className="glyphicon glyphicon-chevron-down rotate-right ease-transition" ref={(e) => this.filterChevronEl = e}></span>
                                            Filter
                                        </h4>
                                    </div>
                                </div>
                                <div className="row collapse" id="filter">
                                    <div className="col-md-12">
                                        <div className="margin-20-left">
                                            <div className="form-group">
                                                <label>Automatic Payments:</label>
                                                <select
                                                    className="form-control"
                                                    onChange={(e) => this.setState({ filters: { ...this.state.filters, automaticPayments: e.target.value } })}
                                                    value={this.state.filters.automaticPayments || ""}
                                                >
                                                    <option value="">Select option...</option>
                                                    <option value="enabled">Enabled</option>
                                                    <option value="disabled">Disabled</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label>Invoice Delivery Method:</label>
                                                <select
                                                    className="form-control"
                                                    onChange={(e) => this.setState({ filters: { ...this.state.filters, invoiceDelivery: e.target.value } })}
                                                    value={this.state.filters.invoiceDelivery || ""}
                                                >
                                                    <option value="">Select option...</option>
                                                    <option value="email">Email</option>
                                                    <option value="text message">Text Message</option>
                                                    <option value="snail mail">Snail Mail</option>
                                                    <option value="in person">In Person</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <button className="btn btn-success margin-10-right" onClick={this.searchInvoices}>
                                                    <i className="fa fa-filter margin-5-right"></i>
                                                    Filter
                                                </button>
                                                <button className="btn btn-danger" onClick={this.resetFilters}>
                                                    <i className="fa fa-refresh margin-5-right"></i>
                                                    Reset
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                        }
                    </div>
                    <div className="col-xs-5 pull-right">
                        <div className="dropdown pull-right">
                            <button
                                className="btn btn-lg btn-success dropdown-toggle actions-dropdown"
                                type="button"
                                data-toggle="dropdown"
                                disabled={this.state.selectedInvoices.length === 0}
                            >
                                Actions
                                <span className="glyphicon glyphicon-chevron-down"></span>
                            </button>
                            <ul className="dropdown-menu">
                                { this.state.selectedStatus !== "processing" &&
                                    <li>
                                        <a
                                            href="#"
                                            onClick={(e) => this.openInvoiceAction(e, "destroy")}
                                            className="text-center padding-10"
                                        >
                                            <span className="glyphicon glyphicon-trash"></span>
                                            &nbsp;
                                            Destroy
                                        </a>
                                    </li>
                                }
                                { (this.state.selectedStatus === "draft" || this.state.selectedStatus === "failed_to_process" || this.state.selectedStatus === "processing") && (this.props.integration && this.props.integration !== "stripe") &&
                                    <li>
                                        <a
                                            href="#"
                                            className="text-center padding-10"
                                            onClick={(e) => this.openInvoiceAction(e, "sync")}
                                        >
                                            <span className="fa fa-refresh"></span>
                                            &nbsp;
                                            Sync
                                        </a>
                                    </li>
                                }
                                { ((this.state.selectedStatus === "draft" || this.state.selectedStatus === "failed_to_process" || this.state.selectedStatus === "past_due") && this.props.integration === "stripe") &&
                                    <li>
                                        <a
                                            href="#"
                                            className="text-center padding-10"
                                            onClick={this.processAutoPayments}
                                            ref={(e) => this.processAutoPaymentsEl = e}
                                        >
                                            <i className="fa fa-cogs"></i>
                                            &nbsp;
                                            Process Automatic Payments
                                        </a>
                                    </li>
                                }
                                { this.canDeliverInvoices() &&
                                    <li>
                                        <a
                                            href="#"
                                            className="text-center padding-10"
                                            onClick={(e) => this.openInvoiceAction(e, "deliver")}
                                        >
                                            <i className="fa fa-paper-plane"></i>
                                            &nbsp;
                                            Deliver Invoices
                                        </a>
                                    </li>
                                }
                                { this.props.integration === "stripe" &&
                                    <li>
                                        <a
                                            href="#"
                                            className="text-center padding-10"
                                            onClick={(e) => this.openInvoiceAction(e, "pdfs")}
                                        >
                                            <i className="fa fa-file-pdf-o"></i>
                                            &nbsp;
                                            Print Invoices
                                        </a>
                                    </li>
                                }
                            </ul>
                        </div>
                    </div>
                </div>

                <hr/>

                { this.state.loading &&
                    <div className="display-flex">
                        <i className="fa fa-spinner fa-pulse fa-3x fa-fw margin-auto"></i>
                    </div>
                }
                { !this.state.loading &&
                    <div className="row">
                        <div className="col-md-12">
                            { this.filteredInvoices(this.state.selectselectedStatus).length > 0 &&
                                <div className="table-responsive">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <td>
                                                    <input
                                                        type="checkbox"
                                                        onChange={() => { this.setSelected(true) }}
                                                        checked={this.state.allSelected}
                                                    />
                                                </td>
                                                <td>
                                                    Invoice #:
                                                </td>
                                                <td>
                                                    Date Created:
                                                </td>
                                                <td>
                                                    Due Date:
                                                </td>
                                                <td>
                                                    Contact Name:
                                                </td>
                                                <td>
                                                    Invoice Type:
                                                </td>
                                                <td>
                                                    Total Amount:
                                                </td>
                                                <td>
                                                    Status:
                                                </td>
                                                { (this.state.selectedStatus === "billed" || this.state.selectedStatus === "past_due") &&
                                                    <Fragment>
                                                        <td>
                                                            Delivered Via:
                                                        </td>
                                                        <td>
                                                            Delivered On:
                                                        </td>
                                                    </Fragment>
                                                }
                                                { this.props.integration === "stripe" &&
                                                    <Fragment>
                                                        <td>
                                                            Preferred Delivery Method:
                                                        </td>
                                                        <td>
                                                            Automatic Payment Enabled:
                                                        </td>
                                                    </Fragment>
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { this.renderInvoiceRows() }
                                        </tbody>
                                    </table>
                                </div>
                            }
                            { !this.state.loading && this.noInvoicesToShow() &&
                                <h4 className="text-center">
                                    No { `${Utils.humanize(this.state.selectedStatus)}` } Invoices to show
                                </h4>
                            }
                        </div>
                    </div>
                }
                <InvoiceAction
                    action={this.state.invoiceAction}
                    invoices={this.state.invoices.filter(i => this.state.selectedInvoices.includes(i.id))}
                    updateInvoices={this.updateInvoices}
                    destroyInvoices={this.destroyInvoices}
                    integration={this.props.integration}
                    start={this.state.start}
                    end={this.state.end}
                    company={this.props.company}
                />
            </div>
        )
    }
 }
