import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Helper from "../../RouteReminders/Helper";

export default class Reminders extends React.Component {
    static propTypes = {
        serviceTechs: PropTypes.array.isRequired,
        timezone: PropTypes.string.isRequired
    }

    constructor(props) {
        super(props);

        this.baseState = {
            selectedTech: null,
            selectedDate: null,
            loading: null,
            reminders: null,
            appointments: null
        }

        this.state = this.baseState;
    }

    fetchReminders = () => {
        this.setState({ loading: true });

        const start = moment.tz(this.state.selectedDate, this.props.timezone).startOf("day").utc().format();
        const end = moment.tz(this.state.selectedDate, this.props.timezone).endOf("day").utc().format();

        $.ajax({
            url: "/appointments.json",
            method: "GET",
            data: {
                q: {
                    user_id_eq: this.state.selectedTech,
                    start_gt_eq: start,
                    end_lt_eq: end,
                    type_eq: "service_stop",
                    completed_null: true
                }
            }
        }).done(appointments => {
            const accountIds = appointments.map(appt => appt.account.id);

            // prevent empty array from returning all reminders in search
            const data = accountIds.length > 0 ? { q: { account_id_in: accountIds } } : { q: { account_id_false: true } };

            this.setState({ appointments });

            $.ajax({
                url: "/route_reminders/scheduled.json",
                method: "GET",
                data
            }).done(_reminders => {
                const reminders = _reminders.filter(reminder => {
                    const selectedDate = moment(this.state.selectedDate).tz(this.props.timezone).format("MM-DD-YYYY");

                    return (Helper.nextReminder(reminder) === selectedDate || (Helper.nextReminder(reminder) === "Next Service Stop" && this.hasAppointmentOnDate(reminder, selectedDate)));
                });
                this.setState({ reminders, loading: false });
            });
        })
    };

    hasAppointmentOnDate = (reminder, date) => {
        const appointments = this.state.appointments.filter(appt => appt.account_id === reminder.account_id);

        return appointments.some(appt => moment(appt.start).tz(this.props.timezone).format("MM-DD-YYYY") === date);
    };

    setSelectedDate = (date) => {
        this.setState({ selectedDate: date }, () => {
            this.fetchReminders();
        });
    };

    renderTechs = () => {
        return this.props.serviceTechs.map((tech, index) => {
            return(
                <option key={index} value={tech.id}>
                    { tech.name }
                </option>
            )
        });
    };

    renderDays = () => {
        const days = [0, 1, 2, 3, 4, 5, 6];
        const today = moment.tz(this.props.timezone).day();

        return days.map((day, index) => {
            let timestamp;

            if (today > day) {
                let target = day + 7;
                timestamp = moment.tz(this.props.timezone).day(target);
            } else {
                timestamp = moment.tz(this.props.timezone).day(day)
            }

            const parsedDate = moment(timestamp).tz(this.props.timezone).format("YYYY-MM-DD");

            return(
                <button
                    key={index}
                    className={`margin-5-right btn ${this.state.selectedDate === parsedDate ? "btn-primary" : "btn-default"}`}
                    onClick={() => this.setSelectedDate(parsedDate) }
                    disabled={!this.state.selectedTech || this.state.selectedTech === ""}
                >
                    { moment(timestamp).tz(this.props.timezone).format("dddd - MM-DD-YYYY") }
                </button>
            )
        });
    }

    render() {
        return(
            <Fragment>
                <div className="row">
                    <div className="form-group col-md-4 col-sm-4">
                        <label htmlFor="tech">
                            Technician:
                        </label>
                        <select className="form-control" onChange={(e) => this.setState({ selectedTech: e.target.value, selectedDate: null })}>
                            <option value="">Select tech...</option>
                            { this.renderTechs() }
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <label>Day:</label>
                        <div className="display-flex overflow-x-scroll padding-10-bottom">
                            { this.renderDays() }
                        </div>
                    </div>
                </div>

                <hr/>

                { !this.state.loading && this.state.reminders === null &&
                    <div className="row">
                        <div className="col-md-12">
                            <h4 className="text-center">
                                Show route reminders for all accounts on selected day
                            </h4>
                        </div>
                    </div>
                }

                { this.state.loading &&
                    <div className="display-flex flex-column justify-content-center align-items-center height-100 margin-10-bottom">
                        <div>
                            <i className="fa fa-spinner fa-pulse fa-3x fa-fw align-self-center flex-1"></i>
                        </div>
                    </div>
                }

                { !this.state.loading && this.state.reminders && this.state.reminders.length > 0 &&
                    <div className="row">
                        <div className="col-md-12">
                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <td>
                                                Contact name:
                                            </td>
                                            <td>
                                                Description:
                                            </td>
                                            <td>
                                                Customer notification:
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.reminders.map((reminder, index) => {
                                                return(
                                                    <tr key={index}>
                                                        <td>
                                                            <a href={`/accounts/${reminder.account_id}`}>
                                                                { reminder.account.contact_name }
                                                            </a>
                                                        </td>
                                                        <td>
                                                            { reminder.description }
                                                        </td>
                                                        <td>
                                                            { reminder.notify &&
                                                                <span className="glyphicon glyphicon-ok"></span>
                                                            }
                                                            { !reminder.notify &&
                                                                <span className="glyphicon glyphicon-remove"></span>
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                }
                { !this.state.loading && this.state.reminders && this.state.reminders.length < 1 &&
                    <div className="row">
                        <div className="col-md-12">
                            <h4 className="text-center">
                                No Route Reminders found for this day
                            </h4>
                        </div>
                    </div>
                }
            </Fragment>
        )
    }
}
