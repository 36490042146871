import React, { useContext, useState, useEffect, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';

import Switch from '@shared/components/CheckboxSwitch';
import ImageArea from '@shared/components/ImageArea/ImageArea';
import Utils from 'Utils';
import AqsModal from 'AqsModal';
import { toast } from 'react-toastify';
import { ShowRepairContext } from '../ShowRepair';

import Task from './Task';
import Appointment from './Appointment';
import SaveTemplate from './SaveTemplate';
import LoadTemplate from './LoadTemplate';
import NotificationSelect from './NotificationSelect';

const RepairItem = (props) => {
    const context = useContext(ShowRepairContext);
    const [repairItem, setRepairItem] = useState(props.repairItem);
    const [editing, setEditing] = useState(!props.repairItem.description);
    const [loading, setLoading] = useState(false);
    const [priceEnabled, setPriceEnabled] = useState(!!(repairItem.quantity && repairItem.price));
    const [validations, setValidations] = useState({
        description: false,
        price: false,
        quantity: false
    });
    const textMessageModal = useRef(null);
    const notificationModal = useRef(null);

    useEffect(() => {
        if (JSON.stringify(props.repairItem) !== JSON.stringify(repairItem)) {
            setRepairItem({ ...props.repairItem })
        }
    }, [props.repairItem]);

    useEffect(() => {
        if (repairItem.price && repairItem.quantity && !priceEnabled) {
            setPriceEnabled(true);
        }
    }, [repairItem]);

    const valid = () => {
        const validations = {
            description: (repairItem.description || "").length < 1,
            price: (priceEnabled && !repairItem.price),
            quantity: (priceEnabled && !repairItem.quantity)
        };

        setValidations(validations);
        return !Object.values(validations).includes(true);
    };

    const saveItem = (e, item = repairItem) => {
        if (e) e.preventDefault();
        if (valid()) {
            setLoading(true);

            const method = item.id ? "PUT" : "POST";

            let url = `/accounts/${context.repair.account_id}/repairs/${context.repair.id}/repair_items`;

            url += repairItem.id ? `/${item.id}.json` : ".json";

            $.ajax({
                method,
                url,
                contentType: "application/json",
                data: JSON.stringify({ repair_item: { ...item } })
            }).done((newRepairItem) => {
                setRepairItem({ ...newRepairItem });
                context.updateRepairItem(newRepairItem, props.index);

                toast.success("Successfully saved Repair Item!", {
                    position: toast.POSITION.TOP_CENTER
                });

                setEditing(false);
            }).fail(() => {
                toast.error("Unable to save Repair Item...", {
                    position: toast.POSITION.TOP_CENTER
                })
            }).always(() => {
                setLoading(false);
            });
        }
    };

    const deleteItem = (e) => {
        e.preventDefault();

        if (confirm("Are you sure you would like to destroy this Repair Item? This cannot be undone.")) {
            if (repairItem.id) {
                $.ajax({
                    method: "DELETE",
                    url: `/accounts/${context.repair.account_id}/repairs/${context.repair.id}/repair_items/${repairItem.id}.json`
                }).done(() => {
                    toast.success("Successfully destroyed Repair Item!", {
                        position: toast.POSITION.TOP_CENTER
                    });
                }).fail(() => {
                    toast.error("Unable to destroy Repair Item...", {
                        position: toast.POSITION.TOP_CENTER
                    })
                });
            }

            removeItemFromRepair();
        }
    };

    const removeItemFromRepair = () => {
        const newRepairItems = context.repair.repair_items.filter((ri, index) => {
            return index !== props.index;
        });

        context.updateRepair({ ...context.repair, repair_items: [...newRepairItems] });
    };

    const togglepriceEnabled = () => {
        if (priceEnabled) {
            const newRepairItem = { ...repairItem, price: null, quantity: null };
            setRepairItem({ ...newRepairItem });
            setPriceEnabled(false);

            const newRepairItems = context.repair.repair_items.filter((ri, index) => {
                if (index === props.index) {
                    return { ...newRepairItem }
                } else {
                    return { ...ri };
                }
            });

            context.updateRepair({ ...context.repair, repair_items: [...newRepairItems] });
        } else {
            setPriceEnabled(true);
        }
    };

    const cancelEditing = (e) => {
        e.preventDefault();

        if (!repairItem.id) {
            removeItemFromRepair();
        } else {
            setRepairItem({ ...props.repairItem });
            setEditing(false);
        }
    };

    const selectTemplate = (template) => {
        const repairItem = { ...template };
        delete repairItem.id;

        setRepairItem(repairItem);
    };

    const showNotificationModal = (e) => {
        e.preventDefault();

        notificationModal.current.show();
    };

    const actions = () => {
        return (
            <div className="display-flex">
                <div className="dropdown">
                    <button
                        className="btn btn-success dropdown-toggle actions-dropdown"
                        type="button"
                        data-toggle="dropdown"
                    >
                        Actions
                        <span className="glyphicon glyphicon-chevron-down"></span>
                    </button>
                    <ul className="dropdown-menu">
                        <li>
                            { !editing &&
                                <Fragment>
                                    <a
                                        href="#"
                                        className="padding-10 text-center"
                                        onClick={(e) => { e.preventDefault(); setEditing(true) }}
                                    >
                                        <span className="glyphicon glyphicon-pencil margin-5-right"></span>
                                        Edit
                                    </a>
                                    <a
                                        href="#"
                                        className="padding-10 text-center"
                                        id={`save-repair-item-template-${props.repairItem.id}`}
                                    >
                                        <i className="fa fa-save margin-5-right"></i>
                                        Save as Template
                                    </a>
                                    { props.repairItem.appointment &&
                                        <a
                                            href="#"
                                            className="padding-10 text-center"
                                            onClick={showNotificationModal}
                                        >
                                            <i className="fa fa-bell margin-5-right"></i>
                                            Send Notification
                                        </a>
                                    }
                                    { !context.repair.invoice_id &&
                                        <a
                                            href="#"
                                            className="padding-10 text-center"
                                            onClick={deleteItem}
                                        >
                                            <i className="fa fa-trash margin-5-right"></i>
                                            Destroy
                                        </a>
                                    }
                                </Fragment>
                            }
                            { editing &&
                                <Fragment>
                                    <a
                                        href="#"
                                        className="padding-10 text-center"
                                        onClick={saveItem}
                                    >
                                        <i className="fa fa-save margin-5-right"></i>
                                        Save Repair Item
                                    </a>
                                    <a
                                        href="#"
                                        id={`load-repair-item-template-${props.index}`}
                                        className="padding-10 text-center"
                                    >
                                        <i className="fa fa-file-text-o margin-5-right"></i>
                                        Load Template
                                    </a>
                                    <a
                                        href="#"
                                        className="padding-10 text-center"
                                        onClick={cancelEditing}
                                    >
                                        <i className="fa fa-times margin-5-right"></i>
                                        Cancel
                                    </a>
                                </Fragment>
                            }
                        </li>
                    </ul>
                </div>
            </div>
        )
    };

    const addTask = (e) => {
        e.preventDefault();
        const riTasks = (repairItem.tasks || []);
        const tasks = [...riTasks, {}];

        setRepairItem({ ...repairItem, tasks });
    };

    const saveTask = (task, index) => {
        const tasks = repairItem.tasks.map((t, _index) => {
            return _index === index ? { ...task } : { ...t };
        });

        const item = { ...repairItem, tasks };
        saveItem(null, item);
    };

    const destroyTask = (index, persist = true) => {
        const tasks = repairItem.tasks.filter((t, _index) => {
            return _index !== index;
        });

        if (persist) {
            const item = { ...repairItem, tasks };
            saveItem(null, item);
        } else {
            setRepairItem({ ...repairItem, tasks });
        }
    };

    const total = () => {
        const price = parseFloat(repairItem.price);
        const quantity = parseFloat(repairItem.quantity);
        const total = price * quantity;

        return isNaN(total) ? "--" : Utils.floatToCurrency(total);
    };

    const toggleSetting = (attr) => {
        const settings = repairItem.settings || {};

        let value;
        if (attr === "text_message") {
            if (settings.text_message) {
                value = false;
            } else {
                value = `This is ${context.currentCompany.name}. Your Repair has just been completed! We have sent you an email with all the details.`;
            }
        } else {
            value = !settings[attr];
        }

        const item = { ...repairItem, settings: { ...settings, [attr]: value } };

        setRepairItem(item);
    };

    const showTextMessageModal = (e) => {
        e.preventDefault();
        textMessageModal.current.show();
    };

    const hideTextMessageModal = () => {
        textMessageModal.current.hide();
    };

    const hideNotificationModal = () => {
        notificationModal.current.hide();
    };

    const settings = (repairItem.settings || {});
    const notificationSettings = {
        include_images: "Include Images",
        include_technician_notes: "Include Technician Notes",
        include_tasks: "Include Tasks"
    }

    if (context.currentCompany.sms_number) {
        notificationSettings.text_message = "Include Text Message";
    }

    const nonNotificationSettings = {
        tasks_required: "Require all tasks to be completed",
        technician_notes_required: "Require Technician Notes",
        image_required: "Require at least 1 Image",
        timestamp_photos: "Timestamp Photo(s)"
    }

    return(
        <div className="panel panel-default repair-item-wrapper">
            <div className="panel-body">
                <div className="display-flex justify-content-space-between">
                    <div className="number-wrapper">
                        <h2 className="color-gray no-margin-top">
                            { props.index + 1 }.)
                        </h2>
                    </div>
                    { loading &&
                        <div>
                            <h4 className="text-center">
                                Saving Repair Item...
                            </h4>
                            <div className="display-flex">
                                <i className="fa fa-spinner fa-pulse fa-2x fa-fw diplay-flex align-self-center flex-1"></i>
                            </div>
                        </div>
                    }
                    { !context.isTech() && actions() }
                </div>

                <hr className="margin-10-top" />

                <div className="body-wrapper">
                    <div className="flex-2 padding-10-right display-flex flex-column">
                        { !editing &&
                            <Fragment>
                                <h5 className="no-margin-top">
                                    Description:
                                </h5>
                                <div>
                                    { repairItem.description }
                                </div>
                                { (!context.isTech() && repairItem.price && repairItem.quantity) &&
                                    <Fragment>
                                        <div className="price-wrapper flex-1 display-flex">
                                            <div className="display-flex align-items-center margin-10-right">
                                                <h5>
                                                    Price:
                                                </h5>
                                                <div className="margin-5-left">
                                                    { Utils.floatToCurrency(parseFloat(repairItem.price)) }
                                                </div>
                                            </div>
                                            <div className="display-flex align-items-center">
                                                <h5>
                                                    Quantity:
                                                </h5>
                                                <div className="margin-5-left">
                                                    { repairItem.quantity }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <h5>
                                                TOTAL: { total() }
                                            </h5>
                                        </div>
                                    </Fragment>
                                }

                                <div className="width-100">
                                    <hr/>
                                </div>

                                <div className="flex-2 display-flex flex-column justify-content-center padding-20-bottom">
                                    <h5 className="margin-20-bottom text-center">
                                        Appointment:
                                    </h5>
                                    <Appointment
                                        index={props.index}
                                        repairItem={repairItem}
                                        appointment={repairItem.appointment}
                                    />
                                </div>
                            </Fragment>
                        }
                        { editing &&
                            <div className="padding-15-right">
                                <div className="form-group">
                                    <label>Description:</label>
                                    <textarea
                                        className="form-control"
                                        onChange={(e) => setRepairItem({ ...repairItem, description: e.target.value })}
                                        value={repairItem.description}
                                    >
                                    </textarea>

                                    { validations.description &&
                                        <div className="validation-wrapper">
                                            <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                                            Valid Description is required
                                        </div>
                                    }
                                </div>
                                { !context.repair.invoice_id &&
                                    <div className="display-flex">
                                        <h5 className="margin-10-right">
                                            Add Price:
                                        </h5>
                                        <Switch
                                            name="priceEnabled"
                                            checked={priceEnabled}
                                            onText="✓"
                                            offText="x"
                                            onChange={togglepriceEnabled}
                                        />
                                    </div>
                                }

                                { (priceEnabled || context.repair.invoice_id) &&
                                    <Fragment>
                                        <div className="form-group margin-10-top">
                                            <label>
                                                Price:
                                            </label>
                                            <div className="input-group">
                                                <div className="input-group-addon">
                                                    <i className="glyphicon glyphicon-usd"></i>
                                                </div>
                                                <input
                                                    name="price"
                                                    type="number"
                                                    min=".01"
                                                    step=".01"
                                                    value={repairItem.price || ""}
                                                    onChange={(e) => setRepairItem({ ...repairItem, price: e.target.value })}
                                                    className="form-control"
                                                />
                                            </div>
                                            { validations.price &&
                                                <div className="validation-wrapper">
                                                    <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                                                    Valid price is required
                                                </div>
                                            }
                                        </div>

                                        <div className="form-group">
                                            <label>
                                                Quantity:
                                            </label>
                                            <input
                                                name="quantity"
                                                type="number"
                                                min=".25"
                                                step=".5"
                                                value={repairItem.quantity || ""}
                                                onChange={(e) => setRepairItem({ ...repairItem, quantity: e.target.value })}
                                                className="form-control"
                                            />
                                            { validations.quantity &&
                                                <div className="validation-wrapper">
                                                    <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                                                    Valid Quantity is required
                                                </div>
                                            }
                                        </div>
                                        <div className="text-center">
                                            <h5>
                                                TOTAL: { total() }
                                            </h5>
                                        </div>
                                    </Fragment>
                                }
                            </div>
                        }
                    </div>
                    { !context.isTech() &&
                        <div className="flex-2 action-wrapper">
                            <h5 className="no-margin-top">
                                Tasks:
                            </h5>

                            <hr/>

                            { (repairItem.tasks || []).length > 0 &&
                                <Fragment>
                                    {
                                        repairItem.tasks.map((task, index) => (
                                            <Task
                                                key={index}
                                                index={index}
                                                task={task}
                                                appointmentCompleted={!!(repairItem.appointment && repairItem.appointment.completed)}
                                                saveTask={saveTask}
                                                destroyTask={destroyTask}
                                            />
                                        ))
                                    }
                                </Fragment>
                            }
                            { !(repairItem.appointment || {}).completed &&
                                <div className="text-center">
                                    { (repairItem.tasks || []).length > 0 &&
                                        <hr/>
                                    }
                                    <a href="#" onClick={addTask}>
                                        + Add Task
                                    </a>
                                </div>
                            }
                            { (repairItem.images || []).length > 0 &&
                                <Fragment>
                                    <hr/>

                                    <h5 className="no-margin-top">
                                        Images:
                                    </h5>

                                    <hr/>

                                    <div className="margin-15-left margin-15-right">
                                        <ImageArea
                                            images={repairItem.images}
                                            resource_name="repair_item"
                                            multiple_allowed={true}
                                            resource_id={repairItem.id}
                                            timestamp_photos={settings.timestamp_photos}
                                        />
                                    </div>
                                </Fragment>
                            }

                            { repairItem.public_notes &&
                                <Fragment>
                                    <hr/>

                                    <h5 className="no-margin-top">
                                        Technician Notes:
                                    </h5>

                                    <hr/>

                                    <div className="well">
                                        { repairItem.public_notes }
                                    </div>
                                </Fragment>
                            }

                            { repairItem.private_notes &&
                                <Fragment>
                                    <hr/>

                                    <h5 className="no-margin-top">
                                        Private Notes:
                                    </h5>

                                    <hr/>

                                    <div className="well">
                                        { repairItem.private_notes }
                                    </div>
                                </Fragment>
                            }
                        </div>
                    }
                </div>

                { !context.isTech() &&
                    <Fragment>
                        <hr/>
                        <h4
                            className="no-margin cursor-pointer"
                            href={`#repair-item-settings-${props.index}`}
                            data-toggle="collapse"
                            onClick={(e) => $(e.target).find("span").toggleClass("rotate-right")}
                        >
                            <span className="glyphicon glyphicon-chevron-down rotate-right"></span>
                            Settings:
                        </h4>
                        <div id={`repair-item-settings-${props.index}`} className="collapse margin-10-top">
                            <hr/>
                            { !editing &&
                                <div className="settings-wrapper">
                                    <div className="display-flex flex-column justify-content-center">
                                        <div className="display-flex">
                                            <h5 className="no-margin">
                                                Send Completed Notification:
                                            </h5>
                                            { settings.notification ?
                                                <span className="glyphicon glyphicon-ok margin-5-left"></span>
                                            :
                                                <span className="glyphicon glyphicon-remove margin-5-left"></span>
                                            }
                                        </div>
                                        { settings.notification &&
                                            <div className="margin-10-left">
                                                {
                                                    Object.keys(notificationSettings).map((setting, index) => (
                                                        <div className="display-flex align-items-center margin-10-top" key={index}>
                                                            <h5 className="no-margin">
                                                                { notificationSettings[setting] }:
                                                            </h5>

                                                            { settings[setting] ?
                                                                <span className="glyphicon glyphicon-ok margin-5-left"></span>
                                                            :
                                                                <span className="glyphicon glyphicon-remove margin-5-left"></span>
                                                            }
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        }
                                    </div>
                                    <div className="display-flex flex-column">
                                        {
                                            Object.keys(nonNotificationSettings).map((key, index) => (
                                                <div className="display-flex" key={index}>
                                                    <h5 className="no-margin-top margin-10-bottom">
                                                        { nonNotificationSettings[key] }:
                                                    </h5>
                                                    { settings[key] ?
                                                        <span className="glyphicon glyphicon-ok margin-5-left"></span>
                                                    :
                                                        <span className="glyphicon glyphicon-remove margin-5-left"></span>
                                                    }
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            }
                            { editing &&
                                <div className="settings-wrapper">
                                    <div className="display-flex flex-column">
                                        <div className="display-flex align-items-center">
                                            <div className="margin-5-right">
                                                <Switch
                                                    name={`repair-item-notification-${props.index}`}
                                                    checked={settings.notification}
                                                    onText="✓"
                                                    offText="x"
                                                    onChange={() => toggleSetting("notification")}
                                                />
                                            </div>
                                            <h5 className="no-margin">
                                                Send Completed Notification
                                            </h5>
                                        </div>
                                        { settings.notification &&
                                            <div className="display-flex flex-column margin-10-left">
                                                {
                                                    ["include_images", "include_technician_notes", "include_tasks", "text_message"].map((setting, index) => {
                                                        if (setting === "text_message" && !context.currentCompany.sms_number) {
                                                            return null;
                                                        } else {
                                                            return(
                                                                <div className="display-flex align-items-center margin-10-top" key={index}>
                                                                    <div className="margin-5-right">
                                                                        <Switch
                                                                            name={`repair-item-notification-${setting}-${props.index}`}
                                                                            checked={!!settings[setting]}
                                                                            onText="✓"
                                                                            offText="x"
                                                                            onChange={() => toggleSetting(setting)}
                                                                        />
                                                                    </div>
                                                                    { setting !== "text_message" ?
                                                                        <h5 className="no-margin">
                                                                            { Utils.humanize(setting) }
                                                                        </h5>
                                                                    :
                                                                        <Fragment>
                                                                            <h5 className="no-margin">
                                                                                Include Text Message
                                                                                { settings[setting] &&
                                                                                    <a
                                                                                        href="#"
                                                                                        className="margin-5-left"
                                                                                        onClick={showTextMessageModal}
                                                                                    >
                                                                                        Edit Message
                                                                                    </a>
                                                                                }
                                                                            </h5>
                                                                        </Fragment>
                                                                    }
                                                                </div>
                                                            )
                                                        }
                                                    })
                                                }
                                            </div>
                                        }
                                    </div>
                                    <div className="display-flex flex-column">
                                        {
                                            Object.keys(nonNotificationSettings).map((key, index) => (
                                                <div className="display-flex align-items-center margin-10-bottom" key={index}>
                                                    <div className="margin-5-right">
                                                        <Switch
                                                            name={`repair-item-setting-${key}-${props.index}`}
                                                            checked={settings[key]}
                                                            onText="✓"
                                                            offText="x"
                                                            onChange={() => toggleSetting(key)}
                                                        />
                                                    </div>
                                                    <h5 className="no-margin">
                                                        { nonNotificationSettings[key] }
                                                    </h5>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </Fragment>
                }
            </div>
            { editing &&
                <LoadTemplate index={props.index} selectTemplate={selectTemplate} />
            }
            { !editing &&
                <SaveTemplate repairItem={repairItem} />
            }
            <AqsModal
                id={`edit-repair-item-text-message-modal-${props.index}`}
                headerText="Edit Text Message"
                footerButtonText="Save"
                footerButtonAction={hideTextMessageModal}
                ref={textMessageModal}
            >
                <Fragment>
                    <h4 className="text-center">
                        Text Message sent to customer upon completion:
                    </h4>
                    <div className="form-group">
                        <textarea
                            className="form-control"
                            onChange={(e) => setRepairItem({ ...repairItem, settings: { ...repairItem.settings, text_message: e.target.value } })}
                            value={settings.text_message || ""}
                        >
                        </textarea>
                    </div>
                </Fragment>
            </AqsModal>
            { props.repairItem.appointment &&
                <AqsModal
                    id="repair-item-notification-modal"
                    headerText="Send Repair Item Notification"
                    ref={notificationModal}
                >
                    <NotificationSelect
                        repairItem={props.repairItem}
                        accountId={context.repair.account_id}
                        company={context.currentCompany}
                        sendCallback={hideNotificationModal}
                    />
                </AqsModal>
            }
        </div>
    )
};

RepairItem.propTypes = {
    repairItem: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired
}

export default RepairItem;
