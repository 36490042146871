import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import AqsModal from 'AqsModal';

export default class InvoiceShareModal extends React.Component {
    static propTypes = {
        invoice: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);

        this.baseState = {
            link: props.invoice.public_url,
            loading: !props.invoice.public_url,
            copied: false
        }

        this.state = this.baseState;
    }

    show = () => {
        this.modalEl.show();
    };

    fetchUrl = () => {
        if (this.state.link) return true;

        this.setState({ loading: true });

        $.ajax({
            method: "GET",
            url: `/invoices/${this.props.invoice.id}/public_url.json`
        }).done(res => {
            this.setState({ link: res.url });
        }).fail(() => {
            // handle failure
        }).always(() => {
            this.setState({ loading: false });
        });
    };

    copyLink = () => {
        this.inputEl.select();
        document.execCommand("copy");

        this.setState({ copied: true });
    };

    render() {
        return(
            <AqsModal
                ref={(e) => this.modalEl = e}
                id="invoice-share-modal"
                headerText="Share Link to this Invoice"
                showCallback={this.fetchUrl}
            >
                <div className="flex column align-items-center width-100">
                    { this.state.loading &&
                        <div className="display-flex flex-column margin-20-bottom">
                            <h4 className="text-center">
                                Fetching Invoice URL...
                            </h4>
                            <div className="text-center">
                                <i className="fa fa-spinner fa-pulse fa-3x fa-fw align-self-center flex-1"></i>
                            </div>
                        </div>
                    }
                    { !this.state.loading &&
                        <Fragment>
                            <h4 className="text-center margin-10-bottom">
                                Link to Invoice:
                            </h4>
                            <div className="form-group">
                                { this.state.copied &&
                                    <h5 className="text-center color-gray">
                                        Link copied to clipboard!
                                    </h5>
                                }
                                <div className="input-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={this.state.link || ""}
                                        ref={(e) => this.inputEl = e}
                                        readOnly={true}
                                    />
                                    <div className="input-group-addon cursor-pointer" onClick={this.copyLink}>
                                        <i className="fa fa-clipboard"></i>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    }
                </div>
            </AqsModal>
        )
    }
}
