import React from 'react';
import PropTypes from 'prop-types';

import ImageArea from '@shared/components/ImageArea/ImageArea';

const RepairTicket = (props) => {
    return(
        <div className="reapir-ticket-wrapper">
            <div className="row">
                <div className="col-md-6">
                    <h5>
                        Description:
                    </h5>
                    <div className="well">
                        { props.repair.description }
                    </div>
                </div>
                <div className="col-md-12">
                    <hr/>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label>Notes:</label>
                        <textarea
                            className="form-control"
                            value={props.repair.private_notes || ""}
                            onChange={(e) => props.update({ ...props.repair, private_notes: e.target.value })}
                        >
                        </textarea>
                    </div>
                </div>
                <div className="col-md-12">
                    <hr/>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <h5>Images:</h5>
                    <ImageArea
                        images={props.repair.images}
                        resource_name={"repair"}
                        multiple_allowed={true}
                        resource_id={props.repair.id}
                        updateImagesCallback={(images) => props.update({ ...props.repair, images })}
                    />
                </div>
                <div className="col-md-12">
                    <hr/>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <button
                        className="btn btn-success btn-lg width-100"
                        onClick={() => props.save(props.repair, true)}
                        disabled={props.appointment.completed || props.saving || (props.repair.images || []).some(i => i.loading)}
                    >
                        <i className="fa fa-save margin-5-right"></i>
                        Complete Repair
                    </button>
                </div>
            </div>
        </div>
    )
};

RepairTicket.propTypes = {
    appointment: PropTypes.object.isRequired,
    repair: PropTypes.object.isRequired,
    update: PropTypes.func.isRequired,
    save: PropTypes.func.isRequired
}

export default RepairTicket;
