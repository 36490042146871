import React, { useState, useContext, useEffect, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';

import { ShowRepairContext } from '../ShowRepair';

import AqsModal from 'AqsModal';

const LoadTemplate = (props) => {
    const context = useContext(ShowRepairContext);
    const [filteredTemplates, setFilteredTemplates] = useState(context.repairItemTemplates);
    const modalEl = useRef(null);

    useEffect(() => {
        const link = $(`a#load-repair-item-template-${props.index}`);

        link.on("click", (e) => {
            e.preventDefault();
            modalEl.current.show();
        });
    }, []);

    const search = (e) => {
        const searchTerm = e.target.value;

        let templates;
        if (searchTerm.length > 0) {
            templates = context.repairItemTemplates.filter(template => {
                return template.name.toLowerCase().search(searchTerm) !== -1
            });
        } else {
            templates = context.repairItemTemplates;
        }

        setFilteredTemplates(templates);
    };

    const selectTemplate = (template) => {
        props.selectTemplate(template);
        modalEl.current.hide();
    };

    return(
        <AqsModal
            id="load-repair-item-template-modal"
            headerText="Load Repair Item Template"
            large={true}
            ref={modalEl}
        >
            <div className="form-group">
                <div className="input-group">
                    <input
                        type="text"
                        placeholder="Search for Templates by Name"
                        className="form-control"
                        onChange={search}
                    />
                    <div className="input-group-addon">
                        <i className="fa fa-search"></i>
                    </div>
                </div>
            </div>
            { filteredTemplates.length > 0 &&
                <Fragment>
                    <div className="display-flex padding-15-left padding-15-right margin-10-bottom">
                        <div className="flex-1">
                            <h5 className="no-margin">
                                Name:
                            </h5>
                        </div>
                        <div className="flex-2 margin-20-right">
                            <h5 className="no-margin">
                                Description:
                            </h5>
                        </div>
                    </div>
                    <div className="template-list-wrapper">
                        {
                            filteredTemplates.map((template, index) => (
                                <div className="panel panel-default" key={index}>
                                    <div className="panel-body">
                                        <div className="display-flex align-items-center">
                                            <div className="flex-2">
                                                { template.name }
                                            </div>
                                            <div className="flex-3">
                                                { template.description }
                                            </div>
                                            <div className="flex-1 display-flex justify-content-end">
                                                <button className="btn btn-default" onClick={() => selectTemplate(template)}>
                                                    <i className="fa fa-download"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </Fragment>
            }
            { filteredTemplates.length < 1 &&
                <div className="h4 text-center margin-30-top">
                    No Repair Item Templates Found
                </div>
            }
        </AqsModal>
    )
};

LoadTemplate.propTypes = {
    index: PropTypes.number.isRequired,
    selectTemplate: PropTypes.func.isRequired
};

export default LoadTemplate;
