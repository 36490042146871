import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { ZoneConsumer } from './Index';
import { toast } from 'react-toastify';

import UserModal from './UserModal';

export default class ZoneDetails extends React.Component {
    static propTypes = {
        userZones: PropTypes.array.isRequired
    }

    constructor(props) {
        super(props);

        this.baseState = {
            addUserType: null
        }

        this.state = this.baseState;
    }

    repairTechUserZones = () => {
        return this.props.userZones.filter(uz => uz.user.roles.includes("repair_tech"));
    };

    routeTechUserZones = () => {
        return this.props.userZones.filter(uz => uz.user.roles.includes("route_tech"));
    };

    showUserModal = (addUserType) => {
        this.setState({ addUserType }, () => {
            this.userModalEl.showModal();
        });
    };

    uniqueTechs = (techs) => {
        const userZones = this.state.addUserType === "route_tech" ? this.routeTechUserZones() : this.repairTechUserZones();
        const baseTechs = userZones.map(uz => uz.user.id);

        return techs.filter(t => !baseTechs.includes(t.id));
    };

    getUserInitialis = (user) => {
        const firstLast = user.name.split(" ");

        if (firstLast.length > 1) {
            return `${firstLast[0].charAt(0).toUpperCase()}${firstLast[1].charAt(0).toUpperCase()}`;
        } else if (firstLast.length === 1) {
            return `${firstLast[0].charAt(0).toUpperCase()}${firstLast[0].charAt(1).toUpperCase()}`;
        } else {
            return "--";
        }
    };

    techDetails = (userZones, type, deleteAction) => {
        const techs = userZones.map(uz => uz.user);
        return(
            <div className={`tech-group-wrapper display-flex ${techs.length > 2 && 'margin-20-left'}`}>
                {
                    techs.map((tech, index) => {
                        return(
                            <div className={`${type} tech-wrapper flex-2 display-flex flex-column align-items-center`} key={index}>
                                <div className="icon">
                                    { this.getUserInitialis(tech) }
                                </div>
                                <h4 className="text-center">
                                    { tech.name }
                                </h4>
                                <div className="delete-wrapper">
                                    <button className="btn btn-danger" onClick={() => deleteAction(tech, type)}>
                                        <i className="fa fa-trash"></i>
                                    </button>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )
    };

    render() {
        return(
            <ZoneConsumer>
                {(context) => (
                    <Fragment>
                        <div className="row no-margin height-100">
                            <div className="col-xs-12 display-flex flex-column justify-content-center height-100">
                                { context.loading === "zone" &&
                                    <Fragment>
                                        <h2 className="text-center">
                                            Loading Zone...
                                        </h2>
                                        <div className="display-flex">
                                            <i className="fa fa-spinner fa-pulse fa-3x fa-fw align-self-center flex-1"></i>
                                        </div>
                                    </Fragment>
                                }

                                { context.loading === "saving_zone" &&
                                    <Fragment>
                                        <h2 className="text-center">
                                            Saving Zone...
                                        </h2>
                                        <div className="display-flex">
                                            <i className="fa fa-spinner fa-pulse fa-3x fa-fw align-self-center flex-1"></i>
                                        </div>
                                    </Fragment>
                                }

                                { context.loading === "destroying_zone" &&
                                    <Fragment>
                                        <h2 className="text-center">
                                            Destroying Zone...
                                        </h2>
                                        <div className="display-flex">
                                            <i className="fa fa-spinner fa-pulse fa-3x fa-fw align-self-center flex-1"></i>
                                        </div>
                                    </Fragment>
                                }
                                { (context.loading !== "zone" && context.loading !== "destroying_zone" && context.loading !== "saving_zone" && context.aqsZone().id) &&
                                    <Fragment>
                                        <div className="panel panel-default detail-panel margin-20-bottom">
                                            <div className="panel-body">
                                                { context.loading === "repair_tech" &&
                                                    <div className="display-flex">
                                                        <i className="fa fa-spinner fa-pulse fa-3x fa-fw align-self-center flex-1"></i>
                                                    </div>
                                                }
                                                { context.loading !== "repair_tech" &&
                                                    <Fragment>
                                                        <div className="disply-flex flex-column">
                                                            <h1 className="text-center color-repair font-5em">
                                                                { this.repairTechUserZones().length }
                                                            </h1>

                                                            <h2 className="text-center">
                                                                Repair Techs Assigned
                                                            </h2>
                                                        </div>

                                                        <div>
                                                            <hr/>
                                                        </div>

                                                        <div className="display-flex justify-content-center">
                                                            { this.repairTechUserZones().length > 0 &&
                                                                <div className="display-flex width-100 justify-content-center">
                                                                    <div className="display-flex align-items-center">
                                                                        <button className="btn btn-lg btn-success" onClick={() => this.showUserModal("repair_tech")}>
                                                                            <span className="glyphicon glyphicon-plus"></span>
                                                                        </button>
                                                                    </div>
                                                                    { this.techDetails(this.repairTechUserZones(), "repair", context.deleteUser) }
                                                                </div>
                                                            }
                                                            { this.repairTechUserZones().length < 1 &&
                                                                <button className="btn btn-lg btn-success" onClick={() => this.showUserModal("repair_tech")}>
                                                                    <span className="glyphicon glyphicon-plus margin-5-right"></span>
                                                                    Add Repair Tech
                                                                </button>
                                                            }
                                                        </div>
                                                    </Fragment>
                                                }
                                            </div>
                                        </div>
                                        <div className="panel panel-default detail-panel margin-20-bottom">
                                            <div className="panel-body">
                                                { context.loading === "route_tech" &&
                                                    <div className="display-flex">
                                                        <i className="fa fa-spinner fa-pulse fa-3x fa-fw align-self-center flex-1"></i>
                                                    </div>
                                                }
                                                { context.loading !== "route_tech" &&
                                                    <Fragment>
                                                        <div className="disply-flex flex-column">
                                                            <h1 className="text-center color-service-stop font-5em">
                                                                { this.routeTechUserZones().length }
                                                            </h1>

                                                            <h2 className="text-center">
                                                                Route Techs Assigned
                                                            </h2>
                                                        </div>

                                                        <div>
                                                            <hr/>
                                                        </div>

                                                        <div className="display-flex justify-content-center">
                                                            { this.routeTechUserZones().length > 0 &&
                                                                <div className="display-flex width-100 justify-content-center">
                                                                    <div className="display-flex align-items-center">
                                                                        <button className="btn btn-lg btn-success" onClick={() => this.showUserModal("route_tech")}>
                                                                            <span className="glyphicon glyphicon-plus"></span>
                                                                        </button>
                                                                    </div>
                                                                    { this.techDetails(this.routeTechUserZones(), "route", context.deleteUser) }
                                                                </div>
                                                            }
                                                            { this.routeTechUserZones().length < 1 &&
                                                                <button className="btn btn-lg btn-success" onClick={() => this.showUserModal("route_tech")}>
                                                                    <span className="glyphicon glyphicon-plus margin-5-right"></span>
                                                                    Add Route Tech
                                                                </button>
                                                            }
                                                        </div>
                                                    </Fragment>
                                                }
                                            </div>
                                        </div>
                                        <div className="panel panel-default detail-panel">
                                            <div className="panel-body">
                                                <div className="display-flex flex-column">
                                                    <h1 className="text-center color-gray font-5em">
                                                        { context.accounts.length }
                                                    </h1>
                                                    <h2 className="text-center">
                                                        Total Accounts
                                                    </h2>
                                                </div>
                                            </div>
                                        </div>
                                    </Fragment>
                                }
                            </div>
                        </div>
                        { this.state.addUserType &&
                            <UserModal
                                ref={(e) => this.userModalEl = e}
                                type={this.state.addUserType}
                                techs={this.uniqueTechs(context.techs)}
                                callback={context.addUser}
                            />
                        }
                    </Fragment>
                )}
            </ZoneConsumer>
        )
    }
}
