import React from 'react';
import PropTypes from 'prop-types';

import ValidationMessage from './ValidationMessage';

import MaskedInput from 'react-text-mask';

export default class Step1 extends React.Component {
    static propTypes = {
        getMasterState: PropTypes.func.isRequired,
        updateMasterState: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props);

        const masterObj = props.getMasterState();
        this.baseState = {
            contact_name: masterObj.contact_name || "",
            contact_phone: masterObj.contact_phone || "",
            contact_email: masterObj.contact_email || "",
            location: masterObj.location || {},
            validations: { location: {} }
        }

        const keys = ["contact_name", "contact_phone", "contact_email"];
        const locationKeys = ["address", "city", "state", "zip"];
        keys.forEach(key => this.baseState.validations[key] = false);
        locationKeys.forEach(key => this.baseState.validations.location[key] = false);

        this.state = this.baseState;
    }

    componentWillReceiveProps(props) {
        let contact_name, contact_phone, contact_email, location;

        this.setState({...this.state, ...({ contact_name, contact_phone, contact_email, location } = { ...props }) })
    }

    isValidated() {
        const validations = this.makeValidations();

        this.setState({ ...this.state, validations });

        const results = Object.keys(validations).map(key => {
            if (typeof key !== "object") {
                return validations[key];
            }
        }).concat(Object.values(validations.location));

        if (!results.includes(true) && this.state.fetchingLocation !== "failed") {
            if ((!this.state.location.lng || !this.state.location.lat) || this.differentAddress()) {
                this.setState({ fetchingLocation: "querying" });

                this.fetchLocation().then(([gResults, status]) => {
                    if (status === "OK") {
                        const location = gResults[0].geometry.location;
                        this.props.updateMasterState({ location: { ...this.state.location, lat: location.lat().toString(), lng: location.lng().toString() } });

                        setTimeout(() => {
                            this.setState({ fetchingLocation: "found" });
                            setTimeout(() => {
                                this.setState({ fetchingLocation: null });
                                this.baseState.location = this.state.location;
                                $("#next-button").click();
                            }, 1000)
                        }, 1000);
                    } else {
                        this.setState({ fetchingLocation: "failed" });
                        var location = { ...this.state.location };
                        delete location.lat;
                        delete location.lng;
                        this.props.updateMasterState({ location });
                    }
                });

                return false;
            } else {
                return true
            }
        } else {
            !results.includes(true);
        }
    }

    makeValidations = () => {
        const keys = ["contact_name", "contact_phone", "contact_email"];
        const locationKeys = ["address", "city", "state", "zip"];

        let validations = { location: {} };
        keys.forEach(key => {
            if (key === "contact_phone") {
                if (this.state.contact_phone.length > 0) {
                    validations[key] = this.state.contact_phone.match(/\d/g).length < 10
                } else {
                    validations[key] = false;
                }
            } else {
                validations[key] = this.state[key].length < 1
            }
        });
        locationKeys.forEach(key => validations.location[key] = (this.state.location[key] || "").length < 1);

        return validations;
    };

    makeChange = (e) => {
        let obj;
        if (e.target.name.match(/\[/)) {
            // nested input objects for location
            const parent = e.target.name.match(/(.*?)\[/)[1];
            const prop = e.target.name.match(/\[(.*?)\]/)[1];

            const masterObj = this.props.getMasterState()[parent] || {};

            obj = { [parent]: { ...masterObj, ...{ [prop]: e.target.value } } };

            if (this.state.fetchingLocation === "failed") {
                this.setState({ fetchingLocation: null });
            }
        } else {
            let value;
            if (e.target.name === "contact_phone") {
                if (e.target.value.length > 0) {
                    value = e.target.value.match(/\d/g).join("");
                } else {
                    value = "";
                }
            } else {
                value = e.target.value
            }
            obj = { [e.target.name]: value };
        }

        this.props.updateMasterState(obj);
    };

    fetchLocation = () => {
        const promise = new Promise((resolve, reject) => {
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode({ 'address': this.fullLocation() }, (results, status) => {
                resolve([results, status]);
            });
        });

        return promise;
    };

    fullLocation = () => {
        return `${this.state.location.address} ${this.state.location.city}, ${this.state.location.state} ${this.state.location.zip}`;
    };

    differentAddress = () => {
        var different = false;
        ["address", "city", "state", "zip"].forEach(key => {
            if (this.state.location[key] !== this.baseState.location[key]) {
                different = true;
            }
        });

        return different;
    };

    render() {
        return(
            <div className="step-1-wrapper row margin-20-top margin-20-bottom" onChange={(e) => this.makeChange(e)}>
                <div className="input-row">
                    <div className="col-sm-4 input-field">
                        <span className="field-label">
                            Contact Name:
                        </span>
                    </div>
                    <div className="col-sm-8 col-xs-12">
                        <div className="form-group no-margin">
                            <input
                                className="input-field form-control"
                                type="text"
                                placeholder="John Smith"
                                name="contact_name"
                                defaultValue={this.state.contact_name}
                            />
                            <ValidationMessage condition={this.state.validations.contact_name} message="Contact Name can't be blank" />
                        </div>
                    </div>
                </div>
                <div className="input-row">
                    <div className="col-sm-4 input-field">
                        <span className="field-label">
                            Contact Phone:
                        </span>
                    </div>
                    <div className="col-sm-8 col-xs-12">
                        <div className="form-group no-margin">
                            <MaskedInput
                                className="form-control"
                                mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                placeholder="(555) 555-5555"
                                name="contact_phone"
                                defaultValue={this.props.getMasterState().contact_phone}
                            />
                            <ValidationMessage condition={this.state.validations.contact_phone} message="Contact Phone invalid length" />
                        </div>
                    </div>
                </div>
                <div className="input-row">
                    <div className="col-sm-4 input-field">
                        <span className="field-label">
                            Contact Email:
                        </span>
                    </div>
                    <div className="col-sm-8 col-xs-12">
                        <div className="form-group no-margin">
                            <input
                                className="input-field form-control"
                                type="text"
                                placeholder="john@email.com"
                                name="contact_email"
                                defaultValue={this.state.contact_email}
                            />
                            <ValidationMessage condition={this.state.validations.contact_email} message="Contact Email can't be blank (use 'noemail@useaquasuite.com' if you do not have this customers email address)" />
                        </div>
                    </div>
                </div>
                <div className="input-row">
                    <div className="col-sm-4 input-field">
                        <span className="field-label">
                            Street Address:
                        </span>
                    </div>
                    <div className="col-sm-8 col-xs-12">
                        <div className="form-group no-margin">
                            <input
                                className="input-field form-control"
                                type="text"
                                placeholder="1234 Plaster Street"
                                name="location[address]"
                                defaultValue={this.state.location.address}
                            />
                            <ValidationMessage condition={this.state.validations.location.address} message="Address can't be blank" />
                        </div>
                    </div>
                </div>
                <div className="input-row city-state-zip">
                    <div className="col-sm-4 col-xs-12 input-field">
                        <span className="field-label">
                            City, State, Zip:
                        </span>
                    </div>
                    <div className="col-sm-4 col-xs-12">
                        <div className="form-group no-margin">
                            <input
                                className="input-field form-control"
                                type="text"
                                placeholder="Phoenix"
                                name="location[city]"
                                defaultValue={this.state.location.city}
                            />
                            <ValidationMessage condition={this.state.validations.location.city} message="City can't be blank" />
                        </div>
                    </div>
                    <div className="col-sm-2 col-xs-6">
                        <div className="form-group no-margin">
                            <input
                                className="input-field form-control"
                                type="text"
                                placeholder="AZ"
                                name="location[state]"
                                defaultValue={this.state.location.state}
                            />
                            <ValidationMessage condition={this.state.validations.location.state} message="State can't be blank" />
                        </div>
                    </div>
                    <div className="col-sm-2 col-xs-6">
                        <div className="form-group no-margin">
                            <input
                                className="input-field form-control"
                                type="text"
                                placeholder="85001"
                                name="location[zip]"
                                defaultValue={this.state.location.zip}
                            />
                            <ValidationMessage condition={this.state.validations.location.zip} message="Zip can't be blank" />
                        </div>
                    </div>
                </div>
                { this.state.fetchingLocation &&
                    <div className="col-xs-12 text-center margin-10-top">
                        { this.state.fetchingLocation === "querying" &&
                            <div className="alert alert-success">
                                Validating address, please wait...
                            </div>
                        }
                        { this.state.fetchingLocation === "found" &&
                            <div className="alert alert-success">
                                Address validated!
                            </div>
                        }
                        { this.state.fetchingLocation === "failed" &&
                            <div className="alert alert-warning">
                                Unable to validate address, this will prevent this account from showing on any map in AquaSuite. Proceed if you wish to continue anyway. Otherwise, please correct the address and try again.
                            </div>
                        }
                    </div>
                }
            </div>
        )
    }
}
