import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Switch from '@shared/components/CheckboxSwitch';
import LoadCollection from '@shared/components/LoadCollection';
import ExternalMatch from '../ExternalMatch/Provider';

import Utils from 'Utils';

export default class AccountList extends React.Component {
    static propTypes = {
        company: PropTypes.object.isRequired,
        currentUser: PropTypes.object.isRequired
    };

    constructor() {
        super();

        this.baseState = {
            loading: true,
            collectionLoadable: true,
            activeAccounts: true,
            accounts: [],
            searchParam: "contact_name",
            searchTerm: "",
            activeCount: "--",
            inactiveCount: "--"
        }

        this.state = this.baseState;
    }

    resetCollection = () => {
        this.setState({ collectionLoadable: false, accounts: [] }, () => {
            this.setState({ collectionLoadable: true });
        });
    };

    fetchAccounts = (page = 1) => {
        const q = {};
        this.state.activeAccounts ? q.active_true = true : q.active_false = true;

        if (this.state.searchTerm.length > 0) {
            q[`${this.state.searchParam}_cont`] = this.state.searchTerm;
        }

        return new Promise((resolve, reject) => {
            $.ajax({
                method: "GET",
                url: "/accounts.json",
                data: { q, page }
            }).done(res => {
                this.setState({ accounts: [...this.state.accounts, ...res.accounts], activeCount: res.active_accounts, inactiveCount: res.inactive_accounts })

                const pageInfo = { currentPage: res.current_page, totalPages: res.total_pages };

                resolve(pageInfo);
            }).fail(() => {
                reject();
            }).always(() => {
                this.setState({ loading: false, collectionLoadable: true });
            });
        });
    };

    setSearchParam = (e) => {
        this.setState({ searchParam: e.target.value });
    };

    toggleActiveAccounts = () => {
        this.setState({ activeAccounts: !this.state.activeAccounts, searchTerm: "", loading: true, collectionLoadable: false }, () => {
            this.fetchAccounts();
        });
    };

    render() {
        return(
            <div className="panel panel-default account-list-wrapper">
                <div className="panel-heading display-flex align-items-center">
                    <div className="flex-1 display-flex align-items-center">
                        <img src="https://s3.amazonaws.com/superswimbros.dev/accounts.svg" alt="Accounts"/>
                        <h4 className="title">
                            Accounts
                        </h4>
                    </div>
                    <div className="flex-1">
                        <div className="dropdown pull-right">
                            <button
                                className="btn btn-lg btn-success dropdown-toggle actions-dropdown"
                                type="button"
                                data-toggle="dropdown"
                            >
                                Actions
                                <span className="glyphicon glyphicon-chevron-down"></span>
                            </button>
                            <ul className="dropdown-menu">
                                <li>
                                    <a href="/accounts/new" className="padding-10 text-center">
                                        <span className="glyphicon glyphicon-plus margin-5-right"></span>
                                        New Account
                                    </a>
                                </li>
                                { this.props.company.invoice_integration === "freshbooks" &&
                                    <li>
                                        <a
                                            href="#"
                                            data-target="#match-account-modal"
                                            data-toggle="modal"
                                        >
                                            <span className="fa fa-leaf freshbooks fa-2x margin-5-right"></span>
                                            Sync to Freshbooks
                                        </a>
                                    </li>
                                }
                                { this.props.company.invoice_integration === "quickbooks" &&
                                    <li>
                                        <a
                                            href="#"
                                            data-target="#match-account-modal"
                                            data-toggle="modal"
                                        >
                                            <div className="display-flex">
                                                <img src="https://s3.amazonaws.com/superswimbros.dev/site_images/qbicon.png" alt="Quickbooks" style={{ width: "25px" }} />
                                                Sync to Quickbooks
                                            </div>
                                        </a>
                                    </li>
                                }
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="panel-body display-flex flex-column">
                    <Fragment>
                        <div className="display-flex top-wrapper">
                            <div className="flex-1 search-wrapper">
                                <h4>
                                    { `Search ${this.state.activeAccounts ? "active" : "inactive"} accounts by:` }
                                </h4>
                                <div
                                    className="display-flex justify-content-space-between"
                                    onChange={this.setSearchParam}
                                >
                                    <div className="form-group margin-10-bottom">
                                        <label htmlFor="contact-name">
                                            Contact Name:
                                        </label>
                                        &nbsp;
                                        <input
                                            id="contact-name"
                                            name="search-param"
                                            value="contact_name"
                                            type="radio"
                                            defaultChecked={this.state.searchParam === "contact_name"}
                                        />
                                    </div>
                                    <div className="form-group margin-10-bottom">
                                        <label htmlFor="contact-email">
                                            Contact Email:
                                        </label>
                                        &nbsp;
                                        <input
                                            id="contact-email"
                                            name="search-param"
                                            value="contact_email"
                                            type="radio"
                                            defaultChecked={this.state.searchParam === "contact_email"}
                                        />
                                    </div>
                                    <div className="form-group margin-10-bottom">
                                        <label htmlFor="address">
                                            Address:
                                        </label>
                                        &nbsp;
                                        <input
                                            id="address"
                                            name="search-param"
                                            value="address"
                                            type="radio"
                                            defaultChecked={this.state.searchParam === "address"}
                                        />
                                    </div>
                                </div>
                                <div className="form-group display-flex">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter search term here..."
                                        value={this.state.searchTerm}
                                        onChange={(e) => this.setState({ searchTerm: e.target.value })}
                                    />
                                    <button
                                        className="btn btn-default margin-5-left"
                                        type="button"
                                        onClick={this.resetCollection}
                                    >
                                        Search
                                    </button>
                                </div>
                                { this.props.currentUser.roles.includes("admin") &&
                                    <div className="display-flex align-items-center">
                                        <h5 className="margin-10-right">
                                            Account Type:
                                        </h5>
                                        <Switch
                                            name="account_type"
                                            checked={this.state.activeAccounts}
                                            onText="Active"
                                            offText="Inactive"
                                            onChange={this.toggleActiveAccounts}
                                        />
                                    </div>
                                }
                            </div>
                            { this.props.currentUser.roles.includes("admin") &&
                                <div className="flex-1 display-flex align-items-center account-count-wrapper">
                                    <div className="flex-1 display-flex align-items-center justify-content-center">
                                        <div className="account-count-box active flex-1">
                                            <h5 className="text-center color-gray">
                                                Total Active Accounts:
                                            </h5>
                                            <h4 className="text-center">
                                                { this.state.activeCount }
                                            </h4>
                                        </div>
                                    </div>
                                    <div className="flex-1 display-flex align-items-center justify-content-center">
                                        <div className="account-count-box inactive flex-1">
                                            <h5 className="text-center color-gray">
                                                Total Inactive Accounts:
                                            </h5>
                                            <h4 className="text-center">
                                                { this.state.inactiveCount }
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>

                        <div className="width-100">
                            <hr/>
                        </div>

                        <div className="table-responsive">
                            { (!this.state.loading && this.state.accounts.length > 0) &&
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <td className="contact-name">
                                                <h5>
                                                    Contact Name:
                                                </h5>
                                            </td>
                                            <td>
                                                <h5>
                                                    Contact Email:
                                                </h5>
                                            </td>
                                            <td className="contact-phone">
                                                <h5>
                                                    Contact Phone:
                                                </h5>
                                            </td>
                                            <td className="address">
                                                <h5>
                                                    Account Address:
                                                </h5>
                                            </td>
                                            <td className="next-service-date">
                                                <h5>
                                                    Next Service Date:
                                                </h5>
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.accounts.map((account, index) => {
                                                return(
                                                    <tr key={index}>
                                                        <td>
                                                            <a href={`/accounts/${account.id}`}>
                                                                { account.contact_name }
                                                            </a>
                                                        </td>
                                                        <td>
                                                            { account.contact_email }
                                                        </td>
                                                        <td>
                                                            { account.contact_phone &&  Utils.formattedPhone(account.contact_phone) }
                                                        </td>
                                                        <td>
                                                            <a href={`/accounts/${account.id}`}>
                                                                { account.full_location }
                                                            </a>
                                                        </td>
                                                        <td>
                                                            { account.next_service_date && moment(account.next_service_date).tz(this.props.company.timezone).format("MM-DD-YYYY hh:mm A") }
                                                            { !account.next_service_date && "N/A" }
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            }
                            { this.state.collectionLoadable &&
                                <LoadCollection
                                    collectionName="accounts"
                                    fetchCollection={this.fetchAccounts}
                                    loadOnMount={true}
                                    collectionLength={this.state.accounts.length}
                                />
                            }
                        </div>
                    </Fragment>
                </div>
                { (this.props.company.invoice_integration === "freshbooks" || this.props.company.invoice_integration === "quickbooks") &&
                    <ExternalMatch integration={this.props.company.invoice_integration} />
                }
            </div>
        )
    }
}
