import PropTypes from 'prop-types';
import React from 'react';

import { toast } from 'react-toastify';

export default class PreviewServiceStopMailer extends React.Component {
    static propTypes = {
        account_id: PropTypes.number
    }

    constructor(props) {
        super(props);

        this.state = {
            submitting: false
        }
    }

    sendPreview = () => {
        this.setState({ submitting: true });

        const email = this.emailEl.value;

        $.ajax("/settings/service_stop_notification_preview.json", {
            method: "POST",
            data: { email, account_id: this.props.account_id },
        }).success(() => {
            toast.success("Preview Email Sent successfully!", {
                position: toast.POSITION.TOP_CENTER
            });
        }).fail(() => {
            toast.error("Unable to send Preview Email...", {
                position: toast.POSITION.TOP_CENTER
            });
        }).always(() => {
            this.setState({ submitting: false })
        });
    };

    render() {
        return(
            <div className="row">
                <div className="col-md-6">
                    <h5>
                        Receive a preview email of what { this.props.account_id ? "this account's" : "the default" } Service Stop Notifications will look like
                    </h5>
                    <div className="w-100">
                        <hr/>
                    </div>
                    <div className="form-group">
                        <input
                            className="form-control"
                            type="text"
                            placeholder="Enter your email address"
                            ref={(e) => this.emailEl = e}
                        />
                    </div>
                    <button
                        className="btn btn-success"
                        type="button"
                        onClick={this.sendPreview}
                        disabled={this.state.submitting}
                    >
                        Send Preview
                    </button>
                </div>
            </div>
        )
    }
}
