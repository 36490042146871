import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';

import { toast } from 'react-toastify';

const NotificationSelect = (props) => {
    const [notificationType, setNotificationType] = useState("");
    const [deliveryMethods, setDeliveryMethods] = useState([]);
    const [textContent, setTextContent] = useState("");
    const [sending, setSending] = useState(false);
    const [validations, setValidations] = useState({
        type: false,
        content: false,
        methods: false
    });

    const selectNotificationType = (e) => {
        setNotificationType(e.target.value);
    };

    const updateNotificationType = (e) => {
        const type = e.target.value;

        const momentStart = moment(props.repairItem.appointment.start).tz(props.company.timezone);
        if (type === "completed") {
            setTextContent(props.repairItem.settings.text_message || `This is ${props.company.name}. Your Repair scheduled on ${momentStart.format("MM/DD/YYYY")} has just been completed! Reply to this text if you have any questions.`);
        } else if (type === "scheduled") {
            setTextContent(`This is ${props.company.name}. Your Repair has just been scheduled with ${props.repairItem.appointment.user.name} on ${momentStart.format("MM/DD/YYYY")} at ${momentStart.format("hh:mm A")}. Reply to this text if you have any questions.`)
        }
        setNotificationType(type);
    };

    const toggleDeliveryMethod = (method) => {
        let methods;
        if (deliveryMethods.includes(method)) {
            methods = deliveryMethods.filter(m => m !== method);
        } else {
            methods = [...deliveryMethods, method];
        }

        setDeliveryMethods(methods);
    };

    const valid = () => {
        const validations = {
            type: notificationType.length < 1,
            content: deliveryMethods.includes("text") && textContent.length < 1,
            methods: deliveryMethods.length < 1
        }

        setValidations(validations);
        return !Object.values(validations).includes(true);
    };

    const send = () => {
        if (valid()) {
            setSending(true);

            $.ajax({
                method: "PUT",
                url: `/accounts/${props.accountId}/repairs/${props.repairItem.repair_id}/repair_items/${props.repairItem.id}/notify.json`,
                data: {
                    notification_type: notificationType,
                    delivery_methods: deliveryMethods,
                    text_content: textContent
                }
            }).done(() => {
                props.sendCallback();
                toast.success("Successfully enqueued Notification(s) to be sent!", {
                    position: toast.POSITION.TOP_CENTER
                });
            }).fail(() => {
                toast.error("An unknown error occurred when attempting to send your Notification(s)...", {
                    position: toast.POSITION.TOP_CENTER
                })
            }).always(() => {
                setSending(false);
            });
        }
    };

    return(
        <div className="display-flex flex-column">
            { sending &&
                <div className="display-flex justify-content-center margin-20-bottom">
                    <h4 className="text-center">
                        Sending Notification(s)...
                    </h4>
                    <div className="display-flex">
                        <i className="fa fa-spinner fa-pulse fa-2x fa-fw diplay-flex align-self-center flex-1"></i>
                    </div>
                </div>
            }
            <h4 className="text-center">
                Select Notification Type:
            </h4>
            <div className="form-group">
                <select
                    className="form-control"
                    onChange={updateNotificationType}
                    value={notificationType}
                >
                    <option value="">Select notification type...</option>
                    { !props.repairItem.appointment.completed &&
                        <option value="scheduled">Scheduled</option>
                    }
                    { props.repairItem.appointment.completed &&
                        <option value="completed">
                            Completed
                        </option>
                    }
                </select>
                { validations.type &&
                    <div className="validation-wrapper">
                        <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                        Must choose type of Notification to send
                    </div>
                }
            </div>
            <h4 className="text-center">
                Delivery Methods:
            </h4>
            <div className="well display-flex flex-column align-self-center">
                <div className="form-group display-flex justify-content-center no-margin-bottom">
                    <label htmlFor="email-delivery-method">
                        Email:
                    </label>
                    &nbsp;
                    <input
                        id="email-delivery-method"
                        type="checkbox"
                        onChange={() => toggleDeliveryMethod("email")}
                    />
                </div>
                { props.company.sms_number &&
                    <Fragment>
                        <div className="form-group display-flex justify-content-center no-margin-bottom margin-10-top">
                            <label htmlFor="text-delivery-method">
                                Text Message:
                            </label>
                            &nbsp;
                            <input
                                id="text-delivery-method"
                                type="checkbox"
                                onChange={() => toggleDeliveryMethod("text")}
                            />
                        </div>
                        { validations.methods &&
                            <div className="validation-wrapper">
                                <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                                Must choose at least one delivery method
                            </div>
                        }
                    </Fragment>
                }
            </div>
            { deliveryMethods.includes("text") &&
                <div className="form-group">
                    <label>Text Message:</label>
                    <textarea
                        className="form-control"
                        value={textContent}
                        onChange={(e) => setTextContent(e.target.value)}
                    >
                    </textarea>
                    { validations.content &&
                        <div className="validation-wrapper">
                            <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                            Must have something set for Text Message
                        </div>
                    }
                </div>
            }

            <div className="display-flex justify-content-center">
                <button
                    className="btn btn-success"
                    disabled={deliveryMethods.length < 1}
                    onClick={send}
                >
                    <i className="fa fa-paper-plane margin-5-right"></i>
                    Send
                </button>
            </div>
        </div>
    )
};

NotificationSelect.propTypes = {
    repairItem: PropTypes.object.isRequired,
    accountId: PropTypes.number.isRequired,
    company: PropTypes.object.isRequired,
    sendCallback: PropTypes.func.isRequired
}

export default NotificationSelect;
