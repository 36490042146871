import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import RepairTemplateList from './List';
import EditRepairTemplate from './Edit';

import { toast } from 'react-toastify';

export default class EditTemplatesModal extends React.Component {
    static propTypes = {
        company: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);

        this.baseState = {
            selectedType: "repair",
            templates: [],
            loading: true,
            editingTemplate: null
        }

        this.state = this.baseState;
    }

    componentDidMount() {
        $(this.modal).on("show.bs.modal", (e) => {
            $(e.target).addClass("center-screen-modal");

            this.fetchTemplates();
        });

        $(this.modal).on("hide.bs.modal", (e) => {
            $(e.target).removeClass("center-screen-modal")
        });
    }

    fetchTemplates = () => {
        this.setState({ loading: true });

        const promises = [];

        promises.push($.ajax({
            method: "GET",
            url: "/rich_media_elements.json",
            data: {
                q: {
                    template_true: true,
                    resource_name: "repair"
                }
            }
        }));

        promises.push($.ajax({
            method: "GET",
            url: "/repair_templates.json"
        }));

        promises.push($.ajax({
            method: "GET",
            url: "/repair_item_templates.json"
        }));

        $.when(...promises).done((rmeRes, repairRes, repairItemRes) => {
            const templates = rmeRes[0].concat(repairRes[0]).concat(repairItemRes[0]);

            this.setState({ templates, loading: false });
        });
    };

    filteredTemplates = (type) => {
        return this.state.templates.filter(template => template.type === this.state.selectedType);
    };

    newTemplate = () => {
        let editingTemplate;

        if (this.state.selectedType === "repair") {
            editingTemplate = { company_id: this.props.company.id, type: "repair" };
        } else {
            editingTemplate = { company_id: this.props.company.id, type: this.state.selectedType, resource_name: "repair", template: true };
        }

        this.setState({ editing: true, editingTemplate });
    };

    setEditingTemplate = (editingTemplate) => {
        this.setState({ editingTemplate });
    };

    updateTemplates = (template) => {
        let templates = this.state.templates.filter(_template =>  {
            if (_template.type === this.state.editingTemplate.type) {
                return _template.id !== template.id
            } else {
                return true;
            }
        })
        templates.push(template);
        templates = templates.sort((a, b) => {
            const nameA = a.name.toLowerCase();
            const nameB = b.name.toLowerCase();

            if (nameA < nameB) {
                return -1;
            } else if (nameA > nameB) {
                return 1;
            }

            return 0;
        });

        this.setState({ templates, editingTemplate: null });
    };

    destroyTemplate = (template) => {
        let url;
        if (template.type === "repair") {
            url = "/repair_templates";
        } else if (template.type === "repair_item") {
            url = "/repair_item_templates";
        } else {
            url = "/rich_media_elements";
        }

        if (window.confirm("Are you sure you would like to destroy this template? This cannot be undone.")) {
            $.ajax({
                method: "DELETE",
                url: `${url}/${template.id}.json`
            }).done(() => {
                const templates = this.state.templates.filter(_template =>  {
                    if (template.type === _template.type && template.id === _template.id) {
                        return false;
                    } else {
                        return true;
                    }
                })

                this.setState({ templates });

                toast.success("Successfully destroyed Template", {
                    position: toast.POSITION.TOP_CENTER
                });
            }).fail(() => {
                toast.error("Unable to destroy Template, please try again later", {
                    position: toast.POSITION.TOP_CENTER
                });
            });
        }
    };

    render() {
        return(
            <div
                id="repair-template-modal"
                className="modal fade"
                ref={(e) => this.modal = e}
            >
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                Templates
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                            </h5>
                            <div id="editor-loading-container" className="display-flex justify-content-center"></div>
                        </div>
                        <div className="modal-body">
                            <ul className="nav nav-tabs">
                                <li className="active">
                                    <a
                                        href="#repairs"
                                        data-toggle="tab"
                                        onClick={() => this.setState({ selectedType: "repair", editingTemplate: null })}
                                    >
                                        <i className="fa fa-wrench"></i>
                                        Repairs
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="#email"
                                        data-toggle="tab"
                                        onClick={() => this.setState({ selectedType: "email", editingTemplate: null })}
                                    >
                                        <i className="fa fa-envelope-o"></i>
                                        Emails
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="#text"
                                        data-toggle="tab"
                                        onClick={() => this.setState({ selectedType: "text", editingTemplate: null })}
                                    >
                                        <i className="fa fa-comment-o"></i>
                                        Texts
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="#web"
                                        data-toggle="tab"
                                        onClick={() => this.setState({ selectedType: "web", editingTemplate: null })}
                                    >
                                        <i className="fa fa-globe"></i>
                                        Web Pages
                                    </a>
                                </li>
                            </ul>
                            { this.state.loading &&
                                <div className="display-flex margin-30-top margin-10-bottom">
                                    <i className="fa fa-spinner fa-pulse fa-3x fa-fw align-self-center flex-1"></i>
                                </div>
                            }
                            { !this.state.loading &&
                                <div className="tab-content margin-20-top">
                                    <div className="panel panel-default">
                                        <div className="panel-body">
                                            { !this.state.editingTemplate &&
                                                <RepairTemplateList
                                                    templates={this.filteredTemplates()}
                                                    newTemplate={this.newTemplate}
                                                    setEditingTemplate={this.setEditingTemplate}
                                                    destroyTemplate={this.destroyTemplate}
                                                    selectedType={this.state.selectedType}
                                                    setSelectedType={(selectedType) => this.setState({ selectedType, editingTemplate: null })}
                                                />
                                            }
                                            { this.state.editingTemplate &&
                                                <Fragment>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <button className="btn btn-default" onClick={(e) => this.setState({ editingTemplate: null, editing: false })}>
                                                                <i className="fa fa-chevron-left margin-5-right"></i>
                                                                Show Templates
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <hr/>

                                                    <EditRepairTemplate
                                                        template={this.state.editingTemplate}
                                                        updateTemplates={this.updateTemplates}
                                                        company={this.props.company}
                                                    />
                                                </Fragment>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-default"
                                data-dismiss="modal"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
