export default {
    /*
        finds the next date for a given day
        (e.g. next tuesday)

        *date - Moment object of date looking for next occurence of in UTC
        *timezone - String (e.g. "America/Phoenix")

        @returns Moment Instance of target date in Timezone passed in
    */
    findNextDay(date, timezone) {
        const compareDate = moment(date.utc().format()).tz(timezone).startOf("day");
        const currentComparedDate = moment().tz(timezone).startOf("day");

        if (compareDate >= currentComparedDate) {
            return date;
        } else {
            const day = date.day() > currentComparedDate.day() ? date.day() : date.day() + 7

            const nextDate = moment().tz(timezone).day(day);
            const newDateString = `${nextDate.format("YYYY-MM-DD")}T${date.format("HH:mm")}`;

            return moment.tz(newDateString, "UTC").tz(timezone);
        }
    }
}
