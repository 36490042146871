import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import ImageArea from '@shared/components/ImageArea/ImageArea';

const RepairItemTicketAppointment = (props) => {
    return(
        <Fragment>
            <div className="row">
                <div className="col-md-12">
                    <h1>
                        Repair Item Details
                    </h1>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <h4>
                        <i className="fa fa-home margin-5-right"></i>
                        Address:
                        <span className="margin-5-left">
                            { props.repairItem.repair.account.location.address }
                        </span>
                    </h4>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <h5>
                        <i className="fa fa-clock-o margin-5-right color-gray"></i>
                        In Time:
                        <span className="margin-5-left">
                            { moment(props.repairItem.in_time).tz(props.timezone).format("MM/DD/YYYY hh:mm A") }
                        </span>
                    </h5>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <h5>
                        <i className="fa fa-clock-o margin-5-right color-gray"></i>
                        Out Time:
                        <span className="margin-5-left">
                            { moment(props.repairItem.out_time).tz(props.timezone).format("MM/DD/YYYY hh:mm A") }
                        </span>
                    </h5>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <h5>
                        <a href={`/accounts/${props.repairItem.repair.account_id}/repairs/${props.repairItem.repair_id}`}>
                            Go To Repair
                        </a>
                    </h5>
                </div>
            </div>

            <hr/>

            <div className="row">
                <div className="col-md-12">
                    <h4>
                        Images:
                    </h4>
                    <ImageArea
                        images={props.repairItem.images}
                        resource_name="repair_item"
                        resource_id={props.repairItem.id}
                        multiple_allowed={true}
                        preventRetry={true}
                    />
                </div>
            </div>

            <hr/>

            <div className="row">
                <div className="col-md-12">
                    <h5>
                        Repair Item Description:
                    </h5>
                    <div className="well">
                        { props.repairItem.description }
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <h5>
                        Private Notes:
                    </h5>
                    <div className="well">
                        { props.repairItem.repair.private_notes }
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <h5>
                        Technician Notes:
                    </h5>
                    <div className="well">
                        { props.repairItem.public_notes }
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <h5>Tasks:</h5>
                    { props.repairItem.tasks.length < 1 &&
                        <h5 className="text-center">
                            No Tasks Present
                        </h5>
                    }
                    { props.repairItem.tasks.length > 0 &&
                        <Fragment>
                            {
                                props.repairItem.tasks.map((task, index) => (
                                    <div className="display-flex" key={index}>
                                        { task.completed ?
                                            <span className="glyphicon glyphicon-ok margin-10-right"></span>
                                        :
                                            <span className="glyphicon glyphicon-remove margin-5-right"></span>
                                        }
                                        <h5 className="no-margin-top margin-20-bottom">
                                            { task.description }
                                        </h5>
                                    </div>
                                ))
                            }
                        </Fragment>
                    }
                </div>
            </div>
        </Fragment>
    )
};

RepairItemTicketAppointment.propTypes = {
    repairItem: PropTypes.object.isRequired,
    timezone: PropTypes.string.isRequired
};

export default RepairItemTicketAppointment;
