import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import MatchDetails from './MatchDetails';

import { ExternalMatchConsumer } from './Provider';

export default class ExternalMatchCustomerAccountPreview extends React.Component {
    static propTypes = {
        account: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);

        this.baseState = {
            activated: false
        }

        this.state = this.baseState
    }

    activate = () => {
        this.setState({ activated: !this.state.activated });
    };

    render() {
        return(
            <ExternalMatchConsumer>
                {(context) => (
                    <Fragment>
                        <div
                            className={`panel panel-default cursor-pointer ${this.state.activated ? "no-margin-bottom" : ""}`}
                            onClick={this.activate}
                        >
                            <div className="panel-body display-flex">
                                <div className="padding-15-right display-flex align-items-center" >
                                    <span className={`glyphicon glyphicon-chevron-down ${this.state.activated ? "" : "rotate-right"}`} ref={(el) => this.chevron = el}></span>
                                </div>
                                <div className="flex-1 display-flex align-items-center">
                                    { this.props.account.contact_name }
                                </div>
                                <div className="flex-1 display-flex align-items-center justify-content-end">
                                    <span className="margin-5-right">Matched:</span>
                                    { this.props.account.external_invoice_profile_id &&
                                        <span className="glyphicon glyphicon-ok"></span>
                                    }
                                    { !this.props.account.external_invoice_profile_id &&
                                        <span className="glyphicon glyphicon-remove"></span>
                                    }
                                </div>
                            </div>
                        </div>
                        <div
                            className={`${this.state.activated ? "display-block" : "display-none"}`}
                        >
                            <div className="well">
                                <MatchDetails
                                    account={this.props.account}
                                    integration={context.integration}
                                    externalCustomers={context.externalCustomers}
                                    activated={this.state.activated}
                                />
                            </div>
                        </div>
                    </Fragment>
                )}
            </ExternalMatchConsumer>
        )
    }
}
