import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import AqsQuill from '../../RichMediaElements/AqsQuill';
import TextMessageEditor from '../../RichMediaElements/TextMessageEditor';
import Task from '../Items/Task';
import Switch from '@shared/components/CheckboxSwitch';

import { toast } from 'react-toastify';
import Utils from 'Utils';

export default class EditRepairTemplate extends React.Component {
    static propTypes = {
        template: PropTypes.object.isRequired,
        updateTemplates: PropTypes.func.isRequired,
        company: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);

        this.baseState = {
            template: props.template,
            validations: {
                name: false,
                content: false,
                description: false,
                quantity: false,
                price: false
            }
        }

        this.state = this.baseState;
    }

    inputsAreValid = () => {
        let validations = { };

        if (this.state.template.type === "repair" || this.state.template.type === "repair_item") {
            validations = {
                name: (this.state.template.name || "").length < 1,
                description: (this.state.template.description || "").length < 1,
                content: false
            }
        } else {
            validations = {
                name: (this.state.template.name || "").length < 1,
                content: (this.state.template.content || "").length < 1,
                description: false
            }
        }

        if (this.state.template.type === "repair_item") {
            if ((this.state.template.quantity || "").length > 0 || (this.state.template.price || "").length > 0) {
                validations.quantity = isNaN(parseFloat(this.state.template.quantity));
                validations.price = isNaN(parseFloat(this.state.template.price));
            }
        }

        this.setState({ validations });

        return !Object.values(validations).includes(true);
    };

    saveTemplate = () => {
        if (this.inputsAreValid()) {
            const method = this.state.template.id ? "PUT" : "POST";
            let url, data;

            if (this.state.template.type === "repair") {
                url = "/repair_templates";
                const template = { ...this.state.template };
                delete template.expenses;
                delete template.rich_media_elements;

                data = { template };
            } else if (this.state.template.type === "repair_item") {
                url = "/repair_item_templates";

                data = { repair_item: { ...this.state.template } }
            } else {
                url = "/rich_media_elements";
                data = {
                    rich_media_element: this.state.template
                }
            }

            url += this.state.template.id ? `/${this.state.template.id}.json` : ".json"

            $.ajax({
                method,
                url,
                data: JSON.stringify(data),
                contentType: "application/json"
            }).done(template => {
                this.props.updateTemplates(template);

                toast.success("Successfully saved Template", {
                    position: toast.POSITION.TOP_CENTER
                });
            }).fail((res) => {
                let errors;
                if ((res.responseJSON || {}).errors) {
                    errors = res.responseJSON.errors;
                }
                toast.error(errors || "Unable to save Template, please try again", {
                    position: toast.POSITION.TOP_CENTER
                });
            })
        }
    };

    saveTask = (task, index, repairItemIndex) => {
        if (this.state.template.type === "repair_item") {
            const tasks = this.state.template.tasks.map((_task, _index) => {
                return index === _index ? { ...task } : { ... _task };
            });

            this.setState({ template: { ...this.state.template, tasks } });
        } else if (this.state.template.type === "repair") {
            const repair_items = [...this.state.template.repair_items].map((item, _index) => {
                if (_index === repairItemIndex) {
                    const tasks = [...item.tasks].map((_task, taskIndex) => {
                        if (taskIndex === index) {
                            return { ...task }
                        } else {
                            return { ..._task }
                        }
                    })
                    return { ...item, tasks }
                } else {
                    return { ...item };
                }
            });

            this.setState({ template: { ...this.state.template, repair_items } })
        }
    };

    destroyTask = (index, repaiItemIndex) => {
        if (this.state.template.type === "repair_item") {
            const tasks = this.state.template.tasks.filter((task, _index) => _index !== index);

            this.setState({ template: { ...this.state.template, tasks } });
        } else if (this.state.template.type === "repair") {

        }
    };

    addTask = (e, repairItemIndex) => {
        e.preventDefault();

        if (this.state.template.type === "repair_item") {
            const tasks = [...(this.state.template.tasks || []), {}];

            this.setState({ template: { ...this.state.template, tasks } });
        } else if (this.state.template.type === "repair") {
            const repair_items = [...this.state.template.repair_items].map((item, index) => {
                if (index === repairItemIndex) {
                    return { ...item, tasks: [...(item.tasks || []), {}] }
                } else {
                    return { ...item }
                }
            });

            this.setState({ template: { ...this.state.template, repair_items } });
        }
    };

    toggleSetting = (attr, repairItemIndex) => {
        const repairItem = this.state.template.type === "repair_item" ? this.state.template : this.state.template.repair_items[repairItemIndex];

        const settings = repairItem.settings || {};

        let value;
        if (attr === "text_message") {
            if (settings.text_message) {
                value = false;
            } else {
                value = `This is ${this.props.company.name}. Your Repair has just been completed! Review the details here: %{repair_item_completed_url}`
            }
        } else {
            value = !settings[attr];
        }

        if (this.state.template.type === "repair_item") {
            const template = { ...this.state.template, settings: { ...settings, [attr]: value } };

            this.setState({ template });
        } else if (this.state.template.type === "repair") {
            const repair_items = [...this.state.template.repair_items].map((item, index) => {
                if (index === repairItemIndex) {
                    return { ...repairItem, settings: { ...repairItem.settings, [attr]: value } }
                } else {
                    return { ...item }
                }
            });

            this.setState({ template: { ...this.state.template, repair_items } });
        }
    };

    nonNotificationSettings = () => (
        {
            tasks_required: "Require all tasks to be completed",
            technician_notes_required: "Require Technician Notes",
            image_required: "Require at least 1 Image",
            timestamp_photos: "Timestamp Photo(s)"
        }
    );

    addRepairItem = (e) => {
        e.preventDefault();

        const repair_items = [...(this.state.template.repair_items || []), {}];

        this.setState({ template: { ...this.state.template, repair_items } });
    };

    removeRepairItem = (e, index) => {
        e.preventDefault();

        const repair_items = this.state.template.repair_items.filter((item, _index) => index !== _index);

        this.setState({ template: { ...this.state.template, repair_items } });
    };

    updateRepairItem = (item, attr, value, repairItemIndex) => {
        if (this.state.template.type === "repair_item") {
            this.setState({ template: { ...item, [attr]: value } })
        } else if (this.state.template.type === "repair") {
            const repair_items = [...this.state.template.repair_items].map((_item, _index) => {
                if (_index === repairItemIndex) {
                    return { ...item, [attr]: value }
                } else {
                    return { ..._item }
                }
            });

            this.setState({ template: { ...this.state.template, repair_items } });
        }
    };

    updateRepairItemTextMessage = (value, repairItemIndex) => {
        if (this.state.template.type === "repair_item") {
            const template = { ...this.state.template, settings: { ...this.state.template.settings, text_message: value } };

            this.setState({ template });
        } else if (this.state.template.type === "repair") {
            const repair_items = [...this.state.template.repair_items].map((_item, index) => {
                if (index === repairItemIndex) {
                    return { ..._item, settings: { ..._item.settings, text_message: value } };
                } else {
                    return { ..._item };
                }
            });

            this.setState({ template: { ...this.state.template, repair_items } });
        }
    };

    renderRepairItem = (item, repairItemIndex) => {
        return(
            <Fragment>
                <div className="col-md-12">
                    <div className="form-group">
                        <label>Description:</label>
                        <textarea
                            className="form-control"
                            value={item.description || ""}
                            onChange={(e) => this.updateRepairItem(item, "description", e.target.value, repairItemIndex)}
                        >
                        </textarea>
                        { this.state.validations.description &&
                            <div className="alert alert-danger no-margin-bottom margin-10-top">
                                <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                                Description is required
                            </div>
                        }
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label>Price:</label>
                        <input
                            className="form-control"
                            value={item.price || ""}
                            type="number"
                            onChange={(e) => this.updateRepairItem(item, "price", e.target.value, repairItemIndex)}
                        />
                        { this.state.validations.price &&
                            <div className="alert alert-danger no-margin-bottom margin-10-top">
                                <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                                Valid Price is required
                            </div>
                        }
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="form-group">
                        <label>Quantity:</label>
                        <input
                            className="form-control"
                            type="number"
                            value={item.quantity || ""}
                            onChange={(e) => this.updateRepairItem(item, "quantity", e.target.value, repairItemIndex)}
                        />
                        { this.state.validations.quantity &&
                            <div className="alert alert-danger no-margin-bottom margin-10-top">
                                <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                                Valid Quantity is required
                            </div>
                        }
                    </div>
                </div>

                <div className="col-md-12">
                    <hr/>

                    <h5 className="no-margin-top">
                        Tasks:
                    </h5>
                    {
                        (item.tasks || []).map((task, taskIndex) => (
                            <Task
                                key={taskIndex}
                                index={taskIndex}
                                task={task}
                                appointmentCompleted={false}
                                saveTask={(task, _index) => this.saveTask(task, _index, repairItemIndex)}
                                destroyTask={(_index) => this.destroyTask(_index, repairItemIndex)}
                            />
                        ))
                    }

                    <div className="text-center">
                        <a href="#" onClick={(e) => this.addTask(e, repairItemIndex)}>
                            + Add Task
                        </a>
                    </div>
                </div>

                <div className="col-md-12">
                    <hr/>

                    <h5 className="no-margin-top">
                        Settings:
                    </h5>

                    <div className="settings-wrapper">
                        <div className="display-flex flex-column">
                            <div className="display-flex align-items-center">
                                <div className="margin-5-right">
                                    <Switch
                                        name={`repair-item-notification-${this.state.template.type === "repair_item" ? "0" : repairItemIndex}`}
                                        checked={(item.settings || {}).notification}
                                        onText="✓"
                                        offText="x"
                                        onChange={() => this.toggleSetting("notification", repairItemIndex)}
                                    />
                                </div>
                                <h5 className="no-margin">
                                    Send Completed Notification
                                </h5>
                            </div>
                            { (item.settings || {}).notification &&
                                <div className="display-flex flex-column margin-10-left">
                                    {
                                        ["include_images", "include_technician_notes", "include_tasks", "text_message"].map((setting, index) => (
                                            <Fragment key={index}>
                                                <div className="display-flex align-items-center margin-10-top">
                                                    <div className="margin-5-right">
                                                        <Switch
                                                            name={`repair-item-notification-${setting}-${this.state.template.type === "repair_item" ? "0" : repairItemIndex}`}
                                                            checked={!!(item.settings || {})[setting]}
                                                            onText="✓"
                                                            offText="x"
                                                            onChange={() => this.toggleSetting(setting, repairItemIndex)}
                                                        />
                                                    </div>
                                                    { setting !== "text_message" ?
                                                        <h5 className="no-margin">
                                                            { Utils.humanize(setting) }
                                                        </h5>
                                                    :
                                                        <h5 className="no-margin">
                                                            Include Text Message
                                                        </h5>
                                                    }
                                                </div>
                                                { (setting === "text_message" && (item.settings || {})[setting]) &&
                                                    <div className="form-group margin-10-top">
                                                        <textarea
                                                            className="form-control"
                                                            value={item.settings[setting]}
                                                            onChange={(e) => this.updateRepairItemTextMessage(e.target.value, repairItemIndex)}
                                                        >
                                                        </textarea>
                                                    </div>
                                                }
                                            </Fragment>
                                        ))
                                    }
                                </div>
                            }
                        </div>
                        <div className="display-flex flex-column">
                            {
                                Object.keys(this.nonNotificationSettings()).map((key, index) => (
                                    <div className="display-flex align-items-center margin-10-bottom" key={index}>
                                        <div className="margin-5-right">
                                            <Switch
                                                name={`repair-item-setting-${key}-${this.state.template.type === "repair_item" ? "0" : repairItemIndex}`}
                                                checked={(item.settings || {})[key]}
                                                onText="✓"
                                                offText="x"
                                                onChange={() => this.toggleSetting(key, repairItemIndex)}
                                            />
                                        </div>
                                        <h5 className="no-margin">
                                            { this.nonNotificationSettings()[key] }
                                        </h5>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    };

    render() {
        return(
            <Fragment>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Template Name:</label>
                            <input
                                type="text"
                                className="form-control"
                                defaultValue={this.state.template.name || ""}
                                onChange={(e) => this.setState({ template: { ...this.state.template, name: e.target.value } })}
                            />
                            { this.state.validations.name &&
                                <div className="alert alert-danger no-margin-bottom margin-10-top">
                                    <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                                    Name is required
                                </div>
                            }
                        </div>
                    </div>
                    { this.state.template.type === "repair" &&
                        <Fragment>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Description:</label>
                                    <textarea
                                        className="form-control"
                                        defaultValue={this.state.template.description || ""}
                                        onChange={(e) => this.setState({ template: { ...this.state.template, description: e.target.value } })}
                                    >
                                    </textarea>
                                    { this.state.validations.description &&
                                        <div className="alert alert-danger no-margin-bottom margin-10-top">
                                            <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                                            Description is required
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Public Notes:</label>
                                    <textarea
                                        className="form-control"
                                        defaultValue={this.state.template.client_notes || ""}
                                        onChange={(e) => this.setState({ template: { ...this.state.template, client_notes: e.target.value } })}
                                    >
                                    </textarea>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Cost:</label>
                                    <div className="input-group">
                                        <div className="input-group-addon">
                                            <span className="glyphicon glyphicon-usd"></span>
                                        </div>
                                        <input
                                            type="text"
                                            className="form-control"
                                            defaultValue={this.state.template.cost || ""}
                                            onChange={(e) => this.setState({ template: { ...this.state.template, cost: e.target.value } })}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Terms/Conditions:</label>
                                    <textarea
                                        className="form-control"
                                        defaultValue={this.state.template.terms || ""}
                                        onChange={(e) => this.setState({ template: { ...this.state.template, terms: e.target.value } })}
                                    >
                                    </textarea>
                                </div>
                            </div>

                            <div className="col-md-12">
                                <h5>
                                    Repair Items:
                                </h5>
                                { (this.state.template.repair_items || []).length > 0 &&
                                    this.state.template.repair_items.map((item, index) => (
                                        <div className="panel panel-default width-100" key={index}>
                                            <div className="panel-body">
                                                <div className="display-flex flex-column width-100">
                                                    <a
                                                        href="#"
                                                        className="margin-10-bottom"
                                                        onClick={(e) => this.removeRepairItem(e, index)}
                                                    >
                                                        Remove Repair Item
                                                    </a>
                                                    { this.renderRepairItem(item, index) }
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                                <div className="text-center margin-20-top">
                                    <a href="#" onClick={this.addRepairItem}>
                                        + Add Repair Item
                                    </a>
                                </div>
                            </div>
                        </Fragment>
                    }
                    { this.state.template.type === "repair_item" &&
                        this.renderRepairItem(this.state.template)
                    }
                    { this.state.template.type === "email" &&
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Email Subject:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder={`${this.props.company.name}  - Approval for Repair`}
                                    defaultValue={this.state.template.subject || ""}
                                    onChange={(e) => this.setState({ template: { ...this.state.template, subject: e.target.value } })}
                                />
                            </div>
                        </div>
                    }
                </div>
                { (this.state.template.type === "email" || this.state.template.type === "web") &&
                    <div className="row">
                        <div className="col-md-12 web-email-edit-wrapper">
                            <AqsQuill
                                content={this.state.template.content}
                                placeholder="Create your template here..."
                                loadingContainerSelector="#repair-template-modal #editor-loading-container"
                                specialInserts={{
                                    "repair_status": "Status",
                                    "repair_priority": "Priority",
                                    "repair_client_notes": "Public Notes",
                                    "repair_public_grand_total": "Grand Total",
                                    "repair_discount_percentage": "Discount Percentage",
                                    "repair_public_discount_amount": "Discount Amount",
                                    "repair_terms": "Terms & Conditions",
                                    "repair_public_url": "Repair Page URL",
                                    "repair_approval_buttons": "Approve/Deny Buttons"
                                }}
                                updateCallback={(content) => this.setState({ template: { ...this.state.template, content } })}
                            />
                            { this.state.validations.content &&
                                <div className="alert alert-danger no-margin-bottom margin-10-top">
                                    <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                                    Content is required
                                </div>
                            }
                        </div>
                    </div>
                }
                { this.state.template.type === "text" &&
                    <div className="row">
                        <div className="text-edit-wrapper col-md-12">
                            <TextMessageEditor
                                content={this.state.template.content}
                                specialInserts={{
                                    "repair_status": "Status",
                                    "repair_priority": "Priority",
                                    "repair_client_notes": "Public Notes",
                                    "repair_public_grand_total": "Grand Total",
                                    "repair_discount_percentage": "Discount Percentage",
                                    "repair_public_discount_amount": "Discount Amount",
                                    "repair_terms": "Terms & Conditions",
                                    "repair_public_url": "Repair Page URL"
                                }}
                                updateCallback={(content) => this.setState({ template: { ...this.state.template, content } })}
                            />
                            { this.state.validations.content &&
                                <div className="alert alert-danger no-margin-bottom margin-10-top">
                                    <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                                    Content is required
                                </div>
                            }
                        </div>
                    </div>
                }
                <div className="col-md-12 margin-10-top">
                    <div className="pull-right">
                        <button className="btn btn-success" onClick={this.saveTemplate}>
                            <i className="fa fa-save margin-5-right"></i>
                            Save
                        </button>
                    </div>
                </div>
            </Fragment>
        )
    }
}
