import React from 'react';
import PropTypes from 'prop-types';

import Entries from './Entries';

import Utils from 'Utils';

const AccountEmailNotifications = (props) => {
    return(
        <div className="table-responsive">
            <table className="table-striped width-100">
                <thead>
                    <tr>
                        <td>
                            <h5>
                                Type:
                            </h5>
                        </td>
                        <td>
                            <h5>
                                Email(s):
                            </h5>
                        </td>
                        <td>
                            &nbsp;
                        </td>
                    </tr>
                </thead>
                <tbody>
                    {
                        ["service_stop", "repair", "invoice"].map((type, index) => (
                            <tr key={index}>
                                <td>
                                    <h5>
                                        { Utils.humanize(`${type}_emails`) }
                                    </h5>
                                </td>
                                <td className="padding-10-top padding-10-bottom">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Entries
                                                type={type}
                                                emails={props.notification_emails[`${type}_emails`]}
                                                accountId={props.account_id}
                                            />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
};

AccountEmailNotifications.propTypes = {
    notification_emails: PropTypes.object.isRequired,
    account_id: PropTypes.number.isRequired
};

export default AccountEmailNotifications;
