import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import ImageArea from '@shared/components/ImageArea/ImageArea';
import Modal from '@shared/components/Modal';

export default class QuickRepairsModal extends React.Component {
    static propTypes = {
        templates: PropTypes.array.isRequired,
        saveQuickRepair: PropTypes.func.isRequired
    }

    constructor(props) {
        super(props);

        this.baseState = {
            repair: { description: "" },
            suggestions: [],
            validations: {
                description: false
            }
        }

        this.state = this.baseState;
    }

    showModal = () => {
        this.modalEl.show();
    };

    hideModal = () => {
        this.modalEl.hide();
    };

    updateDescription = (e) => {
        const description = e.target.value;

        let suggestions = this.props.templates.filter(t => t.name.toLowerCase().search(description.toLowerCase()) !== -1);

        if (description.length < 1) {
            suggestions = this.props.templates
        }

        this.setState({ repair: { ...this.state.repair, description }, suggestions });
    };

    selectSuggestion = (suggestion) => {
        this.setState({ repair: { ...this.state.repair, ...suggestion }, suggestions: [suggestion] });
    };

    updateImagesCallback = (images) => {
        this.setState({ repair: { ...this.state.repair, image_ids: images.map(i => i.id) } });
    };

    isValid = () => {
        if ((this.state.repair.description || "").length > 0) {
            this.setState({ validatons: { description: false } })
            return true;
        } else {
            this.setState({ validations: { description: true } });
            return false;
        }
    };

    saveRepair = () => {
        if (this.isValid()) {
            this.props.saveQuickRepair({ ...this.state.repair });
            this.hideModal();
        }
    };

    render() {
        return(
            <Modal
                id="quick-repairs-modal"
                headerText="Quick Repair"
                footerButtonText="Save"
                footerButtonAction={this.saveRepair}
                ref={(e) => this.modalEl = e}
                closeCallback={() => this.setState({ ...this.baseState })}
            >
                <Fragment>
                    <div className="form-group">
                        <label>
                            Describe this Repair:
                        </label>

                        <textarea
                            className="form-control"
                            onChange={(e) => this.updateDescription(e)}
                            value={this.state.repair.description}
                        ></textarea>
                        { this.state.validations.description &&
                            <div className="validation-wrapper">
                                <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                                You must describe this Repair
                            </div>
                        }
                    </div>

                    <label>Suggestions:</label>
                    <div className="quick-repair-suggestion-wrapper">
                        <div className="well display-flex flex-column">
                            { this.state.suggestions.length > 0 &&
                                <Fragment>
                                    {
                                        this.state.suggestions.map((suggestion, index) => {
                                            const suggestionClass = this.state.repair.description === suggestion.description ? "panel-body" : "panel-heading";

                                            return(
                                                <div className="row" key={index}>
                                                    <div className="col-xs-12">
                                                        <div
                                                            className="panel panel-default cursor-pointer"
                                                            onClick={(e) => this.selectSuggestion(suggestion)}
                                                        >
                                                            <div className={`${suggestionClass} display-flex`}>
                                                                { this.state.repair.description === suggestion.description &&
                                                                    <i className="fa fa-check-circle-o font-1-5em margin-5-right"></i>
                                                                }
                                                                { suggestion.name }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </Fragment>
                            }
                            { this.state.suggestions.length < 1 &&
                                <div className="height-100 display-flex align-items-center">
                                    <h5 className="text-center width-100">
                                        No Suggestions found
                                    </h5>
                                </div>
                            }
                        </div>
                    </div>

                    <ImageArea
                        images={[]}
                        resource_name="repair"
                        multiple_allowed={true}
                        updateImagesCallback={this.updateImagesCallback}
                    />
                </Fragment>
            </Modal>
        )
    }
}
