import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

export default class SubscriptionPlans extends React.Component {
    componentDidMount() {
        $(window).on("scroll", (e) => {
            if (this.lastFeature.getBoundingClientRect().y < 20.15) {
                $(this.stickyBar).removeClass("sticky-bar");
            } else {
                $(this.stickyBar).addClass("sticky-bar");
            }
        });
    }

    render() {
        return(
            <div className="subscription-plans-wrapper row margin-40-top">
                <div className="col-md-10 col-md-offset-1">
                    <div className="display-flex sticky-bar" ref={(e) => this.stickyBar = e}>
                        <div className="flex-2 feature-label"></div>
                        <div className="flex-1 feature-value">
                            <h4>
                                <span className="label label-info">
                                    Lite
                                </span>
                            </h4>
                        </div>
                        <div className="flex-1 feature-value">
                            <h4>
                                <span className="label label-success">
                                    Plus
                                </span>
                            </h4>
                        </div>
                        <div className="flex-1 feature-value">
                            <h4>
                                <span className="label label-warning">
                                    Pro
                                </span>
                            </h4>
                        </div>
                    </div>
                    <div className="display-flex">
                        <div className="flex-2 feature-label">
                            Track Service Stops
                        </div>
                        <div className="flex-1 feature-value">
                            <h4>
                                <span className="glyphicon glyphicon-ok"></span>
                            </h4>
                        </div>
                        <div className="flex-1 feature-value">
                            <h4>
                                <span className="glyphicon glyphicon-ok"></span>
                            </h4>
                        </div>
                        <div className="flex-1 feature-value">
                            <h4>
                                <span className="glyphicon glyphicon-ok"></span>
                            </h4>
                        </div>
                    </div>
                    <div className="display-flex">
                        <div className="flex-2 feature-label">
                            Track Chemical Usage
                        </div>
                        <div className="flex-1 feature-value">
                            <h4>
                                <span className="glyphicon glyphicon-ok"></span>
                            </h4>
                        </div>
                        <div className="flex-1 feature-value">
                            <h4>
                                <span className="glyphicon glyphicon-ok"></span>
                            </h4>
                        </div>
                        <div className="flex-1 feature-value">
                            <h4>
                                <span className="glyphicon glyphicon-ok"></span>
                            </h4>
                        </div>
                    </div>
                    <div className="display-flex">
                        <div className="flex-2 feature-label">
                            Attach Images to Service Stops/Customers Accounts
                        </div>
                        <div className="flex-1 feature-value">
                            <h4>
                                <span className="glyphicon glyphicon-ok"></span>
                            </h4>
                        </div>
                        <div className="flex-1 feature-value">
                            <h4>
                                <span className="glyphicon glyphicon-ok"></span>
                            </h4>
                        </div>
                        <div className="flex-1 feature-value">
                            <h4>
                                <span className="glyphicon glyphicon-ok"></span>
                            </h4>
                        </div>
                    </div>
                    <div className="display-flex">
                        <div className="flex-2 feature-label">
                            Rearrange and Optimize Route Order
                        </div>
                        <div className="flex-1 feature-value">
                            <h4>
                                <span className="glyphicon glyphicon-ok"></span>
                            </h4>
                        </div>
                        <div className="flex-1 feature-value">
                            <h4>
                                <span className="glyphicon glyphicon-ok"></span>
                            </h4>
                        </div>
                        <div className="flex-1 feature-value">
                            <h4>
                                <span className="glyphicon glyphicon-ok"></span>
                            </h4>
                        </div>
                    </div>
                    <div className="display-flex">
                        <div className="flex-2 feature-label">
                            Send Service Stop Notifications
                        </div>
                        <div className="flex-1 feature-value">
                            <h4>
                                <span className="glyphicon glyphicon-remove"></span>
                            </h4>
                        </div>
                        <div className="flex-1 feature-value">
                            <h4>
                                <span className="glyphicon glyphicon-ok"></span>
                            </h4>
                        </div>
                        <div className="flex-1 feature-value">
                            <h4>
                                <span className="glyphicon glyphicon-ok"></span>
                            </h4>
                        </div>
                    </div>
                    <div className="display-flex">
                        <div className="flex-2 feature-label">
                            Route Reminders
                        </div>
                        <div className="flex-1 feature-value">
                            <h4>
                                <span className="glyphicon glyphicon-remove"></span>
                            </h4>
                        </div>
                        <div className="flex-1 feature-value">
                            <h4>
                                <span className="glyphicon glyphicon-ok"></span>
                            </h4>
                        </div>
                        <div className="flex-1 feature-value">
                            <h4>
                                <span className="glyphicon glyphicon-ok"></span>
                            </h4>
                        </div>
                    </div>
                    <div className="display-flex">
                        <div className="flex-2 feature-label">
                            Schedule/Assign Multiple Appointment Types
                        </div>
                        <div className="flex-1 feature-value">
                            <h4>
                                <span className="glyphicon glyphicon-remove"></span>
                            </h4>
                        </div>
                        <div className="flex-1 feature-value">
                            <h4>
                                <span className="glyphicon glyphicon-ok"></span>
                            </h4>
                        </div>
                        <div className="flex-1 feature-value">
                            <h4>
                                <span className="glyphicon glyphicon-ok"></span>
                            </h4>
                        </div>
                    </div>
                    <div className="display-flex">
                        <div className="flex-2 feature-label">
                            Track, Manage, and get Approval for Repairs
                        </div>
                        <div className="flex-1 feature-value">
                            <h4>
                                <span className="glyphicon glyphicon-remove"></span>
                            </h4>
                        </div>
                        <div className="flex-1 feature-value">
                            <h4>
                                <span className="glyphicon glyphicon-ok"></span>
                            </h4>
                        </div>
                        <div className="flex-1 feature-value">
                            <h4>
                                <span className="glyphicon glyphicon-ok"></span>
                            </h4>
                        </div>
                    </div>
                    <div className="display-flex">
                        <div className="flex-2 feature-label">
                            Track Service Stop, Service Item, Repair Commissions
                        </div>
                        <div className="flex-1 feature-value">
                            <h4>
                                <span className="glyphicon glyphicon-remove"></span>
                            </h4>
                        </div>
                        <div className="flex-1 feature-value">
                            <h4>
                                <span className="glyphicon glyphicon-remove"></span>
                            </h4>
                        </div>
                        <div className="flex-1 feature-value">
                            <h4>
                                <span className="glyphicon glyphicon-ok"></span>
                            </h4>
                        </div>
                    </div>
                    <div className="display-flex">
                        <div className="flex-2 feature-label">
                            Automatically Generate Invoices For Service, Chemicals, Service Items
                        </div>
                        <div className="flex-1 feature-value">
                            <h4>
                                <span className="glyphicon glyphicon-remove"></span>
                            </h4>
                        </div>
                        <div className="flex-1 feature-value">
                            <h4>
                                <span className="glyphicon glyphicon-remove"></span>
                            </h4>
                        </div>
                        <div className="flex-1 feature-value">
                            <h4>
                                <span className="glyphicon glyphicon-ok"></span>
                            </h4>
                        </div>
                    </div>
                    <div className="display-flex">
                        <div className="flex-2 feature-label">
                            Sync Invoices to Billing Software/Use Integrated Billing Software
                        </div>
                        <div className="flex-1 feature-value">
                            <h4>
                                <span className="glyphicon glyphicon-remove"></span>
                            </h4>
                        </div>
                        <div className="flex-1 feature-value">
                            <h4>
                                <span className="glyphicon glyphicon-remove"></span>
                            </h4>
                        </div>
                        <div className="flex-1 feature-value">
                            <h4>
                                <span className="glyphicon glyphicon-ok"></span>
                            </h4>
                        </div>
                    </div>
                    <div className="display-flex">
                        <div className="flex-2 feature-label">
                            Track/Remind Past Due Customers
                        </div>
                        <div className="flex-1 feature-value">
                            <h4>
                                <span className="glyphicon glyphicon-remove"></span>
                            </h4>
                        </div>
                        <div className="flex-1 feature-value">
                            <h4>
                                <span className="glyphicon glyphicon-remove"></span>
                            </h4>
                        </div>
                        <div className="flex-1 feature-value">
                            <h4>
                                <span className="glyphicon glyphicon-ok"></span>
                            </h4>
                        </div>
                    </div>
                    <div className="display-flex" ref={(e) => this.lastFeature = e}>
                        <div className="flex-2 feature-label">
                            Prioritized Tech Support
                        </div>
                        <div className="flex-1 feature-value">
                            <h4>
                                <span className="glyphicon glyphicon-remove"></span>
                            </h4>
                        </div>
                        <div className="flex-1 feature-value">
                            <h4>
                                <span className="glyphicon glyphicon-remove"></span>
                            </h4>
                        </div>
                        <div className="flex-1 feature-value">
                            <h4>
                                <span className="glyphicon glyphicon-ok"></span>
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
