import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import RecordListPreview from './RecordListPreview';

export default class RecordList extends React.Component {
    static propTypes = {
        records: PropTypes.array.isRequired
    }

    render() {
        return(
            <Fragment>
                { this.props.records.length > 0 &&
                    this.props.records.map((record, index) => {
                        return(
                            <RecordListPreview
                                key={index}
                                record={record}
                                index={index}
                            />
                        )
                    })
                }
                { this.props.records.length < 1 &&
                    <div className="text-center">
                        No Reminders Present
                    </div>
                }
            </Fragment>
        )
    }
}
