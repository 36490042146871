import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import MaskedInput from 'react-text-mask';
import { toast } from 'react-toastify';

import Entry from './Entry';

export default class ContactInfos extends React.Component {
    static propTypes = {
        account_id: PropTypes.number.isRequired,
        contact_infos: PropTypes.array.isRequired
    }

    constructor(props) {
        super(props);

        this.baseState = {
            contactInfos: props.contact_infos
        }

        this.state = this.baseState;
    }

    addCi = (e) => {
        e.preventDefault();

        const contactInfos = [...this.state.contactInfos, { uniqueKey: btoa(Math.random()) }]

        this.setState({ contactInfos });
    };

    destroyCi = (index) => {
        const contactInfos = [...this.state.contactInfos].map((ci, _index) => {
            if (_index === index) {
                if (ci.id) {
                    $.ajax({
                        method: "DELETE",
                        url: `/accounts/${this.props.account_id}/contact_infos/${ci.id}.json`
                    }).done(() => {
                        toast.success("Successfully removed Contact Info!", {
                            position: toast.POSITION.TOP_CENTER
                        })
                    }).fail(() => {
                        toast.error("Unable to remove Contact Info", {
                            position: toast.POSITION.TOP_CENTER
                        })
                    })
                }
            } else {
                return { ...ci }
            }
        }).filter(Boolean);

        this.setState({ contactInfos });
    };

    updateCi = (index, ci) => {
        const contactInfos = [...this.state.contactInfos].map((_ci, _index) => {
            if (_index === index) {
                return { ...ci }
            } else {
                return { ..._ci };
            }
        });

        this.setState({ contactInfos });
    }

    render() {
        return(
            <Fragment>
                {
                    this.state.contactInfos.map((ci, index) => {
                        return(
                            <Fragment key={ci.id || ci.uniqueKey}>
                                <h4>
                                    Alternate Contact Phone #{ index + 1 }:
                                </h4>
                                <Entry
                                    ci={ci}
                                    accountId={this.props.account_id}
                                    index={index}
                                    destroyCi={this.destroyCi}
                                    updateCi={this.updateCi}
                                />
                            </Fragment>
                        )
                    })
                }
                <a href="#" onClick={this.addCi}>
                    <i className="fa fa-plus-circle margin-5-right"></i>
                    Add Phone
                </a>
            </Fragment>
        )
    }
};
