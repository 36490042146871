import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import RepairAttachment from './Attachment';
import RepairAttachmentModal from './Modal';

import { toast } from 'react-toastify';

export default class RepairAttachmentsList extends React.Component {
    static propTypes = {
        richMediaElements: PropTypes.array.isRequired,
        templates: PropTypes.array.isRequired,
        repair: PropTypes.object.isRequired,
        company: PropTypes.object.isRequired,
        basePlan: PropTypes.string.isRequired
    }

    constructor(props) {
        super(props);

        this.baseState = {
            richMediaElements: props.richMediaElements,
            selectedAttachment: null
        }

        this.state = this.baseState;
    }

    getAttachments() {
        return this.state.richMediaElements;
    }

    attachmentList = () => {
        if (this.props.basePlan === "pro" && this.props.company.sms_number) {
            return ["email", "text", "web"];
        } else {
            return ["email", "web"];
        }
    };

    findRme = (type) => {
        return this.state.richMediaElements.find(rme => rme.type === type);
    };

    launchModal = (selectedAttachment) => {
        this.setState({ selectedAttachment }, () => {
            $("#repair-attachment-modal").modal("show");
        });
    };

    updateAttachment = (attachment) => {
        const richMediaElements = this.state.richMediaElements.filter(rme => rme.type !== attachment.type);

        richMediaElements.push(attachment);

        this.setState({ richMediaElements, selectedAttachment: attachment });
    };

    saveAttachment = (attachment) => {
        const method = attachment.id ? "PUT" : "POST";
        let url = "/rich_media_elements";

        url += attachment.id ? `/${attachment.id}.json` : ".json"

        $.ajax({
            method,
            url,
            data: {
                rich_media_element: attachment
            }
        }).done((attachment) => {
            this.updateAttachment(attachment);

            $("#repair-attachment-modal").modal("hide");

            toast.success("Successfully saved Attachment", {
                position: toast.POSITION.TOP_CENTER
            });
        }).fail(() => {
            toast.error("Unable to save Attachment, please try again later", {
                position: toast.POSITION.TOP_CENTER
            });
        });
    };

    render() {
        return(
            <Fragment>
                <div className="repair-attachments-wrapper">
                    <Fragment>
                        {
                            this.attachmentList().map((type, index) => {
                                return(
                                    <div className="row" key={index}>
                                        <div className="col-sm-5 col-md-6">
                                            <RepairAttachment
                                                updateAttachments={this.updateAttachments}
                                                launchModal={this.launchModal}
                                                type={type}
                                                attachment={this.findRme(type)}
                                                repairId={this.props.repair.id}
                                            />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </Fragment>
                </div>
                { this.state.selectedAttachment &&
                    <RepairAttachmentModal
                        templates={this.props.templates}
                        saveAttachment={this.saveAttachment}
                        updateAttachment={this.updateAttachment}
                        attachment={this.state.selectedAttachment}
                        company={this.props.company}
                        repair={this.props.repair}
                    />
                }
            </Fragment>
        )
    }
}
