import React, { Fragment, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';

import Modal from 'AqsModal';
import AppointmentList from './AppointmentList';
import Chart from './Chart';
import ServiceStopForm from '../../NewServiceStops/Form/Index';
import QuickRepairModal from '../../NewServiceStops/Form/QuickRepairModal';
import RepairItemTicket from '../../Repairs/ItemTicket/Index';
import RepairTicket from '../../Repairs/Ticket/Index';

import { DashboardContext } from '../Index';
import { RouteContext } from './Index';
import Utils from 'Utils';
import ServiceStopUtils from '@main/components/NewServiceStops/Utils';
import RepairItemTicketUtils from '@main/components/Repairs/Items/Utils';
import RouteUtils from '../Utils/Route';
import RepairTicketUtils from '@main/components/Repairs/Ticket/Utils';
import { toast } from 'react-toastify';

const RouteTicket = (props) => {
    const dashboardContext = useContext(DashboardContext);
    const routeContext = useContext(RouteContext);
    const ticketHeaderElRef = useRef(null);
    let quickRepairModalEl;

    useEffect(() => {
        if (routeContext.arbitrarySelectedAppointment && routeContext.arbitrarySelectedAppointment.type === "service_stop") {
            if (routeContext.currentRouteReminders(routeContext.arbitrarySelectedAppointment).filter(rr =>  !rr.remarks && !rr.acknowledged).length > 0) {
                routeContext.displayRouteReminderModal();
            }
        }
    }, [routeContext.arbitrarySelectedAppointment]);

    const showQuickRepairModal = () => {
        if (!dashboardContext.companySettings.enable_quick_repairs) return false;

        quickRepairModalEl.showModal();
    };

    const previousDisabled = () => {
        return routeContext.selectedAppointmentIndex() === 0;
    };

    const nextDisabled = () => {
        return routeContext.selectedAppointmentIndex() === (routeContext.appointments.length - 1);
    };

    const selectAppointment = (direction, scrollIntoView = false) => {
        let index = routeContext.selectedAppointmentIndex();
        index = direction === "next" ? (index + 1) : (index - 1);
        const nextSelectedAppointment = routeContext.appointments[index];

        if (scrollIntoView) {
            let appointment;
            if (nextSelectedAppointment) {
                appointment = (nextSelectedAppointment.completed || nextSelectedAppointment.status) ? routeContext.firstIncompleteAppointment() : nextSelectedAppointment;
            } else {
                appointment = routeContext.firstIncompleteAppointment()
            }

            if (appointment && !isLastAppointment(appointment)) {
                setTimeout(() => {
                    ticketHeaderElRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
                    setTimeout(() => {
                        const routeWidgetWrapper = $(`.${props.widgetClass}.route-widget-wrapper`);
                        if (!routeWidgetWrapper.hasClass("expanded")) routeWidgetWrapper.toggleClass("expanded");

                        $(`.${props.widgetClass}.route-widget-wrapper tr[data-appt-id=${appointment.id}]`)[0].scrollIntoView({ behavior: "smooth", block: "center", inline: "start" });

                        if (appointment.type === "service_stop") {
                            setTimeout(() => {
                                routeContext.setSelectedAppointment(appointment);
                                if (routeContext.currentRouteReminders(appointment).filter(rr => !rr.acknowledged).length > 0) {
                                    routeContext.displayRouteReminderModal();
                                }
                            }, 1000);
                        }
                    });
                })
            } else {
                showRouteCompleted();
            }
        } else {
            routeContext.setSelectedAppointment(nextSelectedAppointment);
            if (routeContext.currentRouteReminders(nextSelectedAppointment).filter(rr => !rr.acknowledged).length > 0) {
                routeContext.displayRouteReminderModal();
            }
        }
    };

    const isLastAppointment = (appointment) => {
        const others = routeContext.appointments.filter(appt => {
            if (appt.id === appointment.id) {
                return (!appointment.completed && !appointment.status)
            } else {
                return !appt.status && !appt.completed;
            }
        });

        return others.length < 1;
    };

    const showRouteCompleted = () => {
        const routeWidgetWrapper = $(`.${props.widgetClass}.route-widget-wrapper`);

        if (routeWidgetWrapper.hasClass("expanded")) {
            routeWidgetWrapper.toggleClass("expanded");
        }

        setTimeout(() => {
            const panelInnard = routeWidgetWrapper.find(".panel-innard")[0];
            panelInnard.scrollIntoView({ behavior: "smooth", block: "center" });
            setTimeout(() => {
                if (routeContext.hasSyncableAppointments()) {
                    toast.warn("Route has been Completed but 1 or more Appointments must be sync'd.", {
                        position: toast.POSITION.TOP_CENTER
                    });
                } else {
                    toast.success("Route has been Completed!", {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            }, 1000);
        })
    };

    const topSectionHeader = () => {
        switch(routeContext.selectedAppointment.type) {
            case 'repair':
                return "Repair Ticket";
                break;
            case 'repair_item':
                return "Repair Ticket";
                break;
            case 'service_stop':
                return "Service Ticket";
                break;
        }
    };

    const topSection = () => (
        <Fragment>
            <div className="display-flex align-items-center" ref={ticketHeaderElRef}>
                <img
                    src={RouteUtils.apptIconUrl(routeContext.selectedAppointment)}
                    alt="Route Ticket"
                    className="widget-icon margin-10-right"
                />
                <h3 className="no-margin">
                    { topSectionHeader() }
                </h3>
            </div>

            <hr/>

            <div className="info-wrapper display-flex justify-content-space-between margin-20-top">
                <div className="flex-1">
                    <i className="fa fa-address-card-o margin-5-right"></i>
                    <a href={`/accounts/${routeContext.selectedAppointment.account.id}`}>
                        { routeContext.selectedAppointment.account.contact_name }
                    </a>
                </div>
                <div className="flex-1 text-center">
                    <i className="fa fa-map-marker margin-5-right"></i>
                    <a href={Utils.googleMapsUrl(routeContext.selectedAppointment.full_location)}>
                        { routeContext.selectedAppointment.full_location }
                    </a>
                </div>
                <div className="flex-1">
                    <i className="fa fa-file-text-o margin-5-right"></i>
                    { routeContext.selectedAppointment.account.service_plan }
                </div>
            </div>

            <Fragment>
                <hr/>
                <h3 className="ticket-account-notes">Account Notes:</h3>
                <div className="well">
                    { routeContext.selectedAppointment.account.notes }
                </div>
            </Fragment>
        </Fragment>
    );

    const saveQuickRepair = (quick_repair) => {
        routeContext.setSelectedAppointment({ ...routeContext.selectedAppointment, service_stop: { ...routeContext.selectedAppointment.service_stop, quick_repair } })
    };

    const updateServiceStop = (service_stop) => {
        routeContext.updateAppointment({ ...routeContext.selectedAppointment, service_stop });
    };

    const saveServiceStop = (serviceStop, next = false) => {
        routeContext.updateAppointment({ ...routeContext.selectedAppointment, status: "saving" });
        if (next) selectAppointment("next", true);

        ServiceStopUtils.saveServiceStop(serviceStop, routeContext.selectedAppointment.account_id).then(service_stop => {
            const newAppointment = { ...routeContext.selectedAppointment, service_stop, completed: true }
            newAppointment.status = (serviceStop.images || []).some(i => i.timeout) ? "needs_to_sync" : null;

            routeContext.updateAppointment(newAppointment);
            routeContext.syncAppointments();
        }).catch(service_stop => {
            routeContext.updateAppointment({ ...routeContext.selectedAppointment, service_stop, status: "needs_to_sync" });
        });
    };

    const updateRepairItem = (repair_item) => {
        routeContext.updateAppointment({ ...routeContext.selectedAppointment, repair_item })
    };

    const saveRepairItemTicket = (repairItem, next = false) => {
        routeContext.updateAppointment({ ...routeContext.selectedAppointment, status: "saving" });
        if (next) selectAppointment("next", true);

        RepairItemTicketUtils.saveRepairTicket(repairItem, routeContext.selectedAppointment.account.id).then(repair_item => {
            const newAppointment = { ...routeContext.selectedAppointment, repair_item, completed: true };
            newAppointment.status = (repairItem.images || []).some(i => i.timeout) ? "needs_to_sync" : null;

            routeContext.updateAppointment(newAppointment);
            routeContext.syncAppointments();
        }).catch(repair_item => {
            routeContext.updateAppointment({ ...routeContext.selectedAppointment, repair_item, status: "needs_to_sync" });
        })
    };

    const updateRepair = (repair) => {
        routeContext.updateAppointment({ ...routeContext.selectedAppointment, repair });
    };

    const saveRepair = (repair, next = false) => {
        if (!repair.completed_at) {
            const completed_at = moment().tz(dashboardContext.currentCompany.timezone).utc().format();

            repair.completed_at = completed_at
        }

        const appt = { ...routeContext.selectedAppointment };

        routeContext.updateAppointment({ ...appt, status: "saving" });
        if (next) selectAppointment("next", true);

        RepairTicketUtils.saveRepairTicket(repair).then(savedRepair => {
            appt.status = (savedRepair.images || []).some(i => i.timeout) ? "needs_to_sync" : null;
            const newAppt = { ...appt, repair: { ...savedRepair }, completed: true };

            routeContext.updateAppointment(newAppt);
            routeContext.syncAppointments();
        }).catch(repair => {
            routeContext.updateAppointment({ ...appt, repair, status: "needs_to_sync" });
        });
    };

    const widgetIconUrl = () => {
        if (props.appointmentType !== "current") {
            return "https://s3.amazonaws.com/superswimbros.dev/deadline.svg";
        } else {
            return "https://s3.amazonaws.com/superswimbros.dev/route.svg";
        }
    };

    return(
        <Fragment>
            <hr/>

            <div className="appointment-wrapper">
                { topSection() }

                <hr/>

                { routeContext.selectedAppointment.type === "service_stop" &&
                    <Fragment>
                        <ServiceStopForm
                            appointment={{ ...routeContext.selectedAppointment }}
                            saveServiceStop={saveServiceStop}
                            uniqueKey={routeContext.selectedAppointment.id}
                            saving={routeContext.selectedAppointment.status === "saving"}
                            account={{ ...routeContext.selectedAppointment.account }}
                            service_stop={{ ...(routeContext.selectedAppointment.service_stop || {}) }}
                            chemical_units={dashboardContext.companySettings.chemical_units}
                            pool_conditions={dashboardContext.poolConditions}
                            master_service_items={dashboardContext.masterServiceItems}
                            is_route_tech={dashboardContext.currentUser.roles.includes("route_tech")}
                            timezone={dashboardContext.currentCompany.timezone}
                            base_plan={dashboardContext.currentCompany.base_plan}
                            quick_repairs_enabled={dashboardContext.companySettings.enable_quick_repairs}
                            chemical_readings={dashboardContext.chemicalReadings}
                            addable_chemicals={dashboardContext.addableChemicals}
                            default_tasks={dashboardContext.defaultTasks}
                            updateAppointment={routeContext.updateAppointment}
                            updateServiceStop={updateServiceStop}
                            allRouteRemindersValid={routeContext.allRouteRemindersValid}
                            showQuickRepairModal={showQuickRepairModal}
                        />
                        { dashboardContext.companySettings.enable_quick_repairs &&
                            <QuickRepairModal
                                templates={dashboardContext.repairTemplates}
                                saveQuickRepair={saveQuickRepair}
                                ref={(e) => quickRepairModalEl = e}
                            />
                        }
                    </Fragment>
                }
                { routeContext.selectedAppointment.type === "repair_item" &&
                    <RepairItemTicket
                        repairItem={{ ...routeContext.selectedAppointment.repair_item }}
                        appointment={{ ...routeContext.selectedAppointment }}
                        timezone={dashboardContext.currentCompany.timezone}
                        saving={routeContext.selectedAppointment.status === "saving"}
                        update={updateRepairItem}
                        save={saveRepairItemTicket}
                    />
                }
                { routeContext.selectedAppointment.type === "repair" &&
                    <RepairTicket
                        appointment={{ ...routeContext.selectedAppointment }}
                        repair={{ ...routeContext.selectedAppointment.repair }}
                        saving={routeContext.selectedAppointment.status === "saving"}
                        update={updateRepair}
                        save={saveRepair}
                    />
                }
            </div>
        </Fragment>
    )
}

RouteTicket.propTypes = {
    widgetClass: PropTypes.string.isRequired
}

export default RouteTicket;
