import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { ShowRepairContext } from '../ShowRepair';

import AqsModal from 'AqsModal';

const SaveTemplate = (props) => {
    const context = useContext(ShowRepairContext);
    const [name, setName] = useState("");
    const [loading, setLoading] = useState(false);
    const [validations, setValidations] = useState({
        name: false
    });
    const modalEl = useRef(null);

    useEffect(() => {
        const link = $(`a#save-repair-item-template-${props.repairItem.id}`);

        link.on("click", (e) => {
            e.preventDefault();
            modalEl.current.show();
        });
    }, []);

    const valid = () => {
        const newValidations = {
            name: name.length < 1
        };

        setValidations(newValidations);
        return !Object.values(newValidations).includes(true);
    };

    const save = () => {
        if (valid()) {
            setLoading(true);

            const tasks = [...(props.repairItem.tasks || [])].map(task => {
                const filteredTask = { ...task }
                delete filteredTask.completed
                return filteredTask;
            });

            const repair_item = { ...props.repairItem, name, tasks };

            delete repair_item.public_notes;
            delete repair_item.private_notes;

            $.ajax({
                method: "POST",
                url: `/repair_item_templates.json`,
                contentType: "application/json",
                data: JSON.stringify({ repair_item })
            }).done(template => {
                context.addRepairItemTemplate(template);

                modalEl.current.hide();

                toast.success("Successfully Saved Repair Item Template!", {
                    position: toast.POSITION.TOP_CENTER
                })
            }).fail((error) => {
                let message;
                if ((error.responseJSON || {}).errors) {
                    message = error.responseJSON.errors;
                } else {
                    message = "An unknown error occurred...";
                }

                toast.error(message, {
                    position: toast.POSITION.TOP_CENTER
                })
            }).always(() => {
                setLoading(false);
            });
        }
    };

    return(
        <AqsModal
            id="save-repair-item-template-modal"
            headerText="Save Repair Item Template"
            footerButtonAction={save}
            footerButtonText="Save"
            ref={modalEl}
        >
            { loading &&
                <div className="display-flex justify-content-center">
                    <h4 className="text-center">
                        Saving Repair Item Template...
                    </h4>
                    <div className="display-flex">
                        <i className="fa fa-spinner fa-pulse fa-2x fa-fw diplay-flex align-self-center flex-1"></i>
                    </div>
                </div>
            }
            <div className="h4 text-center margin-20-bottom">
                Repair Item Template Name:
            </div>
            <div className="form-group">
                <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setName(e.target.value)}
                />
                { validations.name &&
                    <div className="validation-wrapper">
                        <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                        Valid Name is required
                    </div>
                }
            </div>
        </AqsModal>
    )
};

SaveTemplate.propTypes = {
    repairItem: PropTypes.object.isRequired
};

export default SaveTemplate;
