import React from 'react';
import PropTypes from 'prop-types';

import Chart from 'chart.js';

export default class AqsChart extends React.Component {
    static propTypes = {
        chartType: PropTypes.string.isRequired,
        canvasWidth: PropTypes.number.isRequired,
        canvasHeight: PropTypes.number.isRequired,
        displayLegend: PropTypes.bool.isRequired,
        chartData: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array
        ]).isRequired,
        options: PropTypes.object,
        dataLabels: PropTypes.array,
        backgroundColors: PropTypes.array,
        borderColors: PropTypes.array
    }

    componentDidMount() {
        const ctx = this.canvasEl.getContext("2d");

        this.chart = new Chart(ctx, {
            type: this.props.chartType,
            options: {
                legend: {
                    display: this.props.displayLegend
                },
                ...(this.props.options || {})
            },
            data: this.chartData()
        });
    }

    componentDidUpdate(prevProps) {
        if (Array.isArray(this.props.chartData)) {
            if (prevProps.chartData.toString() !== this.props.chartData.toString()) {
                this.chart.data = this.chartData();

                this.chart.update();
            }
        } else if (this.props.chartData instanceof Object) {
            const oldData = prevProps.chartData.datasets.map(ds => ds.data);
            const newData = this.props.chartData.datasets.map(ds => ds.data);

            if (oldData.toString() !== newData.toString()) {
                this.chart.data = this.chartData();

                this.chart.update();
            }
        }
    }

    chartData = () => {
        if (Array.isArray(this.props.chartData)) {
            return {
                labels: this.props.dataLabels,
                datasets: [
                    {
                        data: this.props.chartData,
                        backgroundColor: this.props.backgroundColors,
                        borderColor: this.props.borderColors
                    }
                ]
            }
        } else if (this.props.chartData instanceof Object) {
            return this.props.chartData;
        }
    };

    render() {
        return(
            <canvas
                width={this.props.canvasWidth}
                height={this.props.canvasHeight}
                ref={(e) => this.canvasEl = e}
            >
            </canvas>
        )
    }
}
