import React from 'react';
import PropTypes from 'prop-types';

import { InvoiceConsumer } from './Index';
import MaskedInput from 'react-text-mask';
import CreateNumberMask from '../../../../Shared/CreateNumberMask';
import Utils from 'Utils';

import AqsModal from 'AqsModal';
import CreditCards from '@shared/components/CreditCards/Index';

export default class PaymentModal extends React.Component {
    static propTypes = {
        timezone: PropTypes.string.isRequired
    };

    constructor() {
        super();

        this.baseState = {
            payment: {
                method: "",
                check_number: null,
                amount: "",
                date_received: null
            }
        }

        this.state = this.baseState
    }

    componentDidMount() {
        $(".datetime-picker").datetimepicker({
            ignoreReadonly: true,
            allowInputToggle: true,
            showClose: true,
            widgetPositioning: {
                horizontal: "left",
                vertical: "bottom"
            },
            icons: {
                time: "glyphicon dt-picker-icon-time",
                close: "glyphicon dt-picker-icon-close",
                date: "glyphicon dt-picker-icon-date"
            }
        });

        $(".datetime-picker").on("dp.change", (e) => {
            const dateTime = moment(e.target.value, "MM/DD/YYYY hh:mm A").format("YYYY-MM-DD HH:mm");
            const parsedDateTime = moment.tz(dateTime, this.props.timezone).utc().format();

            this.setState({ payment: { ...this.state.payment, date_received: parsedDateTime } });
        });
    }

    closeModalCallback = () => {
        this.setState({ ...this.baseState });
    };

    show = () => {
        this.modalEl.show();
    };

    hide = () => {
        this.modalEl.hide();
    };

    savePayment = (integration, savePaymentCallback) => {
        if (this.state.payment.method === "cash" || this.state.payment.method === "check") {
            savePaymentCallback(this.state.payment);
            this.modalEl.hide();
        }
    };

    render() {
        const amountMask = CreateNumberMask({
            prefix: "",
            allowDecimal: true
        });

        return(
            <InvoiceConsumer>
                { (context) => (
                    <AqsModal
                        ref={(e) => this.modalEl = e}
                        id="invoice-payment-modal"
                        headerText="Apply Payment to this Invoice"
                        large={true}
                        closeCallback={this.closeModalCallback}
                        footerButtonText={this.state.payment.method === "credit card" ? undefined : "Apply Payment"}
                        footerButtonAction={() => this.savePayment(context.integration, context.savePayment)}
                    >
                        <div className="display-flex flex-column align-items-center width-100">
                            <h4 className="text-center no-margin-bottom">
                                Amount Due: <span className="color-gray">{ Utils.floatToCurrency(parseFloat(context.invoice.total_due)) }</span>
                            </h4>

                            <div className="width-100">
                                <hr/>
                            </div>

                            <div className="form-group">
                                <label htmlFor="method">
                                    Payment Method:
                                </label>
                                <select
                                    className="form-control"
                                    onChange={(e) => this.setState({ payment: { ...this.state.payment, method: e.target.value } })}
                                    value={this.state.payment.method}
                                >
                                    <option value="">Choose payment method...</option>
                                    <option value="check">Check</option>
                                    <option value="cash">Cash</option>
                                    { context.integration === "stripe" &&
                                        <option value="credit card">Credit/Debit Card</option>
                                    }
                                </select>
                            </div>

                            { this.state.payment.method === "check" &&
                                <div className="form-group">
                                    <label htmlFor="check-number">
                                        Check Number:
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        onChange={(e) => this.setState({ payment: { ...this.state.payment, check_number: e.target.value } })}
                                        value={this.state.payment.check_number || ""}
                                    />
                                </div>
                            }
                            <div className={`row ${(this.state.payment.method === "cash" || this.state.payment.method === "check") ? "display-block" : "display-none"}`}>
                                <div className="form-group">
                                    <label htmlFor="date-received">
                                        Date Received:
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control datetime-picker"
                                        value={undefined}
                                        readOnly
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="amount">Amount:</label>
                                <div className="input-group">
                                    <div className="input-group-addon">
                                        <span className="glyphicon glyphicon-usd"></span>
                                    </div>
                                    <MaskedInput
                                        className="form-control"
                                        mask={amountMask}
                                        onChange={(e) => this.setState({ payment: { ...this.state.payment, amount: e.target.value.replace(",", "") } })}
                                        value={this.state.payment.amount}
                                    />
                                </div>
                            </div>
                            { this.state.payment.method === "credit card" &&
                                <div className="width-100">
                                    <hr/>
                                    <CreditCards
                                        displayPayment={true}
                                        cardSelectable={true}
                                        fetchPaymentSourcesOnMount={context.invoice.payment_status !== "paid"}
                                        setupCardElement={true}
                                        invoice={context.invoice}
                                        chargeAmount={parseFloat(this.state.payment.amount)}
                                        stripeKey={context.stripeKey}
                                        paymentSourcesUrl={context.paymentSourcesUrl}
                                        deleteSourceUrl={context.deleteSourceUrl}
                                        makePaymentUrl={context.makePaymentUrl}
                                        updateCustomerUrl={context.updateCustomerUrl}
                                        updateInvoice={context.updateInvoice}
                                    />
                                </div>
                            }
                        </div>
                    </AqsModal>
                )}
            </InvoiceConsumer>
        )
    }
}
