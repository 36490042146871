import React from 'react';
import PropTypes from 'prop-types';

export default class AqsDatePicker extends React.Component {
    static propTypes = {
        date: PropTypes.string,
        options: PropTypes.object,
        onChange: PropTypes.func
    }

    componentDidMount() {
        this.options = {
            ignoreReadonly: true,
            allowInputToggle: true,
            format: "MM/DD/YYYY",
            ...this.props.options
        };

        $(".datetime-picker").datetimepicker(this.options);

        $(".datetime-picker").on("dp.change", (e) => {
            if (this.props.onChange) this.props.onChange(e);
        });
    }

    updateInputValue(value) {
        $(this.inputEl).val(value);
    }

    showDTPicker = (e) => {
        $($(e.target).parent().find("input")).data("DateTimePicker").show();
    };

    render() {
        return(
            <div className="datetime-picker-wrapper">
                <input
                    type="text"
                    className="form-control datetime-picker"
                    readOnly
                    defaultValue={this.props.date}
                    ref={(e) => this.inputEl = e}
                />
                <span onClick={this.showDTPicker} className="glyphicon glyphicon-chevron-down"></span>
            </div>
        )
    }
}
