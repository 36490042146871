import React from 'react';
import PropTypes from 'prop-types';

const ServiceSetInput = (props) => {
    return(
        <div className="form-group">
            <h4 className="no-margin">
                { `${props.heading}:` }
            </h4>
            { props.chargeSetable &&
                <div className="margin-10-top margin-10-bottom">
                    <label htmlFor={`charge-for-${props.chemName}-${props.appointmentId}`} className="margin-5-right no-margin-bottom">Charge for chemical:</label>
                    <input
                        id={`charge-for-${props.chemName}-${props.appointmentId}`}
                        type="checkbox"
                        checked={props.chargeFor}
                        onChange={props.toggleChemicalCharge}
                    />
                </div>
            }
            <div className="margin-10-bottom">
                { `Previous reading: ${props.previousReading}` }
            </div>
            <div>
                <input
                    type="number"
                    className="form-control"
                    value={props.value || ""}
                    step="1"
                    onChange={(e) => props.setValue(e.target.value)}
                />
            </div>
        </div>
    )
}

ServiceSetInput.propTypes = {
    chargeFor: PropTypes.bool.isRequired,
    chargeSetable: PropTypes.bool.isRequired,
    toggleChemicalCharge: PropTypes.func,
    setValue: PropTypes.func.isRequired,
    heading: PropTypes.string.isRequired,
    chemName: PropTypes.string.isRequired,
    previousReading: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    appointmentId: PropTypes.number
}

export default ServiceSetInput;
