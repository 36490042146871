import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Metrics from '../Metrics/Index';
import StartingPoint from './StartingPoint';

import RouteTechUtils from '../Utils';
import Utils from 'Utils';
import { toast } from 'react-toastify';

export default class RouteTechAppointmentsWidget extends React.Component {
    static propTypes = {
        route: PropTypes.object,
        tech: PropTypes.object.isRequired,
        techIndex: PropTypes.number.isRequired,
        companySettings: PropTypes.object.isRequired,
        metrics: PropTypes.object,
        startingPoints: PropTypes.object,
        toggleable: PropTypes.bool.isRequired,
        toggled: PropTypes.bool,
        timezone: PropTypes.string.isRequired,
        toggleCallback: PropTypes.func,
        toggleStartingPoint: PropTypes.func,
        updateStartingPoint: PropTypes.func,
        updateTechMetrics: PropTypes.func,
        startingPointMarker: PropTypes.object,
        saveOrderCallback: PropTypes.func,
        disableEditOrder: PropTypes.bool,
        displayDayName: PropTypes.string
    };

    constructor(props) {
        super(props);

        this.baseState = {
            userSettings: props.tech.settings,
            originalSettings: props.tech.settings,
            metrics: props.metrics,
            currentMetrics: { ...props.metrics },
            route: props.route || {},
            currentRoute: { ...props.route },
            savingMaxStops: false,
            editingOrder: false,
            savingOrder: false
        }

        this.state = this.baseState;
    }

    toggleExpand = () => {
        $(this.widgetEl).toggleClass("expanded");
    };

    routeDays = () => {
        return Object.keys(this.props.route[this.props.tech.id]).filter(d => d.length === 3);
    };

    userFormattedObj = (obj, day) => {
        if (obj && obj[this.props.tech.id]) {
            return { [this.props.tech.id]: { [day]: obj[this.props.tech.id][day] } };
        }
    };

    hasApptLocked = (accountId, day) => {
        return !!this.state.userSettings.locked_appointments.find(la => la.day_name === day && la.account_id === accountId);
    };

    toggleLockedAppt = (account_id, day_name) => {
        let confirmMessage;
        if (this.hasApptLocked(account_id, day_name)) {
            confirmMessage = "Are you sure you would like to remove this Account from being locked to this Tech/Day?"
        } else {
            confirmMessage = "Are you sure you would like lock this Account to this Tech/Day?"
        }

        if (confirm(confirmMessage)) {
            let locked_appointments;
            if (!this.hasApptLocked(account_id, day_name)) {
                locked_appointments = [...this.state.userSettings.locked_appointments, { account_id, day_name }]
            } else {
                locked_appointments = [...this.state.userSettings.locked_appointments].filter(la => la.account_id !== account_id && la.day_name !== day_name)
            }

            $.ajax({
                method: "PUT",
                url: `/users/${this.props.tech.id}.json`,
                contentType: "application/json",
                data: JSON.stringify({
                    user: { locked_appointments },
                    non_invite: true
                })
            }).done(() => {
                this.setState({ userSettings: { ...this.state.userSettings, locked_appointments } });

                toast.success("Successfully updated Technician's Route!", {
                    position: toast.POSITION.TOP_CENTER
                });
            }).fail(() => {
                toast.error("Unable to update Technicians Route...", {
                    position: toast.POSITION.TOP_CENTER
                });
            });
        }
    };

    saveMaxStops = (day) => {
        this.setState({ savingMaxStops: true });

        $.ajax({
            method: "PUT",
            url: `/users/${this.props.tech.id}.json`,
            data: {
                user: { [`${day}_max_route_stops`]: this.state.userSettings[`${day}_max_route_stops`] }
            }
        }).done(() => {
            toast.success("Successfully updated Technician's Route!", {
                position: toast.POSITION.TOP_CENTER
            });

            this.setState({ originalSettings: { ...this.state.userSettings } });
        }).fail(() => {
            toast.error("Unable to update Technicians Route...", {
                position: toast.POSITION.TOP_CENTER
            });
        }).always(() => {
            this.setState({ savingMaxStops: false });
        })
    };

    saveOrder = (day) => {
        if (confirm("Are you sure you would like to change this Technicians Appointments for this day to be in the newly set order?")) {
            const appointments = {};
            this.state.currentRoute[this.props.tech.id][day].forEach(appt => {
                appointments[appt.id] = (({ start, end }) => ({ start, end }))(appt);
            });
            $.ajax({
                method: "PUT",
                url: "/appointments/batch_update.json",
                data: { appointments }
            }).done(() => {
                toast.success("Successfully updated Route order!", {
                    position: toast.POSITION.TOP_CENTER
                });

                this.setState({ route: this.state.currentRoute, metrics: this.state.currentMetrics });

                if (this.props.saveOrderCallback) this.props.saveOrderCallback(this.props.tech.id.toString(), this.state.currentRoute);
            }).fail(() => {
                toast.error("Unable to update Route order...", {
                    position: toast.POSITION.TOP_CENTER
                });
            }).always(() => {
                this.setState({ savingOrder: false, editingOrder: false })
            })
        }
    };

    updateStartingPoint = (techId, day, startingPoint) => {
        if (this.props.updateStartingPoint) {
            this.props.updateStartingPoint(techId, day, startingPoint);
            return;
        }
    };

    toggleStartingPoint = (tech, day, startingPoint) => {
        if (this.props.toggleStartingPoint) {
            this.props.toggleStartingPoint(tech, day, startingPoint);
            return;
        }
    };

    route = (day) => {
        return ((this.state.currentRoute[this.props.tech.id] || {})[day] || []);
    };

    singleStopMetric = (day, apptIndex, stopNumber, which) => {
        if ((this.state.currentMetrics || {})[this.props.tech.id]) {
            const metric = this.state.currentMetrics[this.props.tech.id][day][which][apptIndex][stopNumber];
            if (which === "distances") {
                const miles = (metric / 1609).toFixed(0);
                const pluralizedMiles = miles <= 1 ? "Mile" : "Miles";
                return miles < 1 ? "< 1 Mile" : `${miles} ${pluralizedMiles}`;
            } else {
                if (metric < 60) {
                    return "< 1 Minute";
                } else {
                    return RouteTechUtils.durationToString(metric);
                }
            }
        }
    };

    differentMaxStops = (day) => {
        return this.state.originalSettings[`${day}_max_route_stops`] !== this.state.userSettings[`${day}_max_route_stops`];
    };

    toggleEditOrder = (day) => {
        const sortEl = $(`#sortable-${this.props.tech.id}-${day}`);
        if (this.state.editingOrder) {
            sortEl.sortable("destroy");
            this.setState({ editingOrder: false, currentRoute: { ...this.state.route }, currentMetrics: { ...this.state.metrics } });

            if (this.props.updateTechMetrics) this.props.updateTechMetrics(this.props.tech.id, day, { ...this.state.metrics[this.props.tech.id][day] });
        } else {
            this.setState({ editingOrder: true });

            sortEl.sortable({
                stop: (event, ui) => {
                    let newAppointments = Array.from(sortEl.find("li")).map(el => {
                        const index = this.route(day).findIndex(a => a.id === $(el).data("appt-id"));
                        return this.route(day)[index];
                    });

                    const newOrderIndexes = [0, ...newAppointments.map(appt => this.route(day).findIndex(a => a.id === appt.id) + 1)]; // 0 represents the starting point

                    const findNewMatrixOrder = (which) => {
                        const baseArray = this.state.currentMetrics[this.props.tech.id][day][which];
                        const array = [];

                        for (let i = 0; i <= newAppointments.length; i++) {
                            let originalIndex = i === 0 ? 0 : newOrderIndexes[i];

                            array.push(baseArray.map((_, index) => {
                                return baseArray[originalIndex][newOrderIndexes[index]];
                            }));
                        }

                        return array;
                    };

                    const distances = findNewMatrixOrder("distances");
                    const durations = findNewMatrixOrder("durations");

                    const currentRoute = { ...this.state.currentRoute[this.props.tech.id] };
                    const currentMetrics = { ...this.state.currentMetrics[this.props.tech.id] };

                    newAppointments = Utils.rearrangeAppointments(newAppointments, this.props.companySettings.service_days, this.props.timezone);

                    currentRoute[day] = newAppointments;
                    currentMetrics[day] = { distances, durations };

                    this.setState({ currentRoute: { [this.props.tech.id]: { ...currentRoute } }, currentMetrics: { [this.props.tech.id]: { ...currentMetrics } } });
                    if (this.props.updateTechMetrics) this.props.updateTechMetrics(this.props.tech.id, day, { distances, durations });
                }
            });
        }
    };

    render() {
        return(
            <div className="panel panel-default" data-tech-id={this.props.tech.id}>
                <div className="panel-body display-flex route-tech-appointment-widget-wrapper" ref={(e) => this.widgetEl = e}>
                    <div className="color-tab" style={{ background: RouteTechUtils.techColorFromIndex(this.props.techIndex) }}></div>
                    <div className="widget-innard">
                        <h4 className="no-margin">
                            { this.props.tech.name }
                            { this.props.displayDayName &&
                                <small> - { RouteTechUtils.shorthandDayToFull(this.props.displayDayName) }</small>
                            }
                        </h4>

                        <hr />

                        <div>
                            { this.props.toggleable &&
                                <div>
                                    <div className="form-group display-flex">
                                        <input
                                            id={`toggle-tech-${this.props.techIndex}`}
                                            type="checkbox"
                                            className="margin-5-right"
                                            checked={this.props.toggled}
                                            onChange={() => this.props.toggleCallback(this.props.tech.id)}
                                        />
                                        <label htmlFor={`toggle-tech-${this.props.techIndex}`}>Show on Map</label>
                                    </div>
                                    <hr />
                                </div>
                            }
                        </div>

                        <div className="metrics-wrapper">
                            { this.props.route &&
                                <Metrics
                                    routeSchedules={this.props.route}
                                    fetchMetrics={false}
                                    loadedRoutes={this.state.currentMetrics}
                                    metricsEnabled={true}
                                />
                            }
                        </div>
                    </div>
                    <div className="expanded-area">
                        <button className="expander btn btn-default" onClick={() => this.toggleExpand()}>
                            <i className="fa fa-chevron-right"></i>
                        </button>
                        <div className="expanded-scroll display-flex">
                            {
                                this.props.route && this.routeDays().map((day, dayIndex) => (
                                    <div className="panel panel-default margin-20-right" key={dayIndex}>
                                        <div className="day-wrapper panel-body display-flex flex-column">
                                            <div className="day-metrics display-flex justify-content-space-between width-100">
                                                <div className="display-flex flex-column justify-content-space-between">
                                                    <h5 className="no-margin">
                                                        <span className="color-gray margin-5-right">
                                                            Day:
                                                        </span>
                                                        { RouteTechUtils.shorthandDayToFull(day) }
                                                    </h5>
                                                    <h5 className="no-margin">
                                                        <span className="color-gray margin-5-right">
                                                            Total Drive Time:
                                                        </span>
                                                        { RouteTechUtils.routeToTotalDuration(this.userFormattedObj(this.state.metrics, day)) }
                                                    </h5>
                                                </div>
                                                <div className="display-flex flex-column justify-content-space-between">
                                                    <h5 className="no-margin">
                                                        <span className="color-gray margin-5-right">
                                                            Total Stops:
                                                        </span>
                                                        { RouteTechUtils.routeToTotalStops(this.userFormattedObj(this.state.route, day)) }
                                                    </h5>
                                                    <h5 className="no-margin">
                                                        <span className="color-gray margin-5-right">
                                                            Total Miles:
                                                        </span>
                                                        { RouteTechUtils.routeToTotalDistance(this.userFormattedObj(this.state.metrics, day)) }
                                                    </h5>
                                                </div>
                                            </div>

                                            <div className="width-100">
                                                <hr/>
                                            </div>

                                            <div className="max-stops form-group display-flex justify-content-center align-items-center no-margin-bottom">
                                                <label className="margin-10-right no-margin-bottom">Maximum Allowed Stops:</label>
                                                <select
                                                    className="form-control margin-10-right"
                                                    value={this.state.userSettings[`${day}_max_route_stops`]}
                                                    onChange={(e) => this.setState({ userSettings: { ...this.state.userSettings, [`${day}_max_route_stops`]: parseInt(e.target.value) } })}
                                                >
                                                    {
                                                        [...Array(25).keys()].map(index => (
                                                            <option value={index} key={index}>
                                                                { index }
                                                            </option>
                                                        ))
                                                    }
                                                </select>
                                                <button
                                                    className="btn btn-success"
                                                    disabled={!this.differentMaxStops(day) || this.state.savingMaxStops}
                                                    onClick={() => this.saveMaxStops(day)}
                                                >
                                                    <i className="fa fa-save margin-5-right"></i>
                                                    Save
                                                </button>
                                            </div>

                                            <div className="width-100">
                                                <hr/>
                                            </div>

                                            { !this.props.disableEditOrder &&
                                                <div className="display-flex justify-content-center margin-20-bottom">
                                                    { this.state.editingOrder ?
                                                        <Fragment>
                                                            <button
                                                                className="btn btn-success margin-10-right"
                                                                onClick={() => this.saveOrder(day)}
                                                                disabled={this.state.savingOrder}
                                                            >
                                                                Save Order
                                                            </button>
                                                            <button className="btn btn-default" onClick={() => this.toggleEditOrder(day)}>
                                                                <i className="fa fa-times"></i>
                                                            </button>
                                                        </Fragment>
                                                    :
                                                        <button className="btn btn-default" onClick={() => this.toggleEditOrder(day)}>
                                                            <i className="fa fa-list margin-5-right"></i>
                                                            Edit Order
                                                        </button>
                                                    }
                                                </div>
                                            }

                                            { this.props.startingPoints ?
                                                <Fragment>
                                                    <StartingPoint
                                                        startingPoint={this.props.startingPoints[day]}
                                                        tech={this.props.tech}
                                                        dayName={day}
                                                        toggleCallback={this.props.toggleable && this.toggleStartingPoint}
                                                        updateCallback={this.props.updateStartingPoint && this.updateStartingPoint}
                                                        startingPointMarker={this.props.startingPointMarker}
                                                        mapboxApiKey={window.mapboxApiKey}
                                                    />
                                                    <div className="display-flex justify-content-center color-gray margin-10-bottom">
                                                        <div className="stop-distance padding-10-right">
                                                            { this.singleStopMetric(day, 0, 1, "distances") }
                                                        </div>
                                                        <div className="margin-10-left">
                                                            { this.singleStopMetric(day, 0, 1, "durations") }
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            :
                                                <h5 className="text-center">
                                                    --
                                                </h5>
                                            }

                                            <ul id={`sortable-${this.props.tech.id}-${day}`} className={`no-padding ${this.state.editingOrder ?
                                            "sorting" : ""}`}>
                                                {
                                                    this.route(day).length > 0 && this.route(day).map((appt, apptIndex) => (
                                                        <li key={(appt.id) || apptIndex} data-appt-id={appt.id}>
                                                            <div className="display-flex align-items-center">
                                                                <i className="fa fa-sort"></i>
                                                                <div className={`panel panel-default margin-10-bottom ${appt.type}-tech-appointment`}>
                                                                    <div className="panel-body display-flex">
                                                                        <div className="appt-number display-flex align-items-center">
                                                                            <h3 className="color-gray margin-10-top">
                                                                                { apptIndex + 1 }
                                                                            </h3>
                                                                        </div>
                                                                        <div className="display-flex justify-content-space-between width-100">
                                                                            <div className="display-flex flex-column justify-content-space-between">
                                                                                <h5 className="no-margin">
                                                                                    { appt.account.contact_name }
                                                                                </h5>
                                                                                <h5 className="no-margin">
                                                                                    Start: { moment(appt.start).tz(this.props.timezone).format("hh:mm A") }
                                                                                </h5>
                                                                            </div>
                                                                            <div className="display-flex flex-column justify-content-space-between margin-10-left">
                                                                                { this.props.toggleable ?
                                                                                    <div className="form-group display-flex no-margin-bottom">
                                                                                        <input
                                                                                            id={`lock-to-appt-${appt.user_id}-${day}-${apptIndex}`}
                                                                                            className="margin-5-right"
                                                                                            type="checkbox"
                                                                                            checked={this.hasApptLocked(appt.account_id, day)}
                                                                                            onChange={() => this.toggleLockedAppt(appt.account_id, day)}
                                                                                            disabled={appt.type !== "service_stop"}
                                                                                        />
                                                                                        <label className="no-margin" htmlFor={`lock-to-appt-${appt.user_id}-${day}-${apptIndex}`}>Lock to this day</label>
                                                                                    </div>
                                                                                :
                                                                                    <div></div>
                                                                                }
                                                                                <h5 className="no-margin">
                                                                                    End: { moment(appt.end).tz(this.props.timezone).format("hh:mm A") }
                                                                                </h5>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            { ((apptIndex + 1) !== this.route(day).length) &&
                                                                <div className="display-flex justify-content-center color-gray margin-10-bottom">
                                                                    <div className="stop-distance padding-10-right">
                                                                        { this.singleStopMetric(day, apptIndex + 1, (apptIndex + 2), "distances") }
                                                                    </div>
                                                                    <div className="margin-10-left">
                                                                        { this.singleStopMetric(day, apptIndex + 1, (apptIndex + 2), "durations") }
                                                                    </div>
                                                                </div>
                                                            }
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};
