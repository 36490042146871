import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { TextMessageContextConsumer } from './ConversationsModal';
import Utils from '../../Utils';
import SafelySetInnerHTML from 'safely-set-inner-html';

const instance = new SafelySetInnerHTML({
    ALLOWED_TAGS: [
        "a"
    ],
    ALLOWED_ATTRIBUTES: [
        "href",
        "target"
    ]
});

export default class TextMessage extends React.Component {
    static propTypes = {
        textMessage: PropTypes.object.isRequired,
        company: PropTypes.object.isRequired
    }

    textSource = () => {
        return this.props.textMessage.from_number === this.props.company.sms_number ? "company" : "external"
    };

    createdAt = () => {
        return moment(this.props.textMessage.created_at).tz(this.props.company.timezone).format("MM-DD hh:mm A")
    };

    parsedText = (context) => {
        if (this.props.textMessage.mms) {
            return(
                <div className="content mms">
                    <img
                        className="width-100 cursor-pointer"
                        src={this.props.textMessage.content}
                        alt="MMS Message"
                        onClick={() => context.setExpandedImage(this.props.textMessage.content)}
                    />
                </div>
            )
        } else {
            let content = this.props.textMessage.content;

            return(
                <div className="content">
                    { instance.transform(Utils.linkifyRawUrls(content)) }
                </div>
            )
        }
    };

    fromLabel = (context) => {
        return(
            <div className="display-flex justify-content-space-between">
                { this.textSource() === "company" &&
                    <Fragment>
                        <div>
                            { this.props.company.name }
                        </div>
                    </Fragment>
                }
                { this.textSource() !== "company" &&
                    <Fragment>
                        { context.selectedConversation.user_name &&
                            <div>
                                { context.selectedConversation.user_name }
                            </div>
                        }
                        { context.selectedConversation.account_names.length > 0 &&
                            <Fragment>
                                { context.selectedConversation.account_names.length > 1 &&
                                    <div>
                                        Account Owner
                                    </div>
                                }
                                { context.selectedConversation.account_names.length === 1 &&
                                    <div>
                                        { context.selectedConversation.account_names[0] }
                                    </div>
                                }
                            </Fragment>
                        }
                        { (!context.selectedConversation.user_name) && (context.selectedConversation.account_names.length < 1) &&
                            <div>
                                { Utils.formattedPhone(this.props.textMessage.from_number) }
                            </div>
                        }
                    </Fragment>
                }
                <div>
                    { this.createdAt() }
                </div>
            </div>
        )
    }

    render() {
        const source = this.textSource();

        return(
            <TextMessageContextConsumer>
                { (context) => (
                    <div className={`text-message-wrapper ${source}`}>
                        <div className="text-message" lang="en">
                            <div className="from-label">
                                { this.fromLabel(context) }
                            </div>
                            { this.parsedText(context) }
                        </div>
                    </div>
                )}
            </TextMessageContextConsumer>
        )
    }
}
