import React from 'react';
import PropTypes from 'prop-types';

import { toast } from 'react-toastify';

export default class SaveTemplate extends React.Component {
    static propTypes = {
        repair: PropTypes.object.isRequired,
        getAttachments: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.baseState = {
            loading: null,
            nameInvalid: false
        }

        this.state = this.baseState;
    }

    componentDidMount() {
        $("#save-repair-template-modal").on("show.bs.modal", (e) => {
            $(e.target).addClass("center-screen-modal");
        });

        $("#save-repair-template-modal").on("hide.bs.modal", (e) => {
            $(e.target).removeClass("center-screen-modal");

            this.setState({ ...this.baseState });
        });
    }

    saveRepairTemplate = () => {
        const templateName = this.templateName.value;

        if (templateName.length < 1) {
            this.setState({ nameInvalid: true });
            return false;
        }

        this.setState({ loading: true });

        let template = (({ description, client_notes, cost, terms, repair_items, tags, settings }) => ({ description, client_notes, cost, terms, repair_items, tags, settings }))(this.props.repair);
        template.name = templateName;
        const attachments = this.props.getAttachments();

        if (attachments.length > 0) {
            template.rich_media_elements = attachments
        }

        if (this.props.repair.expense_set && (this.props.repair.expense_set.expenses || []).length > 0) {
            template.expenses = this.props.repair.expense_set.expenses
        }

        $.ajax({
            method: "POST",
            url: "/repair_templates.json",
            contentType: "application/json",
            data: JSON.stringify({
                template
            })
        }).done((res) => {
            $("#save-repair-template-modal").modal("hide");

            toast.success("Repair Template successfully saved!", {
                position: toast.POSITION.TOP_CENTER,
            });
        }).fail((res) => {
            const errors = Object.values(res.responseJSON.errors);

            const Msg = ({ closeToast }) => (
                <ul>
                    { errors.map((error, index) => {
                        return(
                            <li key={index}>
                                { error }
                            </li>
                        )
                    }) }
                </ul>
            );
            toast.error(<Msg />, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: false
            })
        }).always(() => this.setState({ loading: false }));
    };

    render() {
        return(
            <div
                id="save-repair-template-modal"
                className="modal fade"
                tabIndex="-1"
                role="dialog"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title float-left">Save Repair Template</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        { !this.state.loading &&
                            <div className="modal-body">
                                <h4 className="text-center margin-20-bottom">
                                    Template Name:
                                </h4>
                                <div className="form-group">
                                    <input type="text" className="form-control" ref={(input) => this.templateName = input}/>
                                    { this.state.nameInvalid &&
                                        <div className="validation-wrapper margin-10-bottom">
                                            <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                                            You must give this Template a Name
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                        { this.state.loading &&
                            <div className="display-flex justify-content-center margin-20">
                                <i className="fa fa-spinner fa-pulse fa-3x fa-fw align-self-center flex-1"></i>
                            </div>
                        }
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={this.saveRepairTemplate}
                            >
                                Save
                            </button>
                            <button
                                type="button"
                                className="btn btn-default"
                                data-dismiss="modal"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
