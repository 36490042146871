import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import MaskedInput from 'react-text-mask';
import { toast } from 'react-toastify';

const ContactInfoEntry = (props) => {
    const [ci, setCi] = useState(props.ci);
    const [editing, setEditing] = useState(!props.ci.id);

    const save = () => {
        const method = ci.id ? "PUT" : "POST";
        let url = `/accounts/${props.accountId}/contact_infos`;

        if (ci.id) url += `/${props.ci.id}`;

        $.ajax({
            method,
            url,
            data: { ...ci }
        }).done((res) => {
            toast.success("Successfully saved Contact Info!", {
                position: toast.POSITION.TOP_CENTER
            });

            if (res) {
                setCi({ ...ci, id: res.id });
                props.updateCi(props.index, res);
            }

        }).fail(() => {
            toast.error("Unable to save Contact Info...", {
                position: toast.POSITION.TOP_CENTER
            });
        }).always(() => {
            setEditing(false);
        })
    };

    return(
        <Fragment>
            { editing &&
                <div className="form-group display-flex">
                    <MaskedInput
                        className="form-control"
                        mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                        placeholder="(555) 555-5555"
                        name="phone"
                        defaultValue={props.ci.phone}
                        onChange={(e) => setCi({ ...ci, phone: e.target.value })}
                    />
                    <button className="btn btn-success margin-5-left" onClick={save}>
                        <i className="fa fa-save"></i>
                    </button>
                    <button className="btn btn-danger margin-5-left" onClick={() => props.destroyCi(props.index)}>
                        <i className="fa fa-trash"></i>
                    </button>
                </div>
            }
            { !editing &&
                <div className="contact-phone display-flex align-items-center margin-10-bottom">
                    <a href={`tel://${ci.phone}`}>
                        { ci.phone }
                    </a>
                    <button className="btn btn-default margin-5-left" onClick={() => setEditing(true)}>
                        <span className="glyphicon glyphicon-pencil"></span>
                    </button>
                    <button className="btn btn-danger margin-5-left" onClick={() => props.destroyCi(props.index)}>
                        <i className="fa fa-trash"></i>
                    </button>
                </div>
            }
        </Fragment>
    )
};

ContactInfoEntry.propTypes = {
    ci: PropTypes.object.isRequired,
    accountId: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    destroyCi: PropTypes.func.isRequired,
    updateCi: PropTypes.func.isRequired
}

export default ContactInfoEntry;
