import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import AqsModal from 'AqsModal';
import Utils from 'Utils';
import RouteTechUtils from '@shared/components/RouteTech/Utils';

export default class OptimizeModal extends React.Component {
    static propTypes = {
        selectedRoutes: PropTypes.object.isRequired,
        pusherKey: PropTypes.string.isRequired,
        environment: PropTypes.string.isRequired,
        currentCompany: PropTypes.object.isRequired,
        callback: PropTypes.func.isRequired,
        startTimes: PropTypes.object.isRequired,
        selectedTechs: PropTypes.array.isRequired,
        toggleOptimizing: PropTypes.func.isRequired
    }

    constructor(props) {
        super(props);

        this.baseState = {
            loading: false,
            completed: false,
            failed: false,
            cacheKey: null,
            availableDays: [],
            enoughStopsAvailable: true
        }

        this.state = this.baseState;

        if (!window.pusherChannel) {
            const pusher = new Pusher(this.props.pusherKey, {
                cluster: "us2",
                forceTLS: true
            });

            window.pusherChannel = pusher.subscribe(`admin-alerts-${this.props.environment}-${this.props.currentCompany.channel_id}`);
        }
    }

    show = () => {
        this.modalEl.show();
    };

    optimizeRoutes = () => {
        if (this.enoughStopsAvailable()) {
            this.setState({ loading: true });
            this.props.toggleOptimizing();

            $.ajax({
                method: "POST",
                url: "/reports/route/optimizer",
                data: {
                    appt_ids: RouteTechUtils.routesToAppointments(this.props.selectedRoutes, this.props.currentCompany.timezone).map(a => a.id),
                    user_ids: Object.keys(this.props.selectedRoutes),
                    available_days: this.state.availableDays
                }
            }).done(res => {
                this.setState({ cacheKey: res.cache_key });

                window.pusherChannel.bind("route-optimizer", (data) => {
                    if (data.success) {
                        $.ajax({
                            method: "GET",
                            url: `/reports/route/optimizer/${this.state.cacheKey}`
                        }).done(res => {
                            const schedules = {};
                            const userIds = Object.keys(res.schedules);
                            userIds.forEach(id => {
                                Object.keys(res.schedules[id]).filter(k => k !== "starting_points").forEach(day => {
                                    const appts = res.schedules[id][day];
                                    const fullDay = RouteTechUtils.shorthandDayToFull(day).toLowerCase();
                                    const newAppointments = Utils.rearrangeAppointments(appts, this.props.startTimes, this.props.currentCompany.timezone, fullDay)

                                    if (!schedules[id]) schedules[id] = {};

                                    schedules[id][day] = newAppointments;
                                    schedules[id].starting_points = res.schedules[id].starting_points;
                                });
                            });

                            const selectedUsers = Object.keys(this.props.selectedRoutes);
                            const emptyRoutes = selectedUsers.filter(u => !Object.keys(res.schedules).includes(u));

                            const missingMetrics = {};

                            emptyRoutes.forEach(id => {
                                schedules[id] = { starting_points: { ...this.props.selectedRoutes[id].starting_points } };

                                missingMetrics[id] = {};
                            });

                            this.props.callback(schedules, { ...res.metrics, ...missingMetrics });

                            this.setState({ completed: true, loading: false });
                            this.props.toggleOptimizing();
                        }).fail(() => {
                            this.setState({ failed: true, loading: false });
                            this.props.toggleOptimizing();
                        })
                    } else {
                        this.setState({ failed: true, loading: false });
                        this.props.toggleOptimizing();
                    }

                    window.pusherChannel.unbind("route-optimizer");
                });
            }).fail(res => {
                this.setState({ failed: true, loading: false });
                this.props.toggleOptimizing();
            })
        }
    };

    stopsAvailable = () => {
        const userIds = Object.keys(this.props.selectedRoutes);
        const stopsPerUser = userIds.map(id => {
            const tech = this.props.selectedTechs.find(t => t.id.toString() === id);
            return this.state.availableDays.map(day => tech.settings[`${day}_max_route_stops`]);
        }).flat();

        return stopsPerUser.reduce((a,b) => a + b);
    };

    enoughStopsAvailable = () => {
        const result = this.stopsAvailable() >= RouteTechUtils.routesToAppointments(this.props.selectedRoutes, this.props.currentCompany.timezone).length
        this.setState({ enoughStopsAvailable: result });

        return result;
    };

    toggleAvailableDay = (day) => {
        let availableDays;
        if (this.state.availableDays.includes(day)) {
            availableDays = this.state.availableDays.filter(ad => ad !== day);
        } else {
            availableDays = [...this.state.availableDays, day];
        }

        this.setState({ availableDays });
    };

    render() {
        return(
            <AqsModal
                id="optimizer-modal"
                headerText="Optimize Route(s)"
                ref={(e) => this.modalEl = e}
                closeCallback={() => this.setState(this.baseState)}
            >
                <div className="display-flex flex-column align-items-center">
                    { (!this.state.loading && !this.state.completed && !this.state.failed && this.state.enoughStopsAvailable) &&
                        <Fragment>
                            <h4 className="text-center">
                                Which days are available for these Appointments to be assigned to?
                            </h4>

                            <h5 className="margin-30-bottom text-center">
                                (You will be able to review the changes made to all routes before they are saved)
                            </h5>

                            <div className="special-select-wrapper">
                                {
                                    ["sun", "mon", "tue", "wed", "thu", "fri", "sat"].map((day, index) => {
                                        const className = this.state.availableDays.includes(day) ? "special-select selected" : "special-select";

                                        return(
                                            <div className="select" key={index}>
                                                <div className={className} onClick={() => this.toggleAvailableDay(day) }>
                                                    { Utils.humanize(day) }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                            <div className="margin-30-top">
                                <button
                                    className="btn btn-success"
                                    disabled={this.state.availableDays.length < 1}
                                    onClick={this.optimizeRoutes}
                                >
                                    Optimize Route(s)
                                </button>
                            </div>
                        </Fragment>
                    }
                    { this.state.loading &&
                        <Fragment>
                            <h4 className="text-center">
                                Optimizing { RouteTechUtils.routesToAppointments(this.props.selectedRoutes, this.props.currentCompany.timezone).length } Appointments across { Object.keys(this.props.selectedRoutes).length } Technicians...
                            </h4>
                            <h5 className="text-center">
                                (This may take a few minutes)
                            </h5>
                            <i className="fa fa-spinner fa-pulse fa-3x fa-fw align-self-center flex-1"></i>
                        </Fragment>
                    }
                    { (!this.state.loading && !this.state.enoughStopsAvailable) &&
                        <Fragment>
                            <div>
                                <i className="fa fa-exclamation-triangle fa-3x color-warning"></i>
                            </div>
                            <h4 className="text-center">
                                There are only { this.stopsAvailable() } / { RouteTechUtils.routesToAppointments(this.props.selectedRoutes, this.props.currentCompany.timezone).length } Appointments that are allowed to be assigned to the selected Technicians.
                            </h4>
                            <h4 className="text-center">
                                You must Adjust the Max Stops Allowed and/or Days Available before you can Optimize Selected Routes.
                            </h4>
                        </Fragment>
                    }
                    { this.state.completed &&
                        <Fragment>
                            <div className="checkmark-animation margin-10-top">
                                <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/><path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/></svg>
                            </div>
                            <h4 className="text-center">
                                All Selected Appointments successfully optimized!
                            </h4>
                        </Fragment>
                    }
                    { this.state.failed &&
                        <Fragment>
                            <h4 className="text-center">
                                Unable to optimize Routes
                            </h4>
                            <i className="fa fa-exclamation-triangle fa-3x"></i>
                        </Fragment>
                    }
                </div>
            </AqsModal>
        )
    }
}
