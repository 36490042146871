import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

export default class AppointmentOrder extends React.Component {
    static propTypes = {
        techs: PropTypes.array.isRequired,
        timezone: PropTypes.string.isRequired,
        defaultTech: PropTypes.number.isRequired,
        serviceDays: PropTypes.object.isRequired,
        apptCallback: PropTypes.func.isRequired
    }

    constructor(props) {
        super(props);

        this.baseState = {
            selectedTech: props.defaultTech,
            selectedDay: null,
            appointments: null,
            map: null,
            mapMarkers: [],
            editing: null,
            infoWindows: [],
            updated: false,
            showInfo: false,
            missingCoords: []
        }

        this.state = { ...this.baseState };
    }

    componentDidMount() {
        const map = new google.maps.Map($(".map-wrapper")[0], {
            zoom: 11,
            center: { lat: 0, lng: 0 },
            zoomControlOptions: {
                position: google.maps.ControlPosition.LEFT_CENTER
            }
        });

        this.setState({ map });

        window.addAppointmentToOrder = this.addAppointmentToOrder;
    }

    dayOptions = () => {
        const days = [0, 1, 2, 3, 4, 5, 6];
        const today = moment.tz(this.props.timezone).day();

        return days.map((day, index) => {
            let timestamp;

            if (today > day) {
                let target = day + 7;
                timestamp = moment.tz(this.props.timezone).day(target);
            } else {
                timestamp = moment.tz(this.props.timezone).day(day)
            }
            return(
                <option key={index} value={moment(timestamp).tz(this.props.timezone).format("YYYY-MM-DD")}>
                    { moment(timestamp).tz(this.props.timezone).format("dddd - MM-DD-YYYY") }
                </option>
            )
        });
    };

    fetchAppointments = () => {
        this.clearMapMarkers();
        this.setState({ appointments: [] })

        const start = moment.tz(this.state.selectedDay, this.props.timezone).startOf("day").utc().format();
        const end = moment.tz(this.state.selectedDay, this.props.timezone).endOf("day").utc().format();

        $.ajax("/appointments.json", {
            method: "GET",
            data: {
                q: {
                    user_id_eq: this.state.selectedTech,
                    start_gteq: start,
                    end_lteq: end,
                    incomplete_eq: true
                }
            }
        }).done(_appointments => {
            const appointments = _appointments.filter(appt => appt.full_location).sort((apptA, apptB) => moment(apptA.start) - moment(apptB.start));
            const apptsWithoutAcct = appointments.filter(appt => !appt.account)
            let geocoder;
            if (apptsWithoutAcct.length > 0) {
                geocoder = new google.maps.Geocoder();
            }
            const geocodedAppointments = apptsWithoutAcct.map(appt => {
                return new Promise((resolve, reject) => {
                    geocoder.geocode({ address: appt.full_location }, (results, status) => {
                        if (status === "OK") {
                            const geometry = results[0].geometry.location
                            appt.account = { location: { lat: geometry.lat(), lng: geometry.lng() } }
                            resolve();
                        } else {
                            console.error(`INVALID OTHER APPT FULL LOCATION - ${appt.full_location}`)
                            reject()
                        }
                    })      
                });
            })
            Promise.all(geocodedAppointments).then(() => {
                if (appointments.length > 0) {
                    this.setState({ appointments }, () => {
                        const withCoords = appointments.filter(appt => appt.account?.location?.lat && appt.account?.location?.lng)

                        google.maps.event.trigger(this.state.map, 'resize');
                        this.state.map.setCenter({ lat: parseFloat(withCoords[0].account.location.lat), lng: parseFloat(withCoords[0].account.location.lng) });
                        this.setPlaceMarkers();
                    });
                } else {
                    this.setState({ appointments })
                }
            })
        });
    };

    clearMapMarkers = () => {
        this.state.mapMarkers.forEach(marker => marker.setMap(null));
    };

    setPlaceMarkers = () => {
        const colors = {
            service_stop: "#3a87ad",
            repair: "#d6ca28",
            repair_item: "#d6ca28",
            other: "#2400dc"
        };
        const withCoords = this.state.appointments.filter(appt => appt.account.location.lat && appt.account.location.lng);
        const mapMarkers = withCoords.map((appt, index) => {
            return new google.maps.Marker({
                position: {
                    lat: parseFloat(appt.account.location.lat),
                    lng: parseFloat(appt.account.location.lng)
                },
                icon: {
                    path: "M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0",
                    fillColor: colors[appt.type],
                    fillOpacity: 1,
                    strokeWeight: 2,
                    labelOrigin: new google.maps.Point(0, 10)
                },
                map: this.state.map,
                label: (index + 1).toString(),
                appt_id: appt.id,
                optimized: false // supposed to help with click events
            });
        });

        const missingCoords = this.state.appointments.filter(appt => !appt.account.location.lng || !appt.account.location.lat);

        this.setState({ mapMarkers, missingCoords }, () => {
            $("[data-toggle='popover']").popover();
        });
    };

    selectFields = (order) => {
        return(
            <div className="width-100">
                <div className="display-flex margin-20-top">
                    <div className="form-group flex-1">
                        <select
                            className="form-control"
                            value={this.state.selectedTech}
                            onChange={(e => this.setState({ selectedTech: e.target.value }))}
                        >
                            { this.props.techs.map((tech, index) => {
                                return(
                                    <option key={index} value={tech.id}
                                    >
                                        { tech.name }
                                    </option>
                                )
                            }) }
                        </select>
                    </div>
                    <div className="form-group flex-1">
                        <select
                            className="form-control"
                            onChange={(e => this.setState({ selectedDay: e.target.value }))}
                        >
                            <option value="">
                                Select day...
                            </option>
                            { this.dayOptions() }
                        </select>
                    </div>
                </div>
                <div className="map-action-buttons display-flex justify-content-center">
                    <button
                        className="btn btn-primary"
                        disabled={!this.state.selectedDay}
                        onClick={() => this.fetchAppointments()}
                    >
                        Show Appointments
                    </button>
                    { order &&
                        <div>
                            <button
                                className="btn btn-primary margin-10-left"
                                disabled={!this.state.selectedDay}
                                onClick={() => this.changeOrder()}
                            >
                                Change Order
                            </button>
                            <button
                                className="btn btn-success margin-10-left"
                                onClick={() => this.saveOrder()}
                                disabled={(!this.state.editing) || (this.state.editing && this.state.editing.length < this.state.appointments.length)}
                            >
                                Save Order
                            </button>
                        </div>
                    }
                </div>
                { order && this.state.editing &&
                    <div className="margin-8-top display-flex justify-content-center">
                        <label
                            htmlFor="customer-info"
                        >
                            Show Customer Info: &nbsp;
                        </label>
                        <input
                            id="customer-info"
                            type="checkbox"
                            onChange={() => this.setState({ showInfo: !this.state.showInfo })}
                            checked={this.state.showInfo}
                        />
                    </div>
                }
            </div>
        )
    };

    addAppointmentToOrder = (index) => {
        const appt = this.state.appointments[index];
        const infoWindow = this.state.infoWindows[index];
        const marker = this.state.mapMarkers[index];

        this.setState({ editing: [...this.state.editing, appt] }, () => {
            marker.setLabel((this.state.editing.length).toString());

            infoWindow.close();
            google.maps.event.clearInstanceListeners(marker);
        });
    };

    changeOrder = () => {
        const infoWindows = this.state.mapMarkers.map((marker, index) => {
            marker.setLabel(null);
            google.maps.event.clearInstanceListeners(marker);

            const appt = this.state.appointments[index];
            let infoWindowContent = "";
            if (appt.account) {
                infoWindowContent += `
                    <b>Contact Name:</b>
                    <br />
                    ${appt.account.contact_name}
                    <br /><br />
                    <b>Address:</b>
                    <br />
                    ${appt.full_location}
                `
            } else if (appt.full_location) {
                infoWindowContent += `
                    <b>Address:</b>
                    <br />
                    ${appt.full_location}
                `
            }

            if (appt.notes) {
                infoWindowContent += `
                    <b>Notes:</b>
                    <br />
                    ${appt.notes}
                `
            }

            infoWindowContent += `
                <br /><br />
                <button class="btn btn-primary" onClick="addAppointmentToOrder(${index})">
                    Select
                </button>
            `

            const infoWindow = new google.maps.InfoWindow({
                content: infoWindowContent
            });

            marker.addListener("mousedown", () => {
                if (this.state.showInfo) {
                    infoWindow.open(this.state.map, marker);
                } else {
                    this.addAppointmentToOrder(index);
                }
            });

            return infoWindow;
        });

        this.setState({ editing: [], infoWindows });
    };

    saveOrder = () => {
        const firstAppt = this.state.editing[0];

        const startDay = moment(firstAppt.start).format("dddd").toLowerCase();

        let startTime = this.props.serviceDays[`${startDay}_start_time`];
        startTime = `${startTime.substr(0,2)}:${startTime.substr(2,3)}`;

        const startDateTime = moment(`${moment(firstAppt.start).format("YYYY-MM-DD")} ${startTime}`);

        this.state.editing.reduce((start, appt) => {
            const end = moment(appt.end);
            const _start = moment(appt.start);
            const duration = moment.duration(end.diff(_start))
            const durationMinutes = duration.as("minutes");

            appt.start = start.format();
            start = start.add(durationMinutes, "m");
            appt.end = start.format();

            return start;
        }, startDateTime);

        // TODO refactor to make just one request
        const ajaxes = this.state.editing.map((appt) => {
            return $.ajax({
                method: "PUT",
                url: `appointments/${appt.id}`,
                data: {
                    appointment: {
                        start: appt.start,
                        end: appt.end
                    }
                }
            });
        });

        $.when(ajaxes).done(() => {
            this.props.apptCallback(this.state.editing);

            this.state.mapMarkers.forEach(marker => {
                google.maps.event.clearInstanceListeners(marker);
            })

            this.setState({ updated: true, editing: null });
        });
    };

    missingCoordsPopover = () => {
        const content = this.state.missingCoords.map(appt => `<a href='/accounts/${appt.account_id}' target='_blank'>${appt.account.contact_name} - ${appt.account.location.address}</a>`).join("<br />");
        return(
            <a
                href="#"
                data-toggle="popover"
                data-content={content}
                data-placement="bottom"
                data-html="true"
            >
                Show
            </a>
        )
    };

    render() {
        const appts = this.state.appointments;
        const mapClass = (appts && appts.length > 0) ? "display-block" : "display-none";
        const wrapperClass = this.state.missingCoords.length > 0 && "with-alert";
        return(
            <div id="appointment-order-modal" className="modal fade">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button
                                className="close"
                                type="button"
                                data-dismiss="modal"
                            >
                                <span>
                                    &times;
                                </span>
                            </button>
                            <h4 className="modal-title">
                                Arrange appointment order
                                { this.state.updated &&
                                    <small>
                                        - Order updated!
                                    </small>
                                }
                            </h4>
                        </div>
                        <div className="modal-body">
                            { !appts &&
                                <div className="display-flex flex-column justify-content-center align-items-center height-100 text-center">
                                    <h3>
                                        Select user and day
                                    </h3>

                                    { this.selectFields() }

                                </div>
                            }
                            { appts && appts.length > 0 &&
                                <div className="display-flex flex-column justify-content-center align-items-center">
                                    { this.selectFields(true) }
                                </div>
                            }

                            { appts && appts.length === 0 &&
                                <div>
                                    <h4 className="text-center">
                                        No incomplete scheduled appointments with locations set for this day.
                                    </h4>
                                    { this.selectFields() }
                                </div>
                            }

                            { this.state.missingCoords.length > 0 &&
                                <div className="alert alert-warning margin-10-top">
                                    { this.state.missingCoords.length === 1 &&
                                        <Fragment>
                                            <span className="margin-5-right">
                                                1 account does not have a validated address and cannot be shown on the map -
                                            </span>
                                            { this.missingCoordsPopover() }
                                        </Fragment>
                                    }
                                    { this.state.missingCoords.length > 1 &&
                                        <Fragment>
                                            <span className="margin-5-right">
                                                { `${this.state.missingCoords.length} accounts do not have validated addresses and cannot be shown on the map` } -
                                            </span>
                                            { this.missingCoordsPopover() }
                                        </Fragment>
                                    }
                                </div>
                            }

                            <div className={`map-wrapper ${mapClass} ${wrapperClass}`}></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
