import React from 'react';
import { ToastContainer } from 'react-toastify';

export default class AqsToastContainer extends React.Component {

    styles = () => {
        const stylez = {};
        if (navigator.userAgent.match(/iPhone/)) {
            stylez.paddingTop = "30px";
        }

        return stylez;
    };

    render() {
        return(
            <ToastContainer
                style={this.styles()}
            />
        )
    }
}
