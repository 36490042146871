import PropTypes from 'prop-types';
import React from 'react';

import Modal from 'AqsModal';

export default class ImageModal extends React.Component {
    static propTypes = {
        images: PropTypes.array.isRequired,
        destroyImage: PropTypes.func.isRequired,
        destroyingImage: PropTypes.bool,
        saveImageCaption: PropTypes.func,
        disableCaption: PropTypes.bool
    }

    constructor(props) {
        super(props);

        this.baseState = {
            caption: null
        };

        this.state = this.baseState;
    }

    componentDidMount() {
        $("#image-carousel").on("slid.bs.carousel", this.setCaption);
    }

    activeImage = () => {
        const index = $("#image-modal").find(".item.active").data("index");

        return this.props.images[index];
    };

    setCaption = () => {
        if ((this.activeImage() || {}).caption) {
            this.setState({ caption: this.activeImage().caption });
        } else {
            this.setState({ caption: null });
        }
    };

    render() {
        return(
            <Modal
                id="image-modal"
                large={true}
                leftFooterButtonText={this.props.destroyingImage ? "Destroying..." : "Destroy Image"}
                leftFooterButtonDisabled={this.props.destroyingImage}
                leftFooterButtonAction={this.props.destroyImage}
                leftFooterButtonClass="btn-danger"
                showCallback={this.setCaption}
            >
                <div id="image-carousel" className="carousel slide" data-interval="false">
                    <ol className="carousel-indicators">
                        { this.props.images.map((image, index) => {
                            return(
                                <li
                                    data-target="#image-carousel"
                                    data-slide-to={index}
                                    key={index}
                                >
                                </li>
                            )
                        }) }
                    </ol>

                    <div className="carousel-inner">
                        { this.props.images.map((image, index) => {
                            return(
                                <div
                                    className="item"
                                    data-index={index} key={index}
                                >
                                    <img
                                        src={image.full_url}
                                        alt="AquaSuite Resource Image"
                                        key={index}
                                    />
                                    { image.caption && image.caption.length > 0 &&
                                        <div className="carousel-caption">
                                            { image.caption }
                                        </div>
                                    }
                                </div>
                            )
                        }) }
                        <a
                            href="#image-carousel"
                            className="left carousel-control"
                            data-slide="prev"
                        >
                            <span className="glyphicon glyphicon-chevron-left"></span>
                        </a>
                        <a
                            href="#image-carousel"
                            className="right carousel-control"
                            data-slide="next"
                        >
                            <span className="glyphicon glyphicon-chevron-right"></span>
                        </a>
                    </div>
                </div>
                { !this.props.disableCaption &&
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group margin-20-top">
                                <input
                                    value={this.state.caption || ""}
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Image Caption"
                                    onChange={(e) => this.setState({ caption: e.target.value })}
                                />
                            </div>
                            <div className="form-group">
                                <button
                                    className="btn btn-success margin-10-right"
                                    disabled={(this.state.caption || "").length < 1}
                                    onClick={() => this.props.saveImageCaption(this.activeImage().id, this.state.caption)}
                                >
                                    <i className="fa fa-save margin-5-right"></i>
                                    Save Caption
                                </button>
                                <button
                                    className="btn btn-danger"
                                    disabled={!(this.activeImage() || {}).caption}
                                    onClick={() => this.props.saveImageCaption(this.activeImage().id, null, () => this.setState({ caption: null }))}
                                >
                                    <i className="fa fa-trash margin-5-right"></i>
                                    Remove Caption
                                </button>
                            </div>
                        </div>
                    </div>
                }
            </Modal>

        )
    }
}
