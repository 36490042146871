import React, { Fragment, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import Switch from '@shared/components/CheckboxSwitch';
import ImageArea from '@shared/components/ImageArea/ImageArea';

import Utils from 'Utils';

const RepairItemTicket = (props) => {
    const [validations, setValidations] = useState({
        inTime: false,
        outTime: false,
        tasks: false,
        techNotes: false,
        images: false
    });

    const inTimeInputElRef = useRef(null);
    const outTimeInputElRef = useRef(null);
    const privateNotesElRef = useRef(null);

    useEffect(() => {
        $(".datetime-picker").datetimepicker({
            allowInputToggle: false,
            showClose: true,
            format: "MM/DD/YYYY hh:mm A",
            icons: {
                time: "glyphicon dt-picker-icon-time",
                close: "glyphicon dt-picker-icon-close",
                date: "glyphicon dt-picker-icon-date"
            }
        });

        $(".datetime-picker").on("dp.change", (e) => {
            const repair_item = { ...props.repairItem };

            const momentTime = moment(e.target.value, "MM/DD/YYYY hh:mm A").tz(props.timezone);
            props.update({ ...repair_item, [e.target.name]: momentTime.utc().format("YYYY-MM-DD HH:mm") });

            e.target.value = momentTime.format("MM/DD/YYYY hh:mm A")
        });
    }, []);

    useEffect(() => {
        if (props.repairItem.in_time) {
            inTimeInputElRef.current.value = moment.utc(props.repairItem.in_time).tz(props.timezone).format("MM/DD/YYYY hh:mm A");
        }

        if (props.repairItem.out_time) {
            outTimeInputElRef.current.value = moment.utc(props.repairItem.out_time).tz(props.timezone).format("MM/DD/YYYY hh:mm A");
        }
    }, [props.appointment.id, props.repairItem.in_time, props.repairItem.out_time]);

    const logTime = (which) => {
        if (which === "out" && !props.repairItem.in_time) {
            setValidations({ ...validations, inTime: true });
            privateNotesElRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
        } else {
            const momentTime = moment().tz(props.timezone);

            setValidations({ ...validations, [`${which}Time`]: false });
            const repair_item = { ...props.repairItem, [`${which}_time`]: momentTime.utc().format("YYYY-MM-DD HH:mm") }

            props.update({ ...repair_item });

            if (which === "out") {
                outTimeInputElRef.current.value = momentTime.format("MM/DD/YYYY hh:mm A");
            } else {
                inTimeInputElRef.current.value = momentTime.format("MM/DD/YYYY hh:mm A");
            }
        }
    };

    const toggleTask = (task, index) => {
        const tasks = [...props.repairItem.tasks].map((_task, _index) => {
            return _index === index ? { ...task, completed: !task.completed } : { ..._task }
        });

        props.update({ ...props.repairItem, tasks });
    };

    const valid = () => {
        const settings = props.repairItem.settings || {};
        const validations = {
            inTime: (props.repairItem.in_time || "").length < 1,
            outTime: (props.repairItem.out_time || "").length < 1,
            tasks: (settings.tasks_required && (props.repairItem.tasks || []).some(t => !t.completed)),
            techNotes: (settings.technician_notes_required && (props.repairItem.public_notes || "").length < 1),
            images: (settings.image_required && props.repairItem.images.length < 1)
        };

        setValidations({ ...validations });

        setTimeout(() => {
            for (const [key, value] of Object.entries(validations)) {
                if (value) {
                    this[`${key}ValidationEl`].scrollIntoView({ behavior: "smooth", block: "center" });

                    break;
                }
            }
        });

        return !Object.values(validations).includes(true);
    };

    const save = () => {
        if (valid()) {
            props.save(props.repairItem, true);
        }
    };

    return(
        <div className="repair-ticket-wrapper">
            <div className="row">
                <div className="col-md-6">
                    <h5>
                        Description:
                    </h5>
                    <div className="well">
                        { props.repairItem.description }
                    </div>
                </div>
                <div className="col-md-6" ref={privateNotesElRef}>
                    <h5>
                        Private Notes:
                    </h5>
                    <div className="well">
                        { props.repairItem.repair.private_notes }
                    </div>
                </div>
            </div>

            <hr/>

            <div className="row">
                <div className="col-md-4">
                    <div className="form-group">
                        <label>
                            In Time:
                        </label>
                        <input
                            className="form-control datetime-picker"
                            type="text"
                            readOnly={true}
                            name="in_time"
                            ref={inTimeInputElRef}
                        />
                        <button
                            className="btn btn-default width-100 margin-10-top" onClick={() => logTime("in")}>
                            Log your In Time
                        </button>
                        { validations.inTime &&
                            <div className="validation-wrapper">
                                <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                                You must log your In Time first
                            </div>
                        }
                    </div>
                </div>

                <div className="col-md-12">
                    <hr/>
                </div>
            </div>

            { props.repairItem.tasks.length > 0 &&
                <div className="row">
                    <div className="col-md-4">
                        <h5 className="no-margin-top">
                            Tasks:
                        </h5>
                        {
                            props.repairItem.tasks.map((task, index) => (
                                <div className="display-flex align-items-center margin-10-bottom" key={index}>
                                    <Switch
                                        name={`repair-item-task-${props.appointment.id}-${index}`}
                                        checked={task.completed}
                                        onChange={() => toggleTask(task, index)}
                                        onText="✓"
                                        offText="x"
                                    />
                                    <label htmlFor={`repair-item-task-${props.appointment.id}-${index}`} className="margin-5-left no-margin-bottom">
                                        { task.description }
                                    </label>
                                </div>
                            ))
                        }
                        { validations.tasks &&
                            <div className="validation-wrapper">
                                <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                                You must complete all Tasks
                            </div>
                        }
                    </div>
                    <div className="col-md-12">
                        <hr/>
                    </div>
                </div>
            }

            <div className="row">
                <div className="col-md-4">
                    <div className="form-group">
                        <label>Technician Notes:</label>
                        <textarea
                            className="form-control"
                            onChange={(e) => props.update({ ...props.repairItem, public_notes: e.target.value })}
                            value={props.repairItem.public_notes || ""}
                        >
                        </textarea>
                        { validations.techNotes &&
                            <div className="validation-wrapper">
                                <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                                You must leave notes for this Repair
                            </div>
                        }
                    </div>
                </div>
                <div className="col-md-12">
                    <hr/>
                </div>
            </div>

            <div className="row">
                <div className="col-md-4">
                    <h4
                        className="no-margin-top margin-10-bottom cursor-pointer"
                        href="#repair-item-images"
                        data-toggle="collapse"
                        onClick={(e) => $(e.target).find("span").toggleClass("rotate-right")}
                    >
                        <span className="glyphicon glyphicon-chevron-down rotate-right"></span>
                        Images:
                    </h4>
                    <div id="repair-item-images" className="collapse">
                        <ImageArea
                            images={props.repairItem.images}
                            resource_name="repair_item"
                            multiple_allowed={true}
                            resource_id={props.repairItem.id}
                            updateImagesCallback={(images) => props.update({ ...props.repairItem, images })}
                            timestamp_photos={(props.repairItem.settings || {}).timestamp_photos}
                        />
                    </div>
                    { validations.images &&
                        <div className="validation-wrapper">
                            <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                            You must upload at least 1 Image
                        </div>
                    }
                </div>
                <div className="col-md-12">
                    <hr/>
                </div>
            </div>

            <div className="row">
                <div className="col-md-4">
                    <div className="form-group">
                        <label>
                            Out Time:
                        </label>
                        <input
                            className="form-control datetime-picker"
                            type="text"
                            readOnly={true}
                            name="out_time"
                            ref={outTimeInputElRef}
                        />
                        <button
                            className="btn btn-default width-100 margin-10-top" onClick={() => logTime("out")}>
                            Log your Out Time
                        </button>
                        { validations.outTime &&
                            <div className="validation-wrapper">
                                <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                                You must log your Out Time
                            </div>
                        }
                    </div>
                </div>
            </div>

            <hr/>

            <div className="row">
                <div className="col-md-4">
                    <button
                        className="btn btn-success btn-lg width-100"
                        onClick={save}
                        disabled={props.saving || (props.repairItem.images || []).some(i => i.loading)}
                    >
                        <i className="fa fa-save margin-5-right"></i>
                        Save Repair Ticket
                    </button>
                </div>
            </div>
        </div>
    )
};

export default RepairItemTicket;

RepairItemTicket.propTypes = {
    repairItem: PropTypes.object.isRequired,
    appointment: PropTypes.object.isRequired,
    timezone: PropTypes.string.isRequired,
    saving: PropTypes.bool.isRequired
}
