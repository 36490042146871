export default {
    basePlan(subscription) {
        return subscription.items.data.find(i => i.plan.id.match(/\_accounts/)).plan.id.match(/lite|plus|pro/)[0]
    },

    labelClass(subscription) {
        switch (this.basePlan(subscription)) {
            case 'lite':
                return "label-info";
            case 'plus':
                return "label-success";
            case 'pro':
                return "label-warning";
        }
    },

    accountLimit(subscription) {
        return subscription.items.data.map(i => {
            if (i.plan.id.match(/\d+\_accounts/)) {
                return parseInt(i.plan.id.match(/\d+/))
            } else if (i.plan.id.match(/\_bulk/)) {
                return i.quantity
            }
        }).reduce((a, b) => a + b);
    },

    userLimit(plan) {
        switch (plan) {
            case 'lite':
                return 1;
            case 'plus':
                return 5;
            case 'pro':
                return "Unlimited";
        }
    },

    userLimitText(plan) {
        const userLimit = this.userLimit(plan);

        if (userLimit === "Unlimited") {
            return "Unlimited Users";
        } else {
            if (userLimit === 1) {
                return "1 User";
            } else {
                return `${userLimit} Users`;
            }
        }
    },

    totalCost(subscription) {
        return subscription.items.data.map(d => (d.plan.amount * d.quantity)).reduce((a, b) => a + b) / 100;
    },

    activeSubscription(subscription) {
        if (!subscription) return false;
        return (subscription.status === "trialing" || subscription.status === "active");
    }
}
