import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';

const Task = (props) => {
    const [editing, setEditing] = useState(!props.task.description);
    const [task, setTask] = useState(props.task);
    const [validations, setValidations] = useState({
        description: false
    });

    useEffect(() => {
        if (props.task.description && props.task.description !== task.description) {
            setTask({ ...props.task });
        }
    }, [props.task])

    const cancelEditing = () => {
        if ((task.description || "").length < 1) {
            props.destroyTask(props.index, false);
        } else {
            setTask({ ...props.task });
            setEditing(false);
        }
    };

    const valid = () => {
        const validations = {
            description: (task.description || "").length < 1
        }

        setValidations(validations);
        return !Object.values(validations).includes(true);
    };

    const save = () => {
        if (valid()) {
            props.saveTask(task, props.index);
            setEditing(false);
        }
    };

    const toolbar = () => (
        <div className="display-flex">
            <div className={`${props.appointmentCompleted ? "completed-" : ""}task-button-wrapper display-flex justify-content-space-between margin-10-right`}>
                <Fragment>
                    { props.appointmentCompleted ?
                        <Fragment>
                            { task.completed ?
                                <span className="glyphicon glyphicon-ok"></span>
                            :
                                <span className="glyphicon glyphicon-remove"></span>
                            }
                        </Fragment>
                    :
                        <Fragment>
                            { !editing &&
                                <Fragment>
                                    <button className="btn btn-default" onClick={() => setEditing(true)}>
                                        <span className="glyphicon glyphicon-pencil"></span>
                                    </button>
                                    <button className="btn btn-danger" onClick={() => props.destroyTask(props.index)}>
                                        <i className="fa fa-trash-o"></i>
                                    </button>
                                </Fragment>
                            }
                            { editing &&
                                <Fragment>
                                    <button className="btn btn-success" onClick={save}>
                                        <i className="fa fa-save"></i>
                                    </button>
                                    <button className="btn btn-default" onClick={cancelEditing}>
                                        <i className="fa fa-times"></i>
                                    </button>
                                </Fragment>
                            }
                        </Fragment>
                    }
                </Fragment>
            </div>
        </div>
    );
    return(
        <div className="task-wrapper">
            { !editing &&
                <div className="display-flex align-items-center">
                    { toolbar() }
                    <h5 className="no-margin">
                        { task.description }
                    </h5>
                </div>
            }
            { editing &&
                <div className="display-flex">
                    { toolbar() }
                    <div className="form-group no-margin">
                        <input
                            type="text"
                            className="form-control"
                            value={task.description || ""}
                            onChange={(e) => setTask({ ...task, description: e.target.value })}
                            placeholder="Describe Task..."
                        />
                        { validations.description &&
                            <div className="validation-wrapper">
                                <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                                Valid Description is required
                            </div>
                        }
                    </div>
                </div>
            }
        </div>
    )
};

Task.propTypes = {
    index: PropTypes.number.isRequired,
    task: PropTypes.object.isRequired,
    appointmentCompleted: PropTypes.bool.isRequired,
    saveTask: PropTypes.func.isRequired,
    destroyTask: PropTypes.func.isRequired
};

export default Task;
