import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import ScheduledPreview from './ScheduledPreview';

export default class ScheduledList extends React.Component {
    static propTypes = {
        updateModalState: PropTypes.func.isRequired,
        reminders: PropTypes.array.isRequired
    }

    destroyReminder = (id) => {
        $.ajax({
            method: "DELETE",
            url: `/route_reminders/scheduled/${id}.json`
        }).done(() => {
            const reminders = this.props.reminders.filter(r => r.id !== id);

            this.props.updateModalState({ reminders, success: "Reminder successfully destroyed!" });

            $(".popover").popover("hide");
        }).fail(() => {
            // handle failure
        });
    };

    render() {
        return(
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">
                            Route Reminders
                        </h5>
                    </div>
                    <div className="modal-body">
                        { this.props.success &&
                            <div className="alert alert-success alert-dismissable">
                                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                { this.props.success }
                            </div>
                        }
                        <div className="display-flex flex-column">
                            <div className="header-icon schedule">
                                <i className="fa fa-bell-o"></i>
                            </div>
                            <h4 className="text-center">
                                { this.props.reminders.length > 0 ? "Active Route Reminders for this Customer:" : "No active Route Reminders for this Customer" }
                            </h4>
                        </div>

                        { this.props.reminders.length > 0 &&
                            <hr/>
                        }

                        { this.props.reminders.length > 0 &&
                            this.props.reminders.map((reminder, index) => {
                                return(
                                    <ScheduledPreview
                                        reminder={reminder}
                                        key={index}
                                        index={index}
                                        updateModalState={this.props.updateModalState}
                                        destroyReminder={this.destroyReminder}
                                    />
                                )
                            })
                        }

                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-default"
                            data-dismiss="modal"
                        >
                            Close
                        </button>
                        <button className="btn btn-success" onClick={() => this.props.updateModalState({ action: "form", selectedReminder: null })}>
                            <span className="glyphicon glyphicon-plus margin-5-right"></span>
                            Add Reminder
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}
