import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

export default class RepairTemplateList extends React.Component {
    static propTypes = {
        templates: PropTypes.array.isRequired,
        newTemplate: PropTypes.func.isRequired,
        setEditingTemplate: PropTypes.func.isRequired,
        destroyTemplate: PropTypes.func.isRequired,
        selectedType: PropTypes.string.isRequired,
        setSelectedType: PropTypes.func.isRequired
    }

    constructor(props) {
        super(props);

        this.baseState = {
            searchTerm: "",
        }

        this.state = this.baseState;
    }

    displayableTemplates = () => {
        const searchTerm = this.state.searchTerm.toLowerCase();

        return this.props.templates.filter(template => {
            return template.name.toLowerCase().search(searchTerm) !== -1;
        })
    }

    render() {
        return(
            <div className="repair-template-wrapper overflow-y-scroll">
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group no-margin-bottom template-search">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search Templates..."
                                onChange={(e) => this.setState({ searchTerm: e.target.value })}
                            />
                            <i className="fa fa-search"></i>
                        </div>
                        { (this.props.selectedType === "repair" || this.props.selectedType === "repair_item") &&
                            <Fragment>
                                <h5 className="margin-20-top">
                                    Type:
                                </h5>
                                <div className="display-flex">
                                    <div className="form-group margin-10-right no-margin-bottom">
                                        <label htmlFor="repair-type">
                                            Repairs:
                                        </label>
                                        &nbsp;
                                        <input
                                            id="repair-type"
                                            type="radio"
                                            checked={this.props.selectedType === "repair"}
                                            onChange={() => this.props.setSelectedType("repair")}
                                        />
                                    </div>
                                    <div className="form-group no-margin-bottom">
                                        <label htmlFor="repair-item-type">
                                            Repair Items:
                                        </label>
                                        &nbsp;
                                        <input
                                            id="repair-item-type"
                                            type="radio"
                                            checked={this.props.selectedType === "repair_item"}
                                            onChange={() => this.props.setSelectedType("repair_item")}
                                        />
                                    </div>
                                </div>
                            </Fragment>
                        }
                    </div>
                    <div className="col-md-6">
                        <div className="new-template-button">
                            <button
                                className="btn btn-success"
                                type="button"
                                onClick={this.props.newTemplate}
                            >
                                <i className="fa fa-plus margin-5-right"></i>
                                New Template
                            </button>
                        </div>
                    </div>
                </div>

                <hr/>

                <div className="row">
                    <div className="col-xs-12">
                        <div className="list-wrapper">
                            { this.displayableTemplates().length < 1 &&
                                <div className="display-flex align-items-center justify-content-center">
                                    <h4 className="text-center">
                                        No Templates Found
                                    </h4>
                                </div>
                            }
                            { this.displayableTemplates().length > 0 &&
                                <Fragment>
                                    {
                                        this.displayableTemplates().map((template, index) => {
                                            return(
                                                <div className="list-entry panel panel-default" key={index}>
                                                    <div className="panel-body display-flex align-items-center">
                                                        <div className="col-xs-7 no-padding-left">
                                                            <h5>
                                                                { template.name }
                                                            </h5>
                                                        </div>
                                                        <div className="col-xs-5 no-padding">
                                                            <div className="float-right">
                                                                <button className="btn btn-default margin-10-right" onClick={(e) => this.props.setEditingTemplate(template)}>
                                                                    <i className="fa fa-pencil"></i>
                                                                </button>
                                                                <button
                                                                    className="btn btn-default"
                                                                    onClick={(e) => this.props.destroyTemplate(template)}
                                                                >
                                                                    <i className="fa fa-trash color-red"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </Fragment>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
