import React from 'react';
import PropTypes from 'prop-types';

export default class UserList extends React.Component {
    constructor(props) {
        super(props);

        this.baseState = {
            userName: null,
            users: [],
            selectedUser: "",
            loading: false,
            pending: null
        }

        this.state = this.baseState;
    }

    renderRows = () => {
        return this.state.users.map((user, index) => {
            return(
                <tr key={index}>
                    <td>
                        { user.name }
                    </td>
                    <td>
                        { user.email }
                    </td>
                    <td>
                        { user.company.name }
                    </td>
                    <td>
                        <button onClick={() => this.disguise(user.id)} className="btn btn-success">
                            Disguise
                        </button>
                    </td>
                </tr>
            )
        });
    };

    disguise = (id) => {
        this.setState({ selectedUser: id }, () => {
            $("#disguise-form").submit();
        });
    };

    searchUsers = () => {
        this.setState({ loading: true });

        $.ajax({
            url: "/admin/list_users.json",
            method: "GET",
            data: {
                "q": {
                    "name_cont": this.state.userName
                }
            }
        }).done(res => this.setState({ users: res, loading: false, pending: null }));
    };

    render() {
        return(
            <div>
                <div className="form-group">
                    <input
                        className="form-control"
                        type="text"
                        onChange={(e) => this.setState({ userName: e.target.value }) }
                        placeholder="User Name"
                    />
                </div>
                <div className="form-group">
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => this.searchUsers()}
                    >
                        Search
                    </button>
                </div>
                { this.state.loading &&
                    <div className="display-flex">
                        <i className="fa fa-spinner fa-pulse fa-3x fa-fw align-self-center flex-1"></i>
                    </div>
                }
                { !this.state.loading && this.state.users.length == 0 &&
                    <h4 className="text-center">
                        No Search Results Found...
                    </h4>
                }
                { !this.state.loading && this.state.users.length > 0 &&
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <td>
                                                Name:
                                            </td>
                                            <td>
                                                Email:
                                            </td>
                                            <td>
                                                Company:
                                            </td>
                                            <td>
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { this.renderRows() }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <form id="disguise-form" action="/admin/disguise" method="POST">
                            <input type="hidden" name="authenticity_token" value={$("meta[name='csrf-token']")[0].content} />
                            <input type="hidden" name="user_id" value={this.state.selectedUser} />
                        </form>
                    </div>
                }
            </div>
        )
    }
}
