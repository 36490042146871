import PropTypes from 'prop-types';
import React from 'react';

export default class MasterServiceItem extends React.Component {
    static propTypes = {
        id: PropTypes.number,
        name: PropTypes.string,
        defaultPrice: PropTypes.string,
        cost: PropTypes.string,
        commission: PropTypes.string,
        msiKey: PropTypes.string,
        removeMasterServiceItem: PropTypes.func.isRequired,
        markedForDeletion: PropTypes.bool
    }

    constructor(props) {
        super(props);

        this.state = { ...props }
    }

    componentWillReceiveProps(props) {
        this.setState({ ...props })
    }

    updateState = (type) => {
        this.setState({
            type: this[`${type}Input`].value
        })
    };

    render() {
        return(
            <div className="master-service-item-group">
                {this.state.id &&
                    <input
                        type="text"
                        hidden={true}
                        name="master_service_items[][id]"
                        defaultValue={this.state.id}
                    />
                }
                <div className="form-group">
                    <label>
                        Name:
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        name="master_service_items[][name]"
                        defaultValue={this.state.name}
                        ref={(input) => this.nameInput = input}
                        onChange={() => this.updateState("name")}
                    />
                </div>
                <div className="form-group">
                    <label>
                        Default Price:
                    </label>
                    <div className="input-group">
                        <div className="input-group-addon">
                            <span className="glyphicon glyphicon-usd"></span>
                        </div>
                        <input
                            type="text"
                            className="form-control"
                            name="master_service_items[][default_price]"
                            defaultValue={this.state.defaultPrice}
                            ref={(input) => this.defaultPriceInput = input}
                            onChange={() => this.updateState("defaultPrice")}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <label>
                        Cost:
                    </label>
                    <div className="input-group">
                        <div className="input-group-addon">
                            <span className="glyphicon glyphicon-usd"></span>
                        </div>
                        <input
                            type="text"
                            className="form-control"
                            name="master_service_items[][default_cost]"
                            defaultValue={this.state.cost}
                            ref={(input) => this.costInput = input}
                            onChange={() => this.updateState("cost")}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <label>
                        Commission:
                    </label>
                    <div className="input-group">
                        <div className="input-group-addon">
                            <span className="glyphicon glyphicon-usd"></span>
                        </div>
                        <input
                            type="text"
                            className="form-control"
                            name="master_service_items[][commission]"
                            defaultValue={this.state.commission}
                            ref={(input) => this.commissionInput = input}
                            onChange={() => this.updateState("commission")}
                        />
                    </div>
                </div>
                <a
                    href="#"
                    onClick={(e) => { e.preventDefault(); this.state.removeMasterServiceItem(this.state.msiKey) }}
                >
                    Remove Service Item
                </a>
                <hr/>
            </div>
        )
    }
}
