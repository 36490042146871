import Utils from '../../Utils';

const module = {
    lite: {
        cost: 35,
        count: 40,
        bulk: .25
    },
    plus: {
        cost: 45,
        count: 40,
        bulk: .50
    },
    pro: {
        cost: 60,
        count: 40,
        bulk: 0.75
    },
    monthlyPrice(selectedAccounts, plan, currency = true) {
        const parsedSelected = parseInt(selectedAccounts);
        const bulkResult = (((parsedSelected - this[plan].count) * this[plan].bulk) + this[plan].cost);

        if (plan === "pro") {
            if (parsedSelected > 40 && parsedSelected <= 80) {
                if (currency) {
                    return Utils.floatToCurrency(100);
                } else {
                    return 100;
                }
            } else if (parsedSelected > 80 && parsedSelected <= 120) {
                if (currency) {
                    return Utils.floatToCurrency(120);
                } else {
                    return 120;
                }
            } else {
                if (currency) {
                    return Utils.floatToCurrency(bulkResult);
                } else {
                    return bulkResult;
                }
            }
        } else {
            if (parsedSelected === this[plan].count) {
                if (currency) {
                    return Utils.floatToCurrency(this[plan].cost);
                } else {
                    return this[plan].cost;
                }
            } else {
                if (currency) {
                    return Utils.floatToCurrency(bulkResult);
                } else {
                    return bulkResult;
                }
            }
        }
    }
}

export default module;
