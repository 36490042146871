import React from 'react';
import Typed from 'typed.js';

export default class TypedHeader extends React.Component {
    shuffle = (a) => {
        for (let i = a.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [a[i], a[j]] = [a[j], a[i]];
        }
        return a;
    };

    componentDidMount() {
        const options = {
            strings: this.shuffle(["Automate your billing", "Know when your customers are past due", "Send service stop notifications", "Instantly get approval for repairs", "Track repairs from start to finish", "Manage and track your pool route", "Access your customers from your phone", "Track chemicals/service items", "Keep track of all your appointments"]),
            typeSpeed: 90,
            backSpeed: 40,
            backDelay: 1000,
            loop: true,
        }

        new Typed(".typed-header", options)
    }

    render() {
        return(
            <h1 className="typed-header"></h1>
        )
    }
}
