import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import ScheduledList from './ScheduledList';
import ScheduledForm from './ScheduledForm';

import LockedFeature from '../Subscription/LockedFeature';

export default class ScheduledModal extends React.Component {
    static propTypes = {
        account_id: PropTypes.number.isRequired,
        reminders: PropTypes.array.isRequired,
        next_service_date: PropTypes.string.isRequired,
        base_plan: PropTypes.string.isRequired
    }

    constructor(props) {
        super(props)

        this.baseState = {
            action: "list",
            selectedReminder: null,
            success: false
        }

        this.state = { ...this.baseState, reminders: this.props.reminders };
    }

    componentDidMount() {
        if ($("a#route-reminders").length > 0) {
            $("a#route-reminders").on("click", (e) => {
                e.preventDefault();

                $("#route-reminders-modal").modal("show");
            });

            $("#route-reminders-modal").on("show.bs.modal", (e) => {
                this.setState({ ...this.baseState, reminders: this.state.reminders });
                $(e.target).addClass("center-screen-modal");
            });

            $("#route-reminders-modal").on("hide.bs.modal", (e) => {
                $(e.target).removeClass("center-screen-modal");
            });
        }
    }

    updateModalState = (state) => {
        this.setState({ ...this.state, ...state });
    };

    renderComponent = () => {
        switch(this.state.action) {
            case 'list':
                return <ScheduledList
                    updateModalState={this.updateModalState}
                    reminders={this.state.reminders}
                    success={this.state.success}
                />
            case 'form':
                return <ScheduledForm
                    account_id={this.props.account_id}
                    updateModalState={this.updateModalState}
                    reminder={this.state.selectedReminder}
                    reminders={this.state.reminders}
                    nextServiceDate={this.props.next_service_date}
                />
        }
    };

    render() {
        return(
            <div id="route-reminders-modal" className="modal fade">
                { this.props.base_plan !== "lite" &&
                    this.renderComponent()
                }
                { this.props.base_plan === "lite" &&
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button className="close" type="button" data-dismiss="modal">
                                    <span>
                                        &times;
                                    </span>
                                </button>
                                <h5 className="modal-title">
                                    Route Reminders
                                </h5>
                            </div>
                            <div className="modal-body">
                                <LockedFeature
                                    plan_required="plus"
                                    image="https://s3.amazonaws.com/superswimbros.dev/route_reminders_locked.png"
                                    feature_text="Route Reminders"
                                />
                            </div>
                            <div className="modal-footer">
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-default"
                                        data-dismiss="modal"
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}
