import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { HashRouter as Router, Route } from 'react-router-dom';

import { toast } from 'react-toastify';
import AqsRoute from './Route/Index';
import AccountList from '../Accounts/List/Index';

export const DashboardContext = React.createContext();

export default class Dashboard extends React.Component {
    static propTypes = {
        current_user: PropTypes.object.isRequired,
        current_company: PropTypes.object.isRequired,
        company_settings: PropTypes.object.isRequired,
        pool_conditions: PropTypes.object.isRequired,
        master_service_items: PropTypes.array.isRequired,
        chemical_readings: PropTypes.array.isRequired,
        addable_chemicals: PropTypes.array.isRequired,
        default_tasks: PropTypes.array.isRequired,
        repair_templates: PropTypes.array.isRequired
    };

    constructor(props) {
        super(props);

        this.baseState = {
            hideDashboard: false,
            activeWidget: null
        },

        this.state = this.baseState;
        this.currentRouteRef = null;
    }

    hideDashboard = () => {
        this.setState({ hideDashboard: true })
    };

    showDashboard = () => {
        this.setState({ hideDashboard: false })
    };

    setActiveWidget = (activeWidget) => {
        this.setState({ activeWidget })
    };

    widgetClass = (widget) => {
        if (!this.state.hideDashboard) {
            return "display-block";
        } else if (this.state.hideDashboard && this.state.activeWidget === widget) {
            return "width-100";
        } else if (this.state.hideDashboard) {
            return "display-none";
        }
    };

    expandRouteWidget = (expand = true) => {
        setTimeout(() => {
            const routeWidgetWrapper = $(`.${this.state.activeWidget}.route-widget-wrapper`);
            if (expand) {
                if (!routeWidgetWrapper.hasClass("expanded")) {
                    routeWidgetWrapper.toggleClass("expanded");
                }
            } else {
                if (routeWidgetWrapper.hasClass("expanded")) {
                    routeWidgetWrapper.toggleClass("expanded");
                }
            }
        });
    };

    backToDashboard = () => {
        this.expandRouteWidget(false);
        this.setState({ hideDashboard: false, activeWidget: null });
    };

    refreshCurrentRoute = () => {
        this.currentRouteRef.refreshAppointments();
    };

    render() {
        const dashClass = this.state.hideDashboard ? "display-none" : "";

        return(
            <Fragment>
                { this.state.hideDashboard &&
                    <Fragment>
                        <button className="btn btn-default margin-10-bottom" onClick={this.backToDashboard}>
                            <span className="glyphicon glyphicon-chevron-left margin-5-right"></span>
                            Back To Dashboard
                        </button>
                    </Fragment>
                }
                <div className="panel panel-default dashboard-wrapper">
                    <DashboardContext.Provider
                        value={{
                            currentUser: this.props.current_user,
                            companySettings: this.props.company_settings,
                            currentCompany: this.props.current_company,
                            poolConditions: this.props.pool_conditions,
                            masterServiceItems: this.props.master_service_items,
                            chemicalReadings: this.props.chemical_readings,
                            addableChemicals: this.props.addable_chemicals,
                            defaultTasks: this.props.default_tasks,
                            repairTemplates: this.props.repair_templates,
                            dashboardHidden: this.state.hideDashboard,
                            hideDashboard: this.hideDashboard,
                            showDashboard: this.showDashboard,
                            activeWidget: this.state.activeWidget,
                            setActiveWidget: this.setActiveWidget,
                            expandRouteWidget: this.expandRouteWidget,
                            refreshCurrentRoute: this.refreshCurrentRoute
                        }}
                    >
                        <div className={`${dashClass} panel-heading display-flex align-items-center`}>
                            <img src="https://s3.amazonaws.com/superswimbros.dev/dashboard.svg" alt="Dashboard" />
                            <h4 className="title">
                                Dashboard
                            </h4>
                        </div>
                        <div className={`panel-body widget-container ${this.state.hideDashboard ? "justify-content-center" : ""}`}>
                            <div className={`${this.widgetClass("todays-route")} widget-wrapper route-widget-wrapper todays-route`}>
                                <AqsRoute
                                    appointmentType="current"
                                    headerText="Your Appointments Today"
                                    widgetClass="todays-route"
                                    ref={ref => this.currentRouteRef = ref}
                                />
                            </div>

                            <div className={`${this.widgetClass("lapsed-route")} widget-wrapper route-widget-wrapper lapsed-route`}>
                                <AqsRoute
                                    appointmentType="lapsed"
                                    headerText="Your Lapsed Appointments"
                                    widgetClass="lapsed-route"
                                />
                            </div>

                            { this.props.current_user.roles.includes("admin") &&
                                <div className={`${this.widgetClass("all-lapsed-appointments")} widget-wrapper route-widget-wrapper all-lapsed-appointments`}>
                                    <AqsRoute
                                        appointmentType="all-lapsed"
                                        headerText="Others Lapsed Appointments"
                                        widgetClass="all-lapsed-appointments"
                                    />
                                </div>
                            }
                        </div>
                    </DashboardContext.Provider>
                </div>
                <div className={dashClass}>
                    <AccountList
                        company={this.props.current_company}
                        currentUser={this.props.current_user}
                    />
                </div>
            </Fragment>
        )
    }
}
