import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Helper from './Helper';

export default class ScheduledPreview extends React.Component {
    static propTypes = {
        reminder: PropTypes.object.isRequired,
        index: PropTypes.number.isRequired,
        updateModalState: PropTypes.func.isRequired,
        destroyReminder: PropTypes.func.isRequired
    }

    constructor(props) {
        super(props);

        this.baseState = {
            activated: false
        }

        this.state = this.baseState;
    }

    componentDidMount() {
        $(`.reminder-${this.props.reminder.id} [data-toggle="popover"]`).popover();

        $(`.reminder-${this.props.reminder.id} [data-toggle="popover"]`).on("shown.bs.popover", (e) => {
            $(e.target.parentElement).find("button.confirm").on("click", () => {
                $(e).popover("destroy");
                this.props.destroyReminder(this.props.reminder.id);
            });

            $(e.target.parentElement).find("button.cancel").on("click", () => {
                $(e.target).popover("hide");
            });
        });
    }

    activate = () => {
        this.setState({ activated: !this.state.activated });
    };

    render() {
        return(
            <Fragment>
                <div
                    className={`reminder-${this.props.reminder.id} panel panel-default ${this.state.activated ? "no-margin-bottom" : ""}`}
                >
                    <div className="panel-body display-flex">
                        <div className="padding-15-right display-flex align-items-center cursor-pointer" onClick={this.activate}>
                            <span className={`glyphicon glyphicon-chevron-down ${this.state.activated ? "" : "rotate-right"}`} ref={(el) => this.chevron = el}></span>
                        </div>
                        <div className="flex-1 display-flex align-items-center cursor-pointer" onClick={this.activate}>
                            { `Reminder #${this.props.index + 1}` }
                        </div>
                        <div className="align-self-end margin-5-right">
                            <button className="btn btn-default" onClick={() => this.props.updateModalState({ action: "form", selectedReminder: this.props.reminder })}>
                                <span className="glyphicon glyphicon-pencil"></span>
                            </button>
                        </div>
                        <div className="align-self-end">
                            <button
                                className="btn btn-danger"
                                data-toggle="popover"
                                title="Delete this reminder?"
                                data-content="<button class='confirm btn btn-danger margin-5-right'>Delete</button><button class='cancel btn btn-default'>Cancel</button>"
                                data-placement="left"
                                data-html="true"
                            >
                                <span className="glyphicon glyphicon-trash"></span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className={`${this.state.activated ? "display-block" : "display-none"}`}>
                    <div className="well overflow-x-scroll">
                        <div className="display-flex margin-10-bottom">
                            <div className="preview-field description flex-1">
                                Description:
                            </div>
                            <div className="preview-field flex-1">
                                # of Reminders:
                            </div>
                            <div className="preview-field flex-1">
                                Next Reminder:
                            </div>
                            <div className="preview-field flex-1">
                                Remind every:
                            </div>
                            <div className="preview-field flex-1">
                                Customer notification:
                            </div>
                        </div>
                        <div className="display-flex">
                            <div className="flex-1 preview-field description">
                                { this.props.reminder.description }
                            </div>
                            <div className="flex-1 preview-field">
                                { this.props.reminder.infinite ? "Infinite" : this.props.reminder.occurrences }
                            </div>
                            <div className="flex-1 preview-field">
                                { Helper.nextReminder(this.props.reminder) }
                            </div>
                            <div className="flex-1 preview-field">
                                { Helper.remindEvery(this.props.reminder) }
                            </div>
                            <div className="flex-1 preview-field">
                                { this.props.reminder.notify &&
                                    <span className="glyphicon glyphicon-ok"></span>
                                }
                                { !this.props.reminder.notify &&
                                    <span className="glyphicon glyphicon-remove"></span>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
