import React from 'react';
import PropTypes from 'prop-types';

import { TextMessageContextConsumer } from './ConversationsModal';

import Utils from '../../Utils';
import SafelySetInnerHTML from 'safely-set-inner-html';
const instance = new SafelySetInnerHTML();

export default class TextMessageConversationPreview extends React.Component {
    static propTypes = {
        conversation: PropTypes.object.isRequired,
    }

    timestamp = (company) => {
        return moment.tz(this.props.conversation.last_message.created_at, "UTC").tz(company.timezone).format("MM-DD hh:mm A")
    };

    render() {
        const read = this.props.conversation.last_message.read ? "read" : "unread";
        return(
            <TextMessageContextConsumer>
                { (context) => (
                    <div className={`conversation-preview margin-10-bottom ${context.conversationType(this.props.conversation)} ${read}`} onClick={(e) => context.selectConversation(this.props.conversation) }>
                        <div className="display-flex justify-content-space-between width-100 margin-5-bottom">
                            <div className="contact">
                                { context.displayedContact(this.props.conversation) }
                            </div>
                            <div className="date">
                                { this.timestamp(context.company) }
                            </div>
                        </div>
                        <div className="content">
                            { this.props.conversation.last_message.mms &&
                                "Picture Message"
                            }
                            { !this.props.conversation.last_message.mms &&
                                instance.transform(this.props.conversation.last_message.content)
                            }
                        </div>
                    </div>
                )}
            </TextMessageContextConsumer>
        )
    }
}
