import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import ScheduleRepair from './ScheduleRepair';
import ImageArea from '@shared/components/ImageArea/ImageArea';
import LoadTemplateModal from './LoadTemplateModal';
import AccountDetails from './AccountDetails';

import Utils from '../../Utils';
import RepairUtils from './Utils';
import { toast } from 'react-toastify';
import LockedFeature from '../Subscription/LockedFeature';
import { Creatable } from 'react-select';

export default class RepairForm extends React.Component {
    static propTypes = {
        account: PropTypes.object,
        repair: PropTypes.object,
        statuses: PropTypes.array.isRequired,
        techs: PropTypes.array.isRequired,
        timezone: PropTypes.string.isRequired,
        toggleEditing: PropTypes.func,
        saveCallback: PropTypes.func,
        setAppointment: PropTypes.func,
        updateImagesCallback: PropTypes.func,
        base_plan: PropTypes.string.isRequired,
        current_user: PropTypes.object.isRequired,
        is_not_admin: PropTypes.bool.isRequired,
        can_view_grand_total: PropTypes.bool.isRequired,
        repair_tags: PropTypes.array
    }

    constructor(props) {
        super(props);

        this.denialReasons = ["Too expensive", "Repair has already been done recently", "This repair is not necessary", "Other"];

        this.baseState = {
            loading: null,
            selectedTemplate: null,
            showOtherDenial: false,
            validations: {
                denialReason: false,
                otherDenial: false
            }
        };

        this.baseState.repair = props.repair || {
            account_id: props.account.id,
            selectedTemplate: null,
            status: "draft",
            denial_reason: null,
            priority: "low",
            description: "",
            client_notes: "",
            user_id: "",
            service_tech_commission_amount: "",
            cost: "",
            discount: "",
            images: [],
            terms: "",
            image_ids: []
        };

        if (this.baseState.repair.status === "denied" && this.baseState.repair.denial_reason && !this.denialReasons.includes(this.baseState.repair.denial_reason)) {
            this.baseState.showOtherDenial = true;
        }

        this.state = this.baseState;
    }

    account = () => {
        return this.state.repair.account || this.props.account;
    };

    updateState = (e) => {
        // prevent event bubbling from interfering with appointment modal and prevent file input from triggering an update state
        if ($(e.target).parents("#appointment-modal").length < 1 && e.target.type !== "file") {
            let { name, value } = e.target;

            if (name && name.length > 0) {
                if (name === "discount") value = value * .01;
                this.setState({ repair: { ...this.state.repair, [name]: value } });
            }
        }
    };

    selectDenyReason = (e) => {
        let showOtherDenial, denial_reason;
        const value = e.target.value;

        if (value === "Other" || !this.denialReasons.includes(value)) {
            showOtherDenial = true;
            denial_reason = null;
        } else {
            showOtherDenial = false;
            denial_reason = value;
        }

        this.setState({ showOtherDenial, repair: { ...this.state.repair, denial_reason } });
    };

    findDefaultDenialReason = () => {
        if (this.state.repair.denial_reason) {
            return this.denialReasons.includes(this.state.repair.denial_reason) ? this.state.repair.denial_reason : "Other"
        } else {
            return "";
        }
    };

    setAppointment = (appt) => {
        if (appt) {
            let { user_id, notes } = appt;
            let notification;

            if (appt.id && appt.id.length > 0) {
                notification = appt.notification ? "update" : ""
            } else {
                notification = appt.notification ? "new" : ""
            }

            const appointment = {
                start: moment(appt.start).tz(this.props.timezone).utc().format(),
                end: moment(appt.end).tz(this.props.timezone).utc().format(),
                user_id,
                notes,
                notification,
                type: "repair"
            };

            this.setState({ repair: { ...this.state.repair, appointment } });

            if (this.props.setAppointment) this.props.setAppointment(appointment)
        }
    };

    isValid = () => {
        const validations = { otherDenial: false, denialReason: false };

        const missingDenialReason = (this.state.repair.denial_reason || "").length < 1;
        if (this.state.showOtherDenial) {
            validations.otherDenial = missingDenialReason;
        } else {
            validations.denialReason = missingDenialReason;
        }

        this.setState({ validations });

        if (!Object.values(validations).includes(true)) {
            return true;
        } else {
            this.denialReasonEl.scrollIntoView();
            return false;
        }
    };

    saveRepair = () => {
        if (this.state.repair.status === "denied") {
            if (!this.isValid()) {
                return false;
            }
        }

        this.setState({ loading: true }, () => {
            $(".loading")[0].scrollIntoView();
        });

        let method, url, saved;

        if (this.state.repair.id) {
            method = "PUT";
            url  = `/accounts/${this.state.repair.account_id}/repairs/${this.state.repair.id}.json`;
        } else {
            method = "POST";
            url = `/accounts/${this.state.repair.account_id}/repairs.json`
        }

        const { appointment, ...filteredRepair } = this.state.repair;

        if (this.state.selectedTemplate) {
            const richMediaElements = this.state.selectedTemplate.rich_media_elements;
            if (richMediaElements.length > 0) filteredRepair.rich_media_elements_attributes = richMediaElements;
        }


        let data = {}
        if (this.state.repair.appointment && !this.state.repair.appointment.id) {
            data.repair = { ...filteredRepair, appointment_attributes: { ...this.state.repair.appointment } }
        } else {
            data.repair = filteredRepair;
        }

        $.ajax({
            method,
            url,
            data: JSON.stringify(data),
            contentType: "application/json"
        }).done((repair) => {
            if (method === "POST") {
                window.location = `/accounts/${this.props.account.id}/repairs/${repair.id}#success`
            } else {
                toast.success("Repair successfully saved!", {
                    position: toast.POSITION.TOP_CENTER
                });

                if (this.props.saveCallback) this.props.saveCallback(repair);
            }
        }).fail((res) => {
            const responseJSON = res.responseJSON || {};
            const errors = responseJSON.errors;

            let Msg;

            if (errors) {
                Msg = ({ closeToast }) => (
                    <ul>
                        { Object.keys(errors).map((error, index) => {
                            return(
                                <li key={index}>
                                    { `${error} ${errors[error]}` }
                                </li>
                            )
                        }) }
                    </ul>
                );
            } else {
                Msg = ({ closeToast }) => (
                    <span>
                        An error has occurred
                    </span>
                )
            }
            toast.error(<Msg />, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: false
            });
        });
    };

    showLoadTemplateModal = () => {
        $("#load-template-modal").modal("show");
    };

    loadTemplateRepair = (template) => {
        this.setState({ selectedTemplate: template });

        const repair = (({ description, client_notes, cost, terms, tags, settings }) => ({ description, client_notes, cost, terms, tags, settings }))(template);

        if (template.expenses.length > 0) {
            repair.expense_set_attributes = {
                expenses_attributes: template.expenses
            }
        }

        if (template.repair_items.length > 0) {
            repair.repair_items_attributes = [...template.repair_items]
        }

        this.setState({ repair: { ...this.state.repair, ...repair } });

        ["description", "client_notes", "cost", "terms"].forEach(key => {
            let input = $(`[name=${key}]`)
            if (input.length > 0) {
                input.val(template[key]);
            }
        });

        $("#load-template-modal").modal("hide");
    };

    setImages = (images) => {
        if (this.state.repair.id) {
            this.props.updateImagesCallback(images);
        } else {
            this.setState({ repair: { ...this.state.repair, image_ids: images.map(i => i.id) } })
        }
    };

    setRepairTag = (newValue) => {
        const tags = newValue.map(obj => obj.value);
        this.setState({ repair: { ...this.state.repair, tags: tags.join(",") } })
    };

    repairTagOptions = () => {
        if ((this.props.repair_tags || []).length > 0) {
            return this.props.repair_tags.map((tag, index) => {
                return {
                    value: tag.name,
                    label: tag.name,
                    color: RepairUtils.repairTagColors[index]
                }
            });
        } else {
            return [];
        }
    };

    repairTagsValue = () => {
        if (this.state.repair.tags) {
            return this.state.repair.tags.split(",").map((tag, index) => (
                {
                    value: tag,
                    label: tag,
                    color: RepairUtils.repairTagColors[index]
                }
            ));
        }
    };

    useRepairGrandTotal = () => {
        return !(RepairUtils.repairItemGrandTotal(this.state.repair) > 0);
    };

    render() {
        const optionsWrapperClass = this.props.toggleEditing ? "justify-content-space-between" : "justify-content-end";
        return(
            <div>
                { this.state.loading &&
                    <div className="loading row margin-20-bottom">
                        <div className="col-md-8">
                            <div className="display-flex justify-content-center margin-20">
                                <i className="fa fa-spinner fa-pulse fa-3x fa-fw align-self-center flex-1"></i>
                            </div>
                        </div>
                    </div>
                }
                { !this.state.loading &&
                    <div className="row margin-20-bottom">
                        <div className={`col-md-8 display-flex align-items-center ${optionsWrapperClass}`}>
                            { this.props.toggleEditing &&
                                <button className="btn btn-default" onClick={this.props.toggleEditing}>
                                    <span className="glyphicon glyphicon-chevron-left"></span>
                                    Show Repair
                                </button>
                            }
                            <div className="dropdown pull-right">
                                <button
                                    className="btn btn-lg btn-success dropdown-toggle actions-dropdown"
                                    type="button"
                                    data-toggle="dropdown"
                                >
                                    Actions
                                    <span className="glyphicon glyphicon-chevron-down"></span>
                                </button>
                                <ul className="dropdown-menu">
                                    <li>
                                        <a
                                            href="#"
                                            className="text-center padding-10"
                                            onClick={(e) => { e.preventDefault(); this.showLoadTemplateModal() }}
                                        >
                                            <i className="fa fa-file-text-o"></i>
                                            &nbsp;
                                            Load Template
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="#"
                                            className="text-center padding-10"
                                            onClick={(e) => { e.preventDefault(); this.saveRepair() }}
                                        >
                                            <i className="fa fa-save"></i>
                                            &nbsp;
                                            Save Repair
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                }
                <AccountDetails account={this.account()} />
                <div className="row" onChange={this.updateState}>
                    <div className="col-md-8">
                        <div className="panel panel-default">
                            <div className="panel-heading">
                                <h3 className="panel-title">
                                    Status:
                                </h3>
                            </div>
                            <div className="panel-body">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group no-padding no-margin">
                                            <select
                                                className="form-control"
                                                name="status"
                                                defaultValue={this.state.repair.status}
                                            >
                                                {
                                                    this.props.statuses.map((status, index) => {
                                                        return(
                                                            <option value={status} key={index}>
                                                                { Utils.humanize(status) }
                                                            </option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        { this.state.repair.status === "denied" &&
                            <div className="panel panel-default" ref={(e) => this.denialReasonEl = e}>
                                <div className="panel-heading">
                                    <h3 className="panel-title">
                                        Reason for Denial:
                                    </h3>
                                </div>
                                <div className="panel-body">
                                    <div className="row">
                                        <div className="col-md-8">
                                            <div className="form-group no-margin">
                                                <select
                                                    className="form-control"
                                                    onChange={this.selectDenyReason}
                                                    defaultValue={this.findDefaultDenialReason()}
                                                >
                                                    <option value="">Select reason...</option>
                                                    {
                                                        this.denialReasons.map((reason, index) => {
                                                            return(
                                                                <option value={reason} key={index}>{ reason }</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            { this.state.validations.denialReason &&
                                                <div className="validation-wrapper">
                                                    <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                                                    You must select a reason for denying this Repair
                                                </div>
                                            }
                                            { this.state.showOtherDenial &&
                                                <div className="form-group no-margin-bottom margin-10-top">
                                                    <textarea
                                                        className="form-control"
                                                        placeholder="Enter reason here..."
                                                        name="denial_reason"
                                                        defaultValue={this.state.repair.denial_reason || ""}
                                                    >
                                                    </textarea>
                                                </div>
                                            }
                                            { this.state.validations.otherDenial &&
                                                <div className="validation-wrapper">
                                                    <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                                                    You must select a reason for denying this Repair
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        <div className="row">
                            <div className="col-xs-6">
                                <div className="panel panel-default">
                                    <div className="panel-heading">
                                        <h3 className="panel-title">
                                            Priority:
                                        </h3>
                                    </div>
                                    <div className="panel-body">
                                        <div className="form-group col-md-4 no-padding no-margin">
                                            <select
                                                className="form-control"
                                                name="priority"
                                                defaultValue={this.state.repair.priority}
                                            >
                                                <option value="low">Low</option>
                                                <option value="medium">Medium</option>
                                                <option value="high">High</option>
                                                <option value="critical">Critical</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-6">
                                <div className="panel panel-default">
                                    <div className="panel-heading">
                                        <h3 className="panel-title">
                                            Tag(s):
                                        </h3>
                                    </div>
                                    <div className="panel-body">
                                        <div className="form-group col-xs-12 no-padding no-margin">
                                            <Creatable
                                                isMulti
                                                onChange={this.setRepairTag}
                                                options={this.repairTagOptions()}
                                                value={this.repairTagsValue()}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="panel panel-default">
                            <div className="panel-heading">
                                <h3 className="panel-title">
                                    Appointment:
                                </h3>
                            </div>
                            <div className="panel-body" onChange={null}>
                                { (this.state.repair.repair_items || this.state.repair.repair_items_attributes || []).length < 1 ?
                                    <ScheduleRepair
                                        appointment={this.state.repair.appointment}
                                        techs={this.props.techs}
                                        timezone={this.props.timezone}
                                        account={this.state.repair.account || this.props.account}
                                        repair={this.state.repair}
                                        appointmentCallback={this.setAppointment}
                                        isNotAdmin={this.props.is_not_admin}
                                        currentUser={this.props.current_user}
                                    />
                                :
                                    <h5 className="text-center">
                                        Schedule through Repair Item Appointment(s)
                                    </h5>
                                }
                            </div>
                        </div>

                        <div className="panel panel-default">
                            <div className="panel-heading">
                                <h3 className="panel-title">
                                    Description:
                                </h3>
                            </div>
                            <div className="panel-body">
                                <div className="form-group no-margin">
                                    <textarea
                                        className="form-control"
                                        name="description"
                                        defaultValue={this.state.repair.description}
                                    >
                                    </textarea>
                                </div>
                            </div>
                        </div>

                        <div className="panel panel-default">
                            <div className="panel-heading">
                                <h3 className="panel-title">
                                    Public Notes:
                                </h3>
                            </div>
                            <div className="panel-body">
                                <div className="form-group no-margin">
                                    <textarea
                                        className="form-control"
                                        name="client_notes"
                                        defaultValue={this.state.repair.client_notes}
                                    >
                                    </textarea>
                                </div>
                            </div>
                        </div>

                        <div className="panel panel-default">
                            <div className="panel-heading">
                                <h3 className="panel-title">
                                    Private Notes:
                                </h3>
                            </div>
                            <div className="panel-body">
                                <div className="form-group no-margin">
                                    <textarea
                                        className="form-control"
                                        name="private_notes"
                                        defaultValue={this.state.repair.private_notes}
                                    >
                                    </textarea>
                                </div>
                            </div>
                        </div>

                        { this.props.base_plan !== "pro" &&
                            <div className="margin-20-bottom">
                                <LockedFeature
                                    plan_required="pro"
                                    image="https://s3.amazonaws.com/superswimbros.dev/repair_expenses_locked.png"
                                    feature_text="Expense Tracking"
                                />
                            </div>
                        }

                        { this.props.base_plan !== "pro" &&
                            <div className="margin-20-bottom">
                                <LockedFeature
                                    plan_required="pro"
                                    image="https://s3.amazonaws.com/superswimbros.dev/repair_commissions_locked.png"
                                    feature_text="Commission Tracking"
                                />
                            </div>
                        }

                        { this.props.base_plan === "pro" && !this.props.is_not_admin &&
                            <Fragment>
                                <div className="panel panel-default">
                                    <div className="panel-heading">
                                        <h3 className="panel-title">
                                            Commission Payable To:
                                        </h3>
                                    </div>
                                    <div className="panel-body">
                                        <div className="form-group col-md-4 no-padding no-margin">
                                            <select
                                                className="form-control"
                                                name="user_id"
                                                defaultValue={this.state.repair.user_id}
                                            >
                                                <option value="">Select technician...</option>
                                                {
                                                    this.props.techs.map((tech, index) => {
                                                        return(
                                                            <option value={tech.id} key={index}>
                                                                { tech.name }
                                                            </option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="panel panel-default">
                                    <div className="panel-heading">
                                        <h3 className="panel-title">
                                            Commission Amount:
                                        </h3>
                                    </div>
                                    <div className="panel-body">
                                        <div className="form-group col-md-4 no-margin no-padding">
                                            <div className="input-group">
                                                <div className="input-group-addon">
                                                    <i className="glyphicon glyphicon-usd"></i>
                                                </div>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="service_tech_commission_amount"
                                                    defaultValue={this.state.repair.service_tech_commission_amount}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                        }

                        { this.props.can_view_grand_total &&
                            <Fragment>
                                <div className="panel panel-default">
                                    <div className="panel-heading">
                                        <h3 className="panel-title">
                                            Repair Cost:
                                        </h3>
                                    </div>
                                    <div className="panel-body">
                                        { this.useRepairGrandTotal() ?
                                            <div className="form-group col-md-4 no-margin no-padding">
                                                <div className="input-group">
                                                    <div className="input-group-addon">
                                                        <i className="glyphicon glyphicon-usd"></i>
                                                    </div>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        name="cost"
                                                        defaultValue={this.state.repair.cost}
                                                    />
                                                </div>
                                            </div>
                                        :
                                            <h5 className="text-center">
                                                Set price through Repair Item(s)
                                            </h5>
                                        }
                                    </div>
                                </div>
                                <div className="panel panel-default">
                                    <div className="panel-heading">
                                        <h3 className="panel-title">
                                            Discount:
                                        </h3>
                                    </div>
                                    <div className="panel-body">
                                        <div className="form-group col-md-4 no-margin no-padding">

                                            <div className="input-group">
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    name="discount"
                                                    defaultValue={this.state.repair.discount * 100}
                                                    max="100"
                                                />
                                                <div className="input-group-addon">
                                                    <i>%</i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                        }

                        <div className="panel panel-default">
                            <div className="panel-heading">
                                <h3 className="panel-title">
                                    Images:
                                </h3>
                            </div>
                            <div className="panel-body">
                                <ImageArea
                                    images={this.state.repair.images}
                                    resource_name="repair"
                                    resource_id={this.state.repair.id}
                                    multiple_allowed={true}
                                    updateImagesCallback={this.setImages}
                                />
                            </div>
                        </div>

                        <div className="panel panel-default">
                            <div className="panel-heading">
                                <h3 className="panel-title">
                                    Terms/Conditions:
                                </h3>
                            </div>
                            <div className="panel-body">
                                <div className="form-group no-margin">
                                    <textarea
                                        className="form-control"
                                        name="terms"
                                        defaultValue={this.state.repair.terms}
                                    >
                                    </textarea>
                                </div>
                            </div>
                        </div>

                        <div className="form-group">
                            <button className="btn btn-default" onClick={this.saveRepair}>
                                <i className="fa fa-save"></i>
                                &nbsp;
                                Save Repair
                            </button>
                        </div>
                    </div>
                </div>
                <LoadTemplateModal loadCallback={this.loadTemplateRepair}/>
            </div>
        )
    }
}
