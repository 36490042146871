import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import AppointmentModal from './AppointmentModal';
import AppointmentOrder from './AppointmentOrder';
import AdvancedEditModal from './AdvancedEditModal';

export default class Calendar extends React.Component {
    static propTypes = {
        techs: PropTypes.array.isRequired,
        current_user: PropTypes.object.isRequired,
        timezone: PropTypes.string.isRequired,
        service_days: PropTypes.object.isRequired,
        base_plan: PropTypes.string.isRequired
    }

    constructor(props) {
        super(props)

        this.state = {
            selectedTech: props.current_user,
            appointments: [],
            selectedAppointment: null,
            selectedAppointments: []
        }
    }

    componentDidMount() {
        $(".calendar-wrapper").fullCalendar({
            header: {
                center: 'month,agendaWeek,agendaDay'
            },
            defaultView: "agendaDay",
            viewRender: (view, element) => {
                if (view.currentRangeUnit === "day") {
                    if ($(".select-all-button").length === 0) {
                        if (window.appointmentSelectable) {
                            this.addSelectAllButton();
                        }
                    }
                } else {
                    if ($(".select-all-button").length > 0) {
                        $(".select-all-button").remove();
                    }
                }
                this.fetchAppointments();
                this.setState({ selectedAppointments: [] });
                window.initialRender = false;
            },
            eventClick: (calEvent, jsEvent, view) => {
                if (jsEvent.target.nodeName !== "INPUT") {
                    // close previously opened popovers;
                    Array.from($(".popover")).forEach(popover => $(popover).popover("hide"));

                    const eventEl = $(jsEvent.target).closest(".fc-event");

                    const popover = eventEl.popover({
                        html: true,
                        container: ".fc-scroller",
                        placement: "top",
                        content: this.popoverContent(calEvent)
                    }).popover("show") // show popover initially
                }
            },
            eventAfterAllRender(view) {
                // move calendar to first appointment after calendar renders
                if ($(".fc-time-grid-event").length > 0 && !window.initialRender) {
                    const scroller = $(".fc-scroller")[0];
                    const firstEvent = $(".fc-event:first");

                    setTimeout(() => {
                        scroller.scrollTop = firstEvent[0].offsetTop;

                        window.initialRender = true;
                    });
                }
            },
            eventRender(event, element) {
                const baseClass = "margin-5-right margin-5-bottom font-2em";
                const icon = $(`<i class='fa fa-calendar ${baseClass}'></i>`);
                const input = $(`<input type='checkbox' class='${baseClass} appointment-select' data-appointment-id=${event.id} />`);

                if (window.appointmentSelectable && !event.completed && window.canSelectAppointment(input)) {
                    const checked = window.appointmentIsSelected(event.id);
                    input[0].onchange = window.toggleAppointment;
                    input.prop("checked", checked);
                    if (event.account && event.account.time_to_service < 30) {
                        $(input).addClass("float-left no-margin-top");
                        if (!event.options) element.find(".fc-content").addClass("padding-5-top");
                    }

                    if (event.options) {
                        if (event.account.time_to_service < 30) {
                            icon.addClass("float-left");
                        }
                        element.find(".fc-time").prepend(icon);
                        input.insertBefore(element.find("i"));
                    } else {
                        element.find(".fc-time").prepend(input);
                    }
                } else if (event.options) {
                    element.find(".fc-time").prepend(icon);

                    if (event.account.time_to_service < 30) {
                        $(icon).addClass("float-left");
                    }
                }
            },
            eventResize: this.updateEvent,
            eventDrop: this.updateEvent,
        });

        window.editAppt = this.editAppt;
        window.destroyAppt = this.destroyAppt;
        window.markCompleted = this.markCompleted;
        window.canSelectAppointment = this.canSelectAppointment;
        window.appointmentIsSelected = this.appointmentIsSelected;
        window.toggleAppointment = this.toggleAppointment;
        window.selectAllAppointments = () => {
            this.setState({ selectedAppointments: [] }, () => {
                const selectedAppointments = [];
                Array.from($(".appointment-select")).forEach(input => {
                    if (window.canSelectAppointment(input, selectedAppointments)) {
                        selectedAppointments.push($(input).data("appointment-id"));
                        $(input).prop("checked", true);
                    }
                });

                this.setState({ selectedAppointments }, () => {
                    $(this.calendarEl).fullCalendar("rerenderEvents");
                });
            });
        };

        this.setCalendarRatio();
        $(window).on("resize", this.setCalendarRatio);
    }

    popoverContent = (calEvent) => {
        const googleMapsLink = (address) => {
            const parsed = address.split(" ").join("+");
            return `https://www.google.com/maps/place/${parsed}`
        }

        if (calEvent.type === "service_stop") {
            return `
                <a href="/accounts/${calEvent.account.id}">${calEvent.title}</a>
                <br /><br />
                <button class="btn btn-danger" onClick="destroyAppt(${calEvent.id})">
                    Destroy
                </button>
                <button class="btn btn-primary" onClick="editAppt(${calEvent.id})">
                    Edit
                </button>
            `
        } else if (calEvent.type === "repair" || calEvent.type === "repair_item") {
            const repair = calEvent.repair || calEvent.repair_item.repair;
            return `
                <p>
                    <b>Notes:</b><br />
                    ${calEvent.notes}
                </p>

                <button class="btn btn-primary" onClick="editAppt(${calEvent.id})">
                    Edit
                </button>
                <a href="/accounts/${calEvent.account.id}/repairs/${repair.id}" class="margin-10-left">
                    Go to repair
                </a>
            `
        } else if (calEvent.type === "other") {
            let value = "";
            if (calEvent.location) {
                if (calEvent.location) {
                    const fullLocation = `${calEvent.location.address} ${calEvent.location.city}, ${calEvent.location.state} ${calEvent.location.zip}`;
                    value += `
                        <p>
                            <a href=${googleMapsLink(fullLocation)} target="_blank">${fullLocation}</a>
                        </p>
                    `;
                }
            }
            value += `
                <b>Notes:</b>
                <p>${calEvent.notes}</p>
                <button class="btn btn-danger" onClick="destroyAppt(${calEvent.id})">
                    Destroy
                </button>
                <button class="btn btn-success" onClick="markCompleted(${calEvent.id})">
                    Mark Completed
                </button>
                <button class="btn btn-primary" onClick="editAppt(${calEvent.id})">
                    Edit
                </button>
            `

            return value;
        }
    };

    destroyAppt = (id) => {
        if (confirm("Are you sure you want to delete this appointment? This cannot be undone.")) {
            $.ajax({
                method: "DELETE",
                url: `appointments/${id}`
            }).done((res) => {
                this.fetchAppointments();
                $(".popover").popover("hide");
            }).fail((res) => {
                // handle failure
            })
        }
    };

    editAppt = (id) => {
        const appt = this.state.appointments.find(appt => appt.id == id)
        this.setState({ selectedAppointment: appt }, () => {
            $("#appointment-modal").modal("show");
        });
    };

    markCompleted = (id) => {
        $.ajax({
            method: "PUT",
            url: "appointments/" + id,
            data: {
                appointment: {
                    completed: true
                }
            }
        }).done(() => {
            $(".popover").popover("hide");

            this.fetchAppointments();
        });
    };

    updateEvent = (event, delta, revertFunc) => {
        let offset = moment().tz(this.props.timezone).format('Z');
        let start = event.start.format() + offset;
        let end = event.end.format() + offset;
        $.ajax({
            method: "PUT",
            url: "appointments/" + event.id,
            data: {
                appointment: {
                    start: start,
                    end: end
                }
            }
        }).done(() => {
            $(".popover").popover("hide");
        });
    };

    setCalendarRatio = () => {
        if (window.innerWidth <= 700) {
            $(".calendar-wrapper").fullCalendar('option', 'aspectRatio', 0.75)
        } else {
            $(".calendar-wrapper").fullCalendar('option', 'aspectRatio', 1);
        }
    };

    closeModalCallback = () => {
        this.setState({ selectedAppointment: null });
    };

    onAdvancedClose = () => {
        // prevent this from getting called in save callback AND when modal closes
        if (window.appointmentSelectable) {
            this.toggleSelectable();
            this.fetchAppointments();
        }
    };

    updateStoredAppointments = (appts) => {
        const ids = appts.map(appt => appt.id);
        const appointments = this.state.appointments.map(appt => {
            if (ids.includes(appt.id)) {
                return appts.find(_appt => _appt.id === appt.id);
            } else {
                return appt
            }
        });

        this.setState({ appointments }, () => {
            this.resetCalendarEvents();
        });
    };

    fetchAppointments = () => {
        let start = $(".calendar-wrapper").fullCalendar("getView").start.format();
        let end = $(".calendar-wrapper").fullCalendar("getView").end.format();

        start = moment.tz(start, this.props.timezone).startOf("day").utc().format();
        end = moment.tz(end, this.props.timezone).startOf("day").utc().format();

        $.ajax("/appointments.json", {
            method: "GET",
            data: {
                q: {
                    user_id_eq: this.state.selectedTech.id,
                    start_gteq: start,
                    end_lteq: end
                }
            }
        }).done(appointments => {
            this.setState({ appointments }, () => {
                $(".popover").popover("hide");
                this.resetCalendarEvents();
            });
        })
    };

    resetCalendarEvents = () => {
        $(".calendar-wrapper").fullCalendar("removeEvents");
        $(".calendar-wrapper").fullCalendar("addEventSource", this.calendarEvents());
    };

    calendarEvents = () => {
        const calendarAppts = [...this.state.appointments];
        return calendarAppts.map(appt => {
            const details = {
                "service_stop": {
                    backgroundColor: "#3a87ad",
                    textColor: "#fff",
                    get title() {
                        return `${appt.account.contact_name} - ${appt.account.address}`;
                    }
                },
                "repair": {
                    backgroundColor: "#d6ca28",
                    textColor: "#000000",
                    get title() {
                        return `${appt.account.contact_name} - ${appt.repair.description}`
                    }
                },
                "repair_item": {
                    backgroundColor: "#d6ca28",
                    textColor: "#000000",
                    get title() {
                        return `${appt.account.contact_name} - ${appt.repair_item.repair.description}`
                    }
                },
                "other": {
                    backgroundColor: "#2400dc",
                    textColor: "#fff",
                    title: appt.notes || (appt.location ? appt.location.address : "No Notes")
                }
            }

            appt.start = moment.tz(appt.start, this.props.timezone).format();
            appt.end = moment.tz(appt.end, this.props.timezone).format();

            appt.title = details[appt.type].title;
            if (appt.completed) {
                appt.title += " - Completed"
            }

            appt.editable = true;

            appt.color = details[appt.type].backgroundColor;
            appt.textColor = details[appt.type].textColor;

            return appt;
        })
    };

    updateSelectedTech = (e) => {
        const id = e.target.value;
        const selectedTech = this.props.techs.find(t => t.id === parseInt(id));

        this.setState({ selectedTech }, () => {
            window.initialRender = false;
            this.fetchAppointments();
        });
    };

    addSelectAllButton = () => {
        const button = $("<button class='btn btn-default margin-10-bottom select-all-button'>Select All Appointments</button>");
        button[0].onclick = window.selectAllAppointments;

        $(".fc-toolbar").after(button);
    };

    toggleSelectable = () => {
        window.appointmentSelectable = !window.appointmentSelectable;
        if (window.appointmentSelectable) {
            if ($(this.calendarEl).fullCalendar("getView").currentRangeUnit === "day") {
                if ($(".select-all-button").length < 1) {
                    this.addSelectAllButton();
                }
            }
        } else {
            if ($(".select-all-button").length > 0) {
                $(".select-all-button").remove();
            }
        }

        $(this.calendarEl).fullCalendar("rerenderEvents");
        this.setState({ selectedAppointments: [] });
    };

    canSelectAppointment = (input, selectedAppointments = this.state.selectedAppointments) => {
        const appt = this.state.appointments.find(appt => appt.id === $(input).data("appointment-id"));
        const testAppt = this.state.appointments.find(appt => appt.id === selectedAppointments[0]);


        if (selectedAppointments.length === 0) {
            return true
        } else if (appt.options && testAppt.options) {
            const apptDay = moment(appt.options.original_start).tz(this.props.timezone).format("dddd");
            const testApptDay = moment(testAppt.options.original_start).tz(this.props.timezone).format("dddd");

            if (appt.options.original_user === testAppt.options.original_user && apptDay === testApptDay) {
                return true
            } else {
                return false
            }
        } else if (!appt.options && !testAppt.options) {
            // must be on same day
            const apptDay = moment(appt.start, "YYYY-MM-DD").tz(this.props.timezone).format("dddd")
            const testApptDay = moment(testAppt.start, "YYYY-MM-DD").tz(this.props.timezone).format("dddd");

            return (apptDay === testApptDay && appt.type === testAppt.type);
        } else {
            return false
        }
    };

    appointmentIsSelected = (id) => {
        return this.state.selectedAppointments.includes(id);
    };

    toggleAppointment = (e) => {
        const id = $(e.target).data("appointment-id");
        let selectedAppointments = [...this.state.selectedAppointments];

        if (this.state.selectedAppointments.includes(id)) {
            selectedAppointments = selectedAppointments.filter(_id => _id !== id);
        } else {
            selectedAppointments.push(id);
        }

        this.setState({ selectedAppointments }, () => {
            $(this.calendarEl).fullCalendar("rerenderEvents");
        });
    };

    render() {
        return(
            <Fragment>
                <div className="row">
                    <div className="col-xs-12">
                        <div className="dropdown pull-right">
                            <button
                                className="btn btn-lg btn-success dropdown-toggle actions-dropdown"
                                type="button"
                                data-toggle="dropdown"
                            >
                                Actions
                                <span className="glyphicon glyphicon-chevron-down"></span>
                            </button>
                            { this.props.current_user.roles.includes("admin") &&
                                <button className="btn btn-lg btn-default margin-5-left" onClick={this.toggleSelectable}>
                                    <i className="fa fa-check-square-o"></i>
                                </button>
                            }
                            <ul className="dropdown-menu">
                                <li>
                                    <a
                                        href="#"
                                        className="text-center padding-10"
                                        data-toggle="modal"
                                        data-target="#appointment-modal"
                                    >
                                        <span className="glyphicon glyphicon-plus"></span>
                                        &nbsp;
                                        Create Appointment
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="#"
                                        className="text-center padding-10"
                                        data-toggle="modal"
                                        data-target="#appointment-order-modal"
                                    >
                                        <i className="fa fa-map-marker font-1-2em"></i>
                                        &nbsp;
                                        Arrange on Map
                                    </a>
                                </li>
                                { this.state.selectedAppointments.length > 0 &&
                                    <li>
                                        <a
                                            href="#"
                                            className="text-center padding-10"
                                            data-toggle="modal"
                                            data-target="#advanced-appointment-modal"
                                        >
                                            <span className="glyphicon glyphicon-pencil"></span>
                                            &nbsp;
                                            Advanced Edit
                                        </a>
                                    </li>
                                }
                            </ul>
                        </div>
                    </div>
                </div>

                <hr/>

                <div className="row">
                    <div className="col-md-4 col-sm-6 col-xs-12">
                        <div className="form-group">
                            <h4>
                                User:
                            </h4>
                            <select
                                name=""
                                id=""
                                className="form-control"
                                onChange={(e) => this.updateSelectedTech(e)}
                                defaultValue={this.state.selectedTech.id}
                            >
                                { this.props.techs.filter(t => t.active).map((tech, index) => {
                                    return(
                                        <option
                                            key={index}
                                            value={tech.id}
                                        >
                                            { tech.name }
                                        </option>
                                    )
                                }) }
                            </select>
                        </div>
                    </div>
                    <div className="col-md-8 col-sm-6 col-xs-12">
                        <div className="pull-right">
                            <div className="col-xs-12">
                                <h5>
                                    Appointment Types:
                                </h5>
                                <div className="appt-types margin-10-bottom">
                                    <div>
                                        <span className="title">
                                            Service Stop:
                                        </span>
                                        <span className="service-stop"></span>
                                    </div>
                                    <div>
                                        <span className="title">
                                            Repair:
                                        </span>
                                        <span className="repair"></span>
                                    </div>
                                    <div>
                                        <span className="title">
                                            Other:
                                        </span>
                                        <span className="other"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="calendar-wrapper col-xs-12" ref={(e) => this.calendarEl = e}></div>
                </div>
                <AppointmentModal
                    techs={this.props.techs}
                    timezone={this.props.timezone}
                    apptCallback={this.fetchAppointments}
                    closeModalCallback={this.closeModalCallback}
                    appointment={this.state.selectedAppointment}
                    base_plan={this.props.base_plan}
                    current_user={this.props.current_user}
                />
                <AppointmentOrder
                    techs={this.props.techs}
                    timezone={this.props.timezone}
                    defaultTech={this.props.current_user.id}
                    serviceDays={this.props.service_days}
                    apptCallback={this.updateStoredAppointments}
                />
                { this.state.selectedAppointments.length > 0 && this.props.current_user.roles.includes("admin") &&
                    <AdvancedEditModal
                        techs={this.props.techs}
                        selectedAppointments={this.state.selectedAppointments.map(id => this.state.appointments.find(appt => appt.id === id))}
                        timezone={this.props.timezone}
                        onClose={this.onAdvancedClose}
                    />
                }
            </Fragment>
        )
    }
}
