import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import UserRow from './UserRow';

import Utils from '../../../Utils';

const UsersSettingsContext = React.createContext();

export default class UsersSettings extends React.Component {
    static propTypes = {
        users: PropTypes.array.isRequired
    }

     constructor(props) {
        super(props);

        this.baseState = {
            users: props.users,
            filteredUsers: props.users
        }

        this.state = this.baseState;
     }

    updateUser = (index, newUser) => {
        let users = [...this.state.users];
        users[index] = newUser;

        this.setState({ users, filteredUsers: users });
    };

    addUser = (e) => {
        e.preventDefault();

        let users = [...this.state.users, { active: true, roles: [] }];

        this.setState({ users, filteredUsers: users });
    };

    removeUser = (index) => {
        let users = [...this.state.users];
        users[index] = null;

        users = users.filter(Boolean);

        this.setState({ users, filteredUsers: users });
    }

    filterUsers = (e) => {
        const term = e.target.value;

        const filteredUsers = this.state.users.filter(user => {
            return user.name.toLowerCase().search(term.toLowerCase()) !== -1
        });

        this.setState({ filteredUsers });
    };

    render() {
        return(
            <UsersSettingsContext.Provider
                value={{
                    users: this.state.users,
                    updateUser: this.updateUser
                }}
            >
                <h3 className="no-margin">
                    Users
                </h3>

                <hr/>

                <div className="row">
                    <div className="col-md-6 col-sm-6">
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search for User"
                                onChange={this.filterUsers}
                            />
                        </div>
                    </div>
                </div>

                <div className="row users-settings-wrapper">
                    <div className="col-md-12">
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        {
                                            ["name", "email", "phone", "role", "status", "action"].map((header, index) => (
                                                <td key={index} className={`${header}-header`}>
                                                    { header === "role" && "Role(s):"}
                                                    { header !== "role" && `${Utils.humanize(header)}:` }
                                                </td>
                                            ))
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.filteredUsers.map((user, index) => {
                                            return(
                                                <UserRow
                                                    key={index}
                                                    user={user}
                                                    index={index}
                                                    updateUser={this.updateUser}
                                                    removeUser={this.removeUser}
                                                />
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <a href="#" onClick={this.addUser}>
                            <i className="fa fa-plus-circle margin-5-right"></i>
                            Add User
                        </a>
                    </div>
                </div>
            </UsersSettingsContext.Provider>
        )
    }
}

export const UsersSettingsConsumer = UsersSettingsContext.Consumer;
