import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Utils from 'Utils';
import { toast } from 'react-toastify';

export default class DownloadCsv extends React.Component {
    static propTypes = {
        csvUrl: PropTypes.string.isRequired,
        cookieName: PropTypes.string.isRequired
    }

    constructor(props) {
        super(props);

        this.baseState = {
            fetching: false,
            cookieValue: this.getCookie()
        }

        this.state = this.baseState;
    }

    getCookie = () => {
        return Utils.getCookieValue(this.props.cookieName);
    };

    fetchCsv = () => {
        this.setState({ fetching: true });

        const fetchInterval = setInterval(() => {
            if (this.state.cookieValue !== this.getCookie()) {
                this.setState({ fetching: false, cookieValue: this.getCookie() })

                clearInterval(fetchInterval);
                toast.success("CSV Generated Successfully!", {
                    position: toast.POSITION.TOP_CENTER
                })
            }
        }, 1000);
    };

    render() {
        return(
            <Fragment>
                { this.state.fetching &&
                    <div className="display-flex align-items-center">
                        <h5 className="text-center margin-5-right">
                            Generating CSV
                        </h5>
                        <div className="flex-1">
                            <i className="fa fa-spinner fa-pulse fa-fw"></i>
                        </div>
                    </div>
                }
                { !this.state.fetching &&
                    <h5>
                        <a href={this.props.csvUrl} onClick={this.fetchCsv}>
                            <i className="fa fa-download margin-5-right"></i>
                            Download CSV
                        </a>
                    </h5>
                }
            </Fragment>
        )
    }
}
