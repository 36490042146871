import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import NewTask from './NewTask'
import Switch from '@shared/components/CheckboxSwitch';

import Utils from '../../../Utils';

export default class ServiceStopTaskSettings extends React.Component {
    static propTypes = {
        default_tasks: PropTypes.array.isRequired,
        task_settings: PropTypes.object.isRequired,
        input_name: PropTypes.string.isRequired
    }

    constructor(props) {
        super(props);

        this.baseState = {
            tasks: [...Object.keys(props.task_settings).sort()]
        }

        this.state = this.baseState;
    }

    taskEnabled = (task) => {
        return this.props.task_settings[task]["enabled"];
    };

    addTask = (e) => {
        e.preventDefault();

        const nonBlankTasks = [...this.state.tasks].filter(t => t.length > 0);
        const blankTasks = [...this.state.tasks].filter(t => t.length === 0);
        const tasks = nonBlankTasks.sort().concat([...blankTasks, ""]);

        this.setState({ tasks });
    };

    render() {
        return(
            <div className="row" ref={(e) => this.wrapperEl = e}>
                {
                    this.state.tasks.map((task, index) => {
                        return(
                            <div className="col-md-4 col-sm-6" key={index}>
                                <div className="panel panel-default">
                                    <div className="panel-body">
                                        <Fragment>
                                            { task.length > 0 &&
                                                <Fragment>
                                                    <h5 className="no-margin flex-1">{ Utils.humanize(task) }:</h5>
                                                    <div className="switch-wrapper">
                                                        <Switch
                                                            name={`${this.props.input_name}[${task}]`}
                                                            id={`${task}_task`}
                                                            checked={this.taskEnabled(task)}
                                                        />
                                                    </div>
                                                </Fragment>
                                            }
                                            { task.length === 0 &&
                                                <NewTask inputName={this.props.input_name} />
                                            }
                                        </Fragment>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }

                <div className="col-md-4 col-sm-6">
                    <div className="panel panel-default">
                        <div className="panel-body">
                            <a href="#" onClick={this.addTask}>+ Add New Task</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
