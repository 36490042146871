import React from 'react';
import PropTypes from 'prop-types';

import { toast } from 'react-toastify';

import Utils from '../../Utils';

export default class AttachToInvoice extends React.Component {
    static propTypes = {
        repair: PropTypes.object.isRequired,
        lineItems: PropTypes.array.isRequired,
        callback: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.baseState = {
            invoices: [],
            loading: null,
            selectedInvoice: null
        }

        this.state = this.baseState;
    }

    componentDidMount() {
        $("#attach-to-invoice-modal").on("show.bs.modal", (e) => {
            $(e.target).addClass("center-screen-modal");

            this.setState({ loading: true });

            $.ajax({
                method: "GET",
                url: "/invoices.json",
                data: {
                    q: {
                        account_id_eq: this.props.repair.account_id,
                        payment_status_eq: "draft",
                    }
                }
            }).done(invoices => {
                this.setState({ invoices, loading: false });
            }).fail(() => {
                toast.error("Something went wrong", {
                    position: toast.POSITION.TOP_CENTER
                });
            });
        });

        $("#attach-to-invoice-modal").on("hide.bs.modal", (e) => {
            $(e.target).removeClass("center-screen-modal");

            this.setState({ ...this.baseState });
        });
    }

    toggleSelected = (invoice) => {
        let selectedInvoice;

        if (this.state.selectedInvoice) {
            selectedInvoice = null
        } else {
            selectedInvoice = invoice;
        }

        this.setState({ selectedInvoice });
    };

    renderInvoices = () => {
        return this.state.invoices.map((invoice, index) => {
            return(
                <tr key={index}>
                    <td>
                        <input
                            type="checkbox"
                            disabled={this.state.selectedInvoice && this.state.selectedInvoice.id !== invoice.id}
                            onChange={() => this.toggleSelected(invoice)}
                        />
                    </td>
                    <td>
                        { Utils.pad(invoice.invoice_number, 5) }
                    </td>
                    <td>
                        { Utils.humanize(invoice.type) }
                    </td>
                    <td>
                        { Utils.floatToCurrency(invoice.total_amount) }
                    </td>
                </tr>
            )
        });
    };

    attachToInvoice = () => {
        this.setState({ loading: true });

        const description = this.props.repair.client_notes.length > 0 ? this.props.repair.client_notes : this.props.repair.description

        $.ajax({
            method: "PUT",
            url: `/invoices/${this.state.selectedInvoice.id}.json`,
            data: {
                invoice: {
                    line_items_attributes: [...this.props.lineItems]
                }
            }
        }).done((invoice) => {
            this.setState({ loading: false });

            this.props.callback(invoice);

            $("#attach-to-invoice-modal").modal("hide");

            toast.success(`Repair successfully attached to Invoice #${Utils.pad(invoice.invoice_number, 5)}`, {
                position: toast.POSITION.TOP_CENTER
            })
        }).fail(() => {
            toast.error("Something went wrong", {
                position: toast.POSITION.TOP_CENTER
            });
        });
    }

    render() {
        return(
            <div
                id="attach-to-invoice-modal"
                className="modal fade"
                tabIndex="-1"
                role="dialog"
            >
                <div className="modal-dialog width-100" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title float-left">
                                Attach repair to invoice
                            </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            { this.state.loading &&
                                <div className="display-flex justify-content-center margin-20">
                                    <i className="fa fa-spinner fa-pulse fa-3x fa-fw align-self-center flex-1"></i>
                                </div>
                            }
                            { !this.state.loading && this.state.invoices.length < 1 &&
                                <h4 className="text-center">
                                    No draft invoices to attach this repair to
                                </h4>
                            }
                            { this.state.loading === false && this.state.invoices.length > 0 &&
                                <div className="row">
                                    <div className="col-md-12">
                                        <h4 className="text-center margin-20-bottom">
                                            Select draft invoice to attach this repair to:
                                        </h4>
                                        <div className="table-responsive">
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <td>
                                                            &nbsp;
                                                        </td>
                                                        <td>
                                                            Invoice Number:
                                                        </td>
                                                        <td>
                                                            Type:
                                                        </td>
                                                        <td>
                                                            Total:
                                                        </td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    { this.renderInvoices() }
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="display-flex justify-content-center">
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                disabled={!this.state.selectedInvoice}
                                                onClick={this.attachToInvoice}
                                            >
                                                <i className="fa fa-file-text-o"></i>
                                                &nbsp;
                                                Attach repair to selected invoice
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-default"
                                data-dismiss="modal"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
