import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { toast } from 'react-toastify';

import ZoneEdit from './Edit';
import ZoneDetails from './Details';

const ZoneContext = React.createContext();

export default class Zones extends React.Component {
    static propTypes = {
        techs: PropTypes.array.isRequired,
        currentCompany: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);

        this.baseState = {
            loading: "initial",
            editZone: false,
            zones: [],
            selectedZone: null,
            creatingZone: false,
            mapMarkers: [],
            accounts: [],
            validations: {
                zoneName: false
            }
        }

        this.state = this.baseState;

        this.deselectedZoneStyle = {
            strokeColor: "#000000",
            strokeOpacity: 0.8,
            strokeWeight: 4,
            fillColor: "#000000",
            fillOpacity: 0.35
        }

        this.selectedZoneStyle = {
            ... this.deselectedZoneStyle,
            strokeColor: "#449d44",
            fillColor: "#449d44",
        }
    }

    componentDidMount() {
        $.ajax({
            url: "/reports/zones.json",
            method: "GET"
        }).done(aqsZones => {
            if (aqsZones.length > 0) {
                this.setState({ creatingZone: true }, () => {
                    this.createMap();
                    setTimeout(() => {
                        const zones = aqsZones.map(zone => {
                            return this.addRectangle(zone);
                        });

                        this.setState({ zones, creatingZone: false, loading: false });
                    });
                });
            } else {
                this.setState({ loading: false });
            }
        });
    }

    addRectangle = (zone, selected = false) => {
        let aqsZone;
        if (!zone.getBounds) {
            const bounds = new google.maps.LatLngBounds({ lat: parseFloat(zone.bounds.sw.lat), lng: parseFloat(zone.bounds.sw.lng) }, { lat: parseFloat(zone.bounds.ne.lat), lng: parseFloat(zone.bounds.ne.lng) });

            const styles = selected ? this.selectedZoneStyle : this.deselectedZoneStyle;

            aqsZone = new google.maps.Rectangle({
                map: this.map,
                bounds,
                ...styles
            });

            aqsZone.aqs = { ...zone };
        } else {
            aqsZone = zone;
        }

        aqsZone.addListener("click", (overlay) => {
            if (this.state.selectedZone) {
                this.state.selectedZone.setOptions(this.deselectedZoneStyle);
            }

            if (this.state.mapMarkers.length > 0) {
                this.clearMarkers();
            }

            this.setState({ selectedZone: aqsZone, accounts: [], mapMarkers: [] }, () => {
                this.state.selectedZone.setOptions(this.selectedZoneStyle);
                if (this.aqsZone().id) {
                    this.fetchZone();
                }
            });
        });

        return aqsZone;
    };

    createMap = () => {
        let center;
        if (this.props.currentCompany.location.lat && this.props.currentCompany.location.lng) {
            center = (({ lat, lng }) => ({ lat: parseFloat(lat), lng: parseFloat(lng) }))(this.props.currentCompany.location);
        }

        const map = new google.maps.Map($(".map-wrapper")[0], {
            zoom: 11,
            center: center || { lat: 0, lng: 0 }
        });

        this.map = map;

        $(window).on("resize", this.adjustMapHeight);

        this.adjustMapHeight();

        if (!center) {
            toast.error("Unable to center map on Company Location", {
                position: toast.POSITION.TOP_CENTER
            });
        }
    };

    adjustMapHeight = () => {
        if (this.mapWrapperEl) {
            const windowHeight = window.innerHeight;
            const mapWrapperY = this.mapWrapperEl.getBoundingClientRect().y;
            const mapWrapperHeight = windowHeight - mapWrapperY;
            $(this.mapWrapperEl).css("height", mapWrapperHeight);
        }
    };

    createZone = () => {
        this.setState({ creatingZone: true, editZone: true }, () => {
            if (!this.map) {
                this.createMap();
                setTimeout(() => {
                    this.setupMap();
                });
            } else {
                this.setupMap();
            }
        });
    };

    setupMap = () => {
        const drawingManager = this.drawingManager || new google.maps.drawing.DrawingManager({
            drawingMode: google.maps.drawing.OverlayType.MARKER,
            drawingControl: true,
            drawingControlOptions: {
                position: google.maps.ControlPosition.TOP_CENTER,
                drawingModes: ["rectangle"]
            }
        });

        drawingManager.setMap(this.map);
        this.drawingManager = drawingManager;
        google.maps.event.addListener(drawingManager, "overlaycomplete", (event) => {
            if (event.type !== "rectangle") {
                event.overlay.setMap(null);
            }
            if (this.state.selectedZone) {
                this.state.selectedZone.setOptions(this.deselectedZoneStyle);
                if (this.state.mapMarkers.length > 0) {
                    this.clearMarkers();
                }
            }

            event.overlay.setOptions(this.selectedZoneStyle);
            this.setState({ selectedZone: event.overlay, zones: [...this.state.zones, event.overlay], creatingZone: false, accounts: [], mapMarkers: [] });
            drawingManager.setMap(null);

            this.drawZones();
        });
    };

    drawZones = () => {
        this.state.zones.forEach(zone => {
            zone.setMap(this.map);
        });
    };

    updateSelectedZone = (e) => {
        const selectedZone = this.state.selectedZone;
        selectedZone.aqs = { ...this.state.selectedZone.aqs, [e.target.name]: e.target.value }
        this.setState({ selectedZone });
    };

    fetchZone = () => {
        $.ajax({
            url: `/reports/zones/${this.aqsZone().id}.json`,
            method: "GET"
        }).done(zone => {
            const selectedZone = this.state.selectedZone;
            selectedZone.aqs = zone;
            this.setState({ selectedZone }, () => {
                this.setMapMarkers();
            });
        });
    };

    editZone = () => {
        this.setState({ editZone: true });
    };

    isValid = () => {
        const validations = {
            zoneName: this.aqsZone().name.length < 1
        }

        this.setState({ validations });
        return !Object.values(validations).includes(true);
    };

    saveZone = () => {
        if (this.isValid()) {
            this.setState({ loading: "saving_zone" });
            const zone = this.state.selectedZone;

            let url = "/reports/zones";
            url += zone.aqs.id ? `/${zone.aqs.id}.json` : ".json";

            const method = zone.aqs.id ? "PUT" : "POST";

            $.ajax({
                url,
                method,
                data: {
                    zone: {
                        name: zone.aqs.name,
                        bounds: {
                            ne: zone.getBounds().getNorthEast().toJSON(),
                            sw: zone.getBounds().getSouthWest().toJSON()
                        }
                    }
                }
            }).done(zone => {
                if (method === "POST") {
                    this.state.selectedZone.setMap(null);
                    const zones = this.state.zones.filter(z => (z.aqs || {}).id);
                    const selectedZone = this.addRectangle(zone, true);
                    this.setState({ selectedZone, zones: [...zones, selectedZone], loading: false, editZone: false }, () => {
                        this.setMapMarkers();
                    })
                } else {
                    const selectedZone = this.state.selectedZone;
                    selectedZone.aqs = { ...zone }
                    this.setState({ selectedZone, loading: false, editZone: false }, () => {
                        this.setMapMarkers();
                    });
                }

                toast.success("Successfully created Zone", {
                    position: toast.POSITION.TOP_CENTER
                });
            });
        }
    };

    destroyZone = () => {
        this.setState({ loading: "destroying_zone" });
        if (this.aqsZone().id) {
            $.ajax({
                method: "DELETE",
                url: `/reports/zones/${this.aqsZone().id}.json`
            }).done(() => {
                const zones = [...this.state.zones.filter(zone => zone.aqs.id !== this.aqsZone().id)];
                this.state.selectedZone.setMap(null)

                this.setState({ zones, selectedZone: null, loading: false, editZone: false }, () => {
                    if (zones.length < 1) {
                        this.map = null;
                        this.drawingManager = null;
                    }
                    toast.success("Successfully destroyed Zone", {
                        position: toast.POSITION.TOP_CENTER
                    });
                });
            });
        } else {
            const zones = [...this.state.zones.filter(zone => ((zone.aqs || {}).id))];

            this.state.selectedZone.setMap(null);
            this.setState({ zones, selectedZone: null, accounts: [], mapMarkers: [], editZone: false, loading: false });
        }

        this.clearMarkers();
    };

    addUser = (user, type, hideCallback) => {
        this.setState({ loading: type });

        $.ajax({
            method: "PUT",
            url: `/reports/zones/${this.aqsZone().id}.json`,
            contentType: "application/json",
            data: JSON.stringify({
                zone: {
                    user_zones_attributes: [{
                        user_id: user.id,
                    }]
                }
            })
        }).done((zone) => {
            const selectedZone = this.state.selectedZone;
            selectedZone.aqs = { ...zone };

            this.setState({ selectedZone, loading: false }, () => {
                hideCallback();
                toast.success("Successfully added Technician to selected Zone", {
                    position: toast.POSITION.TOP_CENTER
                });
            });
        });
    };

    deleteUser = (user, type) => {
        this.setState({ loading: type });

        const userZone = this.aqsZone().user_zones.find(uz => uz.user.id === user.id);
        $.ajax({
            method: "PUT",
            url: `/reports/zones/${this.aqsZone().id}.json`,
            contentType: "application/json",
            data: JSON.stringify({
                zone: {
                    user_zones_attributes: [{ id: userZone.id, user_id: user.id, _destroy: true }]
                }
            })
        }).done(() => {
            const selectedZone = this.state.selectedZone;
            const user_zones = this.aqsZone().user_zones.filter(uz => uz.id !== userZone.id);
            selectedZone.aqs = { ...this.aqsZone(), user_zones }

            this.setState({ selectedZone, loading: false }, () => {
                toast.success("Successfully removed Technician from selected Zone", {
                    position: toast.POSITION.TOP_CENTER
                });
            });
        });
    };

    aqsZone = () => {
        return (this.state.selectedZone.aqs || {});
    };

    setMapMarkers = () => {
        this.clearMarkers();
        const mapMarkers = this.aqsZone().accounts.map(account => {
            return new google.maps.Marker({
                position: {
                    lat: parseFloat(account.location.lat),
                    lng: parseFloat(account.location.lng)
                },
                map: this.map
            });
        });

        this.setState({ mapMarkers, accounts: this.aqsZone().accounts });
    };

    clearMarkers = () => {
        this.state.mapMarkers.forEach(marker => {
            marker.setMap(null);
        });
    };

    render() {
        return(
            <ZoneContext.Provider
                value={{
                    updateSelectedZone: this.updateSelectedZone,
                    saveZone: this.saveZone,
                    destroyZone: this.destroyZone,
                    addUser: this.addUser,
                    deleteUser: this.deleteUser,
                    aqsZone: this.aqsZone,
                    setEditZone: this.editZone,
                    techs: this.props.techs,
                    ...this.state
                }}
            >
                <div className="zones-report-wrapper">
                    { (this.state.zones.length > 0 || this.state.creatingZone) &&
                        <div className="map-wrapper padding-15-right" ref={(e) => this.mapWrapperEl = e}></div>
                    }

                    { this.state.loading === "initial" &&
                        <div className="width-100">
                            <h2 className="text-center">
                                Loading Zones...
                            </h2>
                            <div className="display-flex">
                                <i className="fa fa-spinner fa-pulse fa-3x fa-fw align-self-center flex-1"></i>
                            </div>
                        </div>
                    }

                    { this.state.loading !== "initial" &&
                        <div className="dashboard-wrapper">
                            { !this.state.loading &&
                                <Fragment>
                                    <div className="row no-margin">
                                        <div className="col-xs-12">
                                            <div className="pull-right">
                                                <button
                                                    className="btn btn-lg btn-success"
                                                    onClick={this.createZone}
                                                    disabled={this.state.creatingZone || (this.state.selectedZone && !this.aqsZone().id)}
                                                >
                                                    <span className="glyphicon glyphicon-plus margin-5-right"></span>
                                                    Create New Zone
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xs-12">
                                        <hr/>
                                    </div>
                                </Fragment>
                            }

                            { (this.state.zones.length > 0 && !this.state.selectedZone && !this.state.creatingZone) &&
                                <div className="row message-wrapper no-margin">
                                    <div className="col-xs-12">
                                        <h2 className="text-center">
                                            Select a Zone
                                        </h2>
                                    </div>
                                </div>
                            }

                            { (this.state.zones.length < 1 && !this.state.creatingZone) &&
                                <div className="row message-wrapper no-margin">
                                    <div className="col-xs-12">
                                        <h2 className="text-center">
                                            No Zones Found
                                        </h2>
                                    </div>
                                </div>
                            }
                            { this.state.creatingZone &&
                                <div className="row message-wrapper">
                                    <div className="col-xs-12">
                                        <h2 className="text-center">
                                            Draw new Zone on Map
                                        </h2>
                                    </div>
                                </div>
                            }

                            { (!this.state.creatingZone && this.state.selectedZone) &&
                                <Fragment>
                                    { !this.state.loading &&
                                        <div className="margin-20-bottom">
                                            <ZoneEdit />
                                        </div>
                                    }
                                    <div className={`margin-20-bottom ${this.state.loading && this.state.loading.match(/zone/) && 'height-100'}`}>
                                        <ZoneDetails
                                            userZones={this.aqsZone().user_zones || []}
                                        />
                                    </div>
                                </Fragment>
                            }
                        </div>
                    }
                </div>
            </ZoneContext.Provider>
        )
    }
}

export const ZoneConsumer = ZoneContext.Consumer;
