export default {
    nextReminder(reminder) {
        if (reminder.occurrence_options) {
            return moment(reminder.occurrence_options.next_occurrence).format("MM-DD-YYYY") || moment(reminder.start).format("MM-DD-YYYY");
        } else if (Object.keys(reminder.occurrence_options || {}).keys.length < 1) {
            if (moment(reminder.start) > moment()) {
                return moment(reminder.start).format("MM-DD-YYYY");
            } else {
                return "Next Service Stop";
            }
        }
    },

    remindEvery(reminder) {
        if (Object.keys(reminder.occurrence_options || {}).length > 0) {
            return `${reminder.occurrence_options.reminder_interval} ${reminder.occurrence_options.reminder_unit}`
        } else {
            return "Service Stop"
        }
    }
}
