import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Utils from '../../../Utils';

export default class ServiceSetsShow extends React.Component {
    static propTypes = {
        serviceSet: PropTypes.object.isRequired,
        chemicalReadings: PropTypes.array.isRequired,
        addableChemicals: PropTypes.array.isRequired,
        chemicalUnits: PropTypes.object.isRequired,
        isNotAdmin: PropTypes.bool.isRequired,
        defaultTasks: PropTypes.array.isRequired,
        tasksCompleted: PropTypes.object.isRequired
    }

    findReadings = (which) => {
        return this.props[which].map(key => {
            const _key = which === "addableChemicals" ? `${key}_added` : key;

            if (this.props.serviceSet[_key]) {
                return { [_key]: this.props.serviceSet[_key] };
            }
        }).filter(Boolean);
    };

    displayReadings = (readings) => {
        return readings.map((object, index) => {
            const key = Object.keys(object)[0];
            const value = Object.values(object)[0];

            let unit;
            if (key.match(/\_added/)) {
                unit = <span>({ this.props.chemicalUnits[key.split("_added")[0]] })</span>;
            } else if (key !== "ph") {
                unit = <span>(ppm)</span>
            }

            return(
                <div className="entry" key={index}>
                    <span className="key">
                        { Utils.humanize(key) }:
                    </span>
                    <span className="value">
                        { value } { unit }
                    </span>
                </div>
            )
        })
    };

    nonChemicalServiceItems = () => {
        return this.props.serviceSet.service_items.filter(si => !si.chemical);
    };

    enabledTasks = () => {
        return Object.keys(this.props.tasksCompleted).filter(key => {
            return this.props.tasksCompleted[key].enabled
        }).sort();
    }

    isDefaultTask = (task) => {
        return this.props.defaultTasks.includes(task);
    };

    serviceTask = (name, iconClass, key) => {
        return(
            <div className="panel panel-default" key={key}>
                <div className="panel-body">
                    <span>
                        { Utils.humanize(name) }:
                    </span>
                    <span className={`glyphicon glyphicon-${iconClass}`}></span>
                </div>
            </div>
        )
    };

    render() {
        return(
            <Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <div className="display-flex readings-wrapper">
                            { this.findReadings("chemicalReadings").length > 0 &&
                                <div className="panel panel-default chemical-readings-wrapper">
                                    <div className="panel-body">
                                        <h4 className="no-margin-top">
                                            Chemical Readings
                                        </h4>

                                        <hr/>

                                        {
                                            this.displayReadings(this.findReadings("chemicalReadings"))
                                        }
                                    </div>
                                </div>
                            }
                            { this.findReadings("addableChemicals").length > 0 &&
                                <div className="panel panel-default chemicals-added-wrapper">
                                    <div className="panel-body">
                                        <h4 className="no-margin-top">
                                            Chemicals Added
                                        </h4>

                                        <hr/>

                                        {
                                            this.displayReadings(this.findReadings("addableChemicals"))
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>

                <hr/>

                <div className="row margin-10-top">
                    { this.props.serviceSet["pool_condition"].length > 0 &&
                        <div className="col-md-4 col-sm-4">
                            <div className="panel panel-default">
                                <div className="panel-body">
                                    Condition: { Utils.humanize(this.props.serviceSet["pool_condition"][0]) }
                                </div>
                            </div>
                        </div>
                    }
                    { this.props.serviceSet["water_level"] &&
                        <div className="col-md-4 col-sm-4">
                            <div className="panel panel-default">
                                <div className="panel-body">
                                    Water Level: { Utils.humanize(this.props.serviceSet["water_level"]) }
                                </div>
                            </div>
                        </div>
                    }

                    { this.props.serviceSet["filter_pressure"] &&
                        <div className="col-md-4 col-sm-4">
                            <div className="panel panel-default">
                                <div className="panel-body">
                                    Filter Pressure: { this.props.serviceSet["filter_pressure"] } (psi)
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className="tasks-completed">
                    {
                        this.enabledTasks().map((task, index) => {
                            let iconClass;

                            if (this.isDefaultTask(task)) {
                                iconClass = this.props.serviceSet[task] ? "ok" : "remove";
                            } else {
                                const customFields = this.props.serviceSet.custom_fields || {}
                                iconClass = (customFields.tasks_completed || []).includes(task) ? "ok" : "remove";
                            }

                            return(
                                this.serviceTask(task, iconClass, index)
                            )
                        })
                    }
                </div>

                <hr/>

                { this.nonChemicalServiceItems().length > 0 &&
                    <div className="row">
                        <div className="col-md-12">
                            <h4>
                                Serivce Items:
                            </h4>
                            {
                                this.nonChemicalServiceItems().map((serviceItem, index) => {
                                    return(
                                        <div className="panel panel-default" key={index}>
                                            <div className="panel-body">
                                                <span className="margin-5-right">
                                                    { serviceItem.name }
                                                </span>
                                                { serviceItem.quantity > 1 &&
                                                    <span className="margin-5-right">
                                                        ({ serviceItem.quantity })
                                                    </span>
                                                }
                                                { !this.props.isNotAdmin &&
                                                    <span>
                                                        : { Utils.floatToCurrency(parseFloat(serviceItem.price)) }
                                                    </span>
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                }
                { (this.props.serviceSet.notes || "").length > 0 &&
                    <Fragment>
                        <h4>
                            Private Notes:
                        </h4>
                        <div className="well">
                            { this.props.serviceSet.notes }
                        </div>
                    </Fragment>
                }
            </Fragment>
        )
    }
}
