import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import TextMessage from './TextMessage';

import { TextMessageContextConsumer } from './ConversationsModal';
import Utils from '../../Utils';

export default class TextMessageConversationWindow extends React.Component {
    static propTypes = {
        conversation: PropTypes.object.isRequired,
        fetchTextMessages: PropTypes.func.isRequired,
        scrollLastTextIntoView: PropTypes.func.isRequired
    }

    componentDidMount() {
        this.props.fetchTextMessages(true, false, true);
    }

    loadMoreMessages = () => {
        this.props.fetchTextMessages(false, true);
    };

    displayContact = () => {
        if (this.props.conversation.account_names.length > 0 || this.props.conversation.user_name) {
            if (this.props.conversation.account_names.length > 0) {
                return(
                    <Fragment>
                        <h4 className="text-center">
                            { this.props.conversation.account_names.join(", ") }
                        </h4>
                        <h5 className="text-center color-gray no-margin-top">
                            { Utils.formattedPhone(this.props.conversation.reply_to_number) }
                        </h5>
                    </Fragment>
                )
            } else {
                return(
                    <Fragment>
                        <h4 className="text-center">
                            { this.props.conversation.user_name }
                        </h4>
                        <h5 className="text-center color-gray no-margin-top">
                            { Utils.formattedPhone(this.props.conversation.reply_to_number) }
                        </h5>
                    </Fragment>
                )
            }
        } else {
            return(
                <h4 className="text-center">
                    { Utils.formattedPhone(this.props.conversation.reply_to_number) }
                </h4>
            )
        }
    };

    render() {
        return(
            <TextMessageContextConsumer>
                { (context) => (
                    <Fragment>
                        <div className="contact-info">
                            { this.displayContact() }
                        </div>
                        { context.expandedImage &&
                            <div className="expanded-image">
                                <span className="close" onClick={() => context.setExpandedImage(null)}>
                                    &times;
                                </span>
                                <img src={`${context.expandedImage}`} />
                            </div>
                        }
                        <div className="conversation margin-10-bottom">
                            { context.fetchingMessages &&
                                <div className="display-flex flex-column justify-content-center align-items-center height-100">
                                    <h4 className="text-center">
                                        Loading Messages...
                                    </h4>
                                    <div>
                                        <i className="fa fa-spinner fa-pulse fa-3x fa-fw align-self-center flex-1"></i>
                                    </div>
                                </div>
                            }
                            { !context.fetchingMessages &&
                                <Fragment>
                                    { context.currentConversationMessagesPage < context.currentConversationMessagesPages &&
                                        <div className="display-flex justify-content-center">
                                            { context.appendingTexts &&
                                                <i className="fa fa-spinner fa-pulse fa-3x fa-fw align-self-center flex-1"></i>
                                            }
                                            { !context.appendingTexts &&
                                                <button className="btn btn-default width-100 text-center margin-10-bottom" onClick={this.loadMoreMessages}>
                                                    Load More Messages
                                                </button>
                                            }
                                        </div>
                                    }
                                    {
                                        context.conversationMessages.map((message, index) => {
                                            return(
                                                <TextMessage
                                                    key={index}
                                                    textMessage={message}
                                                    company={context.company}
                                                />
                                            )
                                        })
                                    }
                                </Fragment>
                            }
                        </div>
                        <div className="reply">
                            <div className="display-flex height-100">
                                <div className="form-group">
                                    <textarea
                                        className="form-control"
                                        onChange={(e) => context.setReplyText(e.target.value)}
                                        onFocus={context.markReadMessages}
                                        value={context.replyText}
                                    >
                                    </textarea>
                                </div>
                                <div className="reply-button-wrapper display-flex justify-content-center align-items-center">
                                    { !context.sendingText &&
                                        <div className="reply-button display-flex flex-column justify-content-center align-items-center" onClick={context.sendText}>
                                            <i className="fa fa-paper-plane margin-5-bottom"></i>
                                            <div className="send-text">
                                                Send
                                            </div>
                                        </div>
                                    }
                                    { context.sendingText &&
                                        <Fragment>
                                            <div>
                                                <i className="fa fa-spinner fa-pulse fa-3x fa-fw align-self-center flex-1"></i>
                                            </div>
                                        </Fragment>
                                    }
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )}
            </TextMessageContextConsumer>
        )
    }
}
