import React, { Fragment, useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

const RecordPreview = (props) => {
    const [activated, setActivated] = useState(false);
    const [validations, setValidations] = useState({ remarks: false });

    useEffect(() => {
        if (props.reminder.active) {
            setActivated(true);
        }
    }, [props.reminder.active]);

    const isValidated = () => {
        let invalid = props.reminder.remarks.replace(/ /g, "").length < 1
        setActivated(invalid);
        setValidations({ remarks: invalid });

        return !invalid;
    };

    const saveReminder = () => {
        if (isValidated()) props.update(props.reminder, { acknowledged: true });
    };

    return(
        <Fragment>
            <div className={`reminder-${props.reminder.id} panel panel-default ${activated ? "no-margin-bottom" : ""}`}>
                <div className="panel-body display-flex">
                    <div className="display-flex align-items-center cursor-pointer margin-5-right">
                        <span className={`glyphicon glyphicon-chevron-down ${activated ? "" : "rotate-right"}`}></span>
                    </div>
                    <div className="flex-1 display-flex align-items-center cursor-pointer" onClick={() => setActivated(!activated)}>
                        { `Reminder #${props.reminderIndex + 1}` }
                    </div>
                    <div className="align-self-end acknowledged">
                        <h5>
                            Acknowledged:
                            <span className={`glyphicon ${props.reminder.acknowledged ? 'glyphicon-ok' : 'glyphicon-remove'} margin-5-left`}></span>
                        </h5>
                    </div>
                </div>
            </div>
            <div className={`${activated ? "display-block" : "display-none"}`}>
                <div className="well">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    <h4 className="no-margin">
                                        Description:
                                    </h4>
                                </div>
                                <div className="panel-body">
                                    { props.reminder.description }
                                </div>
                            </div>
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    <h4 className="no-margin">
                                        Remarks:
                                    </h4>
                                </div>
                                <div className="panel-body">
                                    <div className="form-group">
                                        <textarea
                                            className="form-control"
                                            value={props.reminder.remarks || ""}
                                            onChange={(e) => props.update(props.reminder, { remarks: e.target.value })}
                                        />
                                        { props.reminder.notify &&
                                            <div className="color-red text-center margin-5-top">
                                                <b>Remarks for this reminder will be viewable by customer</b>
                                            </div>
                                        }
                                        { validations.remarks &&
                                            <div className="validation-wrapper">
                                                <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                                                Remarks are required
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group col-xs-12 no-margin">
                            <label>
                                Quick Remarks:
                            </label>
                            <br/>
                            <div className="display-flex">
                                <div className="flex-1 display-flex">
                                    <h5 className="no-margin-top margin-5-right cursor-pointer" onClick={() => props.update(props.reminder, { remarks: "Completed." })}>
                                        <span className="label label-default">
                                            Completed.
                                        </span>
                                    </h5>
                                    <h5 className="no-margin-top cursor-pointer" onClick={() => props.update(props.reminder, { remarks: "Unable to complete." })}>
                                        <span className="label label-default">
                                            Unable to complete.
                                        </span>
                                    </h5>
                                </div>
                            </div>
                            <div className="display-flex justify-content-end">
                                <button
                                    type="button"
                                    className="btn btn-success pull-right"
                                    onClick={saveReminder}
                                    disabled={(props.reminder.remarks || "").length < 1}
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

RecordPreview.propTypes = {
    reminder: PropTypes.object.isRequired,
    reminderIndex: PropTypes.number.isRequired,
    update: PropTypes.func.isRequired
}

export default RecordPreview;
