import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import CustomerFeedbackForm from '@shared/components/CustomerFeedbackForm';

import { toast } from 'react-toastify';

export default class CustomerFeedbackSettings extends React.Component {
    static propTypes = {
        settings: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);

        this.baseState = {
            settings: {
                customer_feedback_enabled: props.settings.customer_feedback_enabled,
                yelp_profile: props.settings.yelp_profile,
                google_profile: props.settings.google_profile,
                facebook_profile: props.settings.facebook_profile,
                nextdoor_profile: props.settings.nextdoor_profile,
                customer_feedback_email: props.settings.customer_feedback_email
            },
            validations: {
                profiles: false
            },
            saving: false
        }

        this.state = this.baseState;
    }

    toggleFeedback = () => {
        const save = this.state.settings.customer_feedback_enabled

        this.setState({ settings: { ...this.state.settings, customer_feedback_enabled: !this.state.settings.customer_feedback_enabled } }, () => {
            if (save) this.save();
        });
    };

    isValid = () => {
        if (!this.state.settings.customer_feedback_enabled) return true;
        const profileKeys = Object.keys(this.state.settings).filter(k => k.match(/profile/));
        const profiles = profileKeys.map(k => this.state.settings[k]).filter(Boolean);

        const validations = {
            profiles: !profiles.some(p => p.length > 0),
            email: this.state.settings.customer_feedback_email.length < 1
        }

        this.setState({ validations });
        return !Object.values(validations).includes(true)
    };

    save = () => {
        if (this.isValid()) {
            this.setState({ saving: true });

            $.ajax({
                method: "PUT",
                url: "/settings.json",
                data: {
                    settings: { ...this.state.settings }
                }
            }).done(() => {
                toast.success("Settings successfully updated!", {
                    position: toast.POSITION.TOP_CENTER
                })
            }).fail(() => {
                toast.error("Settings unable to be updated...", {
                    position: toast.POSITION.TOP_CENTER
                })
            }).always(() => {
                this.setState({ saving: false })
            });
        }
    };

    updateSettings = (e) => {
        const prop = e.target.name;
        this.setState({ settings: { ...this.state.settings, [`${prop}`]: e.target.value } });
    };

    render() {
        return(
            <CustomerFeedbackForm
                settings={this.state.settings}
                toggleFeedback={this.toggleFeedback}
                updateSettings={this.updateSettings}
                saveCallback={this.save}
                saving={this.state.saving}
                validations={this.state.validations}
            />
        )
    }
}
