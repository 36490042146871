import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { UsersSettingsConsumer } from './Settings';
import Utils from '../../../Utils';

export default class Roles extends React.Component {
    static propTypes = {
        user: PropTypes.object.isRequired,
        editing: PropTypes.bool.isRequired,
        updateUser: PropTypes.func.isRequired,
        index: PropTypes.number.isRequired
    }

    updateRole = (e) => {
        const originalRole = e.target.getAttribute("originalrole");
        const role = e.target.value;
        const user = { ...this.props.user };
        let roles;

        if (originalRole) {
            // changing role
            roles = [...user.roles.filter(_role => _role !== originalRole), role]
        } else {
            // adding new role
            roles = [...user.roles.filter(role => role.length > 0), role];
        }

        if (roles.includes("admin")) {
            if (role !== "admin") {
                roles = [role];
            } else {
                roles = ["admin"];
            }
        }

        user.roles = roles;

        this.props.updateUser(this.props.index, user);
    };

    addRole = (e) => {
        e.preventDefault();

        const user = { ...this.props.user };
        const roles = user.roles;
        user.roles = [...roles, ""];

        this.props.updateUser(this.props.index, user);
    };

    canAddRole = () => {
        const roles = this.props.user.roles;
        return !roles.includes("admin") && roles.length !== 2;
    };

    removeRole = (e, role) => {
        e.preventDefault();

        const user = { ...this.props.user };
        let roles = user.roles.filter(_role => role !== _role);

        user.roles = roles;

        this.props.updateUser(this.props.index, user);
    };

    render() {
        return(
            <UsersSettingsConsumer>
                {(context) => (
                    <Fragment>
                        { !this.props.editing &&
                            <Fragment>
                                { this.props.user.roles.length > 0 &&
                                    this.props.user.roles.map(user => Utils.humanize(user)).join(" / ")
                                }
                            </Fragment>
                        }
                        { this.props.editing &&
                            <Fragment>
                                { this.props.user.roles.map((role, index) => {
                                    return(
                                        <Fragment key={index}>
                                            { this.props.user.company_contact && role === "admin" &&
                                                "Admin"
                                            }
                                            { (!this.props.user.company_contact || role !== "admin") &&
                                                <div className="form-group no-margin display-flex" key={index}>
                                                    <select
                                                        value={role}
                                                        onChange={(e) => this.updateRole(e)}
                                                        className="form-control width-80"
                                                        originalrole={role === "" ? undefined : role}
                                                    >
                                                        <option value="">
                                                            Select role...
                                                        </option>
                                                        {
                                                            ["admin", "route_tech", "repair_tech"].map((role, index) => {
                                                                return(
                                                                    <option
                                                                        key={index}
                                                                        value={role}
                                                                    >
                                                                        { Utils.humanize(role) }
                                                                    </option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <div className="margin-5-left width-20 display-flex flex-column justify-content-center">
                                                        <a href="#" onClick={(e) => this.removeRole(e, role)}>
                                                            <i className="fa fa-minus-circle"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            }
                                        </Fragment>
                                    )
                                }) }
                                { this.canAddRole() &&
                                    <a
                                        href="#"
                                        onClick={this.addRole}
                                        className={`display-block ${this.props.user.roles.length > 0 ? "margin-10-top" : ""}`}
                                    >
                                        <i className="fa fa-plus-circle margin-5-right"></i>
                                        Add Role
                                    </a>
                                }
                            </Fragment>
                        }
                    </Fragment>
                )}
            </UsersSettingsConsumer>
        )
    }
}
