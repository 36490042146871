import React from 'react';
import PropTypes from 'prop-types';
import StepZilla from 'react-stepzilla';

import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import SaveAccount from './SaveAccount';

const WizardContext = React.createContext();

export default class AccountFormWizard extends React.Component {
    static propTypes = {
        account: PropTypes.object.isRequired,
        timezone: PropTypes.string.isRequired,
        service_techs: PropTypes.array.isRequired,
        service_plans: PropTypes.array.isRequired,
        invoice_integration: PropTypes.string,
        base_plan: PropTypes.string.isRequired,
        company: PropTypes.object.isRequired,
        company_settings: PropTypes.object.isRequired,
        environment: PropTypes.string.isRequired,
        pusher_key: PropTypes.string.isRequired
    };

    constructor(props) {
        super(props);

        const accountLocation = props.account.location || {};

        this.baseState = {
            body_of_waters_attributes: props.account.body_of_waters,
            appointments_attributes: props.account.appointments.filter(a => !a.completed && a.type === "service_stop"),
            contact_name: props.account.contact_name || "",
            contact_phone: props.account.contact_phone || "",
            contact_email: props.account.contact_email || "",
            location: accountLocation,
            on_service: props.account.on_service || false,
            service_plan: props.account.service_plan || "",
            time_to_service: props.account.time_to_service || 30,
            service_frequency: props.account.service_frequency || "weekly",
            billing_period_type: props.account.billing_period_type,
            invoice_delivery: props.account.invoice_delivery,
            automatic_payments: props.account.automatic_payments,
            rate_type: props.account.rate_type,
            base_cost: props.account.base_cost,
            next_billing_date: props.account.next_billing_date,
            billing_start_date: props.account.billing_start_date,
            generate_invoices_for_service_in: props.account.generate_invoices_for_service_in,
            days_to_issue_bill: props.account.days_to_issue_bill,
            days_til_due_date: props.account.days_til_due_date,
            notes: props.account.notes || "",
            home_warranty: props.account.home_warranty,
            pre_approved_budget: props.account.pre_approved_budget,
            active: props.account.active || true,
        }

        this.state = this.baseState;
    }

    componentDidMount() {
        if (this.props.account.id) {
            // allows ability to navigate through all steps initially
            this.stepZillaComp.props.steps.forEach(c => c.validated = true);
        }

        $(document).ready(() => {
            let interval = setInterval(() => {
                if ($(".intercom-launcher-frame").length > 0) {
                    clearInterval(interval);

                    $(".intercom-launcher-frame").css({ top: "18%", bottom: "inherit" });
                    $(".intercom-launcher-discovery-frame").css({ top: "18%", bottom: "inherit" });
                }
            }, 1);
        });
    }

    getMasterState = () => {
        return this.state;
    };

    updateMasterState = (obj) => {
        this.setState({
            ...this.state,
            ...obj
        });
    };

    scrollToTop = () => {
        setTimeout(() => {
            if (document.body.scrollTop !== 0) {
                $(".progtrckr")[0].scrollIntoView();
            }
        });
    };

    render() {
        const steps = [
            {
                name: "Contact Info", component: <Step1 getMasterState={this.getMasterState} contact_name={this.state.contact_name} contact_phone={this.state.contact_phone} contact_email={this.state.contact_email} location={this.state.location} updateMasterState={this.updateMasterState} />
            },
            {
                name: "Service Info", component: <Step2 getMasterState={this.getMasterState} updateMasterState={this.updateMasterState} serviceTechs={this.props.service_techs} servicePlans={this.props.service_plans} on_service={this.props.on_service === false ? false : true} service_plan={this.state.service_plan} timezone={this.props.timezone} body_of_waters_attributes={this.state.body_of_waters_attributes} appointments_attributes={this.state.appointments_attributes} time_to_service={this.state.time_to_service} on_service={this.state.on_service} />
            },
            {
                name: "Billing Info", component: <Step3 getMasterState={this.getMasterState} updateMasterState={this.updateMasterState} timezone={this.props.timezone} billing_period_type={this.state.billing_period_type} rate_type={this.state.rate_type} base_cost={this.state.base_cost} next_billing_date={this.state.next_billing_date} billing_start_date={this.state.billing_start_date} generate_invoices_for_service_in={this.state.generate_invoices_for_service_in} days_to_issue_bill={this.state.days_to_issue_bill} days_til_due_date={this.state.days_til_due_date} automatic_payments={this.state.automatic_payments} invoice_delivery={this.state.invoice_delivery} base_plan={this.props.base_plan} invoiceIntegration={this.props.invoice_integration || ""} />
            },
            {
                name: "Misc. Info", component: <Step4 getMasterState={this.getMasterState} updateMasterState={this.updateMasterState} notes={this.state.notes} active={this.state.active} home_warranty={this.state.home_warranty} pre_approved_budget={this.state.pre_approved_budget} />
            },
            {
                name: "Account Summary", component: <Step5 account={this.state} serviceTechs={this.props.service_techs} />
            },
            {
                name: "Save Account", component: <SaveAccount getMasterState={this.getMasterState} account={this.props.account} invoiceIntegration={this.props.invoice_integration} />
            }
        ]

        return(
            <WizardContext.Provider
                value={{
                    ...this.state,
                    company: this.props.company,
                    environment: this.props.environment,
                    pusherKey: this.props.pusher_key,
                    serviceTechs: this.props.service_techs,
                    companySettings: this.props.company_settings,
                    basePlan: this.props.base_plan
                }}
            >
                <div className="account-wizard-wrapper">
                    <div className="row page-header">
                        <div className="col-md-12">
                            <h1>
                                { this.props.account.id ? "Edit Account" : "New Account" }
                            </h1>
                        </div>
                    </div>
                    <div className="row margin-20-bottom">
                        <div className="col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2">
                            <div className="new-account-Wizard">
                                <div className="step-progress">
                                    <StepZilla
                                        steps={steps}
                                        nextTextOnFinalActionStep="Save Account"
                                        onStepChange={() => this.scrollToTop()}
                                        stepsNavigation={true}
                                        ref={(sz) => this.stepZillaComp = sz}
                                        stepIcons={["fa-id-card", "fa-truck", "fa-credit-card", "fa-info-circle", "fa-list-alt", "fa-save"]}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </WizardContext.Provider>
        )
    }
}

export const WizardConsumer = WizardContext.Consumer;
