import React from 'react';
import PropTypes from 'prop-types';

export default class AqsDateTimePicker extends React.Component {
    static propTypes = {
        inputName: PropTypes.string.isRequired,
        callback: PropTypes.func.isRequired,
        value: PropTypes.string,
        options: PropTypes.object
    }

    componentDidMount() {
        $(this.inputEl).datetimepicker({
            ignoreReadonly: true,
            allowInputToggle: true,
            ...this.props.options
        });

        $(this.inputEl).on("dp.change", (e) => {
            this.props.callback(e);
        });
    }

    showDTPicker = (e) => {
        $($(e.target).parent().find("input")).data("DateTimePicker").show();
    };

    render() {
        return(
            <div className="form-group no-margin">
                <div className="datetime-picker-wrapper">
                    <input
                        ref={(e) => this.inputEl = e}
                        name={this.props.inputName}
                        type="text"
                        className="form-control cursor-pointer"
                        readOnly
                        value={this.props.value || ""}
                    />
                    <span className="glyphicon glyphicon-chevron-down" onClick={this.showDTPicker}></span>
                </div>
            </div>
        )
    }
}
