import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Utils from '../../../Utils';

export default class RepairExpenseItem extends React.Component {
    static propTypes = {
        expense: PropTypes.object.isRequired,
        index: PropTypes.number.isRequired,
        updateItem: PropTypes.func.isRequired,
        saveItem: PropTypes.func.isRequired,
        destroyItem: PropTypes.func.isRequired
    }

    constructor(props) {
        super(props);

        this.baseState = {
            editing: !props.expense.id,
            validations: {
                description: false,
                cost: false
            }
        }

        this.state = this.baseState;
    }

    isValid = () => {
        const validations = {};
        ["description", "cost"].forEach(prop => validations[prop] = (this.props.expense[prop] || "").length < 1);

        this.setState({ validations });

        return !Object.values(validations).includes(true)
    };

    update = (e) => {
        e.stopPropagation(); // prevent event from bubbling and updating repair

        const { name, value } = e.target;
        const expense = { ...this.props.expense };
        expense[name] = value;

        this.props.updateItem(this.props.index, expense);
    };

    totalCost = () => {
        if (this.props.expense.cost) {
            return Utils.floatToCurrency(this.props.expense.cost * this.props.expense.quantity)
        } else {
            return "--";
        }
    };

    displayTotal = () => {
        return(
            <div className="col-md-4">
                <div className="pull-right">
                    <h5>
                        Total:
                    </h5>
                    { this.totalCost() }
                </div>
            </div>
        )
    };

    toolbar = () => {
        const deleteButton = (
            <button className="btn btn-danger margin-5-left" onClick={this.deleteItem}>
                <span className="glyphicon glyphicon-trash"></span>
            </button>
        )
        return(
            <div className="display-flex justify-content-end">
                { !this.state.editing &&
                    <div className="display-flex">
                        <button className="btn btn-default" onClick={() => this.setState({ editing: true })}>
                            <span className="glyphicon glyphicon-pencil"></span>
                        </button>
                        { deleteButton }
                    </div>
                }
                { this.state.editing &&
                    <div className="display-flex">
                        <button className="btn btn-success" onClick={this.saveItem}>
                            <i className="fa fa-save"></i>
                        </button>
                        { deleteButton }
                    </div>
                }
            </div>
        )
    };

    saveItem = () => {
        if (this.isValid()) {
            this.props.saveItem(this.props.index);
            this.setState({ editing: false });
        }
    };

    deleteItem = () => {
        if (confirm("Are you sure you would like to Delete this Repair Expense?")) {
            this.props.destroyItem(this.props.index);
        }
    };

    render() {
        return(
            <div className="panel panel-default">
                <div className="panel-body">
                    { !this.state.editing &&
                        <Fragment>
                            <div className="display-flex margin-10-bottom">
                                <div className="flex-1 display-flex flex-column justify-content-end">
                                    <h5 className="no-margin-bottom">
                                        Description:
                                    </h5>
                                </div>
                                <div className="flex-1">
                                    { this.toolbar() }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    { this.props.expense.description }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <h5>Quantity:</h5>
                                    { this.props.expense.quantity }
                                </div>
                                <div className="col-md-5">
                                    <h5>
                                        Cost:
                                    </h5>
                                    { Utils.floatToCurrency(parseFloat(this.props.expense.cost)) }
                                </div>
                                { this.displayTotal() }
                            </div>
                        </Fragment>
                    }
                    { this.state.editing &&
                        <div className="display-flex flex-column" onChange={(e) => this.update(e)}>
                            <div className="display-flex margin-10-bottom">
                                <div className="flex-1 display-flex flex-column justify-content-end">
                                    <label className="no-margin-bottom">Description:</label>
                                </div>
                                <div className="flex-1">
                                    { this.toolbar() }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <textarea
                                            name="description"
                                            type="text"
                                            defaultValue={this.props.expense.description || ""}
                                            className="form-control"
                                        >
                                        </textarea>
                                        { this.state.validations.description &&
                                            <div className="validation-wrapper">
                                                <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                                                Description is required
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Quantity:</label>
                                        <input
                                            name="quantity"
                                            type="number"
                                            min="1"
                                            defaultValue={this.props.expense.quantity}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="form-group">
                                        <label>Cost:</label>
                                        <div className="input-group">
                                            <div className="input-group-addon">
                                                <i className="glyphicon glyphicon-usd"></i>
                                            </div>
                                            <input
                                                name="cost"
                                                type="number"
                                                min=".01"
                                                step=".01"
                                                defaultValue={this.props.expense.cost || ""}
                                                className="form-control"
                                            />
                                        </div>
                                        { this.state.validations.cost &&
                                            <div className="validation-wrapper">
                                                <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                                                Cost is required
                                            </div>
                                        }
                                    </div>
                                </div>
                                { this.displayTotal() }
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}
