import React from 'react';
import PropTypes from 'prop-types';

import Payment from './Payment';

import { toast } from 'react-toastify';
import { InvoiceConsumer } from '../Index';

export default class InvoicePayments extends React.Component {
    sortedPayments = (payments) => (
        payments.filter(p => p.id).sort((a,b) => (
            moment(a.date_received || a.created_at) - moment(b.date_received || b.created_at))
        ).reverse()
    )

    render() {
        return(
            <InvoiceConsumer>
                {(context) => (
                    <div>
                        <h3 className="no-margin-top">
                            Invoice Payments:
                        </h3>

                        <hr/>
                        { context.invoice.invoice_payments.length > 0 &&
                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <td>
                                                <h5>
                                                    Date:
                                                </h5>
                                            </td>
                                            <td>
                                                <h5>
                                                    Method:
                                                </h5>
                                            </td>
                                            <td>
                                                <h5>
                                                    Payment Amount:
                                                </h5>
                                            </td>
                                            <td>
                                                <h5>
                                                    Details:
                                                </h5>
                                            </td>
                                            <td>
                                                <h5>
                                                    Actions:
                                                </h5>
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.sortedPayments(context.invoice.invoice_payments).map((payment, index) => (
                                                <Payment
                                                    key={payment.id}
                                                    invoice={context.invoice}
                                                    payment={payment}
                                                    index={index}
                                                    updatePayment={context.updatePayment}
                                                    saveInvoice={context.saveInvoice}
                                                    destroyPayment={context.destroyPayment}
                                                    updateInvoice={context.updateInvoice}
                                                    timezone={context.currentCompany.timezone}
                                                />
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        }
                        { context.invoice.invoice_payments.length < 1 &&
                            <h4 className="text-center color-gray">
                                No Payments found for this Invoice
                            </h4>
                        }
                    </div>
                )}
            </InvoiceConsumer>
        )
    }
}
