import React from 'react';
import PropTypes from 'prop-types';

export default class CompanySettingsAddress extends React.Component {
    static propTypes = {
        location: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);

        this.baseState = {
            location: { ...props.location },
            fetchingLocation: null,
        }

        this.state = this.baseState
    }

    componentDidMount() {
        const form = $("#company-settings form");
        form.on("submit", (e) => {
            if (this.state.fetchingLocation != "found" && JSON.stringify(this.baseState.location) != JSON.stringify(this.state.location)) {
                e.preventDefault();

                this.setState({ fetchingLocation: "querying" })
                this.fetchLocation().then(([gResults, status]) => {
                    if (status == "OK") {
                        const location = gResults[0].geometry.location;
                        const lat = location.lat().toString()
                        const lng = location.lng().toString();

                        this.setState({ location: { ...this.state.location, lat, lng }, fetchingLocation: "found" });
                        setTimeout(() => {
                            e.target.submit()
                        });
                    } else {
                        this.setState({ fetchingLocation: "failed" });
                        form.find("input[type='submit']").removeAttr("disabled");
                    }
                })
            }
        });
    }

    fetchLocation = () => {
        const promise = new Promise((resolve) => {
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode({ 'address': this.fullLocation() }, (results, status) => {
                resolve([results, status]);
            });
        });

        return promise;
    };

    fullLocation = () => {
        const location = this.state.location
        return `${location.address} ${location.city}, ${location.state} ${location.zip}`;
    };

    render() {
        return(
            <>
                <div className="row">
                    <div className="col-md-4 col-sm-6">
                        <div className="form-group">
                            <label name="street">
                                Street Address
                            </label>
                            <input 
                                type="text" 
                                name="company[location][address]"
                                className="form-control"
                                value={this.state.location.address}
                                onChange={(e) => this.setState({ location: { ...this.state.location, address: e.target.value } })}
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4 col-sm-6">
                        <div className="form-group">
                            <label name="city">
                                City
                            </label>
                            <input 
                                type="text" 
                                className="form-control"
                                name="company[location][city]"
                                value={this.state.location.city}
                                onChange={(e) => this.setState({ location: { ...this.state.location, city: e.target.value } })}
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-2 col-sm-3">
                        <div className="form-group">
                            <label name="state">
                                State
                            </label>
                            <input 
                                type="text" 
                                className="form-control"
                                name="company[location][state]"
                                value={this.state.location.state}
                                onChange={(e) => this.setState({ location: { ...this.state.location, state: e.target.value } })}
                            />
                        </div>
                    </div>
                    <div className="col-md-2 col-sm-3">
                        <div className="form-group">
                            <label name="zip">
                                Zip
                            </label>
                            <input 
                                type="text" 
                                className="form-control"
                                name="company[location][zip]"
                                value={this.state.location.zip}
                                onChange={(e) => this.setState({ location: { ...this.state.location, zip: e.target.value } })}
                            />
                        </div>
                    </div>
                </div>
                { this.state.fetchingLocation &&
                    <div className="row">
                        <div className="col-md-4 col-xs-6 text-center margin-10-top">
                            { this.state.fetchingLocation === "querying" &&
                                <div className="alert alert-success">
                                    Validating address, please wait...
                                </div>
                            }
                            { this.state.fetchingLocation === "found" &&
                                <div className="alert alert-success">
                                    Address validated!
                                </div>
                            }
                            { this.state.fetchingLocation === "failed" &&
                                <div className="alert alert-warning">
                                    Unable to validate address, please check the address and try again
                                </div>
                            }
                        </div>
                    </div>
                }
                { ["lat", "lng"].map((which, index) => {
                    return(
                        <input
                            key={index}
                            type="hidden"
                            name={`company[location][${which}]`}
                            value={this.state.location[which]}
                        />
                    )
                }) }
            </>
        )
    }
}