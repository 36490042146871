import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Roles from './Roles';

import { toast } from 'react-toastify';
import MaskedInput from 'react-text-mask';
import { UsersSettingsConsumer } from './Settings';
import Utils from '../../../Utils';

export default class UserRow extends React.Component {
    static propTypes = {
        user: PropTypes.object.isRequired,
        index: PropTypes.number.isRequired,
        updateUser: PropTypes.func.isRequired,
        removeUser: PropTypes.func.isRequired
    }

    constructor(props) {
        super(props);

        this.baseState = {
            editing: !props.user.id,
            loading: false,
            validations: {},
            sendInvite: null
        }

        this.state = this.baseState;
    }

    statusClass = (user) => {
        let baseClass = "label label-";

        if (user.active.toString() === "true") {
            baseClass += "success"
        } else {
            baseClass += "danger"
        }

        return baseClass;
    };

    statusLabel = (user) => {
        if (user.active.toString() === "true") {
            return "Active";
        } else {
            return "Inactive";
        }
    };

    isValid = () => {
        let validations = {};

        if ((this.props.user.phone || "").length > 0) {
            if (this.props.user.phone.match(/\d/g).length < 10) {
                validations.phone = true

                this.setState({ validations });
                return false;
            }
        }

        this.setState({ validations });
        return true;
    }

    saveUser = () => {
        if (this.isValid()) {
            const user = { ...this.props.user };
            user.active = user.active.toString() === "true";
            user.roles = user.roles.filter(Boolean);

            this.setState({ loading: true });

            let url = "/users";
            let method = this.props.user.id ? "PUT" : "POST";

            url += this.props.user.id ? `/${this.props.user.id}.json` : ".json";
            if (!this.props.user.id) this.setState({ sendInvite: true });

            if (user.phone && (user.phone || "").match(/\d/g).length === 10) {
                user.phone = user.phone.match(/\d/g).join("");
            }

            $.ajax({
                url,
                method,
                dataType: "json",
                contentType: "application/json",
                data: JSON.stringify({ user })
            }).done(res => {
                if (Object.keys(res.errors || {}).length > 0) {
                    let message = "";
                    Object.keys(res.errors).forEach(key => {
                        message += `${key} ${res.errors[key]} `
                    });

                    toast.error(message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                } else {
                    if (this.state.sendInvite) {
                        toast.success("Invitation sent to Technician", {
                            position: toast.POSITION.TOP_CENTER
                        });
                    } else {
                        toast.success("Successfully updated Technician", {
                            position: toast.POSITION.TOP_CENTER
                        });
                    }
                }
            }).fail(res => {
                toast.error("Something went wrong...", {
                    position: toast.POSITION.TOP_CENTER
                });
            }).always((res) => {
                this.setState({ loading: false, sendInvite: false });

                if (!Object.keys(res.errors || {}).length > 0) {
                    this.cancelEdit();
                }

                if (res.user && res.user.id) {
                    this.props.updateUser(this.props.index, res.user);
                }
            })
        }
    };

    sendInvite = () => {
        this.setState({ sendInvite: true }, () => {
            this.saveUser();
        });
    };

    cancelEdit = () => {
        if (this.props.user.id) {
            this.setState({ editing: false });
        } else {
            this.props.removeUser(this.props.index)
        }
    };

    render() {
        return(
            <UsersSettingsConsumer>
                {(context) => (
                    <tr className={`user-row ${this.state.editing ? "editing" : ""}`}>
                        <td className="users-name">
                            { this.state.editing &&
                                <div className="form-group no-margin">
                                    <input
                                        type="text"
                                        className="form-control"
                                        defaultValue={this.props.user.name}
                                        onChange={(e) => context.updateUser(this.props.index, { ...this.props.user, name: e.target.value })}
                                    />
                                </div>
                            }
                            { !this.state.editing &&
                                this.props.user.name
                            }
                        </td>
                        <td className="users-email">
                            { this.state.editing &&
                                <div className="form-group no-margin">
                                    <input
                                        type="text"
                                        className="form-control"
                                        defaultValue={this.props.user.email}
                                        onChange={(e) => context.updateUser(this.props.index, { ...this.props.user, email: e.target.value })}
                                    />
                                </div>
                            }
                            { !this.state.editing &&
                                this.props.user.email
                            }
                        </td>
                        <td className="users-phone">
                            { this.state.editing &&
                                <div className="form-group no-margin">
                                    <MaskedInput
                                        className="form-control"
                                        mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                        placeholder="(555) 555-5555"
                                        defaultValue={this.props.user.phone}
                                        onChange={(e) => context.updateUser(this.props.index, { ...this.props.user, phone: e.target.value })}
                                    />
                                    { this.state.validations.phone &&
                                        <div className="validation-wrapper">
                                            <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                                            Invalid Phone Number
                                        </div>
                                    }
                                </div>
                            }
                            { (!this.state.editing && this.props.user.phone) &&
                                Utils.formattedPhone(this.props.user.phone)
                            }
                        </td>
                        <td className="users-roles">
                            {
                                <Roles
                                    user={this.props.user}
                                    editing={this.state.editing}
                                    updateUser={context.updateUser}
                                    index={this.props.index}
                                />
                            }
                        </td>
                        <td className="users-status">
                            { (!this.state.editing || this.state.editing && this.props.user.company_contact)  &&
                                <span className={this.statusClass(this.props.user)}>
                                    { this.statusLabel(this.props.user) }
                                </span>
                            }
                            { (this.state.editing && !this.props.user.company_contact) &&
                                <div className="form-group no-margin">
                                    <select
                                        className="form-control" defaultValue={this.props.user.active.toString()}
                                        onChange={(e) => this.props.updateUser(this.props.index, { ...this.props.user, active: e.target.value })}
                                    >
                                        <option value="">Select status...</option>
                                        <option value="true">
                                            Active
                                        </option>
                                        <option value="false">
                                            Inactive
                                        </option>
                                    </select>
                                </div>
                            }
                        </td>
                        <td className="users-actions">
                            <div className={`float-left ${this.state.loading ? "margin-5-top" : ""}`}>
                                { !this.state.editing &&
                                    <Fragment>
                                        <button className="btn btn-default" onClick={(e) => this.setState({ editing: true })}>
                                            Edit
                                        </button>
                                        { this.props.user["needs_invitation?"] && this.props.user.active &&
                                            <button className="btn btn-warning margin-5-left" onClick={this.sendInvite}>
                                                Send Invite
                                            </button>
                                        }
                                    </Fragment>
                                }
                                { this.state.editing && !this.state.loading &&
                                    <Fragment>
                                        <button className="btn btn-success" onClick={this.saveUser}>
                                            Save
                                        </button>
                                        <button className="btn btn-default margin-5-left" onClick={this.cancelEdit}>
                                            Cancel
                                        </button>
                                    </Fragment>
                                }
                            </div>
                            { this.state.loading &&
                                <div className="float-left">
                                    <i className="fa fa-spinner fa-pulse fa-3x fa-fw align-self-center flex-1"></i>
                                </div>
                            }
                        </td>
                    </tr>
                )}
            </UsersSettingsConsumer>
        )
    }
}
