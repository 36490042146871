import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import AqsModal from 'AqsModal';
import ImageArea from '@shared/components/ImageArea/ImageArea';
import CustomerFeedbackForm from '@shared/components/CustomerFeedbackForm';

import { toast } from 'react-toastify';
import CheckboxSwitch from '@shared/components/CheckboxSwitch';

export default class RepairSettingsModal extends React.Component {
    static propTypes = {
        repair: PropTypes.object.isRequired,
        company: PropTypes.object.isRequired,
        updateRepairSettings: PropTypes.func.isRequired,
        basePlan: PropTypes.string.isRequired
    };

    constructor(props) {
        super(props);

        const hasRepairCf = Object.keys(props.repair.settings).includes("customer_feedback_enabled");
        const cf = hasRepairCf ? props.repair.settings : props.company.settings.customer_feedback;

        this.baseState = {
            settings: {
                estimate_logo: props.repair.settings.estimate_logo,
                company_website: props.repair.settings.company_website,
                company_phone: props.repair.settings.company_phone,
                reply_to_email: props.repair.settings.reply_to_email,
                company_name: props.repair.settings.company_name,
                company_address: props.repair.settings.company_address,
                e_sign_required: props.repair.settings.e_sign_required,
                e_sign_doc_url: props.repair.e_sign_doc_url,
                customer_feedback_enabled: cf.customer_feedback_enabled,
                customer_feedback_email: cf.customer_feedback_email,
                yelp_profile: cf.yelp_profile,
                google_profile: cf.google_profile,
                facebook_profile: cf.google_profile,
                nextdoor_profile: cf.nextdoor_profile
            },
            saving: false,
            validations: {
                cfProfiles: false,
                cfEmail: false
            }
        }

        this.state = this.baseState;
    }

    showModal = () => {
        this.modalEl.show();
    };

    updateImage = (images) => {
        let estimate_logo;
        if (images.length > 0) {
            const url = images[0].full_url;
            estimate_logo = url;
        } else {
            estimate_logo = this.props.company.image.public_url;
        }

        this.setState({ settings: { ...this.state.settings, estimate_logo } });
    };

    isDefaultImage = () => {
        if (!this.props.company.image) return true;
        return this.state.settings.estimate_logo === this.props.company.image.public_url;
    };

    images = () => {
        return this.isDefaultImage() ? [] : [{ full_url: this.state.settings.estimate_logo }]
    };

    updateSettings = (e) => {
        this.setState({ settings: { ...this.state.settings, [e.target.name]: e.target.value } });
    };

    isValid = () => {
        if (!this.state.settings.customer_feedback_enabled) return true;

        const profileKeys = Object.keys(this.state.settings).filter(k => k.match(/profile/));
        const profiles = profileKeys.map(k => this.state.settings[k]);

        const validations = {
            cfProfiles: !profiles.some(p => p.length > 0),
            cfEmail: this.state.settings.customer_feedback_email.length < 1
        }

        this.setState({ validations });
        return !Object.values(validations).includes(true)
    };

    saveSettings = () => {
        if (this.isValid()) {
            this.setState({ saving: true });

            $.ajax({
                method: "PUT",
                contentType: "application/json",
                url: `/accounts/${this.props.repair.account_id}/repairs/${this.props.repair.id}.json`,
                data: JSON.stringify({
                    repair: {
                        settings: { ...this.state.settings }
                    }
                })
            }).done(() => {
                this.props.updateRepairSettings({ ...this.state.settings });

                toast.success("Successfully saved Repair Settings!", {
                    position: toast.POSITION.TOP_CENTER
                });
            }).fail(() => {
                toast.error("Unable to save Repair Settings...", {
                    position: toast.POSITION.TOP_CENTER
                });
            }).always(() => {
                this.setState({ saving: false });
            });
        }
    };

    render() {
        return(
            <AqsModal
                id="repair-settings-modal"
                headerText="Repair Settings"
                ref={(e) => this.modalEl = e}
                large={true}
                footerButtonText={this.state.saving ? "Saving..." : "Save"}
                footerButtonAction={this.saveSettings}
                footerButtonDisabled={this.state.saving}
            >
                <h4 className="text-center">
                    Settings for sending Estimates/Approval Requests
                </h4>

                <hr/>

                <h5>
                    Logo:
                </h5>
                { this.isDefaultImage() &&
                    <h5 className="color-gray text-center">
                        (Default Company Logo Currently Set)
                    </h5>
                }
                <ImageArea
                    images={this.images()}
                    resource_name="repair"
                    multiple_allowed={false}
                    updateImagesCallback={this.updateImage}
                    disableCaption={true}
                    makePublic={true}
                />
                <hr/>

                <h5>
                    Company Website:
                </h5>
                <div className="form-group">
                    <input
                        type="text"
                        className="form-control"
                        value={this.state.settings.company_website || ""}
                        name="company_website"
                        placeholder={this.props.company.website}
                        onChange={this.updateSettings}
                    />
                </div>

                <hr/>

                <h5>
                    Company Name:
                </h5>
                <div className="form-group">
                    <input
                        type="text"
                        className="form-control"
                        value={this.state.settings.company_name || ""}
                        name="company_name"
                        placeholder={this.props.company.name}
                        onChange={this.updateSettings}
                    />
                </div>

                <hr/>

                <h5>
                    Company Address:
                </h5>
                <div className="form-group">
                    <input
                        type="text"
                        className="form-control"
                        value={this.state.settings.company_address || ""}
                        name="company_address"
                        placeholder={this.props.company.full_location}
                        onChange={this.updateSettings}
                    />
                </div>

                <hr/>

                <h5>
                    Company Phone:
                </h5>
                <div className="form-group">
                    <input
                        type="text"
                        className="form-control"
                        value={this.state.settings.company_phone || ""}
                        name="company_phone"
                        placeholder={this.props.company.phone}
                        onChange={this.updateSettings}
                    />
                </div>

                <hr/>

                <h5>
                    Reply-To Email:
                </h5>
                <div className="form-group">
                    <input
                        type="text"
                        className="form-control"
                        value={this.state.settings.reply_to_email || ""}
                        name="reply_to_email"
                        placeholder={this.props.company.company_contact.email}
                        onChange={this.updateSettings}
                    />
                </div>

                <hr/>

                { !this.state.settings.e_sign_doc_url &&
                    <Fragment>
                        <div className="display-flex align-items-center">
                            <CheckboxSwitch
                                name="e-sign-required"
                                checked={this.state.settings.e_sign_required}
                                onChange={() => this.setState({ settings: { ...this.state.settings, e_sign_required: !this.state.settings.e_sign_required } })}
                            />
                            <label htmlFor="e-sign-required" className="no-margin-bottom">
                                E-Sign Required
                            </label>
                        </div>
                    </Fragment>
                }
                { this.state.settings.e_sign_doc_url &&
                    <Fragment>
                        <h5>
                            E-Sign Document:
                        </h5>
                        <a href={this.state.settings.e_sign_doc_url} target="_blank">
                            Download Copy
                        </a>
                    </Fragment>
                }
                { this.props.basePlan === "pro" &&
                    <Fragment>
                        <hr/>
                        <CustomerFeedbackForm
                            settings={this.state.settings}
                            toggleFeedback={() => this.setState({ settings: { ...this.state.settings, customer_feedback_enabled: !this.state.settings.customer_feedback_enabled } })}
                            updateSettings={this.updateSettings}
                            validations={{ profiles: this.state.validations.cfProfiles, email: this.state.validations.cfEmail }}
                        />
                    </Fragment>
                }
            </AqsModal>
        )
    }
}
