import React from 'react';
import PropTypes from 'prop-types';

import AqsChart from '@shared/components/Chart';

import Utils from '../../../Utils';

export default class UserAppointments extends React.Component {
    static propTypes = {
        user: PropTypes.object.isRequired,
        appointments: PropTypes.array.isRequired,
        timezone: PropTypes.string.isRequired,
        selectAppointment: PropTypes.func.isRequired,
        selectedAppointment: PropTypes.object
    }

    sortedAppointments = () => {
        return this.props.appointments.sort((left, right) => {
            return moment(left.end).diff(moment(right.end));
        });
    };

    completedAppointmentCount = () => {
        return this.props.appointments.filter(appt => appt.completed).length;
    };

    appointmentIconClass = (type) => {
        switch(type) {
            case 'service_stop':
                return "fa-truck";
                break;
            case 'repair':
                return "fa-wrench";
                break;
            case 'repair_item':
                return "fa-wrench";
                break;
            case 'other':
                return "fa-calendar";
                break;
        }
    };

    selectAppointment = (appointment) => {
        if (appointment.completed || appointment.completed_at) {
            this.props.selectAppointment(appointment);
        }
    };

    cursorClass = (appointment) => {
        let condition;

        switch(appointment.type) {
        case 'service_stop':
            condition = (appointment.completed && appointment.service_stop_id);
            break;
        case 'repair':
            condition = appointment.completed;
            break;
        case 'repair_item':
            condition = appointment.completed
        }

        return condition ? "cursor-pointer" : "";
    };

    render() {
        return(
            <div className="user-appointment-wrapper panel panel-default">
                <div className="panel-body">
                    <div className="display-flex flex-column flex-1">
                        <h4 className="text-center">
                            { this.props.user.name }
                        </h4>
                        <div className="chart-wrapper">
                            <AqsChart
                                chartType="doughnut"
                                canvasWidth={100}
                                canvasHeight={100}
                                displayLegend={false}
                                chartData={[this.completedAppointmentCount(), (this.props.appointments.length - this.completedAppointmentCount())]}
                                dataLabels={["complete", "incomplete"]}
                                backgroundColors={["rgba(0, 255, 113, 0.2)", "rgba(255, 99, 132, 0.2)"]}
                                borderColors={["rgba(0, 255, 113, 1)", "rgba(255,99,132,1)"]}
                            />
                            <div className="chart-stats">
                                <div className="top-label">
                                    Appointments
                                </div>
                                <h4>
                                    { this.completedAppointmentCount() }
                                    /
                                    { this.props.appointments.length }
                                </h4>
                                <div className="bottom-label">
                                    Completed
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="display-flex flex-column flex-1 overflow-y">
                        {
                            this.sortedAppointments().map((appointment, index) => {
                                const cursorClass = this.cursorClass(appointment);
                                const bgClass = this.props.selectedAppointment.id === appointment.id && "bg-gray";
                                return(
                                    <div className="account-wrapper panel panel-default" key={index}>
                                        <div className={`panel-body display-flex ${cursorClass} ${bgClass}`} onClick={() => this.selectAppointment(appointment)}>
                                            <div className="flex-1 display-flex">
                                                <div className="display-flex align-items-center">
                                                    <i className={`fa ${this.appointmentIconClass(appointment.type)} margin-10-right`}></i>
                                                </div>
                                                <div>
                                                    { appointment.type !== "other" &&
                                                        <div>
                                                            { appointment.account.contact_name }
                                                        </div>
                                                    }
                                                    { appointment.type === "other" &&
                                                        <div>
                                                            { Utils.truncate((appointment.location && appointment.location.address) || appointment.notes, 100) }
                                                        </div>
                                                    }
                                                    <div className="color-gray">
                                                        { moment.tz(appointment.end, this.props.timezone).format("MM/DD/YYYY hh:mm A") }
                                                    </div>
                                                </div>
                                            </div>
                                            { (appointment.completed || appointment.completed_at) &&
                                                <div className="display-flex align-items-center">
                                                    <span className="glyphicon glyphicon-ok"></span>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }
}
