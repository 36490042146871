import React from 'react';
import PropTypes from 'prop-types';

import SubUtils from './SubUtils';

export default class ChangeCard extends React.Component {
    static propTypes = {
        stripeKey: PropTypes.string.isRequired,
        callback: PropTypes.func.isRequired
    }

    constructor(props) {
        super(props);

        this.baseState = {
            cardValid: false,
            loading: false,
            cardToken: null,
            cardError: null
        }

        this.state = this.baseState;
    }

    componentDidMount() {
        $(this.modalEl).on("show.bs.modal", (e) => {
            $(e.target).addClass("center-screen-modal");
        });

        $(this.modalEl).on("hide.bs.modal", (e) => {
            $(e.target).removeClass("center-screen-modal");
        });

        const stripe = Stripe(this.props.stripeKey)
        const elements = stripe.elements();
        const card = elements.create("card");

        this.stripe = stripe;
        this.stripeCard = card;

        card.mount("#card-element");
        card.on("change", (e) => {
            if (e.complete) {
                this.setState({ cardValid: true });
            } else {
                this.setState({ cardValid: false });
            }
        });
    }

    showModal() {
        $(this.modalEl).modal("show");
    }

    hideModal() {
        $(this.modalEl).modal("hide");
    }

    saveCard = () => {
        this.setState({ cardError: null });

        $.ajax({
            method: "PUT",
            url: "/subscriptions/update_payment_source",
            data: {
                card_token: this.state.cardToken
            }
        }).done(() => {
            this.setState({ loading: false });
            this.props.callback();
            this.hideModal();
        }).fail(res => {
            this.setState({ cardError: res.responseJSON.error, loading: false });
        });
    };

    validateCard = () => {
        this.setState({ loading: true });

        this.stripe.createToken(this.stripeCard).then(result => {
            if (result.error) {
                this.setState({ cardError: result.error.message, loading: false });
            } else {
                this.setState({ cardToken: result.token }, () => {
                    this.saveCard();
                });
            }
        });
    };

    render() {
        return(
            <div
                id="change-subscription-card-modal"
                className="modal fade"
                ref={(e) => this.modalEl = e}
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button
                                className="close"
                                type="button"
                                data-dismiss="modal"
                            >
                                &times;
                            </button>
                            <h5 className="modal-title">
                                Change Credit/Debit Card
                            </h5>
                        </div>
                        <div className="modal-body">
                            <div className="display-flex justify-content align-items-center">
                                <div className="col-xs-12">
                                    { this.state.cardError &&
                                        <div className="alert alert-warning">
                                            { this.state.cardError }
                                        </div>
                                    }
                                    { this.state.loading &&
                                        <div className="display-flex justify-content-center">
                                            Validating card...
                                        </div>
                                    }
                                    <div className={`form-group ${this.state.loading ? "display-none" : "display-block"}`}>
                                        <label className="width-100 text-center">
                                            New Credit/Debit Card Number:
                                        </label>
                                        <div id="card-element"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            { !this.state.loading &&
                                <button
                                    className="btn btn-success"
                                    onClick={this.validateCard}
                                    disabled={!this.state.cardValid}
                                >
                                    Save Card
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
