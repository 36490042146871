import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import ImageArea from '@shared/components/ImageArea/ImageArea';

const RepairTicket = (props) => {
    return(
        <Fragment>
            <div className="row">
                <div className="col-md-12">
                    <h1>
                        Repair Details
                    </h1>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <h4>
                        <i className="fa fa-home margin-5-right"></i>
                        Address:
                        <span className="margin-5-left">
                            { props.appointment.repair.account.location.address }
                        </span>
                    </h4>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <h5>
                        <i className="fa fa-clock-o margin-5-right color-gray"></i>
                        Completed At:
                        <span className="margin-5-left">
                            { moment(props.appointment.completed_at).tz(props.timezone).format("MM/DD/YYYY hh:mm A") }
                        </span>
                    </h5>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <h5>
                        <a href={`/accounts/${props.appointment.repair.account_id}/repairs/${props.appointment.repair.id}`}>
                            Go To Repair
                        </a>
                    </h5>
                </div>
            </div>

            <hr/>

            <div className="row">
                <div className="col-md-12">
                    <h4>
                        Images:
                    </h4>
                    <ImageArea
                        images={props.appointment.repair.images}
                        resource_name="repair"
                        resource_id={props.appointment.repair.id}
                        multiple_allowed={true}
                        preventRetry={true}
                    />
                </div>
            </div>

            <hr/>

            <div className="row">
                <div className="col-md-12">
                    <h5>
                        Description:
                    </h5>
                    <div className="panel panel-default">
                        <div className="panel-body">
                            { props.appointment.repair.description }
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <h5>
                        Private Notes:
                    </h5>
                    <div className="well">
                        { props.appointment.repair.private_notes }
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <h5>
                        Public Notes:
                    </h5>
                    <div className="well">
                        { props.appointment.repair.client_notes }
                    </div>
                </div>
            </div>
        </Fragment>
    )
};

RepairTicket.propTypes = {
    appointment: PropTypes.object.isRequired,
    timezone: PropTypes.string.isRequired
};

export default RepairTicket;
