import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

export default class DefaultTaxRates extends React.Component {
    static propTypes = {
        settings: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);

        const enabled = Object.values(this.props.settings.tax_amounts).reduce((a, b) => a + b) > 0.0;

        const { default_service_tax_rate, default_repair_tax_rate, default_service_item_tax_rate } = this.props.settings.tax_amounts

        this.baseState = {
            enabled,
            loading: false,
            settings: {
                default_service_tax_rate,
                default_repair_tax_rate,
                default_service_item_tax_rate
            },
            validations: {
                default_service_tax_rate: false,
                default_service_item_tax_rate: false,
                default_repair_tax_rate: false
            }
        }

        this.state = this.baseState;
        this.taxProperties = ["default_service_tax_rate", "default_service_item_tax_rate", "default_repair_tax_rate"];
    }

    toggleEnable = () => {
        if (this.state.enabled) {
            const settings = {};
            this.taxProperties.forEach(prop => settings[prop] = 0.0)

            this.setState({ settings });

            this.saveSettings();
        }

        this.setState({ enabled: !this.state.enabled });
    };

    updateSetting = (prop, val) => {
        this.setState({ settings: { ...this.state.settings, [prop]: (parseFloat(val) * 0.01) } });
    };

    isValid = () => {
        const validations = {};
        this.taxProperties.forEach(prop => {
            const val = parseFloat(this.state.settings[prop]);
            validations[prop] = isNaN(val);
        });

        this.setState({ validations });

        return !Object.values(validations).includes(true);
    };

    saveSettings = () => {
        if (this.isValid()) {
            this.setState({ loading: true });
            const tax_amounts = { ...this.state.settings };

            $.ajax({
                method: "PUT",
                url: "/settings.json",
                data: {
                    settings: { tax_amounts }
                }
            }).done(() => {
                this.setState({ loading: false });
                toast.success("Successfully updated Settings!", {
                    position: toast.POSITION.TOP_CENTER
                });
            });
        }
    };

    render() {
        return(
            <Fragment>
                <div className="form-group no-margin display-flex justify-content-center">
                    <label htmlFor="enable">Enable Default Sales Tax Rates:</label>
                    <input
                        id="enable"
                        className="margin-5-left"
                        type="checkbox"
                        onChange={this.toggleEnable}
                        checked={this.state.enabled}
                    />
                </div>
                { this.state.enabled &&
                    <Fragment>
                        <div className="width-100">
                            <hr/>
                        </div>

                        <div className="row display-flex flex-column align-items-center">
                            <div className="col-md-4 col-sm-4 col-xs-6">
                                <div className="form-group display-flex flex-column">
                                    <label>Default Tax Rate for Weekly/Monthly Service:</label>
                                    <div className="input-group">
                                        <input
                                            className="form-control"
                                            type="text"
                                            defaultValue={(parseFloat(this.state.settings.default_service_tax_rate) * 100).toFixed(3)}
                                            onChange={(e) => this.updateSetting("default_service_tax_rate", e.target.value) }
                                        />
                                        <div className="input-group-addon">
                                            %
                                        </div>
                                    </div>
                                    { this.state.validations.default_service_tax_rate &&
                                        <div className="validation-wrapper">
                                            <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                                            Invalid rate
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="row display-flex flex-column align-items-center">
                            <div className="col-md-4 col-sm-4 col-xs-6">
                                <div className="form-group display-flex flex-column">
                                    <label>Default Tax Rate for Service Items/Chemicals:</label>
                                    <div className="input-group">
                                        <input
                                            className="form-control"
                                            type="text"
                                            defaultValue={(parseFloat(this.state.settings.default_service_item_tax_rate) * 100).toFixed(3)}
                                            onChange={(e) => this.updateSetting("default_service_item_tax_rate", e.target.value) }
                                        />
                                        <div className="input-group-addon">
                                            %
                                        </div>
                                    </div>
                                    { this.state.validations.default_service_item_tax_rate &&
                                        <div className="validation-wrapper">
                                            <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                                            Invalid rate
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="row display-flex flex-column align-items-center">
                            <div className="col-md-4 col-sm-4 col-xs-6">
                                <div className="form-group display-flex flex-column">
                                    <label>Default Tax Rate for Repairs:</label>
                                    <div className="input-group">
                                        <input
                                            className="form-control"
                                            type="text"
                                            defaultValue={(parseFloat(this.state.settings.default_repair_tax_rate) * 100).toFixed(3)}
                                            onChange={(e) => this.updateSetting("default_repair_tax_rate", e.target.value) }
                                        />
                                        <div className="input-group-addon">
                                            %
                                        </div>
                                    </div>
                                    { this.state.validations.default_repair_tax_rate &&
                                        <div className="validation-wrapper">
                                            <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                                            Invalid rate
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="display-flex justify-content-center">
                            <button className="btn btn-success text-center" onClick={this.saveSettings}>
                                { !this.state.loading && "Save Default Rates" }
                                { this.state.loading &&
                                    <i className="fa fa-spinner"></i>
                                }
                            </button>
                        </div>
                    </Fragment>
                }
            </Fragment>
        )
    }
}
