import React from 'react';
import PropTypes from 'prop-types';

import Chart from '@shared/components/Chart';

const RouteChart = (props) => {
    const bgColors = () => {
        switch(props.appointmentType) {
            case 'current':
                return ["rgba(0, 255, 113, 0.2)", "rgba(186, 186, 186, 0.2)", "rgba(240, 173, 78, 0.2)"];
                break;
            case 'lapsed':
                return ["rgba(0, 255, 113, 0.2)", "rgba(255, 99, 132, 0.2)", "rgba(240, 173, 78, 0.2)"];
            case 'all-lapsed':
                return ["rgba(0, 255, 113, 0.2)", "rgba(255, 99, 132, 0.2)", "rgba(240, 173, 78, 0.2)"];
        }
    };

    const borderColors = () => {
        switch(props.appointmentType) {
            case 'current':
                return ["rgba(0, 255, 113, 1)", "rgba(186,186,186,1)", "rgba(240, 173, 78, 1)"];
                break;
            case 'lapsed':
                return ["rgba(0, 255, 113, 1)", "rgba(255,99,132,1)", "rgba(240, 173, 78, 1)"];
                break;
            case 'all-lapsed':
                return ["rgba(0, 255, 113, 1)", "rgba(255,99,132,1)", "rgba(240, 173, 78, 1)"];
                break;
        }
    };

    return(
        <div className="position-relative">
            <div className="route-chart">
                <Chart
                    chartType="doughnut"
                    canvasWidth={100}
                    canvasHeight={100}
                    displayLegend={false}
                    chartData={[props.completedCount, props.incompletedCount, props.syncableCount]}
                    dataLabels={["Completed", "Incomplete", "Needs to Sync"]}
                    backgroundColors={bgColors()}
                    borderColors={borderColors()}
                />
            </div>
            <div className="chart-stats">
                <div className="top-label">
                    Appointments
                </div>
                <h4 className="margin-10">
                    { props.completedCount }/{ props.appointmentCount }
                </h4>
                <div className="bottom-label">
                    Completed
                </div>
            </div>
        </div>
    )
};

RouteChart.propTypes = {
    completedCount: PropTypes.number.isRequired,
    incompletedCount: PropTypes.number.isRequired,
    syncableCount: PropTypes.number.isRequired,
    appointmentCount: PropTypes.number.isRequired,
    appointmentType: PropTypes.string.isRequired
}

export default RouteChart;
