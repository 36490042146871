import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Utils from '../../Utils';

export default class FallbackSettingsCheckbox extends React.Component {
    static propTypes = {
        primary_enabled: PropTypes.bool.isRequired,
        form_name: PropTypes.string.isRequired,
        bute: PropTypes.string.isRequired,
        raw_elements: PropTypes.bool
    }

    constructor(props) {
        super(props);

        this.baseState = {
            toggled: false
        }

        this.state = this.baseState;
    }

    fields = () => (
        <Fragment>
            <label
                htmlFor={this.props.bute}
                className={!this.props.raw_elements ? "width-100 text-center" : ""}
            >
                { Utils.humanize(this.props.bute) }:&nbsp;
            </label>
            { !this.props.raw_elements &&
                <br />
            }
            { this.state.toggled &&
                <input
                    type="hidden"
                    name={this.props.form_name}
                    defaultValue={this.props.primary_enabled ? "0" : "1"}
                />
            }
            <input
                type="checkbox"
                className={!this.props.raw_elements ? "display-block margin-auto-important" : ""}
                id={this.props.bute}
                defaultChecked={this.props.primary_enabled}
                onChange={() => this.setState({ toggled: !this.state.toggled })}
            />
        </Fragment>
    )

    render() {
        return(
            <Fragment>
                { !this.props.raw_elements &&
                    <div className="settings-input">
                        <div className="checkbox-settings-input float-left">
                            { this.fields() }
                        </div>
                    </div>
                }
                { this.props.raw_elements && this.fields() }
            </Fragment>
        )
    }
}
