import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';

import RecordPreview from './RecordPreview';
import Modal from '@shared/components/Modal';

const RecordsModal = (props) => {
    let recordsModal;

    useEffect(() => {
        if (props.showModal) recordsModal.show();
    }, [props.showModal]);

    const updateRouteReminder = (reminder, object) => {
        const newRouteReminderRecords = props.routeReminders.map(rr => {
            if (rr.id === reminder.id) {
                return { ...reminder, ...object };
            } else {
                return { ...rr }
            }
        });

        props.update(newRouteReminderRecords);
    };

    return(
        <Modal
            id="route-reminder-records-modal"
            headerText="Route Reminders"
            ref={(e) => recordsModal = e}
        >
            <Fragment>
                <div className="display-flex flex-column">
                    <div className="header-icon schedule">
                        <i className="fa fa-bell-o"></i>
                    </div>
                    <h4 className="text-center margin-20-bottom">
                        The following Route Reminders require your attention:
                    </h4>
                    { props.error &&
                        <div className="validation-wrapper margin-10-bottom">
                            <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                            You must acknowledge all route reminders
                        </div>
                    }
                </div>
                {
                    props.routeReminders.map((reminder, index) => {
                        return(
                            <RecordPreview
                                reminder={reminder}
                                key={index}
                                reminderIndex={index}
                                update={updateRouteReminder}
                            />
                        )
                    })
                }
            </Fragment>
        </Modal>
    )
}

RecordsModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    routeReminders: PropTypes.array.isRequired,
    error: PropTypes.bool.isRequired,
    update: PropTypes.func.isRequired
}

export default RecordsModal;
