import React from 'react';
import PropTypes from 'prop-types';

import AqsQuill from '../../RichMediaElements/AqsQuill';
import TextMessageEditor from '../../RichMediaElements/TextMessageEditor';

import Select from 'react-select';
import Utils from '../../../Utils';

export default class RepairAttachmentModal extends React.Component {
    static propTypes = {
        saveAttachment: PropTypes.func.isRequired,
        updateAttachment: PropTypes.func.isRequired,
        templates: PropTypes.array.isRequired,
        company: PropTypes.object.isRequired,
        repair: PropTypes.object.isRequired,
        attachment: PropTypes.object
    }

    constructor(props) {
        super(props);

        this.baseState = {
            showTemplates: false,
            selectedTemplate: {},
            validations: {
                content: false
            }
        }

        this.state = this.baseState;
    }

    componentDidMount() {
        $(this.modal).on("show.bs.modal", (e) => {
            $(e.target).addClass("center-screen-modal");
        });

        $(this.modal).on("hide.bs.modal", (e) => {
            $(e.target).removeClass("center-screen-modal");

            this.setState({ ...this.baseState });
        });
    }

    filteredTemplates = () => {
        return this.props.templates.filter(template => template.type === this.props.attachment.type);
    };

    setSelectedTemplate = (template) => {
        const selectedTemplate = this.props.templates.find(_template => _template.name === template.label );

        this.setState({ selectedTemplate });
    };

    loadTemplate = () => {
        const attachment = this.props.attachment;
        const subject = this.state.selectedTemplate.subject;
        const content = this.state.selectedTemplate.content;

        if (attachment.type === "web" || attachment.type === "email") {
            if (attachment.type === "email" && this.state.selectedTemplate.subject) {
                this.subjectElement.value = subject;
            }

            this[`${attachment.type}Quill`].insertContent(content);
        } else if (this.props.attachment.type === "text") {
            this.textMessageEditor.loadContent(content);
        }

        this.props.updateAttachment({ ...attachment, subject, content });
    };

    saveAttachment = () => {
        const length = this.props.attachment.type === "text" ? 1 : 2 // length for quill is 1 by default
        this.setState({ validations: { content: (this.props.attachment.content || "").length < length } });

        if (!this.state.validations.content) {
            this.props.saveAttachment(this.props.attachment);
        }
    };

    renderQuill = () => {
        return(
            <AqsQuill
                placeholder="Create your content here..."
                loadingContainerSelector="#editor-loading-container"
                content={this.props.attachment.content}
                specialInserts={{
                    "repair_status": "Status",
                    "repair_priority": "Priority",
                    "repair_client_notes": "Public Notes",
                    "repair_public_grand_total": "Grand Total",
                    "repair_discount_percentage": "Discount Percentage",
                    "repair_public_discount_amount": "Discount Amount",
                    "repair_terms": "Terms & Conditions",
                    "repair_public_url": "Repair Page URL",
                    "repair_approval_buttons": "Approve/Deny Buttons"
                }}
                updateCallback={(content) => this.props.updateAttachment({ ...this.props.attachment, content })}
                ref={(e) => this[`${this.props.attachment.type}Quill`] = e}
            />
        )
    };

    render() {
        const type = (this.props.attachment).type ? Utils.humanize(this.props.attachment.type) : "";
        return(
            <div
                id="repair-attachment-modal"
                className="modal fade"
                ref={(e) => this.modal = e}
            >
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                Edit { type } Attachment
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                            </h5>
                            <div id="editor-loading-container" className="display-flex justify-content-center"></div>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label htmlFor="use-template" className="no-margin-bottom">Use a Template:</label>
                                &nbsp;
                                <input
                                    type="checkbox"
                                    id="use-template"
                                    checked={this.state.showTemplates}
                                    onChange={(e) => this.setState({ showTemplates: !this.state.showTemplates })}
                                />
                            </div>
                            { this.state.showTemplates &&
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group no-margin-bottom">
                                            <Select
                                                onChange={this.setSelectedTemplate}
                                                options={this.filteredTemplates().map(template => ({ value: template.name, label: template.name }))}
                                            />
                                            <div className="margin-10-top">
                                                <button className="btn btn-success margin-5-right" onClick={this.loadTemplate}>
                                                    <i className="fa fa-clipboard margin-5-right"></i>
                                                    Load
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            <hr/>

                            { this.props.attachment.type === "email" &&
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Email Subject:</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                defaultValue={this.props.attachment.subject || ""}
                                                onChange={(e) => this.props.updateAttachment({ ...this.props.attachment, subject: e.target.value })}
                                                placeholder={`${this.props.company.name}  - Approval for Repair`}
                                                ref={(e) => this.subjectElement = e}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }

                            <div className="row">
                                <div className="col-md-12">
                                    { this.props.attachment.type === "email" &&
                                        this.renderQuill()
                                    }
                                    { this.props.attachment.type === "web" &&
                                        this.renderQuill()
                                    }
                                    { this.props.attachment.type === "text" &&
                                        <TextMessageEditor
                                            content={this.props.attachment.content}
                                            specialInserts={{
                                                "repair_status": "Status",
                                                "repair_priority": "Priority",
                                                "repair_client_notes": "Public Notes",
                                                "repair_public_grand_total": "Grand Total",
                                                "repair_discount_percentage": "Discount Percentage",
                                                "repair_public_discount_amount": "Discount Amount",
                                                "repair_terms": "Terms & Conditions",
                                                "repair_public_url": "Repair Page URL"
                                            }}
                                            updateCallback={(content) => this.props.updateAttachment({ ...this.props.attachment, content })}
                                            ref={(e) => this.textMessageEditor = e}
                                            placeholder={`This is ${this.props.repair.settings.company_name}. We need your approval for the following repair: %{repair_public_url}`}
                                        />
                                    }
                                </div>
                                <div className="col-md-12">
                                    { this.state.validations.content &&
                                        <div className="alert alert-danger no-margin-bottom margin-10-top">
                                            <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                                            Content is required
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default margin-5-right" data-dismiss="modal">
                                Close
                            </button>
                            <button
                                type="button"
                                className="btn btn-success"
                                onClick={this.saveAttachment}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
