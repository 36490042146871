import React from 'react';
import PropTypes from 'prop-types';
import CreateExternalCustomer from './CreateExternalCustomer';

import Utils from '../../../Utils';

export default class SaveAccount extends React.Component {
    static propTypes = {
        getMasterState: PropTypes.func.isRequired,
        account: PropTypes.object,
        invoiceIntegration: PropTypes.string
    }

    constructor(props) {
        super(props);

        this.baseState = {
            account: props.getMasterState(),
            loading: true,
            success: false,
            errors: false
        }

        if (this.props.account && this.props.account.id) {
            this.baseState.account.id = this.props.account.id;
        }

        this.state = this.baseState;
    }

    componentDidMount() {
        const method = this.state.account.id ? "PUT" : "POST";
        const url = this.state.account.id ? `/accounts/${this.state.account.id}.json` : "/accounts.json";

        const appts = this.props.getMasterState().appointments_attributes;
        if (appts && appts.length > 0) {
            const apptWithSuggestion = appts.find(appt => appt.suggestedScheduling);
            if (apptWithSuggestion) {
                const appts = apptWithSuggestion.suggestedScheduling;
                const appointments = {};
                appts.forEach(appt => {
                    appointments[appt.id] = {
                        start: appt.start,
                        end: appt.end
                    }
                });

                $.ajax({
                    method: "PUT",
                    url: "/appointments/batch_update.json",
                    data: { appointments }
                }).done(() => {}).fail(() => {
                    this.setState({ loading: false, success: false, errors: {} });

                    return false;
                })
            };
        }

        $.ajax({
            url,
            method,
            data: {
                account: this.props.getMasterState()
            }
        }).done(account => {
            this.setState({ loading: false, success: true, errors: null, account });
        }).fail(errors => {
            this.setState({ loading: false, success: false, errors });
        });
    }

    renderErrors = () => {
        const responseJSON = this.state.errors.responseJSON || {}
        if (responseJSON.errors && Object.values(responseJSON.errors)) {
            return(
                <ul>
                    {
                        Object.keys(this.state.errors.responseJSON.errors).map((error, index) => {
                            return(
                                <li key={index}>
                                    { `${Utils.humanize(error)}: ${this.state.errors.responseJSON.errors[error]}` }
                                </li>
                            )
                        })
                    }
                </ul>
            )
        } else {
            return "Unknown error";
        }
    };

    render() {
        return(
            <div className="save-account-wrapper margin-40-bottom margin-40-top">
                { this.state.loading &&
                    <div>
                        <h2 className="text-center">
                            Saving Account...
                        </h2>
                        <div className="display-flex">
                            <i className="fa fa-spinner fa-pulse fa-3x fa-fw align-self-center flex-1"></i>
                        </div>
                    </div>
                }
                { !this.state.loading && this.state.success &&
                    <div>
                        <div className="checkmark-animation margin-20-bottom">
                            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/><path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/></svg>
                        </div>
                        <h3 className="text-center">
                            Account Successfully Saved!
                        </h3>
                        <h4 className="text-center">
                            <a href={`/accounts/${this.state.account.id}`}>Go to Account</a>
                        </h4>
                    </div>
                }
                { !this.state.loading && this.state.errors &&
                    <div className="display-flex flex-column">
                        <h3 className="text-center">
                            <span className="glyphicon glyphicon-remove margin-10-right"></span>
                            Some errors occurred:
                        </h3>
                        <div className="display-flex justify-content-center">
                            { this.renderErrors() }
                        </div>
                    </div>
                }
                { !this.state.loading && !this.state.errors && !this.state.account.external_invoice_profile_id && this.props.invoiceIntegration === "quickbooks" &&
                    <div className="margin-40-top">
                        <CreateExternalCustomer integration={this.props.invoiceIntegration} account={this.state.account} />
                    </div>
                }
            </div>
        )
    }
}
