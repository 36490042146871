import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

export default class AccountServiceStops extends React.Component {
    static propTypes = {
        service_stops: PropTypes.array.isRequired,
        timezone: PropTypes.string.isRequired
    }
    constructor(props) {
        super(props);

        this.baseState = {
            serviceStops: props.service_stops
        }

        this.state = this.baseState;
    }

    componentDidMount() {
        window.addEventListener("serviceStopAdded", (e) => {
            const serviceStops = [...this.state.serviceStops, e.detail].sort((a, b) => moment(a.out_time) - moment(b.out_time)).reverse();
            this.setState({ serviceStops });
        });
    }

    render() {
        return(
            <Fragment>
                { this.state.serviceStops.length > 0 &&
                    this.state.serviceStops.map((ss, index) => (
                        <div key={index} className="panel panel-default">
                            <div className="panel-heading">
                                <div className="row">
                                    <div className="col-xs-6">
                                        <a href={`/accounts/${ss.account_id}/service_stops/${ss.id}`}>
                                            { moment(ss.out_time).tz(this.props.timezone).format("MM-DD-YYYY hh:mm A") }
                                        </a>
                                    </div>
                                    <div className="col-xs-6 text-right">
                                        Serviced By: { ss.user ? ss.user.name : "--" }
                                    </div>
                                </div>
                            </div>
                            <div className="panel-body">
                                { ss.notes }
                            </div>
                            <div className="panel-footer">
                                <div className="display-flex">
                                    <div className="flex-1">
                                        pH: { ss.ph }
                                    </div>
                                    <div className="flex-1">
                                        Free Chlorine: { ss.free_chlorine }
                                    </div>
                                    <div className="flex-1">
                                        Acid Added: { ss.acid_added }
                                    </div>
                                    <div className="flex-1">
                                        Shock Added / Tabs Added: { ss.shock_added } / { ss.tabs_added }
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
                { this.state.serviceStops.length === 0 &&
                    <div className="display-flex flex-column align-items-center padding-30">
                        No Service Stops For This Account
                    </div>
                }
            </Fragment>
        )
    }
};
