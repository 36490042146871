import React from 'react';
import PropTypes from 'prop-types';

import AppointmentDaySelect from './Appointments/AppointmentDaySelect';
import BodyOfWatersSelect from './BodyOfWaters/BodyOfWatersSelect';
import ValidationMessage from './ValidationMessage';

export default class Step2 extends React.Component {
    static propTypes = {
        getMasterState: PropTypes.func.isRequired,
        updateMasterState: PropTypes.func.isRequired,
        timezone: PropTypes.string.isRequired,
        serviceTechs: PropTypes.array.isRequired,
        servicePlans: PropTypes.array.isRequired
    }

    constructor(props) {
        super(props);

        const masterObj = props.getMasterState();

        let { on_service, service_plan, body_of_waters_attributes, appointments_attributes, time_to_service, service_frequency } =  masterObj;

        const validations = {};
        ["service_plan", "time_to_service", "appointments_attributes", "mainBowNotSelected"].forEach(prop => validations[prop] = false);

        this.state = { on_service, service_plan, body_of_waters_attributes, appointments_attributes, time_to_service, service_frequency, validations };
    }

    componentWillReceiveProps(props) {
        let { body_of_waters_attributes, appointments_attributes, on_service, service_plan, service_frequency, time_to_service } = props;

        this.setState({ body_of_waters_attributes, appointments_attributes, on_service, service_plan, service_frequency, time_to_service });
    }

    isValidated() {
        const results = [];

        const validations = {};
        ["service_plan", "time_to_service", "appointments_attributes"].forEach(prop => {
            const bool = (this.state.on_service && this.state[prop].length < 1)
            results.push(bool);

            validations[prop] = bool;
        });

        if (this.appointmentDaySelect) {
            if (this.props.getMasterState().appointments_attributes.filter(appt => !appt._destroy).length < 1) {
                // they're all set to be destroyed
                if (this.state.on_service) {
                    validations.appointments_attributes = true;
                    results.push(true);
                }
            } else {
                results.push(this.appointmentDaySelect.invalidAppointments());
            }
        }

        let mainBowNotSelected = false;
        if (this.bodyOfWaterSelect) {
            if (this.state.body_of_waters_attributes.filter(bow => bow.main).length < 1) {
                mainBowNotSelected = true;
            }

            results.push(this.bodyOfWaterSelect.invalidBodyOfWaters(), mainBowNotSelected);
            validations["mainBowNotSelected"] = mainBowNotSelected;
        }

        this.setState({ validations });

        return !results.includes(true);
    }

    toggleOnService = (bool) => {
        let appointments_attributes = [];

        if (!bool) {
            appointments_attributes = this.state.appointments_attributes.map(appt => {
                if (appt.id) {
                    appt._destroy = true;
                    return appt;
                } else {
                    return
                }
            }).filter(appt => appt); // filter undefined

            this.props.updateMasterState({ on_service: bool, appointments_attributes, next_billing_period: null, billing_period_type: null, billing_start_date: null, next_billing_date: null, next_bill_issue_date: null, generate_invoices_for_service_in: null, days_to_issue_bill: null, days_til_due_date: null, base_cost: null });
        } else {
            this.props.updateMasterState({ on_service: bool, appointments_attributes });
        }
    };

    toggleBows(bool) {
        let body_of_waters_attributes = [{}];

        if (!bool) {
            body_of_waters_attributes = this.state.body_of_waters_attributes.map(bow => {
                if (bow.id) {
                    bow._destroy = true;
                    return bow;
                }
            }).filter(bow => bow); // filter undefined
        }

        this.props.updateMasterState({ body_of_waters_attributes });
    }

    render() {
        return(
            <div className="step-2-wrapper row margin-20-top margin-20-bottom">
                <div className="input-row">
                    <div className="col-sm-4 input-field">
                        <span className="field-label">
                            On Service:
                        </span>
                    </div>
                    <div className="col-sm-8 input-field">
                        <div className="field-description">
                            Will this customers pool be serviced regularly?
                        </div>
                        <div className="margin-10-top">
                            <button
                                className={`btn ${this.state.on_service ? "btn-primary" : "btn-default"} margin-10-right`}
                                onClick={() => this.toggleOnService(true)}
                            >
                                Yes
                            </button>
                            <button
                                className={`btn ${this.state.on_service ? "btn-default" : "btn-primary"}`}
                                onClick={() => this.toggleOnService(false) }
                            >
                                No
                            </button>
                        </div>
                    </div>
                </div>
                { this.state.on_service &&
                    <div>
                        <div className="input-row">
                            <div className="col-sm-4 input-field">
                                <div className="field-label">
                                    Service Plan:
                                </div>
                            </div>
                            <div className="col-sm-8 input-field">
                                <div className="field-description margin-10-bottom">
                                    What service plan will this customer have?
                                </div>
                                <div className="form-group no-margin">
                                    <select
                                        className="form-control"
                                        defaultValue={this.state.service_plan}
                                        onChange={(e) => this.props.updateMasterState({ service_plan: e.target.value })}
                                    >
                                        <option value="">Select service plan...</option>
                                        {
                                            this.props.servicePlans.map((plan, key) => {
                                                return(
                                                    <option key={key} value={plan}>
                                                        { plan }
                                                    </option>
                                                )
                                            })
                                    }
                                    </select>
                                </div>
                                <ValidationMessage condition={this.state.validations.service_plan} message="Service Plan must be selected" />
                            </div>
                        </div>
                        <div className="input-row">
                            <div className="col-sm-4 input-field">
                                <span className="field-label">
                                    Service Frequency:
                                </span>
                            </div>
                            <div className="col-sm-8 input-field">
                                <div className="field-description margin-10-bottom">
                                    How often will this customers pool be serviced?
                                </div>
                                <div className="form-group no-margin col-md-4 no-padding">
                                    <select
                                        className="form-control"
                                        defaultValue={this.state.service_frequency}
                                        onChange={(e) => this.props.updateMasterState({ service_frequency: e.target.value })}
                                    >
                                        <option value="weekly">
                                            Every week
                                        </option>
                                        <option value="bi-weekly">
                                            Every other week
                                        </option>
                                        <option value="4 weeks">
                                            Every 4 weeks
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="input-row">
                            <div className="col-sm-4 input-field">
                                <span className="field-label">
                                    Service Day(s):
                                </span>
                            </div>
                            <div className="col-sm-8 col-xs-12 input-field">
                                <div className="field-description margin-10-bottom">
                                    Set the day(s) this pool will be serviced, and by what technician(s)
                                </div>
                                <div>
                                    <AppointmentDaySelect
                                        ref={(ref) => this.appointmentDaySelect = ref}
                                        getMasterState={this.props.getMasterState}
                                        updateMasterState={this.props.updateMasterState}
                                        appointments_attributes={this.state.appointments_attributes}
                                        timezone={this.props.timezone}
                                        serviceTechs={this.props.serviceTechs}
                                    />
                                </div>
                                <ValidationMessage condition={this.state.validations.appointments_attributes} message="Customer must have a service day if they will be on service." />
                            </div>
                        </div>
                        <div className="input-row">
                            <div className="col-sm-4 input-field">
                                <span className="field-label">
                                    Time To Service:
                                </span>
                            </div>
                            <div className="col-sm-8 input-field">
                                <div className="field-description margin-10-bottom">
                                    How long do you estimate it will take to clean this customers pool each time? This will be the amount of time that will be automatically blocked off on the calendar to clean this customers pool.
                                </div>
                                <div className="form-group no-margin">
                                    <div className="input-group col-sm-4 col-xs-6">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={this.state.time_to_service}
                                            onChange={(e) => this.props.updateMasterState({ time_to_service: e.target.value })}
                                        />
                                        <div className="input-group-addon">
                                            minutes
                                        </div>
                                    </div>
                                </div>
                                <ValidationMessage condition={this.state.validations.time_to_service} message="Time to Service can't be blank" />
                            </div>
                        </div>
                        <div className="input-row">
                            <div className="col-sm-4 input-field">
                                <span className="field-label">
                                    Bodies of Water:
                                </span>
                            </div>
                            <div className="col-sm-8 input-field">
                                <div className="field-description margin-10-bottom">
                                    Does this customer have more than one body of water that you will service?
                                </div>
                                <div>
                                    <button
                                        className={`btn ${this.state.body_of_waters_attributes.length > 0 ? "btn-primary" : "btn-default"} margin-10-right`}
                                        onClick={() => this.toggleBows(true)}
                                    >
                                        Yes
                                    </button>
                                    <button
                                        className={`btn ${this.state.body_of_waters_attributes.length > 0 ? "btn-default" : "btn-primary"}`}
                                        onClick={() => this.toggleBows(false)}
                                    >
                                        No
                                    </button>
                                </div>
                                { this.state.body_of_waters_attributes.length > 0 &&
                                    <div className="margin-10-top">
                                        <BodyOfWatersSelect
                                            getMasterState={this.props.getMasterState}
                                            updateMasterState={this.props.updateMasterState}
                                            body_of_waters_attributes={this.state.body_of_waters_attributes}
                                            mainBowNotSelected={this.state.validations.mainBowNotSelected}
                                            ref={(select) => this.bodyOfWaterSelect = select}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}
