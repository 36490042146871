import PropTypes from 'prop-types';
import React from 'react';

export default class ServiceTechCommissions extends React.Component {
    static propTypes = {
        serviceTechs: PropTypes.array.isRequired,
        isNotAdmin: PropTypes.bool.isRequired,
        timeZone: PropTypes.string.isRequired
    }

    constructor(props) {
        super(props);

        this.state = {
            commissions: null,
            paidFilter: null,
            selectedServiceTech: null,
            selectedRows: [],
            selectedTypes: [],
            loading: false
        }
    }

    searchParams = () => {
        const searchParams = {
            "user_id_eq": this.state.selectedServiceTech
        };

        if (this.startDateInput.value != "") searchParams["created_at_gteq"] = moment(this.startDateInput.value).tz(this.props.timeZone).startOf('day').utc().format();
        if (this.endDateInput.value != "") searchParams["created_at_lteq"] = moment(this.endDateInput.value).tz(this.props.timeZone).endOf('day').utc().format();
        if (!!this.state.paidFilter) {
            searchParams["paid_date_gteq"] = moment(this.paidStartDateInput.value).tz(this.props.timeZone).startOf('day').utc().format();
            searchParams["paid_date_lteq"] = moment(this.paidEndDateInput.value).tz(this.props.timeZone).endOf('day').utc().format();
        } else if (this.state.paidFilter === false) {
            searchParams["paid_date_null"] = true;
        }
        if (this.state.selectedTypes.length > 0) searchParams["type_in"] = this.state.selectedTypes;

        return searchParams;
    }

    fetchAndSetCommissions = () => {
        this.setState({
            selectedRows: [],
            loading: true
        });

        $.ajax({
            url: "/reports/show.json",
            method: "get",
            data: {
                type: "service_tech_commissions",
                q: this.searchParams(),
            }
        }).done((res) => {
            this.setState({
                commissions: res["service_tech_commissions"],
                selectedRows: [],
                loading: false
            });

            this.toggleAllSelected();
        })
    };

    updateSelectedCommissions = (e, bool) => {
        $.ajax({
            url: "/reports/update.json",
            method: "put",
            data: {
                type: "service_tech_commissions",
                q: this.searchParams(),
                report: {
                    ids: this.state.selectedRows.map((index) => this.state.commissions[index]).map((c) => c.id),
                    paid_date: bool ? moment().utc().format() : null
                }
            }
        }).done((res) => {
            this.setState({
                commissions: res["service_tech_commissions"],
                selectedRows: []
            })
        })
    };

    destroyCommissions = () => {
        if (confirm("Are you sure you would like to destroy the selected commission? This cannot be undone.")) {
            $.ajax({
                url: "/reports/destroy.json",
                method: "delete",
                data: {
                    type: "service_tech_commissions",
                    q: this.searchParams(),
                    report: {
                        ids: this.state.selectedRows.map((index) => this.state.commissions[index]).map((c) => c.id)
                    }
                }
            }).done((res) => {
                this.setState({
                    commissions: res["service_tech_commissions"],
                    selectedRows: []
                })
            })
        }
    };

    setPaidFilter = (value) => {
        this.setState({
            paidFilter: value
        })
    };

    setSelectedServiceTech = () => {
        this.setState({
            selectedServiceTech: this.serviceTechInput.value
        })
    };

    totalCommissions = () => {
        if (this.state.selectedRows && this.state.selectedRows.length > 0) {
            const filteredCommissions = this.state.selectedRows.map((index) => this.state.commissions[index]);

            const sum = filteredCommissions.map((c) => parseFloat(c.amount)).reduce((a,b) => a + b, 0);
            return `$${parseFloat(sum).toFixed(2)}`
        } else {
            return "--"
        }
    };

    isSelected = (val) => {
        return this.state.selectedRows.indexOf(val) > -1
    };

    toggleAllSelected = () => {
        if (this.state.selectedRows.length ===  this.state.commissions.length) {
            this.setState({
                selectedRows: []
            })
        } else {
            this.setState((prevState) => {
                const selectedRows = [...Array(this.state.commissions.length).keys()];
                const newState = {...this.state, selectedRows}

                return newState
            })
        }
    };

    addToSelected = (val) => {
        const selectedRows = [...this.state.selectedRows, val];

        this.setState({ selectedRows });
    };

    removeFromSelected = (val) => {
        const selectedRows = this.state.selectedRows.filter((_val) => _val !== val);

        this.setState({ selectedRows });
    };

    toggleSelected = (val) => {
        if (this.isSelected(val)) {
            this.removeFromSelected(val);
        } else {
            this.addToSelected(val);
        }
    };

    toggleSelectedType = (val) => {
        let types;
        if (this.state.selectedTypes.indexOf(val) > -1) {
            types = this.state.selectedTypes.filter(type => type !== val);
        } else {
            types = [...this.state.selectedTypes, val];
        }

        this.setState({
            selectedTypes: types
        })
    };

    setShouldDisplayUpdateLink = (rows) => {
        const selectedCommissions = rows.map((index) => this.state.commissions[index]);

        const results = [];
        selectedCommissions.forEach((c) => {
            results.push(c.paid);
        });

        this.setState({
            shouldDisplayUpdateLink: results.indexOf(true) > -1
        })
    };

    render() {
        return(
            <div>
                <div className="row">
                    <div className="col-md-4">
                        <div className="form-group">
                            <label>
                                Service Tech:
                            </label>
                            <select
                                className="form-control"
                                onChange={() => { this.setSelectedServiceTech() }}
                                ref={(input) => { this.serviceTechInput = input }}
                            >
                                <option value="">
                                    Select Service Tech...
                                </option>
                                {this.props.serviceTechs.map((serviceTech, index) => (
                                    <option key={index} value={serviceTech.id}>{serviceTech.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label>
                                Commission Earned Start Date:
                            </label>
                            <input
                                type="date"
                                className="form-control"
                                ref={(input) => { this.startDateInput = input }}
                            />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label>
                                Commission Earned End Date:
                            </label>
                            <input
                                type="date"
                                className="form-control"
                                ref={(input) => { this.endDateInput = input }}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-12">
                                <h4>
                                    Filter Search:
                                </h4>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group no-margin-bottom">
                                    <label htmlFor="paid">
                                        Paid Between Dates:
                                        &nbsp;
                                    </label>
                                    <input
                                        name="filter"
                                        id="paid"
                                        type="radio"
                                        onChange={() => { this.setPaidFilter(true) }}
                                    />
                                    {this.state.paidFilter &&
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="paid-start">
                                                                Paid Start Date:
                                                            </label>
                                                            <input
                                                                className="form-control"
                                                                type="date"
                                                                ref={(input) => { this.paidStartDateInput = input }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="paid-end">
                                                                Paid End Date:
                                                            </label>
                                                            <input
                                                                className="form-control"
                                                                type="date"
                                                                ref={(input) => { this.paidEndDateInput = input }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group no-margin-bottom">
                                    <label htmlFor="not-paid">
                                        Not Paid:
                                        &nbsp;
                                    </label>
                                    <input
                                        name="filter"
                                        id="not-paid"
                                        type="radio"
                                        onChange={() => { this.setPaidFilter(false) }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group no-margin-bottom">
                                    <label htmlFor="none">
                                        All Paid Statuses:
                                        &nbsp;
                                    </label>
                                    <input
                                        name="filter"
                                        type="radio"
                                        id="none"
                                        onChange={() => { this.setPaidFilter(null) }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row margin-10-top">
                            <div className="col-md-12">
                                <label>Include Commission Types:</label>
                                <div className="form-group">
                                    <input
                                        id="service-stop-comish"
                                        type="checkbox"
                                        onChange={() => { this.toggleSelectedType('service_stop') }}
                                    />
                                    &nbsp;
                                    <label htmlFor="service-stop-comish" className="margin-10-right">
                                        Service Stops
                                    </label>
                                    <input
                                        id="repair-comish"
                                        type="checkbox"
                                        onChange={() => { this.toggleSelectedType('repair') }}
                                    />
                                    &nbsp;
                                    <label htmlFor="repair-comish" className="margin-10-right">
                                        Repairs
                                    </label>
                                    <input
                                        id="service-item-comish"
                                        type="checkbox"
                                        onChange={() => { this.toggleSelectedType('service_item') }}
                                    />
                                    &nbsp;
                                    <label htmlFor="service-item-comish">
                                        Service Items
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <button
                            className="btn btn-success"
                            onClick={() => this.fetchAndSetCommissions()}
                            disabled={!this.state.selectedServiceTech}
                        >
                            Search Commissions
                        </button>
                    </div>
                    <div className="col-md-6 margin-10-top">
                        <div className="pull-right">
                            <h4>
                                Total Selected Commissions: {this.totalCommissions()}
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        {(this.state.selectedRows.length > 0 && this.state.commissions && this.state.commissions.length > 0 && !this.props.isNotAdmin) &&
                            <div>
                                <hr/>
                                <div className="margin-10-top">
                                    <button className="btn btn-default" onClick={(e) => { this.updateSelectedCommissions(e, true) }}>
                                        Mark As Paid
                                    </button>
                                    <button className="btn btn-default margin-10-left" onClick={(e) => { this.updateSelectedCommissions(e, false) }}>
                                        Mark As Not Paid
                                    </button>
                                </div>
                                <div className="margin-10-top">
                                    <button className="btn btn-danger" onClick={() => (this.destroyCommissions())}>
                                        Destroy
                                    </button>
                                </div>
                            </div>
                        }
                    </div>
                </div>

                <hr/>
                {this.state.loading &&
                    <div className="display-flex">
                        <i className="fa fa-spinner fa-pulse fa-3x fa-fw align-self-center flex-1"></i>
                    </div>
                }
                {this.state.commissions && !this.state.loading &&
                    <div className="row">
                        <div className="col-md-12">
                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    checked={this.state.selectedRows.length === this.state.commissions.length}
                                                    onChange={() => { this.toggleAllSelected() }}
                                                />
                                            </td>
                                            <td>
                                                Type:
                                            </td>
                                            <td style={{width: "50%"}}>
                                                Description:
                                            </td>
                                            <td>
                                                Date Commission Earned:
                                            </td>
                                            <td>
                                                Commission Amount:
                                            </td>
                                            <td>
                                                Commission Paid:
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.commissions.map((stc, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <input
                                                        type="checkbox"
                                                        checked={this.isSelected(index)}
                                                        onChange={() => { this.toggleSelected(index) }}
                                                    />
                                                </td>
                                                <td>
                                                    {stc.type}
                                                </td>
                                                <td>
                                                    {stc.description}
                                                </td>
                                                <td>
                                                    {stc.date}
                                                </td>
                                                <td>
                                                    {`$${parseFloat(stc.amount).toFixed(2)}`}
                                                </td>
                                                <td>
                                                    {stc.paid_date}
                                                    {!stc.paid_date && "Not Yet Paid"}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                }
                {(!this.state.selectedServiceTech || !this.state.commissions) &&
                    <h4 className="text-center">
                        View commissions for Service Stops, Service Items, and Repairs
                    </h4>
                }
                {(this.state.selectedServiceTech && this.state.commissions && this.state.commissions.length === 0) &&
                    <h4 className="text-center">
                        There are no commissions for the parameters provided
                    </h4>
                }
            </div>
        )
    }
}
