import React from 'react';
import PropTypes from 'prop-types';

export default class CompanyList extends React.Component {
    constructor(props) {
        super(props);

        this.baseState = {
            companyName: "",
            companies: [],
            loading: false,
            pending: null
        }

        this.state = this.baseState;
    }

    renderRows = () => {
        return this.state.companies.map((company, index) => {
            return(
                <tr key={index}>
                    <td>
                        { company.name }
                    </td>
                    <td>
                        { company.contact.name }
                    </td>
                    <td>
                        { this.state.pending !== company.id && company.sandbox &&
                            "Trial"
                        }
                        { this.state.pending !== company.id && !company.sandbox &&
                            "Activated"
                        }
                        { this.state.pending === company.id &&
                            "Pending..."
                        }
                    </td>
                    <td>
                        { company.sandbox &&
                            <button
                                className="btn btn-success"
                                onClick={() => this.updateCompany("activate", company.id)}
                                disabled={this.state.pending == company.id}
                            >
                                Activate
                            </button>
                        }
                        { !company.sandbox &&
                            <button
                                className="btn btn-danger"
                                onClick={() => this.updateCompany("deactivate", company.id)}
                                disabled={this.state.pending == company.id}
                            >
                                Deactivate
                            </button>
                        }
                    </td>
                </tr>
            )
        });
    };

    searchCompanies = () => {
        this.setState({ loading: true });

        $.ajax({
            url: "/admin/list_companies.json",
            method: "GET",
            data: {
                "q": {
                    "name_cont": this.state.companyName
                }
            }
        }).done(res => this.setState({ companies: res, loading: false, pending: null }));
    };

    updateCompany = (company_action, id) => {
        $.ajax({
            url: "/admin/update_company.json",
            method: "POST",
            data: { company_action, id }
        }).done(res => {
            this.setState({ pending: res.id });
        });
    };

    render() {
        return(
            <div>
                <div className="form-group">
                    <input
                        className="form-control"
                        type="text"
                        onChange={(e) => this.setState({ companyName: e.target.value }) }
                        placeholder="Company Name"
                    />
                </div>
                <div className="form-group">
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => this.searchCompanies()}
                    >
                        Search
                    </button>
                </div>
                { this.state.loading &&
                    <div className="display-flex">
                        <i className="fa fa-spinner fa-pulse fa-3x fa-fw align-self-center flex-1"></i>
                    </div>
                }
                { !this.state.loading && this.state.companies.length == 0 &&
                    <h4 className="text-center">
                        No Search Results Found...
                    </h4>
                }
                { !this.state.loading && this.state.companies.length > 0 &&
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <td>
                                                Company Name:
                                            </td>
                                            <td>
                                                Contact Name:
                                            </td>
                                            <td>
                                                Status:
                                            </td>
                                            <td>
                                                Actions:
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { this.renderRows() }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}
