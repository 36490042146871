import React, { Fragment } from 'react';
import PropTypes from 'prop-types'

import PricingInfo from './PricingInfo';

import SubUtils from './SubUtils';

export default class SubscriptionPricing extends React.Component {
    static propTypes = {
        buttonTxt: PropTypes.string.isRequired,
        minAmount: PropTypes.number,
        callback: PropTypes.func,
        activePlan: PropTypes.string,
        selectedAccounts: PropTypes.string,
        subscription: PropTypes.object
    };

    constructor(props) {
        super(props);

        this.baseState = {
            activePlan: "pro",
            selectedAccounts: props.selectedAccounts || "40"
        }

        if (props.subscription) {
            this.subscriptionPlan = SubUtils.basePlan(props.subscription);
            this.subscriptionAccounts = SubUtils.accountLimit(props.subscription).toString();
        }

        this.state = this.baseState;
    }

    updateCallback = (activePlan, selectedAccounts) => {
        this.setState({ activePlan, selectedAccounts: selectedAccounts.toString() });
    };

    buttonDisabled = (plan) => {
        if (this.props.subscription && this.props.subscription.status !== "trialing") {
            if (SubUtils.activeSubscription(this.props.subscription)) {
                return this.subscriptionPlan === plan && this.subscriptionAccounts === this.state.selectedAccounts
            } else {
                return false;
            }
        } else {
            return false;
        }
    };

    render() {
        return(
            <div className="subscription-pricing-wrapper">
                <ul className="nav nav-tabs display-flex justify-content-center">
                    <li className="active">
                        <a
                            className="display-flex justify-content-center"
                            href="#aqs-pro"
                            data-toggle="tab"
                        >
                            <img src="https://s3.amazonaws.com/superswimbros.dev/no+word+smaller+logo.png" alt="AquaSuite"/>
                            <h4 className="position-relative">
                                <span className="label label-warning">
                                    Pro
                                </span>
                            </h4>
                        </a>
                    </li>
                </ul>
                <div className="tab-content margin-20-top">
                    <div className={`tab-pane ${this.state.activePlan === "lite" && "active"}`} id="aqs-lite">
                        { this.props.subscription &&
                            <Fragment>
                                { (this.subscriptionPlan === "lite" && this.subscriptionAccounts === this.state.selectedAccounts) &&
                                    <h4 className="text-center current-plan width-100 no-margin-bottom">
                                        Current Plan
                                    </h4>
                                }
                                { (this.subscriptionPlan !== "lite" || this.subscriptionAccounts !== this.state.selectedAccounts) &&
                                    <h4 className="text-center current-plan width-100 no-margin-bottom">
                                        Updated Plan
                                    </h4>
                                }
                            </Fragment>
                        }
                        <PricingInfo
                            plan="lite"
                            buttonTxt={this.props.buttonTxt}
                            callback={this.props.callback}
                            updateCallback={this.updateCallback}
                            activePlan={this.state.activePlan}
                            selectedAccounts={this.state.selectedAccounts}
                            buttonDisabled={this.buttonDisabled("lite")}
                            minAmount={this.props.minAmount}
                        />
                    </div>
                    <div className={`tab-pane ${this.state.activePlan === "plus" && "active"}`} id="aqs-plus">
                        { this.props.subscription &&
                            <Fragment>
                                { (this.subscriptionPlan === "plus" && this.subscriptionAccounts === this.state.selectedAccounts) &&
                                    <h4 className="text-center current-plan width-100 no-margin-bottom">
                                        Current Plan
                                    </h4>
                                }
                                { (this.subscriptionPlan !== "plus" || this.subscriptionAccounts !== this.state.selectedAccounts) &&
                                    <h4 className="text-center current-plan width-100 no-margin-bottom">
                                        Updated Plan
                                    </h4>
                                }
                            </Fragment>
                        }
                        <PricingInfo
                            plan="plus"
                            buttonTxt={this.props.buttonTxt}
                            callback={this.props.callback}
                            updateCallback={this.updateCallback}
                            activePlan={this.state.activePlan}
                            selectedAccounts={this.state.selectedAccounts}
                            buttonDisabled={this.buttonDisabled("plus")}
                            minAmount={this.props.minAmount}
                        />
                    </div>
                    <div className={`tab-pane ${this.state.activePlan === "pro" && "active"}`} id="aqs-pro">
                        { this.props.subscription &&
                            <Fragment>
                                { (this.subscriptionPlan === "pro" && this.subscriptionAccounts === this.state.selectedAccounts) &&
                                    <h4 className="text-center current-plan width-100 no-margin-bottom">
                                        Current Plan
                                    </h4>
                                }
                                { (this.subscriptionPlan !== "pro" || this.subscriptionAccounts !== this.state.selectedAccounts) &&
                                    <h4 className="text-center current-plan width-100 no-margin-bottom">
                                        Updated Plan
                                    </h4>
                                }
                            </Fragment>
                        }
                        <PricingInfo
                            plan="pro"
                            buttonTxt={this.props.buttonTxt}
                            callback={this.props.callback}
                            updateCallback={this.updateCallback}
                            activePlan={this.state.activePlan}
                            selectedAccounts={this.state.selectedAccounts}
                            buttonDisabled={this.buttonDisabled("pro")}
                            minAmount={this.props.minAmount}
                        />
                    </div>
                </div>
            </div>
        )
    }
}
