import React from 'react';
import PropTypes from 'prop-types';

import AqsModal from 'AqsModal';
import CreditCards from '@shared/components/CreditCards/Index';

export default class ManageCreditCards extends React.Component {
    static propTypes = {
        stripe_key: PropTypes.string.isRequired,
        payment_sources_url: PropTypes.string.isRequired,
        delete_source_url: PropTypes.string.isRequired,
        update_customer_url: PropTypes.string.isRequired,
        add_payment_source_url: PropTypes.string.isRequired
    }

    componentDidMount() {
        $("a#manage-credit-cards").on("click", (e) => {
            e.preventDefault();

            this.modalEl.show();
        });
    }

    render() {
        return(
            <AqsModal
                ref={(e) => this.modalEl = e}
                id="manage-credit-cards"
                headerText="Manage Credit Cards"
                large={true}
            >
                <CreditCards
                    displayPayment={false}
                    cardSelectable={false}
                    showAddNewCard={true}
                    fetchPaymentSourcesOnMount={true}
                    setupCardElement={true}
                    stripeKey={this.props.stripe_key}
                    paymentSourcesUrl={this.props.payment_sources_url}
                    deleteSourceUrl={this.props.delete_source_url}
                    updateCustomerUrl={this.props.update_customer_url}
                    addPaymentSourceUrl={this.props.add_payment_source_url}
                />
            </AqsModal>
        )
    }
}
