import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import TechWidget from '@shared/components/RouteTech/AppointmentsWidget/Index';

import LockedFeature from '@main/components/Subscription/LockedFeature';
import ActivateMapbox from '@shared/components/ActivateMapbox';

import Utils from '../../Utils';
import { toast } from 'react-toastify';

export default class SuggestedTech extends React.Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        callback: PropTypes.func.isRequired,
        uniqueKey: PropTypes.number.isRequired,
        company: PropTypes.object.isRequired,
        pusherKey: PropTypes.string.isRequired,
        account: PropTypes.object.isRequired,
        techs: PropTypes.array.isRequired,
        companySettings: PropTypes.object.isRequired,
        suggestionCallback: PropTypes.func.isRequired,
        basePlan: PropTypes.string.isRequired
    }

    constructor(props) {
        super(props);

        this.baseState = {
            enabled: false,
            loading: false,
            route: null,
            suggestedUser: null,
            suggestedDay: null,
            noneFound: false
        };

        this.state = this.baseState;

        if (!window.pusherChannel && this.props.basePlan === "pro") {
            const pusher = new Pusher(this.props.pusherKey, {
                cluster: "us2",
                forceTLS: true
            });

            window.pusherChannel = pusher.subscribe(`admin-alerts-${this.props.environment}-${this.props.company.channel_id}`);
        }
    }

    findSuggestion = (e) => {
        $(e.target).find("span").toggleClass("rotate-right");
        if (!this.state.enabled) {
            this.setState({ enabled: true, loading: true });

            $.ajax({
                method: "POST",
                url: "/reports/route/suggested.json",
                data: {
                    lat: this.props.location.lat,
                    lng: this.props.location.lng
                }
            }).done(res => {
                window.pusherChannel.bind("suggested-route-tech", (data) => {
                    if (data.success) {
                        $.ajax({
                            method: "GET",
                            url: `/reports/route/suggested/${res.cache_key}.json`,
                        }).done(cache => {
                            const newStart = moment.tz(cache.new_start, "UTC");
                            const newEnd = moment(newStart).add(this.props.account.time_to_service, "minutes");

                            const newAppt = { account: { ...this.props.account }, start: newStart.format(), end: newEnd.format(), user_id: cache.user.id, type: "service_stop" };

                            const route = cache.route
                            const day = Object.keys(route).find(k => k.length === 3);
                            route[day].push(newAppt);

                            const sorted = route[day].sort((a,b) => moment(a.start) - moment(b.start));

                            const newRoute = Utils.rearrangeAppointments(sorted, this.props.companySettings.service_days, this.props.company.timezone);

                            route[day] = newRoute;

                            const metrics = { [cache.user.id]: { [day]: { ...cache.metrics } } };

                            this.setState({ route: { [cache.user.id]: route }, metrics, loading: false, suggestedUser: cache.user, suggestedDay: day }, () => {
                                setTimeout(() => {
                                    $(".route-tech-appointment-widget-wrapper").toggleClass("expanded");
                                    setTimeout(() => {
                                        $(".expanded-area li:not([data-appt-id])")[0].scrollIntoView({ block: "center", behavior: "smooth" })
                                    }, 500);
                                }, 500);
                            });
                        }).fail(() => {
                            this.setState({ noneFound: true, loading: false });
                        });
                    } else {
                        this.setState({ noneFound: true, loading: false });
                    }

                    window.pusherChannel.unbind("suggested-route-tech");
                });
            }).fail(() => {
                this.setState({ noneFound: true, loading: false })
            })
        }
    };

    sortedTechs = () => {
        return this.props.techs.sort((a, b) => moment(a.created_at) - moment(b.created_at));
    };

    findTechIndex = () => {
        const id = Object.keys(this.state.route)[0];
        return this.sortedTechs().findIndex(t => t.id.toString() === id);
    };

    render() {
        return(
            <div>
                <h5
                    className="cursor-pointer"
                    href={`#suggested-scheduling-${this.props.uniqueKey}`}
                    data-toggle="collapse"
                    onClick={this.findSuggestion}
                >
                    <span className="glyphicon glyphicon-chevron-down rotate-right margin-5-right"></span>
                    Show Suggested Scheduling
                </h5>
                <div id={`suggested-scheduling-${this.props.uniqueKey}`} className="collapse">
                    { this.props.basePlan !== "pro" &&
                        <LockedFeature
                            plan_required="pro"
                            image="https://s3.amazonaws.com/superswimbros.dev/suggested-tech-locked.png"
                            feature_text="Suggested Scheduling"
                        />
                    }
                    { this.props.basePlan === "pro" &&
                        <Fragment>
                            { this.props.company.mapbox_api_key && this.props.company.mapbox_api_key.length > 0 ?
                                <Fragment>
                                    { this.state.loading &&
                                        <Fragment>
                                            <div className="display-flex flex-column justify-content-center align-items-center">
                                                <h5 className="text-center">
                                                    Finding suggested Route/Tech for this Account...
                                                </h5>
                                                <i className="fa fa-spinner fa-pulse fa-3x fa-fw align-self-center flex-1"></i>
                                            </div>
                                        </Fragment>
                                    }
                                    { (!this.state.loading && this.state.noneFound) &&
                                        <div className="alert alert-warning text-center">
                                            No available suggested Techs/Routes found
                                        </div>
                                    }
                                    { (!this.state.loading && !this.state.noneFound && this.state.route) &&
                                        <Fragment>
                                            <div className="display-flex overflow-x-scroll">
                                                <TechWidget
                                                    displayDayName={this.state.suggestedDay}
                                                    route={this.state.route}
                                                    metrics={this.state.metrics}
                                                    tech={this.state.suggestedUser}
                                                    startingPoints={this.state.route[this.state.suggestedUser.id].starting_points}
                                                    techIndex={this.findTechIndex()}
                                                    companySettings={this.props.companySettings}
                                                    toggleable={false}
                                                    timezone={this.props.company.timezone}
                                                    disableEditOrder={true}
                                                />
                                            </div>
                                            <div className="display-flex justify-content-center margin-20-top margin-20-bottom">
                                                <button className="btn btn-success" onClick={() => this.props.suggestionCallback(this.state.route[this.state.suggestedUser.id][this.state.suggestedDay])}>
                                                    Use Suggestion
                                                </button>
                                            </div>
                                        </Fragment>
                                    }
                                </Fragment>
                            :
                                <ActivateMapbox />
                            }
                        </Fragment>
                    }
                </div>
            </div>
        )
    }
}
