import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const Countdown = (props) => {
    const secondsEl = useRef(null);

    useEffect(() => {
        countdown();
    }, []);

    const countdown = () => {
        let secondsLeft = props.amount
        const interval = setInterval(() => {
            if (secondsLeft === 0) {
                clearInterval(interval);
            } else {
                secondsLeft = secondsLeft - 1;
                $(secondsEl.current).text(secondsLeft);
            }
        }, 1000);
    };

    return(
        <span>
            <span ref={secondsEl}>
                { props.amount }
            </span>
            &nbsp;Seconds
        </span>
    )
};

Countdown.propTypes = {
    amount: PropTypes.number.isRequired
}

export default Countdown;
