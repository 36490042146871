import React from 'react';
import PropTypes from 'prop-types';

import { toast } from 'react-toastify';
import Utils from '../../Utils';

export default class RepairAction extends React.Component {
    static propTypes = {
        action: PropTypes.string.isRequired,
        timezone: PropTypes.string.isRequired,
        callback: PropTypes.func,
        destroyRepairs: PropTypes.func,
        statuses: PropTypes.array,
        repairs: PropTypes.array
    }

    constructor(props) {
        super(props);

        this.baseState = {
            loading: false,
            selectedAttachments: []
        }

        this.state = this.baseState;
    }

    componentDidMount() {
        $("#repair-action-modal").on("show.bs.modal", (e) => {
            $(e.target).addClass("center-screen-modal");
        });

        $("#repair-action-modal").on("hide.bs.modal", (e) => {
            $(e.target).removeClass("center-screen-modal");

            this.setState({ ...this.baseState });
        });
    }

    modalTitle = () => {
        switch (this.props.action) {
            case 'update':
                return "Update Repair(s)";
            case 'destroy':
                return "Destroy Repair(s)";
            case 'attachments':
                return "Send Repair Attachment(s)";
            case 'newInvoice':
                return "Create new Invoices from Repair(s)";
        }
    };

    renderBody = () => {
        switch (this.props.action) {
            case 'update':
                return(
                    <div className="display-flex flex-column justify-content-center align-items-center">
                        <h4 className="text-center">
                            Select status to update repairs to:
                        </h4>
                        <div className="form-group">
                            <select
                                className="form-control"
                                ref={(ref) => this.repairStatus = ref}
                            >
                                {
                                    this.props.statuses.map((status, index) => {
                                        return(
                                            <option
                                                key={index}
                                                value={status}
                                            >
                                                { Utils.humanize(status) }
                                            </option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div>
                )
            case 'destroy':
                return(
                    <div className="display-flex flex-column justify-content-center align-items-center">
                        <h4 className="text-center">
                            Are you sure you want to destroy these Repairs?
                        </h4>
                        <div>
                            <button className="btn btn-danger" onClick={this.destroyRepairs}>
                                <span className="glyphicon glyphicon-trash"></span>
                                &nbsp;
                                Destroy
                            </button>
                        </div>
                    </div>
                )
            case 'attachments':
                return(
                    <div className="display-flex flex-column justify-content-center align-items-center width-100">
                        <h4 className="text-center margin-30-bottom">
                            Each of the following Repairs will have their Attachments delivered to the customer:
                        </h4>

                        { this.repairPreviews() }

                        <h5 className="text-center">
                            Select Attachment(s) to send:
                        </h5>

                        <div className="well width-100 display-flex flex-column justify-content-center align-items-center">
                            <div className="form-group">
                                <label htmlFor="email-attachment" className="margin-5-right">
                                    Email:
                                </label>
                                <input
                                    id="email-attachment"
                                    type="checkbox"
                                    onChange={() => this.toggleSelection("email")}
                                    checked={this.state.selectedAttachments.includes("email")}
                                />
                            </div>
                            <div className="form-group no-margin-bottom">
                                <label htmlFor="text-attachment" className="margin-5-right">
                                    Text Message:
                                </label>
                                <input
                                    id="text-attachment"
                                    type="checkbox"
                                    onChange={() => this.toggleSelection("text")}
                                    checked={this.state.selectedAttachments.includes("text")}
                                    disabled={!this.allAccountsHaveValidPhones()}
                                />
                            </div>
                            { !this.allAccountsHaveValidPhones() &&
                                <div className="alert alert-danger">
                                    <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                                    One or more accounts do not have a valid phone number
                                </div>
                            }
                        </div>

                        <div>
                            <button
                                className="btn btn-success"
                                onClick={() => this.updateRepairs()}
                                disabled={this.state.selectedAttachments.length < 1}
                            >
                                <i className="fa fa-paperclip"></i>
                                &nbsp;
                                Send Attachment(s)
                            </button>
                        </div>
                    </div>
                )
            case 'newInvoice':
                return(
                    <div className="display-flex flex-column justify-content-center align-items-center">
                        <h4 className="text-center margin-30-bottom">
                            Review repairs to create invoices from:
                        </h4>

                        { this.repairPreviews(true) }
                    </div>
                )
        }
    };

    closeButton = () => {
        return(
            <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
            >
                Close
            </button>
        )
    };

    renderFooter = () => {
        switch (this.props.action) {
            case "update":
                return(
                    <div>
                        { this.closeButton() }
                        <button
                            type="button"
                            className="btn btn-primary margin-10-right"
                            onClick={() => this.updateRepairs(this.repairStatus.value)}
                        >
                            Save
                        </button>
                    </div>
                )
            default:
                return this.closeButton();
        }
    };

    closeModal = () => {
        $("#repair-action-modal").modal("hide");
    };

    repairPreviews = (grouped = false) => {
        const Repair = ({ repair }) => {
            return(
                <div className="repair">
                    <div>
                        <b>Description:</b>
                    </div>
                    <div className="margin-10-bottom">
                        { repair.description }
                    </div>
                    <div>
                        <b>Grand Total:</b>
                    </div>
                    <div>
                        { Utils.floatToCurrency(repair.grand_total) }
                    </div>
                </div>
            )
        }

        if (grouped) {
            const accounts = this.groupedRepairs();

            return(
                <div className="display-flex flex-column width-100">
                    {
                        Object.keys(accounts).map((account, index) => {
                            return(
                                <div key={index} className="multi-repair-invoice-wrapper">
                                    <h5 className="font-1-1em">
                                        Account: { account }
                                    </h5>
                                    <h5 className="font-1-1em">
                                        Repair(s):
                                    </h5>
                                    <div className="repair-preview-wrapper">
                                        {
                                            accounts[account].repairs.map((repair, index) => {
                                                return(
                                                    <Repair repair={repair} key={index} />
                                                )
                                            })
                                        }
                                    </div>

                                    <h5 className="text-right font-1-1em">
                                        Invoice Total: { Utils.floatToCurrency(accounts[account].repairs.map(repair => repair.grand_total).reduce((a, b) => { return (a + b) }, 0)) }
                                    </h5>

                                    <hr/>
                                </div>
                            )

                        })
                    }

                    <div className="margin-auto">
                        <button className="btn btn-primary" onClick={this.createInvoices}>
                            <i className="fa fa-file-text-o"></i>
                            &nbsp;
                            Create invoices from repair(s)
                        </button>
                    </div>

                </div>
            )
        } else {
            return(
                <div className="display-flex flex-column width-100">
                    {
                        this.props.repairs.map((repair, index) => {
                            return(
                                <div key={index} className="multi-repair-attachments-wrapper">
                                    <h5 className="font-1-1em">
                                        Account: { repair.account.contact_name }
                                    </h5>
                                    <div className="repair-preview-wrapper">
                                        <Repair repair={repair} />
                                    </div>
                                    <hr/>
                                </div>
                            )
                        })
                    }
                </div>
            )
        }

    };

    toggleSelection = (type) => {
        if (this.state.selectedAttachments.includes(type)) {
            this.setState({ selectedAttachments: this.state.selectedAttachments.filter(_type => _type !== type) })
        } else {
            this.setState({ selectedAttachments: [...this.state.selectedAttachments, type] });
        }
    };

    allAccountsHaveValidPhones = () => {
        const results = this.props.repairs.map(repair => {
            const phone = repair.account.contact_phone;
            if (phone) {
                const length = phone.match(/\d/g).length;
                return (length <= 11 && length > 9);
            } else {
                return false;
            }
        });

        return !results.includes(false);
    };

    groupedRepairs = () => {
        const accounts = {};

        this.props.repairs.filter(r => !r.invoice).forEach(repair => {
            if (!accounts[repair.account.contact_name]) {
                accounts[repair.account.contact_name] = {
                    repairs: [repair]
                }
            } else {
                accounts[repair.account.contact_name].repairs.push(repair);
            }
        });

        return accounts;
    };

    updateRepairs = (status) => {
        this.setState({ loading: true });

        let data, success;

        try {
            if (status) {
                data = {
                    ids: this.props.repairs.map(r => r.id),
                    repair: {
                        status
                    }
                };

                success = "Successfully updated repairs";
            } else if (this.props.action == "attachments") {
                data = {
                    ids: this.props.repairs.map(r => r.id),
                    repair: {
                        attachments_to_deliver: this.state.selectedAttachments
                    }
                }

                success = "Repair attachment(s) successfully queued for delivery";
            }
        } catch(e) {
            debugger;
        }

        $.ajax("/repairs/batch_update.json", {
            method: "PUT",
            data
        }).done(repairs => {
            if (this.props.action !== "attachments") this.props.callback();

            this.closeModal();

            toast.success(success, {
                position: toast.POSITION.TOP_CENTER,
            });
        }).fail(() => {
            toast.error("Something went wrong...", {
                position: toast.POSITION.TOP_CENTER
            });
        }).always(() => {
            this.setState({ loading: false });
        });
    };

    createInvoices = () => {
        this.setState({ loading: true });

        const promises = Object.keys(this.groupedRepairs()).map(_account => {
            const account = this.groupedRepairs()[_account];

            return new Promise((resolve, reject) => {
                $.ajax({
                    method: "POST",
                    url: "/invoices.json",
                    data: {
                        invoice: {
                            account_id: account.repairs[0].account.id,
                            // this will take into account if user is in different timezone than what their company is configured for at the time of creating this invoice (making sure we get to utc relative to their configured timezone)
                            due_date: moment().utc().tz(this.props.timezone).endOf("day").utc().format("YYYY-MM-DD HH:mm"),
                            type: "repairs",
                            payment_status: "draft",
                            line_items_attributes: account.repairs.map(repair => {
                                return {
                                    name: "Repair",
                                    description: repair.client_notes ? repair.client_notes : repair.description,
                                    quantity: 1,
                                    repair_id: repair.id,
                                    cost: repair.grand_total
                                }
                            })
                        }
                    }
                }).done(invoice => {
                    const repairs = account.repairs.map(repair => {
                        repair.invoice = invoice;
                        return repair;
                    });

                    resolve({ repairs });
                }).fail(() => {
                    toast.error("Something went wrong...", {
                        position: toast.POSITION.TOP_CENTER
                    });

                    reject();
                });
            });
        });

        Promise.all(promises).then(_repairs => {
            const repairs = [];
            _repairs.forEach(obj => repairs.concat(obj.repairs));

            this.props.callback();

            this.closeModal();

            toast.success("Invoice(s) successfully created", {
                position: toast.POSITION.TOP_CENTER,
            });
        });
    };

    destroyRepairs = () => {
        this.setState({ loading: true });

        $.ajax("/repairs/batch_destroy.json", {
            method: "DELETE",
            data: {
                ids: this.props.repairs.map(r => r.id)
            }
        }).done(res => {
            this.props.destroyRepairs(this.props.repairs.map(r => r.id));

            this.closeModal();

            toast.success("Repairs have been successfully queued to be destroyed!", {
                position: toast.POSITION.TOP_CENTER
            });
        }).fail(() => {
            toast.error("Something went wrong...", {
                position: toast.POSITION.TOP_CENTER
            });
        }).always(() => {
            this.setState({ loading: false });
        });
    };

    render() {
        return(
            <div
                id="repair-action-modal"
                className="modal fade"
                tabIndex="-1"
                role="dialog"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title float-left">{ this.modalTitle() }</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        { !this.state.loading &&
                            <div className="modal-body">
                                { this.renderBody() }
                            </div>
                        }
                        { this.state.loading &&
                            <div className="display-flex justify-content-center margin-20">
                                <i className="fa fa-spinner fa-pulse fa-3x fa-fw align-self-center flex-1"></i>
                            </div>
                        }
                        <div className="modal-footer">
                            { this.renderFooter() }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
