import React from 'react';
import PropTypes from 'prop-types';

import MaskedInput from 'react-text-mask';

import Utils from '../../Utils';

export default class SignUpForm extends React.Component {
    constructor(props) {
        super(props);

        const baseState = {
            stage: "companyInfo",
            companyName: "",
            companyAddress: "",
            companyCity: "",
            companyState: "",
            companyZip: "",
            companyReferral: "",
            userName: "",
            userEmail: "",
            userPhone: "",
            userPassword: "",
            errors: []
        }

        baseState.validations = {
            company: {
                companyName: false,
                companyAddress: false,
                companyCity: false,
                companyState: false,
                companyZip: false,
                companyReferral: false
            },
            user: {
                userName: false,
                userEmail: false,
                userPhone: false,
                userPassword: false,
            }
        }

        this.state = baseState;
    }

    submitCompany = () => {
        const props = ["Name", "Address", "City", "State", "Zip", "Referral"];

        const validations = {};
        let stage = "companyInfo";

        props.forEach(prop => {
            validations[`company${prop}`] = (!this.state[`company${prop}`].length > 0);
        });

        if (!Object.values(validations).includes(true)) {
            stage = "userInfo";
        }

        this.setState({ validations: { ...this.state.validations, company: validations }, stage });
    };

    submitUser = () => {
        const props = ["Name", "Email", "Password"];

        const validations = {};

        props.forEach(prop => {
            validations[`user${prop}`] = (!this.state[`user${prop}`].length > 0);
        });

        validations.userPhone = ((this.state.userPhone.match(/\d+/g) || []).join("").length !== 10);

        if (!Object.values(validations).includes(true)) {
            this.signUp();
        } else {
            this.setState({ validations: { ...this.state.validations, user: validations } });
        }
    };

    signUp = () => {
        this.setState({ stage: "loading", errors: [] });

        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ 'address': `${this.state.companyAddress} ${this.state.companyCity} ${this.state.companyState}, ${this.state.companyZip}` }, (results, status) => {
            let location = {};
            if (status === "OK") {
                const geometry = results[0].geometry;
                location = { lat: geometry.location.lat().toString(), lng: geometry.location.lng().toString() }
            }
            $.ajax({
                method: "POST",
                url: "/onboarding/register",
                contentType: "application/json",
                data: JSON.stringify({
                    company: {
                        name: this.state.companyName,
                        referral_source: this.state.companyReferral,
                        location: {
                            address: this.state.companyAddress,
                            city: this.state.companyCity,
                            state: this.state.companyState,
                            zip: this.state.companyZip,
                            lat: location.lat,
                            lng: location.lng
                        },
                        users_attributes: [{
                            name: this.state.userName,
                            email: this.state.userEmail,
                            phone: this.state.userPhone,
                            password: this.state.userPassword,
                            company_contact: true
                        }]
                    }
                })
            }).done(res => {
                window.location = "/accounts"
            }).fail(res => {
                this.setState({ errors: res.responseJSON.errors, stage: "companyInfo" })
            });
        });
    };

    render() {
        const headerText = this.state.stage === "companyInfo" ? "Company Info:" : "Your User Info:"
        return(
            <div className="panel panel-primary">
                <div className="panel-heading">
                    <h3 className="panel-title">
                        { headerText }
                    </h3>
                </div>
                <div className="panel-body">
                    { Object.keys(this.state.errors).length > 0 &&
                        <div className="alert alert-warning">
                            <ul>
                                { Object.keys(this.state.errors).map((error, index) => {
                                    return(
                                        <li key={index}>
                                            <b>{ Utils.humanize(error) }: </b>
                                            { this.state.errors[error] }
                                        </li>
                                    )
                                }) }
                            </ul>
                        </div>
                    }
                    { this.state.stage === "companyInfo" &&
                        <div>
                            <div className="row">
                                <div className="form-group col-md-12">
                                    <input
                                        className="form-control"
                                        type="text"
                                        onChange={(e) => this.setState({ companyName: e.target.value })}
                                        value={this.state.companyName}
                                        placeholder="Company Name"
                                    />
                                    { this.state.validations.company.companyName &&
                                        <div className="validation-wrapper">
                                            <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                                            Company Name is required
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group col-md-12">
                                    <input
                                        type="text"
                                        className="form-control"
                                        onChange={(e) => this.setState({ companyAddress: e.target.value })}
                                        value={this.state.companyAddress}
                                        placeholder="Street Address"
                                    />
                                    { this.state.validations.company.companyAddress &&
                                        <div className="validation-wrapper">
                                            <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                                            Street Address is required
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group col-md-12">
                                    <input
                                        type="text"
                                        className="form-control"
                                        onChange={(e) => this.setState({ companyCity: e.target.value })}
                                        value={this.state.companyCity}
                                        placeholder="City"
                                    />
                                    { this.state.validations.company.companyCity &&
                                        <div className="validation-wrapper">
                                            <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                                            City is required
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group col-md-12">
                                    <input
                                        type="text"
                                        className="form-control"
                                        onChange={(e) => this.setState({ companyState: e.target.value })}
                                        value={this.state.companyState}
                                        placeholder="State"
                                    />
                                    { this.state.validations.company.companyState &&
                                        <div className="validation-wrapper">
                                            <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                                            State is required
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group col-md-12">
                                    <input
                                        type="text"
                                        className="form-control"
                                        onChange={(e) => this.setState({ companyZip: e.target.value })}
                                        value={this.state.companyZip}
                                        placeholder="Zip"
                                    />
                                    { this.state.validations.company.companyZip &&
                                        <div className="validation-wrapper">
                                            <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                                            Zip is required
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group col-md-12">
                                    <input
                                        type="text"
                                        className="form-control"
                                        onChange={(e) => this.setState({ companyReferral: e.target.value })}
                                        value={this.state.companyReferral}
                                        placeholder="How did you hear about AquaSuite?"
                                    />
                                    { this.state.validations.company.companyReferral &&
                                        <div className="validation-wrapper">
                                            <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                                            Please let us know how you heard about AquaSuite
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="float-right">
                                <button
                                    className="btn btn-primary"
                                    onClick={this.submitCompany}
                                >
                                    Next
                                </button>
                            </div>
                        </div>
                    }
                    { this.state.stage === "userInfo" &&
                        <div>
                            <div className="row">
                                <div className="form-group col-md-12">
                                    <input
                                        type="text"
                                        className="form-control"
                                        onChange={(e) => this.setState({ userName: e.target.value })}
                                        value={this.state.userName}
                                        placeholder="Name"
                                    />
                                    { this.state.validations.user.userName &&
                                        <div className="validation-wrapper">
                                            <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                                            Name is required
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group col-md-12">
                                    <input
                                        type="text"
                                        className="form-control"
                                        onChange={(e) => this.setState({ userEmail: e.target.value })}
                                        value={this.state.userEmail}
                                        placeholder="Email"
                                    />
                                    { this.state.validations.user.userEmail &&
                                        <div className="validation-wrapper">
                                            <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                                            Email is required
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group col-md-12">
                                    <MaskedInput
                                        className="form-control"
                                        mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                        placeholder="Contact Phone Number"
                                        onChange={(e) => this.setState({ userPhone: e.target.value })}
                                        value={this.state.userPhone}
                                    />
                                    { this.state.validations.user.userPhone &&
                                        <div className="validation-wrapper">
                                            <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                                            Phone is required
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group col-md-12">
                                    <input
                                        type="password"
                                        className="form-control"
                                        onChange={(e) => this.setState({ userPassword: e.target.value })}
                                        value={this.state.userPassword}
                                        placeholder="Password"
                                    />
                                    { this.state.validations.user.userPassword &&
                                        <div className="validation-wrapper">
                                            <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                                            Password is required
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="text-center">
                                By signing up for an account you are agreeing to the <a href="https://www.useaquasuite.com/tos" target="_blank">Terms of Service</a>
                            </div>

                            <hr />

                            <div className="float-right">
                                <button
                                    className="btn btn-primary"
                                    onClick={() => this.setState({ stage: "userInfo" })}
                                    onClick={this.submitUser}
                                >
                                    Next
                                </button>
                            </div>
                        </div>
                    }
                    { this.state.stage === "loading" &&
                        <div className="display-flex flex-column">
                            <i className="fa fa-spinner fa-pulse fa-3x fa-fw align-self-center flex-1"></i>
                            <h4 className="text-center margin-20-top">
                                Please wait while we get your account setup...
                            </h4>
                        </div>
                    }
                </div>
            </div>
        )
    }
}
