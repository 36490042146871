export default {
    repairTagColors: ["#00B8D9", "#FF8B00", "#5243AA", "#FF5630", "#0052CC", "#FFC400", "#253858", "#666666"],
    repairItemGrandTotal(repair) {
        const repairItems = (repair.repair_items || repair.repair_items_attributes || []);
        if (repairItems.length > 0) {
            const riTotals = repairItems.map(ri => (parseFloat(ri.price || 0) * parseFloat(ri.quantity || 0)));

            const riTotal = riTotals.reduce((a,b) => a + b);

            return riTotal;
        } else {
            return 0.0;
        }
    }
}
