import React from 'react';
import PropTypes from 'prop-types';

import Appointment from './Appointment';

export default class AppointmentDaySelect extends React.Component {
    static propTypes = {
        getMasterState: PropTypes.func.isRequired,
        updateMasterState: PropTypes.func.isRequired,
        appointments_attributes: PropTypes.array.isRequired,
        timezone: PropTypes.string.isRequired,
        serviceTechs: PropTypes.array.isRequired
    }

    constructor(props) {
        super(props);

        this.baseState = {
            appointments_attributes: props.appointments_attributes,
        }

        this.state = this.baseState;
    }

    componentWillReceiveProps(props) {
        let appointments_attributes;
        this.setState({ appointments_attributes } = { ...props });
    }

    invalidAppointments() {
        const results = this.state.appointments_attributes.map((appt, index) => {
            return this[`appointment_${index}`].checkInvalid();
        });

        return results.includes(true);
    }

    addAppointment = () => {
        const appointments_attributes = this.props.getMasterState().appointments_attributes;
        this.props.updateMasterState({ appointments_attributes: [...appointments_attributes, {}] });
    };

    updateAppointment = (_key) => {
        if (this[`appointment_${_key}`]) {
            const appointment = this[`appointment_${_key}`].getAppointment();

            const appointments_attributes = this.props.getMasterState().appointments_attributes.map((appt, index) => {
                if (index === _key) {
                    return appointment
                } else {
                    return appt
                }
            });

            this.props.updateMasterState({ appointments_attributes });
        }
    };

    removeAppointment = (_key) => {
        let appointments_attributes = this.props.getMasterState().appointments_attributes.map((appt, index) => {
            if (_key !== index) {
                return this[`appointment_${index}`].getAppointment();
            } else if (_key === index && appt.id) {
                return { ...this[`appointment_${index}`].getAppointment(), _destroy: true }
            }
        });

        appointments_attributes = appointments_attributes.filter(appt => appt); // filter out undefiend

        this.props.updateMasterState({ appointments_attributes });
    };

    hasSuggestion = () => {
        return !!(this.props.appointments_attributes.find(appt => appt.suggestedScheduling));
    };

    render() {
        return(
            <div className="appointment-day-select-wrapper">
                {
                    this.state.appointments_attributes.map((appt, index) => {
                        return(
                            <Appointment
                                key={index}
                                _key={index}
                                appointment={appt}
                                timezone={this.props.timezone}
                                serviceTechs={this.props.serviceTechs}
                                updateAppointment={this.updateAppointment}
                                removeAppointment={this.removeAppointment}
                                ref={(ref) => this[`appointment_${index}`] = ref}
                                hasSuggestion={this.hasSuggestion()}
                            />
                        )
                    })
                }

                { this.props.appointments_attributes.length > 0 &&
                    <div className="width-100 margin-20-top">
                        <hr/>
                    </div>
                }
                <div>
                    <a
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            this.addAppointment();
                        } }
                    >
                        Add Service Day
                    </a>
                </div>
            </div>
        )
    }
}
