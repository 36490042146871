import Timeout from '@main/Timeout';
import { toast } from 'react-toastify';

export default {
    activeRemindersFromAccount(account) {
        return account.scheduled_route_reminders.filter(reminder => {
            const current = moment();
            const start = moment(reminder.start);
            const nextOccurrence = moment((reminder.occurrence_options || {}).next_occurrence);

            if (reminder.occurrence_options) {
                return current >= nextOccurrence
            } else {
                return current >= start
            }
        });
    },

    saveServiceStop(serviceStop, accountId) {
        return new Promise((resolve, reject) => {
            let new_route_reminder_records = [];
            if ((serviceStop.new_route_reminder_records || []).length > 0) {
                serviceStop.new_route_reminder_records.forEach(reminder => {
                    const filteredReminder = (({ id, scheduled_route_reminder_id, description, remarks, acknowledged }) => ({ scheduled_route_reminder_id: id || scheduled_route_reminder_id, description, remarks, acknowledged }))(reminder);
                    new_route_reminder_records.push(filteredReminder);
                })
            }

            const image_ids = (serviceStop.images || []).map(i => i.id);
            let service_stop = { ...serviceStop, new_route_reminder_records, image_ids };

            const images = service_stop.images;
            delete service_stop.images;

            let url = `/accounts/${accountId}/service_stops`;
            const method = serviceStop.id ? "PUT" : "POST";
            if (serviceStop.id) url += `/${serviceStop.id}`
            url += ".json";

            $.ajax({
                method,
                url,
                contentType: "application/json",
                data: JSON.stringify({ service_stop }),
                timeout: Timeout.RESOURCE,
                success(savedServiceStop) {
                    service_stop = { ...savedServiceStop, images }
                    resolve(service_stop)
                },
                error(jqXHR, textStatus, errorThrown) {
                    if (textStatus === "error" && (jqXHR.responseJSON || {}).errors) {
                        toast.error(`Unable to save Service Stop ${jqXHR.responseJSON.errors}`, {
                            position: toast.POSITION.TOP_CENTER
                        });
                    }

                    service_stop.images = images;
                    reject({ ...service_stop, timeout: true });
                }
            });
        });
    }
}
