import React, { Fragment, useContext, useState, useEffect, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';

import AppointmentList from './AppointmentList';
import RouteTicket from './Ticket';
import Chart from './Chart';
import RouteReminderModal from '../../RouteReminders/RecordsModal';

import Utils from 'Utils';
import Timeout from '@main/Timeout';
import { toast } from 'react-toastify';
import { DashboardContext } from '../Index';
import RouteUtils from '@main/components/NewServiceStops/Utils';
import RepairItemTicketUtils from '@main/components/Repairs/Items/Utils';
import RepairTicketUtils from '@main/components/Repairs/Ticket/Utils';
import ImageUtils from '@shared/components/ImageArea/Utils';

export const RouteContext = React.createContext();

const Route = React.forwardRef((props, ref) => {
    const [appointments, setAppointments] = useState([]);
    const [completedCount, setCompletedCount] = useState(0);
    const [incompletedCount, setIncompletedCount] = useState(0);
    const [currentApptPage, setCurrentApptPage] = useState(1);
    const [totalApptPages, setTotalApptPages] = useState(null);
    const [originalAppointments, setOriginalAppointments] = useState([]);
    const [fetchingAppointments, setFetchingAppointments] = useState(true);
    const [selectedAppointment, setSelectedAppointment] = useState(null);
    const [arbitrarySelectedAppointment, setArbitrarySelectedAppointment] = useState(null);
    const [showRouteReminderModal, setShowRouteReminderModal] = useState(false);
    const [routeReminderModalError, setRouteReminderModalError] = useState(false);
    const context = useContext(DashboardContext);

    useImperativeHandle(ref, () => ({
        refreshAppointments: fetchAppointments
    }))

    useEffect(() => {
        fetchAppointments()
    }, []);

    const fetchAppointments = (page = 1) => {
        const q = {};

        if (props.appointmentType !== "all-lapsed") {
            q.user_id_eq = context.currentUser.id;
        } else {
            q.user_id_not_eq = context.currentUser.id;
        }

        if (props.appointmentType === "current") {
            q.start_gteq =  moment().tz(context.currentCompany.timezone).startOf("day").utc().format();
            q.end_lteq = moment().tz(context.currentCompany.timezone).endOf("day").utc().format();
        } else if (props.appointmentType === "lapsed" || props.appointmentType === "all-lapsed") {
            q.end_lt = moment().tz(context.currentCompany.timezone).startOf("day").utc().format();
            q.completed_null = true;
        }

        q.type_in = ["service_stop", "repair", "repair_item"];

        return new Promise(resolve => {
            $.ajax({
                method: "GET",
                url: "/appointments.json",
                data: {
                    q,
                    page,
                    route: true
                }
            }).done(res => {
                setCurrentApptPage(res.current_page);
                setTotalApptPages(res.total_pages);
                setCompletedCount(res.completed_count);
                setIncompletedCount(res.incompleted_count)
                setAppointments([...appointments, ...res.appointments]);
                setOriginalAppointments(res.appointments);

                resolve({ totalPages: res.total_pages, currentPage: res.current_page });
            }).fail(() => {
                toast.error("Unable to fetch Appointments...", {
                    position: toast.POSITION.TOP_CENTER
                });
            }).always(() => {
                setFetchingAppointments(false);
            });
        });
    };

    const setSelectedAppointmentProxy = (appt) => {
        setSelectedAppointment(appt);
        setArbitrarySelectedAppointment(appt);
    };

    const selectedAppointmentIndex = () => {
        if (!selectedAppointment) {
            return undefined;
        } else {
            return appointments.findIndex(appt => appt.id === selectedAppointment.id);
        }
    };

    const firstIncompleteAppointment = () => {
        let filteredAppointments;
        if (selectedAppointment) {
            filteredAppointments = appointments.filter(appt => appt.id !== selectedAppointment.id);
        } else {
            filteredAppointments = appointments;
        }

        return filteredAppointments.find(appt => !appt.completed && !appt.status);
    };

    const displayRouteReminderModal = () => {
        setShowRouteReminderModal(false);
        setTimeout(() => {
            setShowRouteReminderModal(true);
        });
    };

    const currentRouteReminders = (appointment = selectedAppointment) => {
        const serviceStop = appointment.service_stop || {};

        if ((serviceStop.route_reminder_records || []).length > 0) {
            return serviceStop.route_reminder_records;
        } else if ((serviceStop.new_route_reminder_records || []).length > 0) {
            return serviceStop.new_route_reminder_records;
        } else {
            return appointment.account.scheduled_route_reminders;
        }
    };

    const updateRouteReminders = (new_route_reminder_records) => {
        updateAppointment({ ...selectedAppointment, service_stop: { ...selectedAppointment.service_stop, new_route_reminder_records } });
    };

    const allRouteRemindersValid = () => {
        setRouteReminderModalError(false);
        const reminders = currentRouteReminders().map(reminder => {
            reminder.invalid = (reminder.remarks || "").replace(/ /g, "").length < 1;
            return reminder;
        });

        if (reminders.filter(r => r.invalid).length > 0) {
            setRouteReminderModalError(true);
            displayRouteReminderModal();
            return false
        }

        return true;
    };

    const hasSyncableAppointments = () => {
        return syncableAppointments().length > 0;
    };

    const appointmentSyncable = (appt) => {
        const apptResource = (appt[appt.type] || {});
        const apptImages = apptResource.images || [];

        if (appt.status && (appt.status === "needs_to_sync" || appt.status === "syncing")) return true
        if (appt.completed && (apptImages.some(i => i.timeout))) return true
        return false;
    };

    const syncableAppointments = () => {
        return appointments.filter(appt => appointmentSyncable(appt))
    };

    const appointmentsEditable = () => {
        if (props.appointmentType === "current") return true;

        return false;
    };

    const updateAppointment = (appointment) => {
        setAppointments(prevAppts => {
            return prevAppts.map(appt => appt.id === appointment.id ? { ...appointment } : { ...appt })
        });

        const prevAppt = appointments.find(appt => appt.id === appointment.id);
        if (!prevAppt.completed && appointment.completed) {
            setCompletedCount(completedCount + 1);
            setIncompletedCount(incompletedCount - 1);
        }

        if (appointment.id === selectedAppointment.id) setSelectedAppointment(appointment);
    };

    const saveAppointments = (successCallback) => {
        if (!navigator.onLine) {
            toast.warn("No Internet Connection detected, please try again once internet is available.", {
                position: toast.POSITION.TOP_CENTER
            });

            return false;
        }

        const sortedAppointments = Utils.rearrangeAppointments(appointments, context.companySettings.service_days, context.currentCompany.timezone);

        const newAppointments = {};
        sortedAppointments.forEach(appt => {
            newAppointments[appt.id] = {
                start: appt.start,
                end: appt.end
            }
        });

        $.ajax({
            method: "PUT",
            url: "/appointments/batch_update.json",
            data: { appointments: newAppointments },
            timeout: Timeout.RESOURCE,
            success: () => {
                toast.success("Successfully updated Appointment order!", {
                    position: toast.POSITION.TOP_CENTER
                });

                setOriginalAppointments(sortedAppointments);
                successCallback();
            },
            error: (jqXHR, textStatus, errorThrown) => {
                if (textStatus === "timeout") {
                    toast.warn("No Internet Connection detected, please try again once internet is available.", {
                        position: toast.POSITION.TOP_CENTER
                    });
                } else {
                    toast.error("Unable to update Appointment order...", {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            }
        });
    };

    const syncAppointments = () => {
        if (!navigator.onLine) {
            toast.warn("No Internet Connection detected, please try again once internet is available.", {
                position: toast.POSITION.TOP_CENTER
            });

            return false;
        }

        syncableAppointments().forEach(appointment => {
            updateAppointment({ ...appointment, status: "syncing" });
            const resource = appointment[appointment.type];
            let saveFunction;

            switch(appointment.type) {
                case 'service_stop':
                    saveFunction = RouteUtils.saveServiceStop;
                    break;
                case 'repair_item':
                    saveFunction = RepairItemTicketUtils.saveRepairTicket;
                    break;
                case 'repair':
                    saveFunction = RepairTicketUtils.saveRepairTicket;
                    break;
            }

            if (resource.timeout) {
                saveFunction(resource, appointment.account_id).then(savedResource => {
                    const newAppointment = { ...appointment, [appointment.type]: { ...savedResource }, completed: true };
                    newAppointment.status = (savedResource.images || []).some(i => i.timeout) ? "syncing" : null;

                    updateAppointment({ ...newAppointment });

                    const timedOutImages = (savedResource.images || []).filter(i => i.timeout);

                    if (timedOutImages.length > 0) {
                        ImageUtils.process(timedOutImages, appointment.type, savedResource.id, (images) => {
                            updateAppointment({ ...newAppointment, [appointment.type]: { ...savedResource, images }, status: images.some(i => i.timeout) ? "needs_to_sync" : null });
                        });
                    }
                }).catch(failedResource => {
                    updateAppointment({ ...appointment, [appointment.type]: { ...failedResource }, status: "needs_to_sync" });
                });
            } else if ((resource.images || []).some(i => i.timeout)) {
                ImageUtils.process(resource.images.filter(i => i.timeout), appointment.type, resource.id, (syncedImages) => {
                    const  images = [...resource.images.filter(i => !i.timeout), ...syncedImages];

                    updateAppointment({ ...appointment, [appointment.type]: { ...resource, images }, status: images.some(i => i.timeout) ? "needs_to_sync" : null });
                });
            }
        });
    };

    const modalActionButtonText = () => {
        if (props.appointmentType === "current") {
            if (completedCount < appointments.length) {
                return completedCount === 0 ? "Start Route" : "Resume Route";
            } else {
                return "Review Route"
            }
        } else {
            if (completedCount < appointments.length) {
                return "Finish Appointments"
            } else {
                return "Review"
            }
        }
    };

    const widgetIconUrl = () => {
        if (props.appointmentType !== "current") {
            return "https://s3.amazonaws.com/superswimbros.dev/deadline.svg";
        } else {
            return "https://s3.amazonaws.com/superswimbros.dev/route.svg";
        }
    };

    const focusWidget = () => {
        context.hideDashboard();
        const appointment = firstIncompleteAppointment();
        setSelectedAppointment(appointment);
        context.setActiveWidget(props.widgetClass);
        context.expandRouteWidget(true);

        setTimeout(() => {
            if (appointment && appointment.type === "service_stop" && currentRouteReminders(appointment).filter(rr => !rr.remarks && !rr.acknowledged).length > 0) {
                setTimeout(() => {
                    displayRouteReminderModal();
                }, 500)
            }
        });
    };

    return(
        <RouteContext.Provider
            value={{
                appointments,
                setAppointments,
                originalAppointments,
                setOriginalAppointments,
                firstIncompleteAppointment,
                displayRouteReminderModal,
                currentRouteReminders,
                allRouteRemindersValid,
                selectedAppointment,
                selectedAppointmentIndex,
                setSelectedAppointment,
                arbitrarySelectedAppointment,
                setSelectedAppointmentProxy,
                hasSyncableAppointments,
                appointmentSyncable,
                syncAppointments,
                syncableAppointments,
                appointmentsEditable,
                saveAppointments,
                updateAppointment,
                completedCount,
                fetchAppointments,
                currentApptPage,
                totalApptPages
            }}
        >
            <Fragment>
                <div className="panel panel-default">
                    <div className="panel-body display-flex">
                        <div className={`panel-innard display-flex flex-column ${(appointments.length === 0 || fetchingAppointments) ? "full-width" : ""}`}>
                            <div className="header display-flex margin-10-bottom">
                                <img
                                    src={widgetIconUrl()}
                                    alt="Route"
                                    className="widget-icon flex-1"
                                />
                                <h5 className="text-center flex-2">
                                    { props.headerText }:
                                </h5>
                            </div>
                            { fetchingAppointments &&
                                <div className="well display-flex flex-column justify-content-center height-100 no-margin color-service-stop">
                                    <h4 className="text-center">
                                        Loading Appointments...
                                    </h4>
                                    <div className="text-center">
                                        <i className="fa fa-spinner fa-pulse fa-fw fa-3x align-self-center flex-1"></i>
                                    </div>
                                </div>
                            }
                            { (!fetchingAppointments && appointments.length === 0) &&
                                <div className="well display-flex align-items-center height-100 no-margin">
                                    <h4 className="text-center color-service-stop">
                                        No Appointments found
                                    </h4>
                                </div>
                            }
                            { (!fetchingAppointments && appointments.length > 0) &&
                                <Fragment>
                                    <Chart
                                        completedCount={completedCount}
                                        incompletedCount={incompletedCount}
                                        syncableCount={syncableAppointments().length}
                                        appointmentCount={completedCount + incompletedCount}
                                        appointmentType={props.appointmentType}
                                    />
                                    { (!context.dashboardHidden || context.activeWidget !== props.widgetClass) &&
                                        <div className="modal-action-wrapper display-flex justify-content-center margin-10-top">
                                            <button onClick={focusWidget} className="btn btn-lg btn-success">
                                                { modalActionButtonText() }
                                            </button>
                                        </div>
                                    }
                                </Fragment>
                            }
                        </div>
                        { appointments.length > 0 &&
                            <div className="expanded-area">
                                <AppointmentList
                                    editable={appointmentsEditable()}
                                    selectable={context.activeWidget === props.widgetClass}
                                    widgetClass={props.widgetClass}
                                    appointmentType={props.appointmentType}
                                />
                            </div>
                        }
                    </div>
                </div>
                { (context.dashboardHidden && context.activeWidget === props.widgetClass && selectedAppointment) &&
                    <Fragment>
                        <RouteTicket widgetClass={props.widgetClass} />
                        { (selectedAppointment || {}).type === "service_stop" &&
                            <RouteReminderModal
                                showModal={showRouteReminderModal}
                                routeReminders={currentRouteReminders()}
                                error={routeReminderModalError}
                                update={updateRouteReminders}
                            />
                        }
                    </Fragment>
                }
            </Fragment>
        </RouteContext.Provider>
    )
})

Route.propTypes = {
    appointmentType: PropTypes.string.isRequired,
    headerText: PropTypes.string.isRequired,
    widgetClass: PropTypes.string.isRequired,
    newCurrentAppointments: PropTypes.array
}

export default Route;
