import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import AWS from 'aws-sdk';
import LoadingUtils from './LoadingUtils';
import { toast } from 'react-toastify';

export default class AqsQuill extends React.Component {
    static propTypes = {
        loadingContainerSelector: PropTypes.string.isRequired,
        content: PropTypes.string,
        placeholder: PropTypes.string,
        specialInserts: PropTypes.object,
        updateCallback: PropTypes.func
    }

    constructor() {
        super();

        this.baseState = {
            selectedSpecialInsert: null
        }

        AWS.config.update({
            region: 'us-east-1',
            credentials: new AWS.CognitoIdentityCredentials({
                IdentityPoolId: 'us-east-1:431956c2-7ce0-4f70-b69e-208a7a15f5d9'
            })
        });

        this.s3 = new AWS.S3({
            apiVersion: '2006-03-01',
            params: { Bucket: 'aquasuite-latest-public' }
        });

        this.state = this.baseState;
    }

    componentDidMount() {
        const FontStyle = Quill.import('attributors/style/font');
        const ColorStyle = Quill.import('attributors/style/color');
        const BackgroundStyle = Quill.import('attributors/style/background');
        const AlignStyle = Quill.import('attributors/style/align');
        const SizeStyle = Quill.import('attributors/style/size');

        Quill.register(FontStyle, true);
        Quill.register(ColorStyle, true);
        Quill.register(BackgroundStyle, true);
        Quill.register(AlignStyle, true);
        Quill.register(SizeStyle, true);

        this.quill = new Quill("#editor-container", {
            modules: {
                toolbar: "#editor-toolbar",
            },
            bounds: "#editor-toolbar",
            placeholder: this.props.placeholder || "",
            theme: "snow"
        });

        if (this.props.content) {
            this.insertContent(this.props.content);
        }

        this.quill.getModule("toolbar").addHandler("image", () => {
            this.fileInput.click();
        });

        if (this.props.updateCallback) {
            this.quill.on('text-change', (eventName, ...args) => {
                this.props.updateCallback(this.getBodyContent());
            });
        }

        const tooltip = this.quill.theme.tooltip;
        const input = tooltip.root.querySelector("input[data-link]");
        input.dataset.link = "https://www.example.com"
    }

    insertContent = (content) => {
        this.quill.clipboard.dangerouslyPasteHTML(content, "API");
    };

    getBodyContent() {
        return this.quill.root.innerHTML;
    }

    getRandomString = () => {
        let array = new Uint32Array(2);
        return ((window.crypto || window.msCrypto).getRandomValues(array).join(""));
    };

    handleImageUpload = (e) => {
        LoadingUtils.addLoader(this.props.loadingContainerSelector, "Uploading Image...");

        const file = e.target.files[0];
        const format = file.type.split("/")[1];
        const key = `${this.getRandomString()}.${format}`

        if (/^image\//.test(file.type)) {
            this.s3.upload({
                Key: key,
                Body: file,
                ACL: 'public-read'
            }, (err, data) => {
                if (err) {
                    console.warn(err);

                    toast.error("Unable to uploaded image", {
                        position: toast.POSITION.TOP_CENTER
                    });
                } else {
                    const range = this.quill.getSelection();
                    this.quill.insertEmbed(range.index, 'image', data.Location);

                    LoadingUtils.clearElements(this.props.loadingContainerSelector);

                    toast.success("Successfully uploaded image", {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            });
        }
    };

    insertSpecial = () => {
        if (this.state.selectedSpecialInsert) {
            const selection = this.quill.getSelection() || { index: 0 };

            if (this.state.selectedSpecialInsert === "repair_approval_buttons") {
                const approveButton = "<a href='%{repair_approve_link}'><img src='https://s3.amazonaws.com/aquasuite-latest-public/approve-button.png' /></a>";
                const denyButton = "<a href='%{repair_deny_link}'><img src='https://s3.amazonaws.com/aquasuite-latest-public/deny-button.png' /></a>";

                this.quill.clipboard.dangerouslyPasteHTML(selection.index, approveButton, true);
                this.quill.clipboard.dangerouslyPasteHTML(selection.index + 1, denyButton, true);
            } else {
                this.quill.insertText(selection.index, `%{${this.state.selectedSpecialInsert}}`, true);
            }
        }
    };

    render() {
        return(
            <Fragment>
                { Object.keys(this.props.specialInserts || {}).length > 0 &&
                    <Fragment>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group no-margin-bottom">
                                    <label>Special Inserts:</label>
                                    <select className="form-control" onChange={(e) => this.setState({ selectedSpecialInsert: e.target.value }) }>
                                        <option value="">Select insert...</option>
                                        {
                                            Object.keys(this.props.specialInserts).map((key, index) => {
                                                return(
                                                    <option key={key} value={key}>{ this.props.specialInserts[key] }</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 margin-10-top margin-10-bottom">
                                <button
                                    className="btn btn-info"
                                    type="button"
                                    onClick={this.insertSpecial}
                                >
                                    Insert
                                </button>
                            </div>
                        </div>
                    </Fragment>
                }
                <div id="editor-toolbar">
                    <span className="ql-formats">
                        <select className="ql-size" value="">
                            <option value="10px">Small</option>
                            <option value="">Normal</option>
                            <option value="18px">Large</option>
                            <option value="32px">Huge</option>
                        </select>
                    </span>
                    <span className="ql-formats">
                        <button className="ql-bold" type="button"></button>
                        <button className="ql-italic" type="button"></button>
                        <button className="ql-underline" type="button"></button>
                        <button className="ql-strike" type="button"></button>
                    </span>
                    <span className="ql-formats">
                        <select className="ql-color" value="">
                            <option value=""></option>
                            <option value="red"></option>
                            <option value="orange"></option>
                            <option value="yellow"></option>
                            <option value="green"></option>
                            <option value="blue"></option>
                            <option value="purple"></option>
                        </select>
                        <select className="ql-background" value="">
                            <option value=""></option>
                            <option value="red"></option>
                            <option value="orange"></option>
                            <option value="yellow"></option>
                            <option value="green"></option>
                            <option value="blue"></option>
                            <option value="purple"></option>
                        </select>
                    </span>
                    <span className="ql-formats">
                        <select className="ql-align" value="">
                            <option value=""></option>
                            <option value="center"></option>
                            <option value="right"></option>
                            <option value="justify"></option>
                        </select>
                    </span>
                    <span className="ql-formats">
                        <button type="button" className="ql-link"></button>
                        <button type="button" className="ql-image"></button>
                    </span>
                </div>
                <div id="editor-container">
                </div>
                <input
                    className="display-none"
                    type="file"
                    ref={(e) => this.fileInput = e}
                    onChange={this.handleImageUpload}
                />
            </Fragment>
        )
    }
}
