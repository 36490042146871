import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Utils from '../../Utils';

export default class InvoicePreview extends React.Component {
    static propTypes = {
        invoice: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);

        this.baseState = {
            activated: false,
        }

        this.state = this.baseState;
    }

    activate = () => {
        this.setState({ activated: !this.state.activated });
    };

    render() {
        return(
            <Fragment>
                <div
                    className={`panel panel-default cursor-pointer ${this.state.activated ? "no-margin-bottom" : ""}`}
                    onClick={this.activate}
                >
                    <div className="panel-body display-flex">
                        <div className="padding-15-right display-flex align-items-center" >
                            <span className={`glyphicon glyphicon-chevron-down ${this.state.activated ? "" : "rotate-right"}`} ref={(el) => this.chevron = el}></span>
                        </div>
                        <div className="flex-1 display-flex align-items-center">
                            { this.props.invoice.account.contact_name }
                        </div>
                        <div className="flex-1 display-flex align-items-center">
                            { Utils.humanize(this.props.invoice.type) }
                        </div>
                        <div className="flex-1 display-flex align-items-center">
                            { Utils.floatToCurrency(this.props.invoice.total_amount) }
                        </div>
                    </div>
                </div>
                <div className={`${this.state.activated ? "display-block" : "display-none"}`}>
                    <div className="well">
                        <div className="display-flex margin-10-bottom">
                            <div className="flex-2">
                                <b>Description:</b>
                            </div>
                            <div className="flex-1">
                                <b>Qty:</b>
                            </div>
                            <div className="flex-1">
                                <b>Rate:</b>
                            </div>
                        </div>
                        {
                            this.props.invoice.line_items.map((lineItem, index) => {
                                return(
                                    <div className="line-item display-flex margin-10-bottom" key={index}>
                                        <div className="flex-2 display-flex align-items-center">
                                            { lineItem.description }
                                        </div>
                                        <div className="flex-1 display-flex align-items-center">
                                            { lineItem.quantity }
                                        </div>
                                        <div className="flex-1 display-flex align-items-center">
                                            { Utils.floatToCurrency(parseFloat(lineItem.cost)) }
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </Fragment>
        )
    }
}
