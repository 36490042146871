export default {
    clearElements(selector) {
        const container = $(selector);
        container.html("");

        return container
    },

    addLoader(selector, text) {
        const container = this.clearElements(selector);

        const wrapper = document.createElement("div");
        const header = document.createElement("h5");
        const spinner = document.createElement("i");

        $(wrapper).addClass("display-flex align-items-center justify-content-center");
        $(spinner).addClass("fa fa-spinner fa-pulse fa-2x fa-fw margin-5-right");

        $(header).text(text);
        $(wrapper).append(spinner);
        $(wrapper).append(header);

        container.append(wrapper);
    }
}
