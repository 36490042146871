import Timeout from '@main/Timeout';
import { toast } from 'react-toastify';

export default {
    saveRepairTicket(repair) {
        return new Promise((resolve, reject) => {
            const images = repair.images;

            $.ajax({
                method: "PUT",
                url: `accounts/${repair.account_id}/repairs/${repair.id}.json`,
                data: {
                    repair: {
                        private_notes: repair.private_notes,
                        completed_at: repair.completed_at
                    }
                },
                timeout: Timeout.RESOURCE,
                success(repair) {
                    repair.images = images;
                    resolve(repair)
                },
                error(jqXHR, textStatus, errorThrown) {
                    if (textStatus === "error" && (jqXHR.responseJSON || {}).errors) {
                        toast.error(`Unable to save Repair ${jqXHR.responseJSON.errors}`, {
                            position: toast.POSITION.TOP_CENTER
                        })
                    }

                    reject({ ...repair, timeout: true });
                }
            });
        });
    }
}
