import PropTypes from 'prop-types';
import React from 'react';
import pluralize from 'pluralize';

export default class ChemicalsUsed extends React.Component {
    static propTypes = {
        chemicals: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);

        this.baseState = {
            results: null,
            startDate: null,
            endDate: null,
            loading: null
        }

        this.state = this.baseState;
    }

    humanize = (string) => {
        return string.split("_").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")
    };

    chemicalCells = () => {
        return Object.keys(this.props.chemicals).map((chem, index) => {
            return(
                <td key={index} style={{ "minWidth": "150px" }}>
                    { `${this.humanize(chem)}:` }
                </td>
            )
        });
    }

    totalChemicalsUsed = () => {
        const chems = this.props.chemicals;
        const values = Object.values(this.state.results);
        const totalChems = Object.keys(this.props.chemicals).reduce((accumulator, currentValue, currentIndex, array) => { accumulator[array[currentIndex]] = 0; return accumulator }, {});

        values.forEach(obj => {
            for(let prop in obj) {
                totalChems[prop] = totalChems[prop] + parseFloat(obj[prop]);
            }
        });

        return Object.keys(totalChems).map((chem, index) => {
            return(
                <div
                    className="chem-box"
                    key={index}
                >
                    <h4 className="text-center">
                        { `${this.humanize(chem)}:` }
                    </h4>
                    <div className="text-center">
                        <b>{ totalChems[chem] }</b>
                    </div>
                    <h5>
                        { pluralize(this.props.chemicals[chem], totalChems[chem]) }
                    </h5>
                </div>
            )
        });
    };

    renderRows = () => {
        const chemicalKeys = Object.keys(this.props.chemicals);
        return Object.keys(this.state.results).map((account, index) => {
            return(
                <tr key={index}>
                    <td>
                        { account }
                    </td>
                    {
                        chemicalKeys.map((chem, index) => {
                            return(
                                <td key={index}>
                                    { this.state.results[account][chem] }
                                </td>
                            )
                        })
                    }
                </tr>
            )
        })
    };

    fetchChemicalReport = () => {
        this.setState({ results: {}, loading: true })
        $.ajax({
            method: "GET",
            url: "/reports/show.json",
            data: {
                start_date: this.state.startDate,
                end_date: this.state.endDate,
                type: "chemicals_used"
            }
        }).done(results => {
            this.setState({ results, loading: false });
        });
    };

    render() {
        return(
            <div className="chemicals-used-wrapper">
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>
                                Start Date:
                            </label>
                            <input
                                type="date"
                                className="form-control"
                                onChange={(e) => this.setState({ startDate: e.target.value })}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>
                                End Date:
                            </label>
                            <input
                                type="date"
                                className="form-control"
                                onChange={(e) => this.setState({ endDate: e.target.value })}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <button
                            className="btn btn-success"
                            disabled={!this.state.startDate || !this.state.endDate}
                            onClick={this.fetchChemicalReport}
                        >
                            Search Chemical Usage
                        </button>
                    </div>
                </div>

                <hr/>

                { this.state.results && !this.state.loading &&
                    <div>
                        <div className="row">
                            <div className="col-md-12">
                                <h4 className="no-margin">
                                    Total Chemicals Used:
                                </h4>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="display-flex overflow-x-scroll">
                                    { this.totalChemicalsUsed() }
                                </div>
                            </div>
                        </div>

                        <hr/>
                    </div>
                }

                <div className="row">
                    <div className="col-md-12">
                        { !this.state.results && !this.state.loading &&
                            <h4 className="text-center">
                                Display chemical usage for all of your accounts over time
                            </h4>
                        }
                        { this.state.results && !this.state.loading && Object.keys(this.state.results).length < 1  &&
                            <h4 className="text-center">
                                No chemicals to show for this search
                            </h4>
                        }
                        { this.state.loading &&
                            <div className="display-flex">
                                <i className="fa fa-spinner fa-pulse fa-3x fa-fw align-self-center flex-1"></i>
                            </div>
                        }
                        { this.state.results && !this.state.loading && Object.keys(this.state.results).length > 0 &&
                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <td style={{ "minWidth": "300px" }}>
                                                Account:
                                            </td>
                                            { this.chemicalCells() }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { this.renderRows() }
                                    </tbody>
                                </table>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}
