import React, { Fragment, useState, useEffect, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';

import { toast } from 'react-toastify';
import Utils from 'Utils';

const LoadCollection = (props) => {
    const [loadingMore, setLoadingMore] = useState(props.loadOnMount);
    const [totalPages, setTotalPages] = useState(props.totalPages);
    const [currentPage, setCurrentPage] = useState(props.currentPage || 0);

    if (props.loadOnMount) {
        useEffect(() => {
            loadMore();
        }, [])
    }

    const canLoadMore = () => {
        return currentPage < totalPages;
    };

    const loadMore = () => {
        setLoadingMore(true)

        props.fetchCollection(currentPage + 1).then(res => {
            setTotalPages(res.totalPages);
            setCurrentPage(res.currentPage);
        }).catch(() => {
            toast.error(`Unable to load ${Utils.humanize(this.props.collectionName)}`, {
                position: toast.POSITION.TOP_CENTER
            })
        }).finally(() => {
            setLoadingMore(false);
        })
    };

    return(
        <Fragment>
            { (!loadingMore && canLoadMore()) &&
                <div className="width-100">
                    <button className="btn btn-default text-center width-100" onClick={loadMore}>
                        Load More
                    </button>
                </div>
            }
            { loadingMore &&
                <div className="display-flex flex-column align-items-center justify-content-center flex-1 width-100">
                    <div className="display-flex">
                        <i className="fa fa-spinner fa-pulse fa-2x fa-fw align-self-center margin-5-right"></i>
                        <h4 className="text-center">
                            Loading { Utils.humanize(props.collectionName) }...
                        </h4>
                    </div>
                </div>
            }
            { (!loadingMore && props.collectionLength < 1) &&
                <div className="display-flex flex-column align-items-center justify-content-center flex-1 width-100 color-gray">
                    <div className="display-flex">
                        <h4 className="text-center">
                            No { Utils.humanize(props.collectionName) } Found
                        </h4>
                    </div>
                </div>
            }
        </Fragment>
    )
}

LoadCollection.propTypes = {
    collectionName: PropTypes.string.isRequired,
    fetchCollection: PropTypes.func.isRequired,
    loadOnMount: PropTypes.bool.isRequired,
    collectionLength: PropTypes.number.isRequired,
    currentPage: PropTypes.number,
    totalPages: PropTypes.number
}

export default LoadCollection
