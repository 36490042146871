import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';

export default class ReportButtons extends React.Component {
    static propTypes = {
        reports: PropTypes.array.isRequired,
    }

    format = (report) => {
        return report.replace("/", "-");
    };

    render() {
        return(
            this.props.reports.map((report, index) => {
                const link = `/${this.format(report).toLowerCase().split(" ").join("-")}`
                return(
                    <NavLink
                        key={index}
                        to={link}
                        className="btn btn-default margin-10-right"
                        activeClassName="btn btn-info"
                    >
                        {report}
                    </NavLink>
                )
            })
        )
    }
}
