import React from 'react';
import PropTypes from 'prop-types';

export default class CheckboxSwitch extends React.Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        stateless: PropTypes.bool,
        checked: PropTypes.bool,
        id: PropTypes.string,
        onText: PropTypes.string,
        offText: PropTypes.string,
        onValue: PropTypes.string,
        offValue: PropTypes.string,
        onChange: PropTypes.func
    }

    constructor(props) {
        super(props);

        this.baseState = {
            checked: props.checked || false
        }

        this.state = this.baseState;
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.checked !== prevProps.checked) {
            this.setState({ checked: this.props.checked })
        }
    }

    onChange = (e) => {
        this.setState({ checked: !this.state.checked }, () => {
            if (this.props.onChange) this.props.onChange();
        });
    };

    inputOptions = () => {
        const baseProps = {
            id: this.props.id || this.props.name,
            type: "checkbox",
            name: this.props.name,
            onChange: this.onChange,
            value: this.props.onValue || "1"
        }

        baseProps.checked = this.props.stateless ? (this.props.checked || false) : this.state.checked;

        return baseProps;
    };

    render() {
        return(
            <div className="can-toggle">
                { (!this.props.onChange && !this.state.checked) &&
                    <input
                        type="hidden"
                        name={this.props.name}
                        value={this.props.offValue || "0"}
                    />
                }
                <input {...this.inputOptions()} />
                <label htmlFor={this.props.id || this.props.name}>
                    <div
                        className="can-toggle-switch"
                        data-checked={this.props.onText || "On"}
                        data-unchecked={this.props.offText || "Off"}
                    >
                    </div>
                </label>
            </div>
        )
    }
}
