import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Utils from '../../Utils';

export default class RepairAccountDetails extends React.Component {
    static propTypes = {
        account: PropTypes.object.isRequired
    }

    render() {
        return(
            <Fragment>
                { this.props.account.home_warranty &&
                    <div className="row">
                        <div className="col-md-6">
                            <div className="alert alert-success">
                                <i className="fa fa-home margin-10-right"></i>
                                This Account has a Home Warranty
                            </div>
                        </div>
                    </div>
                }
                { (this.props.account.pre_approved_budget && this.props.account.pre_approved_budget > 0.0) &&
                    <div className="row">
                        <div className="col-md-8">
                            <div className="alert alert-success">
                                <i className="fa fa-usd margin-10-right"></i>
                                This Account has a Pre-Approved Budget of { Utils.floatToCurrency(parseFloat(this.props.account.pre_approved_budget)) }
                            </div>
                        </div>
                    </div>
                }
            </Fragment>
        )
    }
}
