import React, { Fragment } from 'react';

import AqsChart from '@shared/components/Chart';
import DateTimePicker from '@shared/components/DateTimePicker';
import Utils from 'Utils';
import DownloadCsv from '@shared/components/DownloadCsv';

import { toast } from 'react-toastify';

export default class ExpensesReport extends React.Component {
    constructor(props) {
        super(props);

        this.baseState = {
            loading: false,
            start: moment().subtract(30, "days").format("MM/DD/YYYY"),
            end: moment().format("MM/DD/YYYY"),
            expenses: {
                repairs: 0.0,
                service_items: 0.0,
                chemicals: 0.0,
                service_tech_commissions: 0.0,
                credit_card_fees: 0.0,
                other: 0.0
            }
        }

        this.state = this.baseState;
    }

    componentDidMount() {
        this.fetchExpenses();
    }

    chartData = () => {
        const backgroundColors = {
            repairs: "rgba(214, 202, 40, 0.5)",
            service_items: "rgba(28, 70, 201, 0.5)",
            chemicals: "rgba(255, 89, 92, 0.5)",
            service_tech_commissions: "rgba(58, 135, 173, 0.5)",
            credit_card_fees_and_refunds: "rgba(165, 125, 115, 0.5)",
            other: "rgba(229, 229, 229, 0.5)"
        };

        const borderColors = {
            repairs: "rgba(214, 202, 40, 1)",
            service_items: "rgba(28, 70, 201, 1)",
            chemicals: "rgba(255, 89, 92, 1)",
            service_tech_commissions: "rgba(58, 135, 173, 1)",
            credit_card_fees_and_refunds: "rgba(165, 125, 115, 1)",
            other: "rgba(229, 229, 229, 1)"
        }

        const datasets = Object.keys(this.state.expenses).map(prop => (
            {
                label: Utils.humanize(prop),
                backgroundColor: backgroundColors[prop],
                borderColor: borderColors[prop],
                borderWidth: 1,
                data: [this.state.expenses[prop]]
            }
        ));

        return { datasets, labels: [] };
    };

    expenseRequestData = () => {
        return {
            start: moment(this.state.start, "MM/DD/YYYY").startOf("day").utc().format(),
            end: moment(this.state.end, "MM/DD/YYYY").endOf("day").utc().format()
        }
    };

    fetchExpenses = () => {
        this.setState({ loading: true })
        $.ajax({
            method: "GET",
            url: "/reports/expenses.json",
            data: this.expenseRequestData()
        }).done((expenses) => {
            this.setState({ expenses, loading: false })
        }).fail(() => {
            toast.error("Unable to fetch Expenses, please try again later...", {
                position: toast.POSITION.TOP_CENTER
            })
        })
    };

    totalExpenses = (currencySymbol = true) => {
        const sum = Object.values(this.state.expenses).reduce((a, b) => a + b);

        if (currencySymbol) return Utils.floatToCurrency(sum);
        return sum;
    };

    render() {
        return(
            <Fragment>
                <h4>
                    Expenses Accrued:
                </h4>
                <div className="display-flex">
                    <div className="form-group display-flex align-items-center">
                        <label className="margin-5-right no-margin-bottom">Between</label>
                        <DateTimePicker
                            inputName="start"
                            callback={(e) => this.setState({ start: e.target.value })}
                            value={this.state.start}
                            options={{
                                format: "MM/DD/YYYY"
                            }}
                        />
                    </div>
                    <div className="form-group display-flex align-items-center margin-5-left">
                        <label className="margin-5-right no-margin-bottom">And</label>
                        <DateTimePicker
                            inputName="start"
                            callback={(e) => this.setState({ end: e.target.value })}
                            value={this.state.end}
                            options={{
                                format: "MM/DD/YYYY"
                            }}
                        />
                    </div>
                </div>
                <div className="display-flex margin-10-top justify-content-space-between align-items-center">
                    <div>
                        <button
                            className="btn btn-success"
                            onClick={this.fetchExpenses}
                        >
                            Load Results
                        </button>
                    </div>
                    { this.totalExpenses(false) > 0.01 &&
                        <DownloadCsv
                            csvUrl={`/reports/expenses.csv?${$.param(this.expenseRequestData())}`}
                            cookieName="expenses_report_downloaded"
                        />
                    }
                </div>

                <hr/>

                { this.state.loading &&
                    <div className="display-flex flex-column">
                        <h3 className="text-center">
                            Loading Expenses Report...
                        </h3>
                        <i className="fa fa-spinner fa-pulse fa-3x fa-fw align-self-center flex-1"></i>
                    </div>
                }

                { (!this.state.loading && this.totalExpenses(false) < 0.01) &&
                    <h3 className="text-center">
                        No Expenses Found
                    </h3>
                }

                { (!this.state.loading && this.totalExpenses(false) >= 0.01) &&
                    <Fragment>
                        <h4>
                            Total Expenses: <span className="color-gray">{ this.totalExpenses() }</span>
                        </h4>

                        <div className="chart-container">
                            <AqsChart
                                chartType="bar"
                                canvasWidth={500}
                                canvasHeight={500}
                                displayLegend={true}
                                chartData={this.chartData()}
                                options={{
                                    tooltips: {
                                        callbacks: {
                                            label(tooltipItems, data) {
                                                return Utils.floatToCurrency(parseFloat(tooltipItems.yLabel.toString()));
                                            }
                                        }
                                    },
                                    scales: {
                                        yAxes: [{
                                            ticks: {
                                                callback(value, index, values) {
                                                    return Utils.floatToCurrency(parseFloat(value));
                                                },
                                                beginAtZero: true
                                            }
                                        }]
                                    },
                                    maintainAspectRatio: false,
                                    responsive: true
                                }}
                            />
                        </div>
                    </Fragment>
                }
            </Fragment>
        )
    }
}
