import PropTypes from 'prop-types';
import React from 'react';
import MasterServiceItem from './MasterServiceItem';

import { toast } from 'react-toastify';

export default class MasterServiceItemsGroup extends React.Component {
    static propTypes = {
        master_service_items: PropTypes.array.isRequired
    }

    constructor(props) {
        super(props);

        const masterServiceItems = this.props.master_service_items.map(msi => ({ ...msi, key: btoa(Math.random()) }))

        this.state = { masterServiceItems }
    }

    removeMasterServiceItem = (key) => {
        const msi = this.state.masterServiceItems.find(msi => msi.key === key);

        if (msi.id) {
            $.ajax(`/master_service_items?master_service_items[][id]=${msi.id}`, {
                method: "DELETE",
            }).done(() => {
                toast.success("Successfully destroyed Service Item!", {
                    position: toast.POSITION.TOP_CENTER
                })
            }).fail(() => {
                toast.error("Unable to destroy Service Item...", {
                    position: toast.POSITION.TOP_CENTER
                })
            });
        }

        const masterServiceItems = [...this.state.masterServiceItems].filter(msi => msi.key !== key);

        this.setState({ masterServiceItems });
    };

    addMasterServiceItem = (e) => {
        e.preventDefault();

        this.setState({
            masterServiceItems: [...this.state.masterServiceItems, [{ key: btoa(Math.random()) }]]
        });

        setTimeout(() => {
            $(".master-service-item-group").last().find(".form-group").last()[0].scrollIntoView();
        });
    };

    render() {
        return(
            <div>
                {this.state.masterServiceItems.length == 0 &&
                    <div>
                        <h4 className="text-center">
                            You have no saved service items
                        </h4>
                        <a href="#" onClick={(e) => this.addMasterServiceItem(e)}>
                            Add Service Item
                        </a>
                    </div>
                }
                {this.state.masterServiceItems.length > 0 &&
                    <div>
                        <a href="#" onClick={(e) => this.addMasterServiceItem(e)}>
                            Add Service Item
                        </a>
                        <hr/>
                        {this.state.masterServiceItems.map(msi => {
                            return(
                                <MasterServiceItem
                                    key={msi.key}
                                    msiKey={msi.key}
                                    id={msi.id}
                                    name={msi.name}
                                    defaultPrice={msi.default_price}
                                    cost={msi.default_cost}
                                    commission={msi.commission}
                                    removeMasterServiceItem={this.removeMasterServiceItem}
                                />
                            )
                        })}
                        <a href="#" onClick={(e) => this.addMasterServiceItem(e)}>
                            Add Service Item
                        </a>
                        <hr/>
                        <div className="form-group">
                            <input
                                type="submit"
                                defaultValue="Save Service Items"
                                className="btn btn-default"
                            />
                        </div>
                    </div>
                }
            </div>
        )
    }
}
