import Timeout from '@main/Timeout';
import Utils from 'Utils';

const createImageRecord = (_image, resource_name, resource_id) => {
    const image = { resource_name, resource_id, format: _image.format }

    const { full_url } = _image;

    return new Promise((resolve, reject) => {
        $.ajax({
            type: "POST",
            url: "/images.json",
            data: { image },
            timeout: Timeout.RESOURCE,
            success(imageRecord) {
                resolve({ ...imageRecord, full_url, finished_url: imageRecord.full_url, status: "created", loading: true });
            },
            error(jqXHR, textStatus, errorThrown) {
                resolve({ ..._image, resource_name, resource_id, timeout: true, loading: false });
            }
        });
    });
};

const uploadImage = (image, blob) => {
    const formData = new FormData();

    for (const [key, value] of Object.entries(image.presigned_post.fields)) {
        formData.append(key, value);
    }

    formData.append("file", blob);

    return new Promise((resolve, reject) => {
        $.ajax({
            method: "POST",
            processData: false,
            contentType: false,
            url: image.presigned_post.url,
            data: formData,
            timeout: Timeout.IMAGE,
            success() {
                resolve({ ...image, status: "uploaded" });
            },
            error(jqXHR, textStatus, errorThrown) {
                resolve({ ...image, timeout: true, loading: false });
            }
        });
    });
};

const markImageAsCompleted = (image, makePublic) => {
    return new Promise((resolve, reject) => {
        const data = { image: { status: "completed" } };

        if (makePublic) data.image.make_image_public = true;

        $.ajax({
            method: "PUT",
            url: `/images/${image.id}.json`,
            data,
            timeout: Timeout.RESOURCE,
            success(updatedImage) {
                resolve({ ...updatedImage, loading: false });
            },
            error(jqXHR, textStatus, errorThrown) {
                resolve({ ...image, timeout: true, loading: false });
            }
        });
    });
};

const allSucceeded = (collection) => {
    return !collection.some(i => i.timeout);
};

export default {
    createImageRecord,
    uploadImage,
    markImageAsCompleted,
    allSucceeded,
    process(images, resourceType, resourceId, callback) {
        const promises = images.map(image => {
            return new Promise((resolve, reject) => {
                switch(image.status) {
                    case 'compressed':
                        createImageRecord(image, resourceType, resourceId)
                            .then((createdImage) => {
                                if (createdImage.timeout) resolve(image);

                                Utils.dataURItoBlob(image.full_url, (blob) => {
                                    uploadImage(createdImage, blob)
                                        .then(uploadedImage => {
                                            if (uploadedImage.timout) resolve(image);

                                            markImageAsCompleted(uploadedImage).then(createdImage => {
                                                if (createdImage.timeout) {
                                                    resolve(image);
                                                } else {
                                                    resolve(createdImage);
                                                }
                                            })
                                        });
                                });
                            })

                        break;
                    case 'created':
                        Utils.dataURItoBlob(image.full_url, (blob) => {
                            uploadImage(image, blob)
                                .then(uploadedImage => {
                                    if (uploadedImage.timout) resolve(image);

                                    markImageAsCompleted(image).then(createdImage => {
                                        if (createdImage.timeout) {
                                            return resolve(image);
                                        } else {
                                            return resolve(createdImage);
                                        }
                                    })
                                });
                        });

                        break;
                    case 'uploaded':
                        markImageAsCompleted(image).then(createdImage => {
                            if (createdImage.timeout) {
                                resolve(image);
                            } else {
                                resolve(createdImage);
                            }
                        });

                        break;
                }
            });
        });

        Promise.all(promises).then(processed => callback(processed));
    }
}
