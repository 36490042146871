import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { toast } from 'react-toastify';

export default class RepairAttachmentSendModal extends React.Component {
    static propTypes = {
        repair: PropTypes.object.isRequired,
        basePlan: PropTypes.string.isRequired
    }

    constructor(props) {
        super(props);

        this.baseState = {
            selectedAttachments: [],
            loading: false
        }

        this.state = this.baseState;
    }

    componentDidMount() {
        $("#send-repair-attachments-modal").on("show.bs.modal", (e) => {
            $(e.target).addClass("center-screen-modal");
        });

        $("#send-repair-attachments-modal").on("hide.bs.modal", (e) => {
            $(e.target).removeClass("center-screen-modal");

            this.setState({ ...this.baseState });
        });
    }

    toggleSelection = (type) => {
        if (type === "text") {
            if (this.props.basePlan !== "pro") {
                $(this.textFormGroup).popover({
                    content: "Upgrade to <a href='https://www.useaquasuite.com/subscriptions/activate'>AquaSuite Pro</a> to unlock Text Messages",
                    html: true,
                    placement: "top",
                    trigger: "focus"
                }).popover("show");

                return false
            }
        }

        if (this.state.selectedAttachments.includes(type)) {
            this.setState({ selectedAttachments: this.state.selectedAttachments.filter(_type => _type !== type) })
        } else {
            this.setState({ selectedAttachments: [...this.state.selectedAttachments, type] });
        }
    };

    sendAttachments = () => {
        this.setState({ loading: true });

        $.ajax({
            method: "PUT",
            url: `/accounts/${this.props.repair.account_id}/repairs/${this.props.repair.id}.json`,
            data: {
                repair: {
                    attachments_to_deliver: this.state.selectedAttachments
                }
            }
        }).done(() => {
            toast.success("Repair Attachment(s) successfully queued for delivery", {
                position: toast.POSITION.TOP_CENTER,
            });
        }).fail(() => {
            toast.error("Unable to send Repair Attachments, please try again later", {
                position: toast.POSITION.TOP_CENTER,
            });
        }).always(() => {
            $("#send-repair-attachments-modal").modal("hide");

            this.setState({ loading: false });
        });
    };

    hasValidAccountPhone = () => {
        const phone = this.props.repair.account.contact_phone
        if (phone) {
            const length = phone.match(/\d/g).length
            return (length <= 11 && length > 9);
        } else {
            return false;
        }
    };

    render() {
        return(
            <div
                id="send-repair-attachments-modal"
                className="modal fade"
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                Send Repair Attachment(s)
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                  <span aria-hidden="true">&times;</span>
                                </button>
                            </h5>
                        </div>
                        <div className="modal-body">
                            { !this.state.loading &&
                                <Fragment>
                                    <h4 className="text-center">
                                        Select Attachments to send:
                                    </h4>
                                    <div className="well display-flex flex-column justify-content-center align-items-center">
                                        <div className="form-group">
                                            <label htmlFor="email-attachment" className="margin-5-right">
                                                Email:
                                            </label>
                                            <input
                                                id="email-attachment"
                                                type="checkbox"
                                                onChange={() => this.toggleSelection("email")}
                                                checked={this.state.selectedAttachments.includes("email")}
                                            />
                                        </div>
                                        <div className="form-group no-margin-bottom" ref={(e) => this.textFormGroup = e}>
                                            <label htmlFor="text-attachment" className="margin-5-right">
                                                Text Message:
                                            </label>
                                            <input
                                                id="text-attachment"
                                                type="checkbox"
                                                onChange={() => this.toggleSelection("text")}
                                                checked={this.state.selectedAttachments.includes("text")}
                                                disabled={!this.hasValidAccountPhone()}
                                            />
                                        </div>
                                        { !this.hasValidAccountPhone() &&
                                            <div className="alert alert-danger">
                                                <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                                                Account does not have valid phone number
                                            </div>
                                        }
                                    </div>
                                </Fragment>
                            }
                            { this.state.loading &&
                                <div className="display-flex justify-content-center margin-20">
                                    <i className="fa fa-spinner fa-pulse fa-3x fa-fw align-self-center flex-1"></i>
                                </div>
                            }
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-success"
                                onClick={this.sendAttachments}
                                disabled={this.state.selectedAttachments.length < 1}
                            >
                                Send Attachments
                            </button>
                            <button
                                type="button"
                                className="btn btn-default"
                                data-dismiss="modal"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
