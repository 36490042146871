import PropTypes from 'prop-types';
import React from 'react';

export default class SettingsButtons extends React.Component {
    static propTypes = {
        settings: PropTypes.array.isRequired
    }

    constructor(props) {
        super(props);

        this.state = {
            selectedSetting: null
        }
    }

    componentWillMount() {
        if (window.location.hash === "#/subscription") {
            this.setSelectedSetting("Subscription");
        }
    }

    setSelectedSetting = (setting) => {
        this.setState({
            selectedSetting: setting
        });

        const settingId = setting.toLowerCase().split(" ").join("-");

        $(".settings").hide();
        $(`.settings#${settingId}`).show();
    };

    render() {
        return(
            <div className="button-wrapper display-flex flex-wrap">
                {this.props.settings.map((setting, index) => {
                    const className = setting == this.state.selectedSetting ? "btn btn-info" : "btn btn-default"

                    return(
                        <div key={index} className="margin-10-top">
                            <button
                                key={index}
                                onClick={() => this.setSelectedSetting(setting)}
                                className={`${className} margin-10-right`}
                            >
                                {setting}
                            </button>
                        </div>
                    )
                })}
            </div>
        )
    }
}
