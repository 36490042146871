import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { toast } from 'react-toastify';
import MaskedInput from 'react-text-mask';
import CreateNumberMask from '@shared/CreateNumberMask';

import AqsModal from 'AqsModal';

import Utils from 'Utils';

export default class InvoicePayment extends React.Component {
    static propTypes = {
        invoice: PropTypes.object.isRequired,
        payment: PropTypes.object.isRequired,
        updatePayment: PropTypes.func.isRequired,
        saveInvoice: PropTypes.func.isRequired,
        updateInvoice: PropTypes.func.isRequired,
        destroyPayment: PropTypes.func.isRequired,
        timezone: PropTypes.string.isRequired
    }

    constructor(props) {
        super(props);

        this.baseState = {
            editing: false,
            issuingRefund: false,
            refundAmount: props.payment.amount,
            validations: {
                amount: false
            }
        }

        this.state = this.baseState
    }

    componentDidUpdate() {
        $("[data-toggle='popover']").popover();
    }

    componentDidMount() {
        setTimeout(() => {
            $("[data-toggle='popover']").popover();
        });

        $('[class*=datetime-picker]').datetimepicker({
            ignoreReadonly: true,
            allowInputToggle: true,
            showClose: true,
            widgetPositioning: {
                horizontal: "left",
                vertical: "bottom"
            },
            icons: {
                time: "glyphicon dt-picker-icon-time",
                close: "glyphicon dt-picker-icon-close",
                date: "glyphicon dt-picker-icon-date"
            }
        });

        $(`.datetime-picker-${this.props.payment.id}`).on("dp.change", (e) => {
            const dateTime = moment(e.target.value, "MM/DD/YYYY hh:mm A").format("YYYY-MM-DD HH:mm");
            const parsedDateTime = moment.tz(dateTime, this.props.timezone).utc().format();

            this.props.updatePayment({ ...this.props.payment, date_received: parsedDateTime });
        })
    }

    details = () => {
        if (this.props.payment.check_number) {
            return `Check Number: ${this.props.payment.check_number}`
        } else if (this.props.payment.stripe_charge_id) {
            return(
                <button
                    className="btn btn-default"
                    data-toggle="popover"
                    data-content={this.props.payment.stripe_charge_id}
                    data-placement="top"
                >
                    Show Charge ID
                </button>
            )
        } else if (this.props.payment.stripe_refund_id) {
            return(
                <button
                    className="btn btn-default"
                    data-toggle="popover"
                    data-content={this.props.payment.stripe_refund_id}
                    data-placement="top"
                >
                    Show Refund ID
                </button>
            )
        } else {
            return "--";
        }
    };

    destroyPayment = () => {
        if (confirm("Are you sure you would like to remove this Payment from the Invoice?")) {
            this.props.destroyPayment(this.props.payment);
        }
    };

    isValid = () => {
        const validations = { amount: false };

        const newTotal = this.props.invoice.invoice_payments.map(ip => parseFloat(ip.amount)).reduce((a, b) => a + b);

        if (newTotal > parseFloat(this.props.invoice.grand_total) || parseFloat(this.props.payment.amount) < 0.01) validations.amount = true

        this.setState({ validations });

        return !validations.amount;
    };

    saveInvoice = () => {
        if (this.isValid()) {
            this.props.saveInvoice();
            this.setState({ editing: false });
        }
    };

    actions = () => {
        if (this.props.payment.method === "cash" || this.props.payment.method === "check") {
            return(
                <Fragment>
                    { this.state.editing ?
                        <button className="btn btn-success" onClick={this.saveInvoice}>
                            <i className="fa fa-save"></i>
                        </button>
                    :
                        <button className="btn btn-default" onClick={() => this.setState({ editing: true })}>
                            <span className="glyphicon glyphicon-pencil"></span>
                        </button>
                    }
                    <button className="btn btn-danger margin-10-left" onClick={this.destroyPayment}>
                        <i className="fa fa-trash"></i>
                    </button>
                </Fragment>
            )
        } else if (this.props.payment.stripe_charge_id && !this.props.payment.refund_issued) {
            return(
                <Fragment>
                    { !this.state.issuingRefund &&
                        <button className="btn btn-danger" onClick={() => this.refundModalEl.show()}>
                            Issue Refund
                        </button>
                    }
                </Fragment>
            )
        } else {
            return "--";
        }
    };

    issueRefund = () => {
        this.setState({ issuingRefund: true });

        $.ajax({
            method: "POST",
            url: `/credit_cards/${this.props.payment.id}/refund.json`,
            data: {
                amount: this.state.refundAmount
            }
        }).done(invoice => {
            this.props.updateInvoice(invoice);

            toast.success("Refund issued successfully!", {
                position: toast.POSITION.TOP_CENTER
            });

            this.refundModalEl.hide()
        }).fail(res => {
            toast.error(res.responseJSON.error, {
                position: toast.POSITION.TOP_CENTER
            });
        }).always(() => {
            this.setState({ issuingRefund: false })
        });
    };

    render() {
        const amountMask = CreateNumberMask({
            prefix: "",
            allowDecimal: true
        });

        const payment = this.props.payment;
        const paymentDate = payment.date_received || payment.created_at;
        const dateReceivedClass = this.state.editing && (this.props.payment.method === "cash" || this.props.payment.method === "check") ? "display-block" : "display-none";

        return(
            <tr>
                <td>
                    <div className={dateReceivedClass}>
                        <div className="form-group">
                            <input
                                type="text"
                                readOnly
                                className={`form-control datetime-picker-${this.props.payment.id}`}
                                value={moment(paymentDate).tz(this.props.timezone).format("MM-DD-YYYY hh:mm A")}
                            />
                        </div>
                    </div>
                    { !this.state.editing && moment(paymentDate).tz(this.props.timezone).format("MM-DD-YYYY hh:mm A") }
                </td>
                <td>
                    { Utils.humanize(this.props.payment.method) }
                </td>
                <td>
                    { (this.state.editing && (this.props.payment.method === "cash" || this.props.payment.method === "check")) ?
                        <div className="form-group col-md-6 col-xs-12 no-padding">
                            <div className="input-group">
                                <div className="input-group-addon">
                                    <span className="glyphicon glyphicon-usd"></span>
                                </div>
                                <MaskedInput
                                    className="form-control"
                                    mask={amountMask}
                                    onChange={(e) => this.props.updatePayment({ ...this.props.payment, amount: e.target.value })}
                                    defaultValue={this.props.payment.amount}
                                />
                            </div>
                            { this.state.validations.amount &&
                                <div className="validation-wrapper">
                                    <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                                    Invalid Amount
                                </div>
                            }
                        </div>
                    :
                        <Fragment>
                            { Utils.floatToCurrency(parseFloat(this.props.payment.amount)) }
                        </Fragment>
                    }
                </td>
                <td>
                    { (this.state.editing && this.props.payment.method === "check") ?
                        <div className="form-group col-md-6 col-xs-12 no-padding">
                            <div className="input-group">
                                <div className="input-group-addon">
                                    #
                                </div>
                                <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={this.props.payment.check_number}
                                    onChange={(e) => this.props.updatePayment({ ...this.props.payment, check_number: e.target.value })}
                                />
                            </div>
                        </div>
                    :
                        this.details()
                    }
                </td>
                <td>
                    { this.actions() }
                    <AqsModal
                        id={`refund-modal-${this.props.payment.id}`}
                        headerText="Issue Credit Card Refund"
                        footerButtonAction={this.issueRefund}
                        footerButtonText="Issue Refund"
                        footerButtonDisabled={this.state.issuingRefund}
                        ref={(e) => this.refundModalEl = e}
                    >
                        { this.state.issuingRefund &&
                            <div className="display-flex flex-column justify-content-center align-items-center">
                                <h4 className="text-center">
                                    Issuing Refund...
                                </h4>
                                <div>
                                    <i className="fa fa-spinner fa-pulse fa-2x fa-fw align-self-center flex-1"></i>
                                </div>
                            </div>
                        }
                        { !this.state.issuingRefund &&
                            <Fragment>
                                <h4 className="text-center">
                                    Specify amount to issue a refund for from this Payment
                                </h4>

                                <hr/>

                                <div className="row display-flex justify-content-center">
                                    <div className="col-md-6 col-sm-6">
                                        <div className="form-group">
                                            <div className="input-group">
                                                <div className="input-group-addon">
                                                    <span className="glyphicon glyphicon-usd"></span>
                                                </div>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    defaultValue={this.props.payment.amount}
                                                    onChange={(e) => this.setState({ refundAmount: e.target.value })}
                                                    max={this.props.payment.amount}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h5 className="text-center">
                                    <small>
                                        Issuing a refund will refund this customer the entire payment amount specified above. Application/Credit Card Fees WILL NOT return to your account.
                                    </small>
                                </h5>
                            </Fragment>
                        }
                    </AqsModal>
                </td>
            </tr>
        )
    }
}
