import React, { Fragment, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import { RouteContext } from './Index';
import { DashboardContext } from '../Index';

import Utils from 'Utils';
import Timeout from '@main/Timeout';
import { toast } from 'react-toastify';
import RouteUtils from '../Utils/Route';

import LoadCollection from '@shared/components/LoadCollection';

const AppointmentList = (props) => {
    const routeContext = useContext(RouteContext);
    const dashboardContext = useContext(DashboardContext);
    const [editing, setEditing] = useState(false);
    const [savingOrder, setSavingOrder] = useState(false);

    const toggleExpander = () => {
        const widget = $(`.${props.widgetClass}`);
        widget.toggleClass("expanded");
    };

    const toggleEditing = () => {
        setEditing(!editing);
        routeContext.setSelectedAppointment(null);

        let appointments;
        if (!editing) {
            appointments = routeContext.appointments;
        } else {
            appointments = routeContext.originalAppointments;
        }

        routeContext.setOriginalAppointments(appointments);
        routeContext.setAppointments(appointments);
    };

    const saveAppointmentOrder = () => {
        setSavingOrder(true);

        routeContext.saveAppointments(() => {
            setSavingOrder(false);
        });
    };

    const changeApptOrder = (direction) => {
        const index = routeContext.appointments.findIndex(appt => appt.id === routeContext.selectedAppointment.id);

        let appointments = [...routeContext.appointments].map((appt, _index) => {
            if (_index === index) {
                return null
            } else {
                return appt.id
            }
        }).filter(Boolean);

        if (direction === "up") {
            if (index > 0) {
                appointments.splice(index - 1, 0, routeContext.selectedAppointment.id);
            } else {
                return false;
            }
        } else if (direction === "down") {
            if (index < routeContext.appointments.length) {
                appointments.splice((index + 1), 0, routeContext.selectedAppointment.id);
            } else {
                return false;
            }
        }

        appointments = appointments.map(id => routeContext.appointments.find(appt => appt.id === id));
        routeContext.setAppointments(appointments);
    };

    const rollAppointmentsForward = () => {
        if (confirm(`This will re-schedule all ${routeContext.appointments.length} lapsed appointments to their next occurring day. Any appointments not currently sync'd will lose their data. Are you sure you want to continue?`)) {
            if (!navigator.onLine) {
                toast.warn("No Internet Connection detected, please try again once internet is available.", {
                    position: toast.POSITION.TOP_CENTER
                });

                return false;
            }

            const sortedAppointments = {};

            const appointments = Utils.rollAppointmentsForward(routeContext.appointments, dashboardContext.currentCompany.timezone);
            appointments.forEach(appt => {
                sortedAppointments[appt.id] = {
                    start: appt.start,
                    end: appt.end
                }
            });

            $.ajax({
                method: "PUT",
                url: "/appointments/batch_update.json",
                data: { appointments: sortedAppointments },
                timeout: Timeout.RESOURCE,
                success: () => {
                    toast.success("Successfully rolled lapsed appointments forward!", {
                        position: toast.POSITION.TOP_CENTER
                    });

                    toggleExpander();
                    routeContext.setAppointments([]);

                    // NOTE: rolling appointments forward gets queued to a job so this may not actually refresh the current appointments
                    dashboardContext.refreshCurrentRoute()
                },
                error: (jqXHR, textStatus, errorThrown) => {
                    if (textStatus === "timeout") {
                        toast.warn("No Internet Connection detected, please try again once internet is available.", {
                            position: toast.POSITION.TOP_CENTER
                        });
                    } else {
                        toast.error("Unable to roll Appointments forward...", {
                            position: toast.POSITION.TOP_CENTER
                        });
                    }
                }
            });
        }
    };

    const apptStatusIcon = (appt) => {
        if (routeContext.appointmentSyncable(appt)) {
            switch(appt.status) {
                case 'needs_to_sync':
                    return(
                        <span className="label label-warning">
                            <i className="fa fa-exclamation-triangle margin-5-right"></i>
                            Needs to Sync
                        </span>
                    )
                    break;
                case 'syncing':
                    return(
                        <span className="label label-default">
                            <i className="fa fa-refresh fa-pulse margin-5-right"></i>
                            Syncing...
                        </span>
                    )
            }
        } else if (appt.completed) {
            return(
                <span className="label label-success">
                    <span className="glyphicon glyphicon-ok color-white margin-5-right"></span>
                    Completed
                </span>
            )
        } else if (!appt.completed) {
            if (appt.status) {
                switch(appt.status) {
                    case 'saving':
                        return(
                            <span className="label label-default">
                                <i className="fa fa-spinner fa-pulse fa-fw margin-5-right color-white"></i>
                                Saving...
                            </span>
                        )
                        break;
                }
            } else {
                return(
                    <span className="label label-danger">
                        <span className="glyphicon glyphicon-remove color-white margin-5-right"></span>
                        Not Completed
                    </span>
                )
            }
        }
    };

    return(
        <Fragment>
            <div className="button-wrapper display-flex margin-10-bottom">
                { (!editing && props.editable) &&
                    <Fragment>
                        <button className="btn btn-default" onClick={toggleEditing}>
                            <Fragment>
                                <i className="fa fa-list-ol margin-5-right"></i>
                                Edit Order
                            </Fragment>
                        </button>
                    </Fragment>
                }
                { editing &&
                    <Fragment>
                        <button className="btn btn-default margin-5-right" onClick={toggleEditing}>
                            <i className="fa fa-times"></i>
                        </button>
                        <button className="btn btn-success" onClick={saveAppointmentOrder}>
                            { savingOrder &&
                                <i className="fa fa-spinner fa-pulse fa-fw"></i>
                            }
                            { !savingOrder &&
                                <Fragment>
                                    <i className="fa fa-save margin-5-right"></i>
                                    Save
                                </Fragment>
                            }
                        </button>
                    </Fragment>
                }
                { routeContext.hasSyncableAppointments() &&
                    <button
                        className={`btn btn-warning ${props.appointmentType !== "current" ? "margin-10-right" : "margin-10-left"}`}
                        onClick={routeContext.syncAppointments}
                        disabled={routeContext.appointments.some(a => a.status === "syncing")}
                    >
                        <i className="fa fa-refresh margin-5-right"></i>
                        Sync
                    </button>
                }
                { (props.appointmentType !== "current" && dashboardContext.currentUser.roles.includes("admin")) &&
                    <button className="btn btn-warning" onClick={rollAppointmentsForward}>
                        Roll All Forward
                    </button>
                }
            </div>
            <div className="table-responsive">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            { (editing || props.selectable) &&
                                <td>
                                    &nbsp;
                                </td>
                            }
                            <td>
                                No.:
                            </td>
                            <td className="type">
                                Type:
                            </td>
                            <td>
                                Status:
                            </td>
                            <td className="account">
                                Account:
                            </td>
                            <td className="location">
                                Location:
                            </td>
                            <td className="time">
                                Start:
                            </td>
                            <td className="time">
                                End:
                            </td>
                            { props.appointmentType === "all-lapsed" &&
                                <td className="tech">
                                    Technician:
                                </td>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            routeContext.appointments.map((appt, index) => (
                                <tr
                                    key={appt.id}
                                    data-appt-id={appt.id}
                                    className={(routeContext.selectedAppointment || {}).id === appt.id ? "selected" : ""}
                                >
                                    { (editing || props.selectable) &&
                                        <td>
                                            <button className="btn btn-primary" onClick={(e) => routeContext.setSelectedAppointmentProxy(appt)}>
                                                Select
                                            </button>
                                        </td>
                                    }
                                    <td>
                                        <div className="display-flex align-items-center">
                                            <b>
                                                { index + 1 }.)
                                            </b>
                                        </div>
                                    </td>
                                    <td>
                                        <img className="widget-icon" src={RouteUtils.apptIconUrl(appt)} />
                                    </td>
                                    <td>
                                        { apptStatusIcon(appt) }
                                    </td>
                                    <td>
                                        { appt.account &&
                                            <a href={`/accounts/${appt.account.id}`} className="display-flex align-items-center">
                                                <i className="fa fa-user margin-5-right"></i>
                                                { appt.account.contact_name }
                                            </a>
                                        }
                                    </td>
                                    <td>
                                        <a
                                            href={Utils.googleMapsUrl(appt.full_location)}
                                            target="_blank"
                                            className="display-flex align-items-center"
                                        >
                                            <i className="fa fa-map-marker margin-5-right"></i>
                                            { appt.full_location }
                                        </a>
                                    </td>
                                    <td>
                                        { moment(appt.start).tz(dashboardContext.currentCompany.timezone).format("MM-DD-YYYY hh:mm A") }
                                    </td>
                                    <td>
                                        { moment(appt.end).tz(dashboardContext.currentCompany.timezone).format("MM-DD-YYYY hh:mm A") }
                                    </td>
                                    { props.appointmentType === "all-lapsed" &&
                                        <td>
                                            { appt.user_name }
                                        </td>
                                    }
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
                { routeContext.totalApptPages &&
                    <LoadCollection
                        collectionName="appointments"
                        fetchCollection={routeContext.fetchAppointments}
                        loadOnMount={false}
                        collectionLength={routeContext.appointments.length}
                        currentPage={routeContext.currentApptPage}
                        totalPages={routeContext.totalApptPages}
                    />
                }
            </div>
            <div className="expand-button display-flex flex-column">
                { editing &&
                    <button
                        className="btn btn-default margin-10-bottom"
                        onClick={() => changeApptOrder("up")}
                        disabled={!routeContext.selectedAppointment}
                    >
                        <i className="fa fa-chevron-up"></i>
                    </button>
                }
                <button className="btn btn-default" onClick={toggleExpander}>
                    <i className="fa fa-chevron-right"></i>
                </button>
                { editing &&
                    <button
                        className="btn btn-default margin-10-top"
                        onClick={() => changeApptOrder("down")}
                        disabled={!routeContext.selectedAppointment}
                    >
                        <i className="fa fa-chevron-down"></i>
                    </button>
                }
            </div>
        </Fragment>
    )
};

AppointmentList.propTypes = {
    editable: PropTypes.bool.isRequired,
    selectable: PropTypes.bool.isRequired,
    widgetClass: PropTypes.string.isRequired,
    appointmentType: PropTypes.string.isRequired
}

export default AppointmentList;
