import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import LockedFeature from '../Subscription/LockedFeature';

import DateHelper from '../Dates/Helper';
import { toast } from 'react-toastify';

export default class AppointmentModal extends React.Component {
    static propTypes = {
        techs: PropTypes.array.isRequired,
        timezone: PropTypes.string.isRequired,
        apptCallback: PropTypes.func,
        closeModalCallback: PropTypes.func,
        appointment: PropTypes.object,
        repair: PropTypes.object,
        repairItem: PropTypes.object,
        account: PropTypes.object,
        base_plan: PropTypes.string,
        current_user: PropTypes.object,
        isNotAdmin: PropTypes.bool
    }

    constructor(props) {
        super(props);

        this.state = this.baseState();
    }

    componentDidMount() {
        this.setState(this.stateFromProps(this.props));

        // datetimepicker
        $(".datetime-picker").datetimepicker({
            ignoreReadonly: true,
            allowInputToggle: true,
            showClose: true,
            widgetPositioning: {
                horizontal: "left",
                vertical: "bottom"
            },
            icons: {
                time: "glyphicon dt-picker-icon-time",
                close: "glyphicon dt-picker-icon-close",
                date: "glyphicon dt-picker-icon-date"
            }
        });

        $(".datetime-picker").on("dp.change", (e) => {
            const dateTime = moment(e.target.value, "MM/DD/YYYY hh:mm A").format("YYYY-MM-DD HH:mm");
            const parsedDateTime = moment.tz(dateTime, this.props.timezone).utc().format();

            this.updateStateFromInput(e.target.name.split("_")[1], parsedDateTime);
        });

        $(".datetime-picker").on("dp.show", (e) => {
            $(".bootstrap-datetimepicker-widget.dropdown-menu")[0].scrollIntoView();
        });

        $(this.modalEl).on("hide.bs.modal", () => {
            $(this.modalEl).toggleClass("center-screen-modal");

            if (this.props.closeModalCallback) {
                const { success, errors, ...appointment } = this.state;
                this.props.closeModalCallback(appointment);
            }

            this.setState(this.baseState());
        });

        $(this.modalEl).on("show.bs.modal", () => {
            $(this.modalEl).toggleClass("center-screen-modal");

            if (this.state.start.length > 0) {
                this.startInput.value = moment(this.state.start).tz(this.props.timezone).format("MM/DD/YYYY hh:mm A");
            }

            if (this.state.end.length > 0) {
                this.endInput.value = moment(this.state.end).tz(this.props.timezone).format("MM/DD/YYYY hh:mm A");
            }
        });
    }

    componentWillReceiveProps(props) {
        if (!this.state.success) { // so success alert doesn't just flash and then go away when appointment is saved
            this.setState(this.stateFromProps(props));
        }
    }

    baseState = () => {
        const _appt = this.props.appointment || {};
        return {
            id: "",
            address: "",
            city: "",
            state: "",
            "zip": "",
            start: _appt.start ? _appt.start : "",
            end: _appt.end ? _appt.end : "",
            user_id: _appt.user_id || "",
            notes: "",
            account: null,
            notification: false,
            success: false,
            errors: null,
        }
    };

    stateFromProps = (props) => {
        let baseState = {
            notification: false,
            success: false,
            errors: null,
        };

        ["id", "start", "end", "user_id", "notes"].forEach(attr => {
            if (props.appointment) {
                baseState[attr] = props.appointment[attr] || "";
            } else {
                baseState[attr] = ""
            }
        });

        ["address", "city", "state", "zip"].forEach(attr => {
            if (props.appointment && props.appointment.location) {
                baseState[attr] = props.appointment.location[attr];
            } else {
                baseState[attr] = ""
            }
        });

        if (props.appointment && props.appointment.account) {
            baseState.account = props.appointment.account
        }

        return baseState;
    };

    showModal = () => {
        $(this.modalEl).modal("show");
    };

    notificationValue = () => {
        let notification = null;
        if (this.state.notification) {
            notification = this.props.appointment ? "update" : "new";
        }

        if (this.props.type === "repair" && !this.props.repair) {
            notification = "new";
        }

        return notification;
    };

    saveAppointment = () => {
        if (this.props.type === "repair" && !this.props.repair.id) {
            // creating appointment with new repair
            $(this.modalEl).modal("hide");
        } else {
            const start = moment.tz(this.state.start, this.props.timezone).utc().format();
            const end = moment.tz(this.state.end, this.props.timezone).utc().format();

            let type;
            if (this.props.appointment) {
                type = this.props.appointment.type;
            } else if (this.props.type) {
                type = this.props.type;
            } else if (this.props.repairItem) {
                type = "repair_item"
            } else {
                type = "other";
            }

            const notification = this.notificationValue();

            this.setState({ errors: null });

            let url;
            if (this.props.appointment) {
                url = `/appointments/${this.props.appointment.id}.json`
            } else {
                url = "/appointments.json"
            }

            const method = this.props.appointment ? "PUT" : "POST";

            let data = {
                appointment: {
                    location: {
                        address: this.state.address,
                        city: this.state.city,
                        state: this.state.state,
                        zip: this.state.zip
                    },
                    start: start,
                    end: end,
                    user_id: this.state.user_id,
                    type,
                    notes: this.state.notes,
                    notification,
                    remove_options: this.state.removeOptions
                }
            };

            if (this.props.repair) {
                data.appointment.repair_id = this.props.repair.id;
            } else if (this.props.repairItem) {
                data.appointment.repair_item_id = this.props.repairItem.id;
            }

            $.ajax(url, {
                method,
                data
            }).done(res => {
                if (this.props.apptCallback) {
                    let success, errors, appointment;
                    if (method === "POST") {
                        appointment = res;
                    } else {
                        appointment = { ...this.state };
                    }

                    this.props.apptCallback(appointment);
                }

                this.setState({ ...this.baseState, success: true });
                $(this.modalEl).modal("hide");

                toast.success("Successfully saved Appointment", {
                    position: toast.POSITION.TOP_CENTER
                });
            }).fail(res => {
                this.setState({ errors: res.responseJSON.errors, success: false });

                toast.fail("Something went wrong with saving the Appointment...", {
                    position: toast.POSITION.TOP_CENTER
                });
            });
        }
    };

    updateStateFromInput = (prop, val) => {
        this.setState({ [prop]: val });
    };

    displayOriginalAlert = () => {
        if ((this.props.appointment || {}).options) {
            const parsedStart = moment(this.state.start).tz(this.props.timezone);
            const parsedOriginal = moment(this.props.appointment.options.original_start).tz(this.props.timezone);

            if (parsedStart.format("dddd") !== parsedOriginal.format("dddd")) {
                return true;
            } else if (this.state.user_id !== parseInt(this.props.appointment.options.original_user)) {
                return true
            } else {
                return false
            }
        } else {
            return false;
        }
    };

    findUser = (id) => {
        return this.props.techs.find(t => t.id === id);
    };

    revertToOriginal = (e) => {
        e.preventDefault();

        const apptOptions = this.props.appointment.options
        const originalUser = this.findUser(apptOptions.original_user);
        const originalStart = moment.tz(apptOptions.original_start, "UTC");
        const nextDate = DateHelper.findNextDay(originalStart, this.props.timezone);
        const timeToService = this.props.appointment.account.time_to_service;

        const end = moment(nextDate.format()).add(timeToService, "minutes");

        this.setState({ start: nextDate.format(), end: end.format(), removeOptions: true, user_id: originalUser.id });

        this.startInput.value = moment(nextDate.format()).tz(this.props.timezone).format("MM/DD/YYYY hh:mm A");
        this.endInput.value = moment(end.format()).tz(this.props.timezone).format("MM/DD/YYYY hh:mm A");
    };

    render() {
        return(
            <div
                id="appointment-modal"
                className="modal fade"
                ref={(e) => this.modalEl = e}
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button className="close" type="button" data-dismiss="modal">
                                <span>
                                    &times;
                                </span>
                            </button>
                            <h4 className="modal-title">
                                Create Appointment
                            </h4>
                        </div>
                        <div className="modal-body">
                            { this.props.base_plan && this.props.base_plan === "lite" &&
                                <LockedFeature
                                    plan_required="plus"
                                    image="https://s3.amazonaws.com/superswimbros.dev/other_appointment_locked.png"
                                    feature_text="Appointment Scheduling"
                                />
                            }
                            { (!this.props.base_plan || this.props.base_plan !== "lite") &&
                                <Fragment>
                                    { this.state.errors &&
                                        <div className="alert alert-warning">
                                            <ul>
                                                { Object.keys(this.state.errors).map((error, index) => {
                                                    return(
                                                        <li key={index}>
                                                            <b>{ error }: </b>
                                                            { this.state.errors[error] }
                                                        </li>
                                                    )
                                                }) }
                                            </ul>
                                        </div>
                                    }
                                    <div className="row">
                                        <div className="col-md-12">
                                            { (this.displayOriginalAlert() && (this.props.current_user && this.props.current_user.roles.includes("admin"))) &&
                                                <div className="alert alert-warning">

                                                    <h5 className="text-center">
                                                        { this.props.appointment.user_id === this.props.appointment.options.original_user &&
                                                            <span>
                                                                This appointment is originally scheduled for { moment(this.props.appointment.options.original_start).format("dddd") }'s -
                                                            </span>
                                                        }
                                                        { this.props.appointment.user_id !== this.props.appointment.options.original_user &&
                                                            <span>
                                                                This appointment is originally assigned to { this.findUser(this.props.appointment.options.original_user).name } on { moment(this.props.appointment.options.original_start).format("dddd") }'s -
                                                            </span>
                                                        }
                                                        <a
                                                            className="margin-5-left"
                                                            href="#"
                                                            onClick={this.revertToOriginal}
                                                        >
                                                            Revert Back
                                                        </a>
                                                    </h5>
                                                </div>
                                            }
                                            { (!this.state.account && (this.props.type !== "repair" && !this.props.repairItem)) &&
                                                <div className="form-group location">
                                                    <h4>
                                                        Location:
                                                    </h4>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Address"
                                                                value={this.state.address}
                                                                onChange={(e) => this.updateStateFromInput("address", e.target.value)}
                                                            />
                                                        </div>
                                                        <div className="col-md-3">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="City"
                                                                value={this.state.city}
                                                                onChange={(e) => this.updateStateFromInput("city", e.target.value)}
                                                            />
                                                        </div>
                                                        <div className="col-md-2">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="State"
                                                                value={this.state.state}
                                                                onChange={(e) => this.updateStateFromInput("state", e.target.value)}
                                                            />
                                                        </div>
                                                        <div className="col-md-3">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Zip"
                                                                value={this.state.zip}
                                                                onChange={(e) => this.updateStateFromInput("zip", e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            <div className="form-group">
                                                <h4>
                                                    Start Time:
                                                </h4>
                                                <input
                                                    name="_start"
                                                    min={moment().tz(this.props.timezone).format("YYYY-MM-DDTHH:mm")}
                                                    className="form-control datetime-picker"
                                                    // the value gets set initially for this in the componentDidMount() public function
                                                    ref={(input) => this.startInput = input}
                                                    readOnly
                                                />
                                            </div>
                                            <div className="form-group">
                                                <h4>
                                                    End Time:
                                                </h4>
                                                <input
                                                    name="_end"
                                                    className="form-control datetime-picker"
                                                    // the value gets set initially for this in the componentDidMount() public function
                                                    ref={(input) => this.endInput = input}
                                                    readOnly
                                                />
                                            </div>
                                            <div className="form-group">
                                                <h4>
                                                    Technician:
                                                </h4>
                                                <select
                                                    name="tech"
                                                    className="form-control"
                                                    value={this.state.user_id}
                                                    onChange={(e) => this.updateStateFromInput("user_id", e.target.value)}
                                                >
                                                    <option value="">Select technician...</option>
                                                    { this.props.techs.map((tech, index) => {
                                                        return(
                                                            <option
                                                                key={index}
                                                                value={tech.id}
                                                            >
                                                                { tech.name }
                                                            </option>
                                                        )
                                                    }) }
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <h4>
                                                    Notes:
                                                </h4>
                                                <textarea
                                                    className="form-control"
                                                    value={this.state.notes}
                                                    onChange={(e) => this.updateStateFromInput("notes", e.target.value)}
                                                >
                                                </textarea>
                                                <div className="margin-10-top">
                                                    <label htmlFor="notification">
                                                        Send notification to technician:&nbsp;
                                                    </label>
                                                    <input
                                                        id="notification"
                                                        type="checkbox"
                                                        checked={this.state.notification}
                                                        onChange={(e) => this.updateStateFromInput("notification", !this.state.notification)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>
                            }
                        </div>
                        <div className="modal-footer">
                            { (!this.props.base_plan || this.props.base_plan !== "lite") &&
                                <button
                                    className="btn btn-success"
                                    type="button"
                                    onClick={this.saveAppointment}
                                >
                                    Save
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
