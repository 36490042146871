import PropTypes from 'prop-types';
import React from 'react';
import ImageThumbnail from './ImageThumbnail';
import ImageModal from './ImageModal';
import ImageCompressor from 'image-compressor.js';

import Utils from 'Utils';
import ImageUtils from './Utils';
import Timeout from '@main/Timeout';
import { toast } from 'react-toastify';

export default class ImageArea extends React.Component {
    static propTypes = {
        images: PropTypes.array.isRequired,
        resource_name: PropTypes.string.isRequired,
        multiple_allowed: PropTypes.bool.isRequired,
        resource_id: PropTypes.number,
        timestamp_photos: PropTypes.bool,
        updateImagesCallback: PropTypes.func,
        preventRetry: PropTypes.bool,
        disableCaption: PropTypes.bool,
        makePublic: PropTypes.bool,
        destroyCallback: PropTypes.func
    }

    constructor(props) {
        super(props);

        this.state = {
            images: props.images,
            showImages: false,
            activeModal: 0,
            destroyingImage: false
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (JSON.stringify(prevState.images) !== JSON.stringify(this.state.images)) {
            if (this.props.updateImagesCallback) this.props.updateImagesCallback(this.state.images);
        }

        if (JSON.stringify(prevProps.images) !== JSON.stringify(this.props.images)) {
            this.setState({ images: this.props.images })
        }
    }

    showImages = () => {
        this.setState({ showImages: true })
    };

    destroyImage = () => {
        if (confirm("Are you sure you would like to destroy this image? This cannot be undone.")) {
            this.setState({ destroyingImage: true });

            const modal = $("#image-modal");
            const activeIndex = $(modal.find(".item.active")).data("index");
            const imageId = this.state.images[activeIndex].id;

            if (imageId) {
                $.ajax(`/images/${imageId}.json`, {
                    method: "DELETE",
                    timeout: Timeout.RESOURCE,
                    success: () => {
                        modal.modal("hide");

                        const images = [...this.state.images.filter(img => img.id !== imageId)];

                        this.setState({ images, destroyingImage: false });
                    },
                    error: (jqXHR, textStatus, errorThrown) => {
                        toast.warn("No Internet Connection detected, please try again once internet is available.", {
                            position: toast.POSITION.TOP_CENTER
                        });

                        this.setState({ destroyingImage: false });

                        return false;
                    }
                });
            } else {
                modal.modal("hide");
                const images = [...this.state.images.filter((img, index) => index !== activeIndex)];

                this.setState({ images });
            }
        }
    };

    addNewImages = () => {
        this.fileInput.click();
        $(this.fileInput).on("change", (e) => {
            if (this.fileInput.value === "") return;

            const files = Array.from(e.target.files);

            const images = files.map(file => ({ format: file.type.split("/")[1], full_url: "", loading: true, status: "adding" }));

            this.setState({ images: [...this.state.images, ...images], showImages: true });

            const compressionPromises = files.map(file => {
                return new Promise((resolve, reject) => {
                    new ImageCompressor(file, {
                        quality: 0.6,
                        beforeDraw: (ctx, canvas) => {
                            setTimeout(() => {
                                if (this.props.timestamp_photos) {
                                    const timestamp = moment().format("MM-DD-YYYY hh:mm A");
                                    const fontSize = canvas.width * 0.03;
                                    const totalFont = `${fontSize}px Arial`;

                                    ctx.font = totalFont;
                                    ctx.textAlign = "right";

                                    const measuredWidth = ctx.measureText(timestamp).width;

                                    ctx.fillStyle = "black";
                                    ctx.fillRect((canvas.width - measuredWidth) - 5, (canvas.height - fontSize), measuredWidth, fontSize);
                                    ctx.fillStyle = "white";
                                    ctx.fillText(timestamp, canvas.width - 5, canvas.height - 5);
                                }
                            });
                        },
                        success(result) {
                            resolve(result)
                        }
                    });
                });
            });

            Promise.all(compressionPromises).then(compressedImages => {
                const base64Promises = compressedImages.map(ci => Utils.blobToDataURI(ci));
                Promise.all(base64Promises).then(results => {
                    const existing = this.state.images.filter(i => {
                        if (i.id) return true;
                        if (i.full_url === "") return false;

                        return !results.includes(i.full_url);
                    });
                    const rest = compressedImages.map((file, index) => ({ format: file.type.split("/")[1], full_url: results[index], loading: true, status: "compressed" }));

                    this.setState({ images: [...existing, ...rest] });

                    const createImagePromises = rest.map(image => ImageUtils.createImageRecord(image, this.props.resource_name, this.props.resource_id));

                    Promise.all(createImagePromises).then(imageRecords => {
                        const imageRecordIds = imageRecords.map(i => i.id).filter(Boolean);
                        const existing = this.state.images.filter(i => {
                            if (i.id) return !imageRecordIds.includes(i.id);

                            return !results.includes(i.full_url);
                        });

                        this.setState({ images: [...existing, ...imageRecords] });

                        if (!ImageUtils.allSucceeded(imageRecords)) return false;

                        const uploadImagePromises = imageRecords.map((image, index) => ImageUtils.uploadImage(image, compressedImages[index]));

                        Promise.all(uploadImagePromises).then(uploadedImages => {
                            this.setState({ images: [...existing, ...uploadedImages] });

                            if (!ImageUtils.allSucceeded(uploadedImages)) return false;

                            const completedImagePromises = uploadedImages.map(i => ImageUtils.markImageAsCompleted(i, this.props.makePublic))

                            Promise.all(completedImagePromises).then((completedImages) => {
                                this.setState({ images: [...existing, ...completedImages] });
                            });
                        });
                    });
                });
            });

            this.fileInput.value = null;
        });
    };

    saveImageCaption = (imageId, caption, callback) => {
        $.ajax({
            method: "PUT",
            url: `/images/${imageId}`,
            data: {
                image: { caption }
            }
        }).done(() => {
            toast.success("Successfully updated Image!", {
                position: toast.POSITION.TOP_CENTER
            })

            const images = this.state.images.map(image => {
                if (image.id !== imageId) {
                    return { ...image }
                } else {
                    return { ...image, caption }
                }
            })

            this.setState({ images }, () => {
                if (callback) callback();
            });
        }).fail(() => {
            toast.error("Unable to update Image, please try again later...", {
                position: toast.POSITION.TOP_CENTER
            })
        })
    };

    updateImage = (image, index) => {
        const images = [...this.state.images].map((img, idx) => {
            if (idx === index) {
                return { ...image }
            } else {
                return img
            }
        });

        this.setState({ images });
    };

    processImage = (image, index, callback) => {
        const images = [...this.state.images].map((img, idx) => {
            if (index === idx) {
                return { ...image, loading: true };
            } else {
                return { ...img }
            }
        });

        this.setState({ images });

        ImageUtils.process([image], image.resource_name, image.resource_id, (images) => {
            const processedImage = images[0];

            const newImages = [...this.state.images].map((i, idx) => {
                if (index === idx) {
                    return { ...processedImage, loading: false };
                } else {
                    return { ...i };
                }
            });

            callback();

            this.setState({ images: newImages });
        });
    };

    render() {
        let multipleClass = "display-flex image-wrapper";
        if (this.props.multiple_allowed) multipleClass += " multiple";
        return(
            <div className="row image-area padding-10-top padding-10-bottom">
                <div className="col-xs-12 display-flex">
                    <input
                        type="file"
                        accept="image/jpg,image/jpeg,image/png"
                        multiple={true}
                        className="display-none"
                        ref={(input) => this.fileInput = input}
                    />
                    { !this.state.showImages && this.state.images.length > 0 &&
                        <div className="display-flex margin-auto">
                            <button
                                className="btn btn-primary display-images"
                                onClick={() => this.showImages()}
                                type="button"
                            >
                                { this.state.images.length > 1 ? "Show Images" : "Show Image" }
                            </button>
                        </div>
                    }
                    { this.state.images.length === 0 &&
                        <div className="display-flex margin-auto">
                            <div className="upload-wrapper cursor-pointer">
                                <button
                                    className="btn btn-primary float-left margin-left-6"
                                    type="button"
                                    onClick={this.addNewImages}
                                >
                                    { this.props.multiple_allowed ? "Attach Images" : "Attach Image" }
                                </button>
                            </div>
                        </div>
                    }
                    { this.state.showImages && this.state.images.length > 0 &&
                        <div className={multipleClass}>
                            { this.props.multiple_allowed &&
                                <span className="add-image glyphicon glyphicon-plus color-gray cursor-pointer" onClick={this.addNewImages}></span>
                            }
                            {
                                this.state.images.map((image, index) => {
                                    return(
                                        <ImageThumbnail
                                            image={image}
                                            index={index}
                                            key={index}
                                            processImage={this.processImage}
                                            updateImage={this.updateImage}
                                            preventRetry={!!this.props.preventRetry}
                                        />
                                    )
                                })
                            }
                        </div>
                    }
                </div>
                { this.state.showImages &&
                    <ImageModal
                        images={this.state.images}
                        destroyImage={this.destroyImage}
                        saveImageCaption={this.saveImageCaption}
                        destroyingImage={this.state.destroyingImage}
                        disableCaption={this.props.disableCaption}
                    />
                }
            </div>
        )
    }
}
