import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import ExternalCustomers from './ExternalCustomers';

import { ExternalMatchConsumer } from './Provider';

export default class MatchDetails extends React.Component {
    static propTypes = {
        account: PropTypes.object.isRequired,
        integration: PropTypes.string.isRequired,
    }

    matchedExternalCustomer = () => {
        return this.props.externalCustomers.find(cust => {
            if (this.props.integration.toLowerCase() === "quickbooks") {
                return cust["Id"] === this.props.account.external_invoice_profile_id.toString();
            } else if (this.props.integration.toLowerCase() === "freshbooks") {
                return cust["id"].toString() === this.props.account.external_invoice_profile_id.toString();
            }
        });
    };

    render() {
        return(
            <ExternalMatchConsumer>
                {(context) => (
                    <div className="external-search-wrapper display-flex flex-column margin-10-bottom">
                        { this.props.account.external_invoice_profile_id &&
                            <Fragment>
                                <h4 className="text-center">
                                    Matched { context.integration } customer:
                                </h4>
                                <div className="display-flex justify-content-space-between">
                                    <div className="matched-desc display-flex flex-column">
                                        <div className="header-wrapper display-flex">
                                            <div className="header">
                                                Name:
                                            </div>
                                            <div className="header">
                                                Address:
                                            </div>
                                            <div className="header">
                                                Email Address:
                                            </div>
                                        </div>
                                        <div className="display-flex">
                                            <div className="header-value">
                                                { context.externalProp("name", this.matchedExternalCustomer()) }
                                            </div>
                                            <div className="header-value">
                                                { context.externalProp("address", this.matchedExternalCustomer()) }
                                            </div>
                                            <div className="header-value">
                                                { context.externalProp("email", this.matchedExternalCustomer()) }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="match-button">
                                        <button className="btn btn-danger" onClick={() => context.updateMatched(this.props.account.id, null)}>
                                            Unmatch
                                        </button>
                                    </div>
                                </div>
                            </Fragment>
                        }
                        { !this.props.account.external_invoice_profile_id && this.props.activated &&
                            <ExternalCustomers
                                integration={context.integration}
                                account={this.props.account}
                                externalCustomers={context.externalCustomers}
                                updateMatched={context.updateMatched}
                                externalProp={context.externalProp}
                                filterExternalCustomers={context.filterExternalCustomers}
                            />
                        }
                    </div>
                )}
            </ExternalMatchConsumer>

        )
    }
}
