import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Prices from './Prices';
import SubUtils from './SubUtils';
import Utils from '../../Utils';

export default class PricingInfo extends React.Component {
    static propTypes = {
        plan: PropTypes.string.isRequired,
        buttonTxt: PropTypes.string.isRequired,
        minAmount: PropTypes.number,
        callback: PropTypes.func,
        updateCallback: PropTypes.func,
        activePlan: PropTypes.string,
        selectedAccounts: PropTypes.string,
        buttonDisabled: PropTypes.bool
    }

    constructor(props) {
        super(props);

        let selectedAccounts;
        if (props.plan === "pro") {
            if (props.selectedAccounts === "60") {
                selectedAccounts = "80";
            } else if (props.selectedAccounts === "100") {
                selectedAccounts = "120";
            }
        }

        this.baseState = {
            selectedAccounts: props.selectedAccounts ? (selectedAccounts || props.selectedAccounts) : Prices[props.plan].count
        }

        if (this.minAmount() > this.baseState.selectedAccounts) {
            this.baseState.selectedAccounts = this.minAmount();
        }

        this.state = this.baseState;
    }

    handleChange = (e) => {
        let selectedAccounts;

        if (this.props.plan === "pro") {
            const parsedValue = parseInt(e.target.value);
            if (parsedValue > 40 && parsedValue <= 80) {
                selectedAccounts = 80;
            } else if (parsedValue > 80 && parsedValue <= 120) {
                selectedAccounts = 120;
            } else {
                selectedAccounts = parsedValue;
            }
        } else {
            selectedAccounts = e.target.value;
        }

        this.setState({ selectedAccounts }, () => {
            if (this.props.updateCallback) {
                this.props.updateCallback(this.props.plan, this.state.selectedAccounts);
            }
        });
    };

    submit = () => {
        window.localStorage.setItem("activePlan", this.props.plan);
        window.localStorage.setItem("selectedAccounts", this.state.selectedAccounts);

        window.location = "/onboarding/sign_up";
    };

    callback = () => {
        if (this.props.callback) {
            this.props.callback(this.props.plan, this.state.selectedAccounts)
        } else {
            this.submit();
        }
    };

    minAmount = () => {
        if (!this.props.minAmount || this.props.minAmount <= 40) {
            return 40
        } else {
            const remainder = this.props.minAmount % 20;
            return this.props.minAmount + (20 - remainder);
        }
    };

    render() {
        return(
            <Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <h3 className="text-center">
                            { this.state.selectedAccounts } Accounts
                        </h3>

                        <h4 className="text-center width-100">
                            { SubUtils.userLimitText(this.props.plan) }
                        </h4>

                        <h4 className="text-center width-100">
                            { Prices.monthlyPrice(this.state.selectedAccounts, this.props.plan) }/month
                        </h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-md-offset-3">
                        <input
                            className="no-margin-bottom"
                            type="range"
                            min={this.minAmount()}
                            max="10000"
                            step="20"
                            onChange={this.handleChange}
                            onInput={this.handleChange}
                            value={this.state.selectedAccounts}
                        />
                        <div className="display-flex justify-content-center slider-label">
                            (use slider to adjust number of accounts)
                        </div>
                    </div>
                </div>
                <div className="row margin-20-top">
                    <div className="col-md-12 display-flex justify-content-center">
                        <button
                            className="btn btn-lg btn-success"
                            onClick={this.callback}
                            disabled={this.props.buttonDisabled}
                        >
                            { this.props.buttonTxt }
                        </button>
                    </div>
                </div>
            </Fragment>
        )
    }
}
