import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

export default class RepairAttachment extends React.Component {
    static propTypes = {
        launchModal: PropTypes.func.isRequired,
        type: PropTypes.string.isRequired,
        repairId: PropTypes.number.isRequired,
        attachment: PropTypes.object
    }

    iconClass = () => {
        switch(this.props.type) {
            case 'email':
                return 'fa-envelope-o';
            case 'text':
                return 'fa-comment-o';
            case 'web':
                return 'fa-globe';
        }
    };

    render() {
        return(
            <div className="panel panel-default">
                <div className="panel-body">
                    <div className="row display-flex align-items-center">
                        <div className="col-xs-9">
                            <i className={`fa ${this.iconClass()} margin-10-right`}></i>
                            { this.props.attachment &&
                                <Fragment>
                                    { !this.props.attachment.name && this.props.attachment.id &&
                                        "( Custom )"
                                    }
                                </Fragment>
                            }
                            { (!this.props.attachment || !this.props.attachment.id) &&
                                <span className="color-gray">
                                    ( Default )
                                </span>
                            }
                        </div>
                        <div className="col-xs-3">
                            <div className="pull-right">
                                <button
                                    className="btn btn-default"
                                    type="button"
                                    onClick={(e) => this.props.launchModal(this.props.attachment || { type: this.props.type, resource_name: "repair", resource_id: this.props.repairIid })}
                                >
                                    <i className="fa fa-pencil"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
