import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Income from './Income';
import Payout from './Payout';

import Expenses from './Expenses';

import LockedFeature from '../../Subscription/LockedFeature';

export default class IncomeExpenses extends React.Component {
    static propTypes = {
        currentCompany: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);

        this.baseState = {
            selectedReports: ["income"]
        }

        this.state = this.baseState;
    }

    selectExpenses = () => {
        if (this.state.selectedReports.includes("expenses")) return true;

        this.setState({ selectedReports: ["income", "expenses"] });
    };

    render() {
        return(
            <Fragment>
                { this.props.currentCompany.invoice_integration !== "stripe" &&
                    <Fragment>
                        <LockedFeature
                            plan_required="aquabiller"
                            image="https://s3.amazonaws.com/superswimbros.dev/income-expenses-report-locked.png"
                            feature_text="Enable AquaBiller to unlock the Income/Expenses Report"
                        />
                    </Fragment>
                }
                { this.props.currentCompany.invoice_integration === "stripe" &&
                    <div className="row income-expenses-wrapper">
                        <div className="col-md-12">
                            <ul className="nav nav-tabs">
                                <li className="active">
                                    <a href="#income" data-toggle="tab">
                                        Income
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="#expenses"
                                        data-toggle="tab"
                                        onClick={this.selectExpenses}
                                    >
                                        Expenses
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="tab-content col-md-12 margin-20-top">
                            <div className="tab-pane active" id="income">
                                { this.state.selectedReports.includes("income") &&
                                    <Fragment>
                                        <Payout timezone={this.props.currentCompany.timezone} />
                                        <hr/>
                                        <Income />
                                    </Fragment>
                                }
                            </div>
                            <div
                                className="tab-pane"
                                id="expenses"
                                ref={(e) => this.expensesTabEl = e}
                            >
                                { this.state.selectedReports.includes("expenses") &&
                                    <Expenses />
                                }
                            </div>
                        </div>
                    </div>
                }
            </Fragment>
        )
    }
}
