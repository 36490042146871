import PropTypes from 'prop-types';
import React from 'react';

const BoxInput = (props) => {
    const renderBoxInputs = () => {
        return props.valueRange.map((val, index) => {
            const parsedVal = val.match(/\d\.\d/)[0];
            const className = props.selectedValue === parsedVal ? "special-select selected" : "special-select";

            return(
                <div className="select" key={index}>
                    <div className={className} onClick={() => { props.setSelectedValue(parsedVal) }}>
                        { val }
                    </div>
                </div>
            )
        });
    };

    return (
        <div className="form-group">
            <h4 className="no-margin">
                { `${props.heading}:` }
            </h4>
            <div className="last-reading">
                { `Previous reading: ${props.previousReading}` }
            </div>
            <div
                className="display-flex overflow-x-scroll"
            >
                <div className={"special-select-wrapper"}>
                    { renderBoxInputs() }
                </div>
            </div>
        </div>
    )
}

BoxInput.propTypes = {
    heading: PropTypes.string.isRequired,
    previousReading: PropTypes.string.isRequired,
    valueRange: PropTypes.array.isRequired,
    setSelectedValue: PropTypes.func.isRequired,
    selectedValue: PropTypes.string,
};

export default BoxInput;
