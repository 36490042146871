import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import BoxInput from './BoxInput';
import ServiceSetInput from './ServiceSetInput';
import ServiceItem from './ServiceItem';
import Switch from '@shared/components/CheckboxSwitch';
import ImageArea from '@shared/components/ImageArea/ImageArea';

import Utils from 'Utils';
import RouteUtils from '../Utils';

export default class ServiceStopForm extends React.Component {
    static propTypes = {
        saveServiceStop: PropTypes.func.isRequired,
        updateServiceStop: PropTypes.func.isRequired,
        uniqueKey: PropTypes.number.isRequired,
        saving: PropTypes.bool.isRequired,
        appointment: PropTypes.object,
        account: PropTypes.object.isRequired,
        service_stop: PropTypes.object.isRequired,
        chemical_units: PropTypes.object.isRequired,
        pool_conditions: PropTypes.object.isRequired,
        master_service_items: PropTypes.array.isRequired,
        is_route_tech: PropTypes.bool.isRequired,
        timezone: PropTypes.string.isRequired,
        base_plan: PropTypes.string.isRequired,
        quick_repairs_enabled: PropTypes.bool.isRequired,
        chemical_readings: PropTypes.array.isRequired,
        addable_chemicals: PropTypes.array.isRequired,
        default_tasks: PropTypes.array.isRequired,
        allRouteRemindersValid: PropTypes.func,
        showQuickRepairModal: PropTypes.func
    }

    constructor(props) {
        super(props);

        this.setBaseServiceStopAttrs();
        this.handleInOutTimeElements();

        this.baseState = {
            accountSettings: { ...props.account.parsed_settings },
            validations: {
                outTime: false,
                inTime: false
            }
        }

        this.state = this.baseState;
    }

    componentDidMount() {
        $(".datetime-picker").datetimepicker({
            ignoreReadonly: true,
            allowInputToggle: true,
            showClose: true,
            format: "MM/DD/YYYY hh:mm A",
            icons: {
                time: "glyphicon dt-picker-icon-time",
                close: "glyphicon dt-picker-icon-close",
                date: "glyphicon dt-picker-icon-date"
            }
        });

        $(".datetime-picker").on("dp.change", (e) => {
            const service_stop = this.props.service_stop;
            this.props.updateServiceStop({ ...service_stop, [e.target.name]: moment(e.target.value, "MM/DD/YYYY hh:mm A").format("YYYY-MM-DD HH:mm") });
        });

        if (this.props.service_stop.in_time) {
            this.inTimeInput.value = moment(this.props.service_stop.in_time).tz(this.props.timezone).format("MM/DD/YYYY hh:mm A");
        }

        if (this.props.service_stop.out_time) {
            this.outTimeInput.value = moment(this.props.service_stop.out_time).tz(this.props.timezone).format("MM/DD/YYYY hh:mm A");
        }
    }

    componentDidUpdate(prevProps) {
        if ((prevProps.appointment || {}).id !== (this.props.appointment || {}).id) {
            const serviceStop = (this.props.appointment.service_stop || {})
            if (serviceStop.in_time) {
                this.inTimeInput.value = moment(serviceStop.in_time).format("MM/DD/YYYY hh:mm A");
            } else {
                this.inTimeInput.value = "";
            }

            if (serviceStop.out_time) {
                this.outTimeInput.value = moment(serviceStop.out_time).format("MM/DD/YYYY hh:mm A");
            } else {
                this.outTimeInput.value = "";
            }

            this.setState({ selectedBow: this.findSelectedBow() });
        }

        this.setBaseServiceStopAttrs(prevProps);
        this.handleInOutTimeElements();
    }

    setBaseServiceStopAttrs = (prevProps) => {
        let serviceStop = { ...this.props.service_stop };
        let updateServiceStop;

        if (!Object.keys(serviceStop).includes("charge_for_service_stop")) {
            serviceStop.charge_for_service_stop = true;
            updateServiceStop = true;
        }

        if (!Object.keys(serviceStop).includes("notification")) {
            serviceStop.notification = this.props.account.parsed_settings.require_service_stop_notification;
            updateServiceStop = true;
        }

        if (!Object.keys(serviceStop).includes("water_level")) {
            serviceStop.water_level = "Normal";
        }

        if (!Object.keys(serviceStop).includes("pool_condition")) {
            serviceStop.pool_condition = this.props.pool_conditions.clear.toString();
        }

        if (!Object.keys(serviceStop).includes("custom_fields")) {
            serviceStop.custom_fields = { tasks_completed: [] }
            updateServiceStop = true;
        }

        if (updateServiceStop) this.props.updateServiceStop(serviceStop);
        if (prevProps && prevProps.appointment && prevProps.appointment.id !== this.props.appointment.id) this.setState({ ...this.baseState });
    };

    findSelectedBow = () => {
        if (this.props.account.body_of_waters.length > 0) {
            return this.props.account.body_of_waters.find(bow => bow.main);
        } else {
            return null;
        }
    };

    handleInOutTimeElements = () => {
        const dtp = document.querySelectorAll(".datetime-picker");
        if (dtp[0]) {
            if (!this.props.account.parsed_settings.allow_settable_appt_times) {
                Array.from(dtp).forEach(el => $(el).data("DateTimePicker").disable())
            } else {
                Array.from(dtp).forEach(el => $(el).data("DateTimePicker").enable())
            }
        }
    };

    logTime = (which) => {
        if (which === "out" && this.props.account.parsed_settings.require_in_time && !this.props.service_stop.in_time) {
            this.setState({ validations: { ...this.state.validations, inTime: true } }, () => {
                const scrollToElement = $(".ticket-account-notes")[0] || $(this.inTimeValidationEl)[0];

                if (scrollToElement) scrollToElement.scrollIntoView({ behavior: "smooth", block: "center" });
            });
        } else {
            this.setState({ validations: { ...this.state.validations, inTime: false } }, () => {
                const serviceStop = { ...this.props.service_stop, [`${which}_time`]: moment().format("YYYY-MM-DD HH:mm") };
                this.props.updateServiceStop(serviceStop);

                this[`${which}TimeInput`].value = moment().format("MM/DD/YYYY hh:mm A");
            });
        }
    };

    selectedServiceSet = () => {
        if (this.props.account.body_of_waters.length > 0 && this.state.selectedBow) {
            if (!this.state.selectedBow || this.state.selectedBow.main) {
                return this.props.service_stop;
            }

            const ss = this.props.service_stop;
            const ssa = (ss.service_sets_attributes || ss.service_sets || []).find(ssa => ssa.body_of_water_id === this.state.selectedBow.id);

            return ssa || {};
        } else {
            return this.props.service_stop
        }
    };

    previousReading = (attr) => {
        let bowName = "main";
        if (this.state.selectedBow && !this.state.selectedBow.main) {
            bowName = this.state.selectedBow.name;
        }
        const readings = this.props.account.previous_readings[bowName];

        if (readings) {
            const value = readings[attr].value;

            if (value) {
                if (attr === "pool_condition") {
                    const conditionName = this.props.pool_conditions[value]
                    return `${conditionName} - ${readings[attr].date}`
                } else if (attr === "back_washed") {
                    return `Last done on ${readings[attr].date}`;
                } else {
                    return `${readings[attr].value} - ${readings[attr].date}`;
                }
            } else {
                return "Never recorded";
            }
        } else {
            return "Never recorded";
        }
    };

    setServiceSetAttr = (attr, value, customTask = null) => {
        const ss = { ...this.props.service_stop };
        if (!this.state.selectedBow || this.state.selectedBow.main) {
            if (customTask) {
                let tasksCompleted = ss.custom_fields.tasks_completed
                if (tasksCompleted.includes(customTask)) {
                    tasksCompleted = tasksCompleted.filter(t => t !== customTask);
                } else {
                    tasksCompleted = [...tasksCompleted, customTask];
                }

                ss.custom_fields.tasks_completed = tasksCompleted
            } else {
                ss[attr] = value;
            }

            this.props.updateServiceStop(ss);
        } else {
            let serviceSetAttributes;
            if ((ss.service_sets_attributes || ss.service_sets || []).length < 1) {
                serviceSetAttributes = [{ body_of_water_id: this.state.selectedBow.id }]

                if (customTask) {
                    serviceSetAttributes[0].custom_fields = { tasks_completed: [customTask] }
                } else {
                    serviceSetAttributes[0][attr] = value;
                }
            } else {
                const serviceSets = ss.service_sets_attributes || ss.service_sets;

                const serviceSet = serviceSets.find(ssa => ssa.body_of_water_id === this.state.selectedBow.id) || { body_of_water_id: this.state.selectedBow.id };
                const filteredServiceSets = serviceSets.filter(ssa => ssa.body_of_water_id !== this.state.selectedBow.id);

                if (customTask) {
                    let tasksCompleted = ((serviceSet.custom_fields || {}).tasks_completed || [])
                    if (tasksCompleted.includes(customTask)) {
                        tasksCompleted = tasksCompleted.filter(t => t !== customTask);
                    } else {
                        tasksCompleted = [...tasksCompleted, customTask];
                    }

                    serviceSet.custom_fields = { ...serviceSet.custom_fields, tasks_completed: tasksCompleted }
                } else {
                    serviceSet[attr] = value;
                }

                serviceSetAttributes = [...filteredServiceSets, serviceSet]
            }

            const serviceStop = { ...this.props.service_stop, service_sets_attributes: serviceSetAttributes };
            this.props.updateServiceStop(serviceStop);
        }
    };

    chargeForChemical = (chem) => {
        return this.state.accountSettings[`charge_for_${chem}`];
    };

    updateAccountSettings = (key, val) => {
        this.setState({ accountSettings: { ...this.state.accountSettings, [key]: val } });
    };

    hasChargeForServiceStopDefined = () => {
        return Object.keys(this.props.service_stop || {}).includes("charge_for_service_stop");
    }

    chargeForServiceStop = () => {
        if (!this.hasChargeForServiceStopDefined()) {
            return true
        } else {
            return this.props.service_stop.charge_for_service_stop;
        }
    };

    toggleChargeForServiceStop = () => {
        let serviceStop;
        if (!this.hasChargeForServiceStopDefined()) {
            serviceStop = { ...this.props.service_stop, charge_for_service_stop: false }
        } else {
            serviceStop = { ...this.props.service_stop, charge_for_service_stop: !this.props.service_stop.charge_for_service_stop };
        }

        this.props.updateServiceStop(serviceStop);
    };

    setChargeForAllChemicals = () => {
        const accountSettings = { ...this.state.accountSettings };
        this.props.addable_chemicals.forEach(chem => accountSettings[`charge_for_${chem}`] = !this.chargeForAllChemicals());
        this.setState({ accountSettings });
    };

    chargeForAllChemicals = () => {
        const values = [];
        for (const [key, value] of Object.entries(this.state.accountSettings)) {
            if (key.match(/charge\_for\_/)) {
                values.push(value);
            }
        }

        return !values.includes(false);
    };

    enabledTasks = () => {
        const tasks = this.props.account.tasks_completed
        return Object.keys(tasks).filter(task => tasks[task].enabled).sort();
    };

    isDefaultTask = (task) => {
        return this.props.default_tasks.includes(task);
    };

    hasTaskCompleted = (task) => {
        if (this.isDefaultTask(task)) {
            return !!this.selectedServiceSet()[task];
        } else {
            const customFields = this.selectedServiceSet().custom_fields;
            return !!(customFields && customFields.tasks_completed.includes(task));
        }
    };

    toggleTask = (task) => {
        if (this.isDefaultTask(task)) {
            this.setServiceSetAttr(task, !this.selectedServiceSet()[task])
        } else {
            this.setServiceSetAttr(task, null, task)
        }
    };

    poolConditionOptions = () => {
        const options = [];

        let poolConditions = Object.keys(this.props.pool_conditions).reverse();

        poolConditions.forEach(condition => {
            options.push(
                <option key={condition} value={this.props.pool_conditions[condition]}>
                    { Utils.humanize(condition) }
                </option>
            );
        });

        return options;
    };

    selectedPoolCondition = () => {
        if (this.selectedServiceSet()["pool_condition"]) {
            return this.selectedServiceSet()["pool_condition"][0]
        } else {
            return "4"
        }
    };

    serviceItems = () => {
        const serviceSet = this.selectedServiceSet()
        return serviceSet["service_items"] || [];
    };

    updateServiceItem = (si, index) => {
        const serviceSet = this.selectedServiceSet();
        const serviceItems = [...serviceSet.service_items].map((_si, _index) => {
            if (_index === index) {
                return { ...si }
            } else {
                return { ..._si }
            }
        });

        this.setServiceSetAttr("service_items", serviceItems);
    };

    addServiceItem = (e) => {
        e.preventDefault();
        const serviceItems = this.selectedServiceSet()["service_items"] || [];
        this.setServiceSetAttr("service_items", [...serviceItems, {}]);
    };

    removeServiceItem = (e, index) => {
        e.preventDefault();
        const serviceItems = [...this.serviceItems()].map((si, _index) => {
            if (index === _index) {
                if (si.id) {
                    return { ...si, _destroy: true }
                } else {
                    return null
                }
            } else {
                return { ...si }
            }
        }).filter(Boolean);

        this.setServiceSetAttr("service_items", serviceItems);
    };

    setImages = (images) => {
        const serviceStop = { ...this.props.service_stop, images };
        this.props.updateServiceStop(serviceStop);
    };

    isValid = () => {
        if (!this.props.allRouteRemindersValid()) return false;

        const validations = {};
        validations.outTime = (this.props.service_stop.out_time || "").length < 1;

        this.setState({ validations }, () => {
            for (const [key, value] of Object.entries(validations)) {
                if (value) {
                    this[`${key}ValidationEl`].scrollIntoView({ behavior: "smooth", block: "center" });

                    break;
                }
            }
        });

        return !Object.values(validations).includes(true);
    };

    saveServiceStop = () => {
        if (this.isValid()) {
            const serviceStop = { ...this.props.service_stop };
            if ((serviceStop.service_items || []).length > 0) {
                serviceStop.service_items_attributes = [...this.props.service_stop.service_items];
            }

            if ((serviceStop.service_sets_attributes || []).length > 0) {
                const ssa = serviceStop.service_sets_attributes.map(serviceSet => {
                    if ((serviceSet.service_items || []).length > 0) {
                        serviceSet.service_items_attributes = [...serviceSet.service_items];
                    }

                    return { ...serviceSet };
                });
            }

            this.props.saveServiceStop(serviceStop, true);
        }
    };

    render() {
        const account = this.props.account;
        return(
            <div className="service-stop-form-wrapper">
                <div className="col-md-4 no-padding">
                    <div className="form-group">
                        <label htmlFor="in-time">
                            In Time:
                        </label>
                        <input
                            className="form-control datetime-picker"
                            id="in-time"
                            name="in_time"
                            type="text"
                            readOnly={true}
                            ref={(e) => this.inTimeInput = e}
                        />
                        { this.state.validations.inTime &&
                            <div className="validation-wrapper" ref={(e) => this.inTimeValidationEl = e}>
                                <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                                You must log your In Time first
                            </div>
                        }
                        <button
                            type="button"
                            name="in_time"
                            className="btn btn-default width-100 margin-10-top"
                            onClick={(e) => { this.logTime("in") }}
                            disabled={(this.props.service_stop || {}).in_time && !this.props.account.parsed_settings.allow_settable_appt_times}
                        >
                            Log In Time
                        </button>
                    </div>

                    { account.body_of_waters.length > 0 &&
                        <div className="display-flex margin-10-bottom overflow-x-scroll">
                            {
                                account.body_of_waters.map((bow, index) => {
                                    const selected = this.state.selectedBow ? this.state.selectedBow.id === bow.id : bow.main;

                                    return(
                                        <button
                                            key={index}
                                            className={`btn btn-${selected ? "primary" : "default"} margin-10-right`}
                                            onClick={() => this.setState({ selectedBow: bow })}
                                        >
                                            { bow.name } { bow.main && "(Main)" }
                                        </button>
                                    )
                                })
                            }
                        </div>
                    }

                    <BoxInput
                        heading={"pH"}
                        previousReading={this.previousReading("ph")}
                        valueRange={["-6.8", "7.0", "7.2", "7.4", "7.6", "7.8", "8.0+"]}
                        selectedValue={this.selectedServiceSet()["ph"]}
                        setSelectedValue={(val) => this.setServiceSetAttr("ph", val)}
                    />

                    <BoxInput
                        heading={"Free Chlorine (ppm)"}
                        previousReading={this.previousReading("free_chlorine")}
                        valueRange={["0.0", "1.0", "1.5", "2.0", "2.5", "3.0", "3.5", "4.0", "4.5", "5.0+"]}
                        selectedValue={this.selectedServiceSet()["free_chlorine"]}
                        setSelectedValue={(val) => this.setServiceSetAttr("free_chlorine", val)}
                    />

                    {
                        this.props.addable_chemicals.slice(0, 3).map((chem, index) => (
                            <ServiceSetInput
                                key={index}
                                chargeFor={this.chargeForChemical(chem)}
                                chargeSetable={!this.props.is_route_tech && this.props.account.billing_enabled}
                                toggleChemicalCharge={() => this.updateAccountSettings(`charge_for_${chem}`, !this.state.accountSettings[`charge_for_${chem}`])}
                                setValue={(val) => this.setServiceSetAttr(`${chem}_added`, val)}
                                heading={`${Utils.humanize(chem)} Added (${this.props.chemical_units[chem]})`}
                                chemName={chem}
                                previousReading={this.previousReading(`${chem}_added`)}
                                value={this.selectedServiceSet()[`${chem}_added`]}
                            />
                        ))
                    }

                    <h4
                        className="no-margin-top margin-15-bottom cursor-pointer"
                        href={`#chemical-readings-${this.props.uniqueKey}`}
                        data-toggle="collapse"
                        onClick={(e) => $(e.target).find("span").toggleClass("rotate-right")}
                    >
                        <span className="glyphicon glyphicon-chevron-down rotate-right"></span>
                        Advanced Chemcial Readings:
                    </h4>
                    <div id={`chemical-readings-${this.props.uniqueKey}`} className="collapse margin-10-bottom">
                        {
                            this.props.chemical_readings.filter(cr => !["ph", "free_chlorine"].includes(cr)).map((chem, index) => {
                                const unit = chem === "phosphates" ? "ppb" : "ppm";

                                return(
                                    <ServiceSetInput
                                        key={index}
                                        chargeFor={false}
                                        chargeSetable={false}
                                        setValue={(val) => this.setServiceSetAttr(chem, val)}
                                        heading={`${Utils.humanize(chem)} (${unit})`}
                                        chemName={chem}
                                        previousReading={this.previousReading(chem)}
                                        value={this.selectedServiceSet()[chem]}
                                   />
                                )
                            })
                        }
                    </div>

                    <h4
                        className="no-margin-top margin-15-bottom cursor-pointer"
                        href={`#chemicals-added-${this.props.uniqueKey}`}
                        data-toggle="collapse"
                        onClick={(e) => $(e.target).find("span").toggleClass("rotate-right")}
                    >
                        <span className="glyphicon glyphicon-chevron-down rotate-right"></span>
                        Advanced Chemcials Added:
                    </h4>
                    <div id={`chemicals-added-${this.props.uniqueKey}`} className="collapse margin-10-bottom">
                        {
                            this.props.addable_chemicals.slice(3, this.props.addable_chemicals.length).map((chem, index) => (
                                <ServiceSetInput
                                    key={index}
                                    chargeFor={this.chargeForChemical(chem)}
                                    chargeSetable={!this.props.is_route_tech && this.props.account.billing_enabled}
                                    toggleChemicalCharge={() => this.updateAccountSettings(`charge_for_${chem}`, !this.state.accountSettings[`charge_for_${chem}`])}
                                    setValue={(val) => this.setServiceSetAttr(`${chem}_added`, val)}
                                    heading={`${Utils.humanize(chem)} Added (${this.props.chemical_units[chem]})`}
                                    chemName={chem}
                                    previousReading={this.previousReading(`${chem}_added`)}
                                    value={this.selectedServiceSet()[`${chem}_added`]}
                                />
                            ))
                        }
                    </div>

                    <h4
                        className="no-margin-top margin-15-bottom cursor-pointer"
                        href={`#tasks-completed-${this.props.uniqueKey}`}
                        data-toggle="collapse"
                        onClick={(e) => $(e.target).find("span").toggleClass("rotate-right")}
                    >
                        <span className="glyphicon glyphicon-chevron-down rotate-right"></span>
                        Tasks Completed:
                    </h4>

                    <div
                        id={`tasks-completed-${this.props.uniqueKey}`}
                        className="collapse"
                    >
                        {
                            this.enabledTasks().map((task, index) => {
                                return(
                                    <Fragment key={index}>
                                        <div className="display-flex align-items-center task-wrapper">
                                            <div className="switch">
                                                <Switch
                                                    name={`${task}-${this.props.uniqueKey}`}
                                                    checked={this.hasTaskCompleted(task)}
                                                    onChange={() => this.toggleTask(task)}
                                                    onText="✓"
                                                    offText="x"
                                                />
                                            </div>
                                            <label htmlFor={`${task}-${this.props.uniqueKey}`}>
                                                { Utils.humanize(task) }
                                                <span className="font-weight-normal">
                                                    { task === "back_washed" && `:  ${this.previousReading(task)}` }
                                                </span>
                                            </label>
                                        </div>
                                    </Fragment>
                                )
                            })
                        }
                    </div>

                    <h4
                        className="no-margin-top margin-15-bottom cursor-pointer"
                        href={`#misc-readings-${this.props.uniqueKey}`}
                        data-toggle="collapse"
                        onClick={(e) => $(e.target).find("span").toggleClass("rotate-right")}
                    >
                        <span className="glyphicon glyphicon-chevron-down rotate-right"></span>
                        Misc. Readings:
                    </h4>
                    <div id={`misc-readings-${this.props.uniqueKey}`} className="collapse">
                        <div className="form-group">
                            <label htmlFor="pool-condition">
                                Pool Condition:
                            </label>
                            <div className="margin-10-bottom">
                                Previous Reading: { this.previousReading("pool_condition") }
                            </div>
                            <select
                                className="form-control"
                                value={this.selectedPoolCondition()}
                                onChange={(e) => this.setServiceSetAttr("pool_condition", e.target.value)}
                            >
                                { this.poolConditionOptions() }
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="water-level">
                                Water Level:
                            </label>
                            <div className="margin-10-bottom">
                                Previous Reading: { this.previousReading("water_level") }
                            </div>
                            <select
                                className="form-control"
                                value={this.selectedServiceSet()["water_level"] || "normal"}
                                onChange={(e) => this.setServiceSetAttr("water_level", e.target.value)}
                            >
                                {
                                    ["normal", "low", "high"].map((level, index) => (
                                        <option key={index} defaultValue={level}>
                                            { Utils.humanize(level) }
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="filter-pressure">
                                Filter Pressure (psi):
                            </label>
                            <div className="margin-10-bottom">
                                Previous Reading: { this.previousReading("filter_pressure") }
                            </div>
                            <input
                                type="number"
                                className="form-control"
                                value={this.selectedServiceSet()["filter_pressure"] || ""}
                                onChange={(e) => this.setServiceSetAttr("filter_pressure", e.target.value)}
                            />
                        </div>
                    </div>

                    <h4
                        className="no-margin-top margin-15-bottom cursor-pointer"
                        href={`#service-items-${this.props.uniqueKey}`}
                        data-toggle="collapse"
                        onClick={(e) => $(e.target).find("span").toggleClass("rotate-right")}
                    >
                        <span className="glyphicon glyphicon-chevron-down rotate-right"></span>
                        Service Items:
                    </h4>
                    <div id={`service-items-${this.props.uniqueKey}`} className="collapse">
                        { this.serviceItems().length > 0 &&
                            <Fragment>
                                {
                                    this.serviceItems().map((si, index) => {
                                        if (!si._destroy) {
                                            return(
                                                <div className="display-flex service-item-group" key={index}>
                                                    <div className="input-wrapper">
                                                        <ServiceItem
                                                            index={index}
                                                            update={this.updateServiceItem}
                                                            serviceItem={si}
                                                            masterServiceItems={this.props.master_service_items}
                                                            isRouteTech={this.props.is_route_tech}
                                                        />
                                                    </div>
                                                    <div className="display-flex align-items-center remove-service-item justify-content-center">
                                                        <a href="#" onClick={(e) => this.removeServiceItem(e, index)}>
                                                            Remove
                                                        </a>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })
                                }
                            </Fragment>
                        }
                        <div className="text-center">
                            <a href="#" onClick={this.addServiceItem}>
                                Add Service Item
                            </a>
                        </div>
                    </div>

                    <div className="form-group">
                        <label>
                            Private Notes:
                        </label>
                        <textarea
                            className="form-control"
                            onChange={(e) => this.setServiceSetAttr("notes", e.target.value)}
                            value={this.selectedServiceSet()["notes"] || ""}
                        >
                        </textarea>
                    </div>

                    <div className="form-group">
                        <label htmlFor="out-time">
                            Out Time:
                        </label>
                        <input
                            id="out-time"
                            name="out_time"
                            className="form-control datetime-picker"
                            readOnly={true}
                            type="text"
                            ref={(e) => this.outTimeInput = e}
                        />
                        { this.state.validations.outTime &&
                            <div className="validation-wrapper" ref={(e) => this.outTimeValidationEl = e}>
                                <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                                You must log your Out Time
                            </div>
                        }
                        <button
                            type="button"
                            className="btn btn-default width-100 margin-10-top"
                            onClick={(e) => { this.logTime("out") }}
                            ref={(e) => this.outTimeEl = e}
                            disabled={(this.props.service_stop || {}).out_time && !this.props.account.parsed_settings.allow_settable_appt_times}
                        >
                            Log Out Time
                        </button>
                    </div>

                    { (this.props.account.billing_enabled && !this.props.is_route_tech) &&
                        <Fragment>
                            <div className="form-group display-flex align-items-center">
                                <div className="switch">
                                    <Switch
                                        name={`charge-for-chemicals-${this.props.uniqueKey}`}
                                        checked={this.chargeForAllChemicals()}
                                        onChange={this.setChargeForAllChemicals}
                                        onText="✓"
                                        offText="x"
                                        stateless={true}
                                    />
                                </div>
                                <label htmlFor={`charge-for-chemicals-${this.props.uniqueKey}`}>
                                    Charge for all Chemicals
                                </label>
                            </div>
                            { (account.billing_period_type === "4 weeks" || account.billing_period_type === "weekly" || account.rate_type === "per_stop") &&
                                <div className="form-group display-flex align-items-center">
                                    <div className="switch">
                                        <Switch
                                            name={`charge-for-service-stop-${this.props.uniqueKey}`}
                                            checked={this.chargeForServiceStop()}
                                            onChange={this.toggleChargeForServiceStop}
                                            onText="✓"
                                            offText="x"
                                            stateless={true}
                                        />
                                    </div>
                                    <label htmlFor={`charge-for-service-stop-${this.props.uniqueKey}`}>
                                        Charge for Service Stop
                                    </label>
                                </div>
                            }
                        </Fragment>
                    }
                    { !this.props.account.parsed_settings.require_service_stop_notification &&
                        <div className="form-group display-flex align-items-center">
                            <div className="switch">
                                <Switch
                                    name={`email-notification-${this.props.uniqueKey}`}
                                    checked={this.props.service_stop.notification}
                                    onChange={() => this.props.updateServiceStop({ ...this.props.service_stop, notification: !this.props.service_stop.notification })}
                                    onText="✓"
                                    offText="x"
                                    stateless={true}
                                />
                            </div>
                            <label htmlFor={`email-notification-${this.props.uniqueKey}`}>
                                Send Email Notification to Customer
                            </label>
                        </div>
                    }
                    { (this.props.service_stop.notification && this.props.account.parsed_settings.allow_service_stop_client_notes) &&
                        <div className="form-group">
                            <label>Client Notes (sent to Client with Notification):</label>
                            <textarea
                                className="form-control"
                                value={this.props.service_stop.client_notes || ""}
                                onChange={(e) => this.props.updateServiceStop({ ...this.props.service_stop, client_notes: e.target.value }) }
                            />
                        </div>
                    }

                    <h4
                        className="no-margin-top cursor-pointer"
                        href={`#images-${this.props.uniqueKey}`}
                        data-toggle="collapse"
                        onClick={(e) => $(e.target).find("span").toggleClass("rotate-right")}
                    >
                        <span className="glyphicon glyphicon-chevron-down rotate-right"></span>
                        Images:
                    </h4>
                    <div id={`images-${this.props.uniqueKey}`} className="collapse">
                        <ImageArea
                            images={this.props.service_stop.images || []}
                            resource_name="service_stop"
                            resource_id={this.props.service_stop.id}
                            multiple_allowed={true}
                            updateImagesCallback={this.setImages}
                            timestamp_photos={this.props.account.parsed_settings.timestamp_photos}
                            preventRetry={true}
                        />
                    </div>

                    { this.props.quick_repairs_enabled &&
                        <div className="form-group margin-20-top">
                            <button className="btn btn-lg btn-info width-100" onClick={this.props.showQuickRepairModal}>
                                <i className="fa fa-wrench margin-5-right"></i>
                                Create Quick Repair
                            </button>
                        </div>
                    }

                    <div className={`form-group ${this.props.quick_repairs_enabled ? "" : "margin-20-top"}`}>
                        <button
                            className="btn btn-lg btn-success width-100"
                            onClick={this.saveServiceStop}
                            disabled={this.props.saving || (this.props.service_stop.images || []).some(i => i.loading)}
                        >
                            <i className="fa fa-save margin-5-right"></i>
                            Save Service Stop
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}
