import React from 'react';
import PropTypes from 'prop-types';

import Utils from '../../../Utils';

export default class Step5 extends React.Component {
    static propTypes = {
        account: PropTypes.object.isRequired,
        serviceTechs: PropTypes.array.isRequired,
    }

    humanize(string) {
        return string.split("_").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
    }

    render() {
        return(
            <div className="step-5-wrapper row margin-20-top margin-20-bottom">
                <h3 className="text-center">
                    Review Account Details:
                </h3>
                <div className="input-row margin-20-bottom">
                    <div className="col-sm-4 input-field">
                        <span className="field-label">
                            Contact Info:
                        </span>
                    </div>
                    <div className="col-sm-8 input-field">
                        {
                            ["contact_name", "contact_phone", "contact_email"].map((prop, index) => {
                                return(
                                    <div key={index}>
                                        <div className="field-description">
                                            { `${this.humanize(prop)}:` }
                                        </div>
                                        <div className="field-value">
                                            { this.props.account[prop] }
                                        </div>
                                    </div>
                                )
                            })
                        }

                        <div className="field-description">
                            Location:
                        </div>
                        <div className="field-value">
                            { `${this.props.account.location.address} ${this.props.account.location.city}, ${this.props.account.location.state} ${this.props.account.location.zip}` }
                        </div>
                    </div>
                </div>

                <hr/>

                <div className="input-row margin-20-bottom">
                    <div className="col-sm-4 input-field">
                        <span className="field-label">
                            Service Info:
                        </span>
                    </div>
                    <div className="col-sm-8 input-field">
                        <div>
                            <div className="field-description">
                                On Service:
                            </div>
                            <div className="field-value">
                                { this.props.account.on_service ? "Yes" : "No" }
                            </div>
                        </div>
                        { this.props.account.on_service &&
                            <div>
                                <div>
                                    <div className="field-description">
                                        Service Plan:
                                    </div>
                                    <div className="field-value">
                                        { this.props.account.service_plan }
                                    </div>
                                </div>
                                <div>
                                    <div className="field-description">
                                        Service Frequency:
                                    </div>
                                    <div className="field-value">
                                        { this.props.account.service_frequency === "weekly" &&
                                            <span>
                                                Every week
                                            </span>
                                        }
                                        { this.props.account.service_frequency === "bi-weekly" &&
                                            <span>
                                                Every other week
                                            </span>
                                        }
                                    </div>
                                </div>
                                <div>
                                    <div className="field-description">
                                        Service Day(s) / Technician(s):
                                    </div>
                                    {
                                        this.props.account.appointments_attributes.map((appt, index) => {
                                            return(
                                                <div className="field-value" key={index}>
                                                    {
                                                        `${moment(appt.start, "YYYY-MM-DD").format("ddd")} - ${this.props.serviceTechs.find(tech => tech.id == appt.user_id).name }`
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div>
                                    <div className="field-description">
                                        Estimated time to service:
                                    </div>
                                    <div className="field-value">
                                        { `${this.props.account.time_to_service} minutes` }
                                    </div>
                                </div>
                                { this.props.account.body_of_waters_attributes.length > 0 &&
                                    <div>
                                        <div className="field-description">
                                            Bodies of water:
                                        </div>
                                        {
                                            this.props.account.body_of_waters_attributes.map((bow, index) => {
                                                let main = bow.main ? " - Main" : ""
                                                return(
                                                    <div className="field-value" key={index}>
                                                        { `${bow.name} - ${bow.gallons} gallons${main}` }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>

                <hr/>

                { this.props.account.on_service && this.props.account.billing_period_type &&
                    <div className="input-row margin-20-bottom">
                        <div className="col-sm-4 input-field">
                            <span className="field-label">
                                Billing Info:
                            </span>
                        </div>
                        <div className="col-sm-8 input-field">
                            <div>
                                <div className="field-description">
                                    Automatic billing enabled:
                                </div>
                                <div className="field-value">
                                    { !!this.props.account.billing_period_type ? "Yes" : "No" }
                                </div>
                            </div>
                            <div>
                                <div className="field-description">
                                    Invoices automatically generated every:
                                </div>
                                <div className="field-value">
                                    { this.props.account.billing_period_type === "weekly" &&
                                        <span>
                                            Week
                                        </span>
                                    }
                                    { this.props.account.billing_period_type === "4 weeks" &&
                                        <span>
                                            4 Weeks
                                        </span>
                                    }
                                    { this.props.account.billing_period_type === "monthly" &&
                                        <span>
                                            Same day every month
                                        </span>
                                    }
                                </div>
                            </div>
                            <div>
                                <div className="field-description">
                                    Next billing period starts on:
                                </div>
                                <div className="field-value">
                                    { moment(this.props.account.next_billing_date, "YYYY-MM-DD").format("MM-DD-YYYY") }
                                </div>
                            </div>
                            <div>
                                <div className="field-description">
                                    Billable items will start recording on:
                                </div>
                                <div className="field-value">
                                    { moment(this.props.account.billing_start_date, "YYYY-MM-DD").format("MM-DD-YYYY") }
                                </div>
                            </div>
                            <div>
                                <div className="field-description">
                                    Customers pool will get serviced before or after bill for pool service is issued?:
                                </div>
                                <div className="field-value">
                                    { this.props.account.generate_invoices_for_service_in === "past" ? "Before" : "After" }
                                </div>
                            </div>
                            <div>
                                <div className="field-description">
                                    Days bill will be issued before billing period starts:
                                </div>
                                <div className="field-value">
                                    { this.props.account.days_to_issue_bill }
                                </div>
                            </div>
                            <div>
                                <div className="field-description">
                                    Days after billing period starts til invoice for service will become due:
                                </div>
                                <div className="field-value">
                                    { this.props.account.days_til_due_date }
                                </div>
                            </div>
                        </div>
                    </div>
                }

                { this.props.account.on_service && this.props.account.billing_period_type &&
                    // TODO fix this with react fragment element?
                    <hr/>
                }

                <div className="input-row">
                    <div className="col-sm-4 input-field">
                        <span className="field-label">
                            Misc Info:
                        </span>
                    </div>
                    <div className="col-sm-8 input-field">
                        <div>
                            <div className="field-description">
                                Account is currently active:
                            </div>
                            <div className="field-value">
                                { this.props.account.active ? "Yes" : "No" }
                            </div>
                        </div>
                        <div>
                            <div className="field-description">
                                Account has a Home Warranty:
                            </div>
                            <div className="field-value">
                                { this.props.account.home_warranty ? "Yes": "No" }
                            </div>
                        </div>
                        <div>
                            <div className="field-description">
                                Pre-Approved Budget:
                            </div>
                            <div className="field-value">
                                { Utils.floatToCurrency(parseFloat(this.props.account.pre_approved_budget || 0)) }
                            </div>
                        </div>
                        <div>
                            <div className="field-description">
                                Notes:
                            </div>
                            <div className="field-value">
                                { this.props.account.notes }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
