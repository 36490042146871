import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import LockedFeature from '../Subscription/LockedFeature';
import { toast } from 'react-toastify';

import Utils from '../../Utils';

export default class TextMessageSettings extends React.Component {
    static propTypes = {
        company: PropTypes.object.isRequired,
        base_plan: PropTypes.string.isRequired,
        current_user: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);

        this.baseState = {
            smsEnabled: props.company.sms_number,
            activating: false,
            areaCode: "",
            availableNumbers: null,
            selectedPhoneNumber: null
        }

        this.state = this.baseState;
    }

    searchNumbers = () => {
        this.setState({ loading: true });

        $.ajax({
            method: "GET",
            url: "/sms/search.json",
            data: {
                area_code: this.state.areaCode
            }
        }).done((availableNumbers) => {
            this.setState({ availableNumbers, loading: false });
        }).fail(() => {
            // handle failure
        });
    }

    saveNumber = (number) => {
        if (confirm(`Are you sure you would like ${number} to be the number you send and receive text messages with? You CANNOT change this number in the future and you will ONLY have access to this number while you maintain an active AquaSuite Pro Subscription. Your phone number will be deleted if your subscription is ever deactivated. By using this phone number you agree to these terms.`)) {

            this.setState({ selectedPhoneNumber: number, loading: true });

            $.ajax({
                method: "POST",
                url: "/sms/create_number.json",
                data: {
                    number: number
                }
            }).done(() => {
                this.setState({ smsEnabled: number, loading: false });
            }).fail(() => {
                // handle failure
            })
        }
    };

    updateSoundSetting = (e) => {
        $.ajax({
            method: "PUT",
            url: `/users/${this.props.current_user.id}.json`,
            data: {
                user: {
                    settings: {
                        text_message_sound: e.target.checked
                    }
                }
            }
        }).done(() => {
            toast.success("Successfully updated your User Settings (you may have to refresh your page for update to take effect)", {
                position: toast.POSITION.TOP_CENTER
            });
        });
    };

    render() {
        return(
            <div className="row">
                <div className="col-md-6">
                    { this.props.base_plan === "pro" &&
                        <div className="panel panel-primary">
                            <div className="panel-heading">
                                <h3 className="panel-title">
                                    Text Message Settings
                                </h3>
                            </div>
                            <div className="panel-body">
                                { this.state.smsEnabled &&
                                    <Fragment>
                                        <div className="display-flex flex-column justify-content-center">
                                            <h4 className="text-center">
                                                Phone number used to text your customers/technicians:
                                            </h4>
                                            <h3 className="text-center no-margin-top">
                                                <span className="label label-default">
                                                    { Utils.formattedPhone(this.state.smsEnabled) }
                                                </span>
                                            </h3>
                                            <h5 className="color-gray text-center">
                                                Plan: 100 Monthly Text Messages
                                            </h5>
                                        </div>
                                        <hr/>

                                        <div className="form-group no-margin-bottom">
                                            <label className="margin-5-right">Play Sound when Text Messages are received:</label>
                                            <input
                                                type="checkbox"
                                                defaultChecked={this.props.current_user.settings.text_message_sound}
                                                onChange={this.updateSoundSetting}
                                            />
                                        </div>
                                    </Fragment>
                                }
                                { !this.state.smsEnabled && this.state.activating &&
                                    <Fragment>
                                        <div className="row display-flex flex-column align-items-center">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>
                                                        Desired Area Code:
                                                    </label>
                                                    <input
                                                        className="form-control text-center"
                                                        type="number"
                                                        min="0"
                                                        max="999"
                                                        onChange={(e) => this.setState({ areaCode: e.target.value })}
                                                    />
                                                    <div className="display-flex justify-content-center">
                                                        <button
                                                            className="btn btn-primary margin-10-top"
                                                            onClick={this.searchNumbers}
                                                            disabled={this.state.loading}
                                                        >
                                                            Search Numbers
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        { this.state.loading && !this.state.selectedPhoneNumber &&
                                            <div className="display-flex flex-column justify-content-center align-items-center">
                                                <div className="text-center">
                                                    Searching for available numbers...
                                                </div>
                                                <i className="fa fa-spinner fa-pulse fa-3x fa-fw align-self-center flex-1"></i>
                                            </div>
                                        }
                                        { this.state.loading && this.state.selectedPhoneNumber &&
                                            <div className="display-flex flex-column justify-content-center align-items-center">
                                                <div className="text-center">
                                                    Activating your phone number...
                                                </div>
                                                <i className="fa fa-spinner fa-pulse fa-3x fa-fw align-self-center flex-1"></i>
                                            </div>
                                        }
                                        { !this.state.loading && this.state.availableNumbers && this.state.availableNumbers.length > 0 &&
                                            <div className="available-numbers-list">
                                                {
                                                    this.state.availableNumbers.map((number, index) => {
                                                        return(
                                                            <div className="panel panel-default" key={index}>
                                                                <div className="panel-body display-flex justify-content-space-between">
                                                                    <h5>
                                                                        { Utils.formattedPhone(number) }
                                                                    </h5>
                                                                    <button className="btn btn-success" onClick={(e) => this.saveNumber(number)}>
                                                                        Select
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        }
                                        { !this.state.loading && this.state.availableNumbers && this.state.availableNumbers.length < 1 &&
                                            <h4 className="text-center">
                                                No numbers with that area code found
                                            </h4>
                                        }
                                    </Fragment>
                                }
                                { !this.state.smsEnabled && !this.state.activating &&
                                    <Fragment>
                                        <h4 className="text-center">
                                            Send/Receive text messages from your customers and technicians
                                        </h4>
                                        <div className="display-flex justify-content-center">
                                            <button
                                                className="btn btn-info margin-auto"
                                                onClick={() => this.setState({ activating: true })}
                                            >
                                                <i className="fa fa-comment-o margin-5-right"></i>
                                                Activate Text Messages
                                            </button>
                                        </div>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    }
                    { this.props.base_plan !== "pro" &&
                        <LockedFeature
                            plan_required="pro"
                            image="https://s3.amazonaws.com/superswimbros.dev/text_messages_locked.png"
                            feature_text="2-Way text messaging with your customers and technicians"
                        />
                    }
                </div>
            </div>
        )
    }
}
