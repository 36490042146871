import React from 'react';
import PropTypes from 'prop-types';

import MaskedInput from 'react-text-mask';

export default class NewServiceStopTask extends React.Component {
    static propTypes = {
        inputName: PropTypes.string.isRequired
    }

    constructor(props) {
        super(props);

        this.baseState = {
            taskName: ""
        }

        this.state = this.baseState;
    }

    textMask = () => {
        const base = "[a-zA-Z|\\s],";
        return base.repeat(29).split(",").map(s => new RegExp(s));
    };

    render() {
        return(
            <div className="form-group no-margin width-100">
                { this.state.taskName.length > 0 &&
                    <input
                        type="hidden"
                        value="1"
                        name={`${this.props.inputName}[${this.state.taskName}]`}
                    />
                }
                <MaskedInput
                    mask={this.textMask()}
                    guide={false}
                    type="text"
                    className="form-control"
                    onChange={(e) => this.setState({ taskName: e.target.value })}
                    placeholder="Task Name"
                />
            </div>
        )
    }
}
