import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

export default class RecordListPreview extends React.Component {
    static propTypes = {
        record: PropTypes.object.isRequired,
        index: PropTypes.number.isRequired
    }

    constructor(props) {
        super(props);

        this.baseState = {
            activated: false
        }

        this.state = this.baseState
    }

    activate = () => {
        this.setState({ activated: !this.state.activated });
    };

    render() {
        return(
            <Fragment>
                <div className={`reminder-${this.props.record.id} panel panel-default ${this.state.activated && "no-margin-bottom"}`}>
                    <div className="panel-body display-flex">
                        <div className="display-flex margin-5-right">
                            <span className={`glyphicon glyphicon-chevron-down ${this.state.activated ? "" : "rotate-right"}`}></span>
                        </div>
                        <div className="flex-1 display-flex align-items-center cursor-pointer" onClick={this.activate}>
                            { `Reminder #${this.props.index + 1}` }
                        </div>
                    </div>
                </div>
                <div className={`${this.state.activated ? "display-block" : "display-none"}`}>
                    <div className="well">
                        <div className="row">
                            <div className="col-xs-12">
                                <div className="panel panel-default">
                                    <div className="panel-heading">
                                        <h4 className="no-margin">
                                            Description:
                                        </h4>
                                    </div>
                                    <div className="panel-body">
                                        { this.props.record.description }
                                    </div>
                                </div>
                                <div className="panel panel-default">
                                    <div className="panel-heading">
                                        <h4 className="no-margin">
                                            Remarks:
                                        </h4>
                                    </div>
                                    <div className="panel-body">
                                        { this.props.record.remarks }
                                    </div>
                                </div>
                                <div className="panel panel-default">
                                    <div className="panel-heading">
                                        <h4 className="no-margin">
                                            Customer Notified:
                                        </h4>
                                    </div>
                                    <div className="panel-body">
                                        { this.props.record.notify ? <span className="glyphicon glyphicon-ok"></span> : <span className="glyphicon glyphicon-remove"></span> }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
