import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

export default class TextMessageEditor extends React.Component {
    static propTypes = {
        content: PropTypes.string,
        specialInserts: PropTypes.object,
        updateCallback: PropTypes.func,
        placeholder: PropTypes.string
    }

    constructor(props) {
        super(props);

        this.baseState = {
            specialInsertSelected: null,
            content: props.content || ""
        }

        this.state = this.baseState
    }

    insertSpecial = () => {
        if (this.state.specialInsertSelected) {
            this.setState({ content: (this.state.content + `%{${this.state.specialInsertSelected}}`) }, () => {
                this.props.updateCallback(this.state.content);
            });
        }
    };

    loadContent = (content) => {
        this.setState({ content });
    }

    handleChange = (e) => {
        this.setState({ content: e.target.value });

        if (this.props.updateCallback) {
            this.props.updateCallback(e.target.value);
        }
    };

    render() {
        return(
            <Fragment>
                { Object.keys(this.props.specialInserts).length > 0 &&
                    <Fragment>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group no-margin-bottom">
                                    <label>Special Inserts:</label>
                                    <select className="form-control" onChange={(e) => this.setState({ specialInsertSelected: e.target.value })}>
                                        <option value="">Select insert...</option>
                                        {
                                            Object.keys(this.props.specialInserts).map((key, index) => {
                                                return(
                                                    <option key={index} value={key}>
                                                        { this.props.specialInserts[key] }
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 margin-10-top margin-10-bottom">
                                <button
                                    className="btn btn-info"
                                    type="button"
                                    onClick={this.insertSpecial}
                                >
                                    Insert
                                </button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <textarea className="form-control"
                                    onChange={this.handleChange}
                                    value={this.state.content}
                                    placeholder={this.props.placeholder}
                                ></textarea>
                            </div>
                        </div>
                    </Fragment>
                }
            </Fragment>
        )
    }
}
