import React from 'react';
import PropTypes from 'prop-types';

export default class ValidationMessage extends React.Component {
    static propTypes = {
        condition: PropTypes.bool.isRequired,
        message: PropTypes.string.isRequired,
        bottomMargin: PropTypes.bool
    };

    constructor(props) {
        super(props);

        this.baseState = {
            condition: props.condition,
            message: props.message,
        };

        this.state = this.baseState;
    }

    componentWillReceiveProps(props) {
        this.setState({ ...props });
    }

    render() {
        const className = this.props.bottomMargin ? "margin-10-bottom" : "";
        return(
            <div className={className}>
                { this.state.condition &&
                    <div className="validation-wrapper">
                        <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                        { this.state.message }
                    </div>
                }
            </div>
        )
    }
}
