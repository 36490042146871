import React from 'react';
import PropTypes from 'prop-types';

import Utils from '../../Utils';

export default class LockedFeature extends React.Component {
    static propTypes = {
        plan_required: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired,
        feature_text: PropTypes.string.isRequired
    }

    labelClass = () => {
        switch(this.props.plan_required) {
            case 'lite':
                return 'label-info';
            case 'plus':
                return 'label-success';
            case 'pro':
                return 'label-warning';
        }
    };

    render() {
        return(
            <div className="feature-locked-wrapper">
                <div className="display-image">
                    <img
                        src={this.props.image}
                        alt={`upgrade to ${this.props.plan_required} to unlock ${this.props.feature_text}`}
                    />
                    <div className="mask"></div>
                </div>
                <div className="upgrade-wrapper">
                    { this.props.plan_required !== "aquabiller" &&
                        <a href="/subscriptions/activate" className="btn-success btn-lg">
                            Upgrade to
                                <div className="plan-wrapper">
                                    <img src="https://s3.amazonaws.com/superswimbros.dev/no+word+smaller+logo.png" alt="AquaSuite"/>
                                    <h4>
                                        <span className={`label ${this.labelClass()}`}>
                                            { Utils.humanize(this.props.plan_required) }
                                        </span>
                                    </h4>
                                </div>
                            to unlock { Utils.humanize(this.props.feature_text) }
                        </a>
                    }
                    { this.props.plan_required === "aquabiller" &&
                        <a
                            href="http://help.useaquasuite.com/en/articles/3256628-income-expense-report"
                            className="btn-success btn-lg"
                            target="_blank"
                        >
                            <div className="plan-wrapper">
                                <img src="https://s3.amazonaws.com/superswimbros.dev/no+word+smaller+logo.png" alt="AquaSuite"/>
                            </div>
                            { this.props.feature_text }
                        </a>
                    }
                </div>
            </div>
        )
    }
}
