import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import ExpenseItem from './Item';
import ImageArea from '@shared/components/ImageArea/ImageArea';

import Utils from '../../../Utils';
import { toast } from 'react-toastify';

export default class RepairExpenseIndex extends React.Component {
    static propTypes = {
        expenseSet: PropTypes.object,
        repair: PropTypes.object,
        updateCallback: PropTypes.func.isRequired
    }

    constructor(props) {
        super(props);

        this.baseState = {
            expenses: (props.expenseSet || {}).expenses || []
        }

        this.state = this.baseState;
    }

    validExpenses = () => {
        const results = this.state.expenses.map((item, index) => this[`expenseItemEl${index}`].isValid());

        return !results.includes(false);
    };

    getExpenses = () => {
        return this.state.expenses;
    };

    setExpenses = (expenses) => {
        this.setState({ expenses });
    };

    addExpense = (e) => {
        e.preventDefault();

        this.setState({ expenses: [...this.state.expenses, { quantity: 1 }] })
    };

    updateItem = (index, item) => {
        const expenses = [...this.state.expenses];
        expenses.splice(index, 1, item);
        this.setState({ expenses });
    };

    updateAjax = (expense, index, action) => {
        $.ajax({
            method: "PUT",
            url: `/accounts/${this.props.repair.account_id}/repairs/${this.props.repair.id}/expense_sets/${this.props.expenseSet.id}.json`,
            data: {
                expense_set: {
                    expenses_attributes: [expense]
                }
            }
        }).done((expenseSet) => {
            let expenses = [...expenseSet.expenses];
            this.setState({ expenses }, () => {
                this.props.updateCallback(expenseSet);
            });

            const tensedAction = action === "destroy" ? "destroyed" : "saved";

            toast.success(`Successfully ${tensedAction} Repair Expense`, {
                position: toast.POSITION.TOP_CENTER
            });
        }).fail(() => {
            toast.error(`A problem occurred while trying to ${action} that Repair Expense`, {
                position: toast.POSITION.TOP_CENTER
            });
        });
    };

    saveAjax = (expense) => {
        $.ajax({
            method: "PUT",
            url: `/accounts/${this.props.repair.account_id}/repairs/${this.props.repair.id}.json`,
            data: {
                repair: {
                    expense_set_attributes: {
                        expenses_attributes: [expense]
                    }
                }
            }
        }).done(repair => {
            const expenses = [...repair.expense_set.expenses];
            this.setState({ expenses }, () => {
                this.props.updateCallback(repair.expense_set);
            });
            toast.success(`Successfully saved Repair Expense`, {
                position: toast.POSITION.TOP_CENTER
            });
        }).fail(() => {
            toast.error(`A problem occurred while trying to save that Repair Expense`, {
                position: toast.POSITION.TOP_CENTER
            });
        });
    };

    saveItem = (index) => {
        const expense = this.state.expenses[index];
        if (this.props.expenseSet && this.props.expenseSet.id) {
            this.updateAjax(expense, index, "save");
        } else if (this.props.repair.id) {
            this.saveAjax(expense);
        }
    };

    destroyItem = (index) => {
        const expense = { ...this.state.expenses[index] };

        if (expense.id) {
            expense._destroy = true;

            this.updateAjax(expense, index, "destroy");
        } else {
            let expenses = [...this.state.expenses];
            expenses.splice(index, 1);
            this.setState({ expenses });
        }
    }

    totalCost = () => {
        const totals = this.state.expenses.map(expense => (parseFloat(expense.cost || 0)) * parseFloat(expense.quantity));

        return totals.reduce((a,b) => a + b);
    };

    render() {
        return(
            <div className="panel panel-default">
                <div className="panel-heading">
                    <h3 className="panel-title">
                        Expenses:
                    </h3>
                </div>
                <div className="panel-body">
                    { this.state.expenses.length > 0 &&
                        <Fragment>
                            {
                                this.state.expenses.map((expense, index) => {
                                    return(
                                        <Fragment key={index}>
                                            <ExpenseItem
                                                expense={expense}
                                                index={index}
                                                ref={(e) => this[`expenseItemEl${index}`] = e}
                                                updateItem={this.updateItem}
                                                saveItem={this.saveItem}
                                                destroyItem={this.destroyItem}
                                            />
                                            <hr/>
                                        </Fragment>
                                    )
                                })
                            }
                            { this.totalCost() > 0.0 &&
                                <Fragment>
                                    <div className="display-flex">
                                        <h4>Total Expenses: { Utils.floatToCurrency(this.totalCost()) }</h4>
                                    </div>
                                    <hr/>
                                </Fragment>
                            }
                        </Fragment>
                    }
                    <div className="text-center">
                        <a href="#" onClick={this.addExpense}>
                            + Add Expense
                        </a>
                    </div>
                    { (this.props.expenseSet && this.props.expenseSet.id) &&
                        <Fragment>
                            <hr/>
                            <ImageArea
                                images={this.props.expenseSet.images}
                                resource_name="expense_set"
                                multiple_allowed={true}
                                resource_id={this.props.expenseSet.id}
                            />
                        </Fragment>
                    }
                </div>
            </div>
        )
    }
}
