import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import RepairAction from './RepairAction';
import EditTemplatesModal from './Templates/Modal';

import { toast } from 'react-toastify';
import Utils from '../../Utils';
import Select from 'react-select';

import LoadCollection from '@shared/components/LoadCollection';

export default class RepairsIndex extends React.Component {
    static propTypes = {
        statuses: PropTypes.array.isRequired,
        timezone: PropTypes.string.isRequired,
        company: PropTypes.object.isRequired,
        is_not_admin: PropTypes.bool.isRequired,
        repair_tags: PropTypes.array.isRequired
    }

    constructor(props) {
        super(props);

        this.baseState = {
            selectedStatus: "draft",
            repairs: [],
            selectedRepairs: [],
            counts: {},
            loading: null,
            allSelected: false,
            repairAction: "",
            start: moment().subtract(14, "days").format("MM/DD/YYYY"),
            end: moment().format("MM/DD/YYYY"),
            filterParams: {
                priority: "any",
                emailAttachmentsMatcher: "any",
                emailAttachmentsAmount: 0,
                textAttachmentsMatcher: "any",
                textAttachmentsAmount: 0,
                tags: []
            },
            currentPage: 1,
            totalPages: null
        }

        this.priorityLabelClasses = {
            low: "default",
            medium: "primary",
            high: "warning",
            critical: "danger"
        }

        this.state = this.baseState;
    }

    componentDidMount() {
        $(".datetime-picker").datetimepicker({
            format: "MM/DD/YYYY",
            ignoreReadonly: true,
            allowInputToggle: true,
            showClose: true,
            widgetPositioning: {
                horizontal: "left",
                vertical: "bottom"
            },
            icons: {
                time: "glyphicon dt-picker-icon-time",
                close: "glyphicon dt-picker-icon-close",
                date: "glyphicon dt-picker-icon-date"
            }
        });

        $(".datetime-picker").on("dp.change", (e) => {
            const date = moment(e.target.value, "MM/DD/YYYY").format("MM/DD/YYYY");
            const name = e.target.name;

            if (this.state[name] !== date) {
                this.setState({ [name]: date }, () => {
                    this.fetchRepairs();
                });
            }
        });

        this.startInput.value = this.state.start;
        this.endInput.value = this.state.end;

        this.fetchRepairs();
    }

    fetchRepairs = (page = 1, query = {}) => {
        this.setState({ loading: page === 1 })

        return new Promise(resolve => {
            $.ajax({
                method: "GET",
                url: "/repairs.json",
                data: {
                    q: {
                        updated_at_gteq: moment(this.state.start, "MM/DD/YYYY").startOf("day").utc().format(),
                        updated_at_lteq: moment(this.state.end, "MM/DD/YYYY").endOf("day").utc().format(),
                        status_eq: this.state.selectedStatus,
                        ...query
                    },
                    page
                }
            }).done((res) => {
                let repairs;

                if (page > 1) {
                    repairs = [...this.state.repairs, ...res.repairs]
                } else {
                    repairs = res.repairs
                }

                this.setState({ repairs, counts: res.counts, totalPages: res.total_pages, currentPage: res.current_page, loading: false });
                resolve({ totalPages: res.total_pages, currentPage: res.current_page });
            });
        });
    };

    updateFilterParams = (e) => {
        const name = e.target.name;
        const value = e.target.value

        this.setState({ filterParams: { ...this.state.filterParams, [name]: value } });
    };

    resetFilter = () => {
        this.setState({ filterParams: { ...this.baseState.filterParams } }, this.filterRepairs);
    };

    filterRepairs = () => {
        const filterParams = this.state.filterParams;
        const query = {};

        if (filterParams.tags.length > 0) {
            query.tags_cont_any = filterParams.tags
        }

        ["text", "email"].forEach(attachmentType => {
            const typeValue = this.state.filterParams[`${attachmentType}AttachmentsMatcher`];
            if (typeValue !== "any") {
                const amount = this.state.filterParams[`${attachmentType}AttachmentsAmount`];
                if (attachmentType === "text") attachmentType = "text_message";
                query[`${attachmentType}_attachments_counter_${typeValue}`] = amount;
            }
        });

        if (filterParams.priority !== "any") {
            query.priority_eq = filterParams.priority;
        }

        this.fetchRepairs(1, query);
    };

    renderCategories = () => {
        return(
            <div className="status-wrapper display-flex overflow-x-scroll">
                { this.props.statuses.map((status, index) => {
                    const klass = this.state.selectedStatus === status ? "status active" : "status"
                    return(
                        <div
                            key={index}
                            className={klass}
                            onClick={() => this.setState({ selectedStatus: status, selectedRepairs: [] }, () => this.fetchRepairs())}
                        >
                            <h4 className="text-center">
                                { Utils.humanize(status) }
                            </h4>
                            <h3>
                                { this.state.counts[status] || 0 }
                            </h3>
                        </div>
                    )
                }) }
            </div>
        )
    };

    setSelected = (all = false, id) => {
        let selectedRepairs = [], allSelected = false;

        if (all) {
            if (this.state.selectedRepairs.length !== this.state.repairs.length) {
                selectedRepairs = this.state.repairs.map(r => r.id);
                allSelected = true;
            }
        } else {
            if (this.state.selectedRepairs.find(i => i == id)) {
                selectedRepairs = this.state.selectedRepairs.filter(_id => _id !== id)
            } else {
                selectedRepairs = [...this.state.selectedRepairs, id]
            }
        }

        this.setState({ selectedRepairs, allSelected });
    };

    destroyRepairs = (ids) => {
        const repairs = this.state.repairs.filter(r => !ids.includes(r.id));

        const status = this.state.repairs[0].status;

        this.setState({ repairs, selectedRepairs: [], counts: this.state.counts[status] - ids.length });
    };

    renderRepairs = () => {
        return this.state.repairs.map((repair, index) => {
            return(
                <tr key={index}>
                    { !this.props.is_not_admin &&
                        <td>
                            <input
                                type="checkbox"
                                onChange={() => this.setSelected(false, repair.id)}
                                checked={this.state.selectedRepairs.includes(repair.id)}
                            />
                        </td>
                    }
                    <td>
                        <a href={`/accounts/${repair.account.id}`}>
                            { repair.account.contact_name }
                        </a>
                    </td>
                    <td>
                        { repair.account.location.address }
                    </td>
                    <td>
                        <a href={`/accounts/${repair.account.id}/repairs/${repair.id}`}>
                            { Utils.truncate(repair.description, 75) }
                        </a>
                    </td>
                    <td>
                        { repair.priority &&
                            <span className={`label label-${this.priorityLabelClasses[repair.priority]}`}>
                                { Utils.humanize(repair.priority) }
                            </span>
                        }
                        { !repair.priority &&
                            "--"
                        }
                    </td>
                    <td>
                        { repair.tags
                        ?
                            repair.tags.split(",").map((tag,index) => (
                                <span
                                    key={index}
                                    className="label margin-5-right"
                                    style={{ background: (this.props.repair_tags.find(t => t.name === tag) || {}).color }}
                                >
                                    { tag }
                                </span>
                            ))
                        :
                            "--"
                        }
                    </td>
                    <td>
                        { Utils.humanize(repair.status) }
                    </td>
                    { !this.props.is_not_admin &&
                        <Fragment>
                            <td>
                                { repair.attachments_sent.text_messages + repair.attachments_sent.emails }
                            </td>
                            <td>
                                { repair.invoice ? (<a href={`/invoices/${repair.invoice.id}`}>{`#${Utils.pad(repair.invoice.invoice_number, 5)}`}</a>) : "--" }
                            </td>
                            <td>
                                { Utils.floatToCurrency(repair.grand_total) }
                            </td>
                        </Fragment>
                    }
                </tr>
            )
        });
    };

    showRepairAction = (repairAction) => {
        this.setState({ repairAction }, () => {
            $("#repair-action-modal").modal("show");
        });
    };

    showTemplateModal = () => {
        $("#repair-template-modal").modal("show");
    };

    toggleChevron = (e) => {
        $($(e.target).find("span")).toggleClass("rotate-right");
    };

    tagOptions = () => {
        return this.props.repair_tags.map(tag => (
            {
                value: tag.name, label: tag.name
            }
        ))
    };

    updateTagFilter = (newValue) => {
        const tags = newValue.map(obj => obj.value);
        this.setState({ filterParams: { ...this.state.filterParams, tags } });
    };

    repairActionCallback = () => {
        this.fetchRepairs();
        this.setState({ selectedRepairs: [] });
    };

    render() {
        return(
            <div className="repair-index-wrapper">
                <div className="row page-header no-margin-top">
                    <div className="col-md-12">
                        <h1>Repairs</h1>
                    </div>
                </div>

                <div className="row page-header no-margin-top">
                   <div className="col-md-12">
                       <h4 className="no-margin-top">
                           <i className="fa fa-filter margin-5-right"></i>
                           Date Last Updated:
                       </h4>
                   </div>
                   <div className="col-md-12">
                       <div className="row">
                           <div className="col-md-2 col-sm-4">
                               <div className="form-group">
                                   <label>Start:</label>
                                   <input
                                       name="start"
                                       type="text"
                                       ref={(e) => this.startInput = e}
                                       readOnly={true}
                                       className="form-control datetime-picker"
                                   />
                               </div>
                           </div>
                           <div className="col-md-2 col-sm-4">
                               <div className="form-group">
                                   <label>End:</label>
                                   <input
                                       name="end"
                                       type="text"
                                       ref={(e) => this.endInput = e}
                                       readOnly={true}
                                       className="form-control datetime-picker"
                                   />
                               </div>
                           </div>
                       </div>
                   </div>
               </div>
               <div className="row page-header no-margin-top">
                   <div className="col-md-12">
                       { this.renderCategories() }
                   </div>
               </div>
               { !this.props.is_not_admin &&
                  <Fragment>
                      <div className="row margin-10-bottom">
                          <div className="col-md-12">
                              <div className="dropdown pull-right">
                                  <div className="btn-group">
                                      <button
                                          className="btn btn-lg btn-success dropdown-toggle actions-dropdown"
                                          type="button"
                                          data-toggle="dropdown"
                                          disabled={this.state.repairs.length < 1}
                                      >
                                          Actions
                                          <span className="glyphicon glyphicon-chevron-down"></span>
                                      </button>
                                      <ul className="dropdown-menu">
                                          <li>
                                              <a
                                                  href="#"
                                                  className="text-center padding-10"
                                                  onClick={(e) => { e.preventDefault(); this.showRepairAction("update") }}
                                              >
                                                  <span className="glyphicon glyphicon-pencil"></span>
                                                  &nbsp;
                                                  Update Status
                                              </a>
                                          </li>
                                          <li>
                                              <a
                                                  href="#"
                                                  className="text-center padding-10"
                                                  onClick={(e) => { e.preventDefault(); this.showRepairAction("destroy") }}
                                              >
                                                  <span className="glyphicon glyphicon-trash"></span>
                                                  &nbsp;
                                                  Destroy
                                              </a>
                                          </li>
                                          <li>
                                              <a
                                                  href="#"
                                                  className="text-center padding-10"
                                                  onClick={(e) => { e.preventDefault(); this.showRepairAction("attachments") }}
                                              >
                                                  <i className="fa fa-paperclip"></i>
                                                  &nbsp;
                                                  Send Attachment(s)
                                              </a>
                                          </li>
                                          <li>
                                              <a
                                                  href="#"
                                                  className="text-center padding-10"
                                                  onClick={(e) => { e.preventDefault(); this.showRepairAction("newInvoice") }}
                                              >
                                                  <i className="fa fa-file-text-o"></i>
                                                  &nbsp;
                                                  Create new Invoice(s)
                                              </a>
                                          </li>
                                      </ul>
                                  </div>
                                  <div className="btn-group">
                                      <button
                                          className="btn btn-lg btn-default dropdown-toggle margin-5-left margin-8-right"
                                          type="button"
                                          data-toggle="dropdown"
                                      >
                                          <i className="fa fa-gear"></i>
                                      </button>
                                      <ul className="dropdown-menu" style={{ right: "8px", left: "inherit" }}>
                                          <li>
                                              <a
                                                  href="#"
                                                  className="text-center padding-10"
                                                  onClick={(e) => { e.preventDefault(); this.showTemplateModal() }}
                                              >
                                                  <i className="fa fa-clipboard margin-5-right"></i>
                                                  Templates
                                              </a>
                                          </li>
                                      </ul>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <hr/>
                  </Fragment>
              }
              { !this.props.is_not_admin &&
                    <Fragment>
                        <div className="row">
                            <div className="col-md-12">
                                <h4
                                    className="cursor-pointer"
                                    href="#filter"
                                    data-toggle="collapse"
                                    onClick={(e) => this.toggleChevron(e)}
                                >
                                    <span className="glyphicon glyphicon-chevron-down rotate-right margin-5-right"></span>
                                    Filter
                                </h4>
                                <div id="filter" className="collapse">
                                    <div className="col-md-12 padding-25-left">
                                        <div className="row">
                                            <div className="col-md-2 col-xs-4">
                                                <div className="form-group">
                                                    <label>
                                                        Priority:
                                                    </label>
                                                    <select
                                                        name="priority"
                                                        className="form-control"
                                                        onChange={(e) => this.updateFilterParams(e)}
                                                        value={this.state.filterParams.priority}
                                                    >
                                                        <option value="any">Any</option>
                                                        <option value="low">Low</option>
                                                        <option value="medium">Medium</option>
                                                        <option value="high">High</option>
                                                        <option value="critical">Critical</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xs-12">
                                                <label>
                                                    Email Attachments Sent:
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-2 col-sm-6 col-xs-6">
                                                <div className="form-group">
                                                    <select
                                                        name="emailAttachmentsMatcher"
                                                        className="form-control"
                                                        onChange={(e) => this.updateFilterParams(e)}
                                                        value={this.state.filterParams.emailAttachmentsMatcher || ""}
                                                    >
                                                        <option value="">Any</option>
                                                        <option value="gt">Greater Than:</option>
                                                        <option value="lt">Less Than:</option>
                                                        <option value="eq">Equal To:</option>
                                                    </select>
                                                </div>
                                            </div>
                                            { ((this.state.filterParams.emailAttachmentsMatcher || "").length > 0) &&
                                                <div className="col-md-1 col-sm-2 col-xs-4">
                                                    <input
                                                        name="emailAttachmentsAmount"
                                                        type="number"
                                                        className="form-control"
                                                        onChange={(e) => this.updateFilterParams(e)}
                                                        value={this.state.filterParams.emailAttachmentsAmount}
                                                        min="0"
                                                    />
                                                </div>
                                            }
                                        </div>
                                        <div className="row">
                                            <div className="col-xs-12">
                                                <label>
                                                    Text Message Attachments Sent:
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-2 col-sm-6 col-xs-6">
                                                <div className="form-group">
                                                    <select
                                                        name="textAttachmentsMatcher"
                                                        className="form-control"
                                                        onChange={(e) => this.updateFilterParams(e)}
                                                        value={this.state.filterParams.textAttachmentsMatcher || ""}
                                                    >
                                                        <option value="">Any</option>
                                                        <option value="gt">Greater Than:</option>
                                                        <option value="lt">Less Than:</option>
                                                        <option value="eq">Equal To:</option>
                                                    </select>
                                                </div>
                                            </div>
                                            { ((this.state.filterParams.textAttachmentsMatcher || "").length > 0) &&
                                                <div className="col-md-1 col-sm-2 col-xs-4">
                                                    <input
                                                        name="textAttachmentsAmount"
                                                        type="number"
                                                        className="form-control"
                                                        onChange={(e) => this.updateFilterParams(e)}
                                                        value={this.state.filterParams.textAttachmentsAmount}
                                                        min="0"
                                                    />
                                                </div>
                                            }
                                        </div>
                                        <div className="row">
                                            <div className="col-xs-12">
                                                <label>
                                                    Tag(s):
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-2 col-sm-6 col-xs-6">
                                                <Select
                                                    isMulti
                                                    options={this.tagOptions()}
                                                    onChange={this.updateTagFilter}
                                                />
                                            </div>
                                        </div>
                                        <div className="row margin-20-top">
                                            <div className="col-xs-12">
                                                <button className="btn btn-info" onClick={this.filterRepairs}>
                                                    <i className="fa fa-filter margin-5-right"></i>
                                                    Filter
                                                </button>
                                                <button className="btn btn-danger margin-10-left" onClick={this.resetFilter}>
                                                    <i className="fa fa-refresh margin-5-right"></i>
                                                    Reset
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr/>
                    </Fragment>
                }

                { this.state.loading &&
                    <div>
                        <i className="fa fa-spinner fa-pulse fa-3x fa-fw margin-auto display-block"></i>
                    </div>
                }

                <div className="row">
                    <div className="col-md-12">
                        { !this.state.loading && this.state.repairs.length > 0 &&
                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            { !this.props.is_not_admin &&
                                                <td>
                                                    <input
                                                        type="checkbox"
                                                        onChange={() => this.setSelected(true)}
                                                        checked={this.state.allSelected}
                                                    />
                                                </td>
                                            }
                                            <td>
                                                Contact Name:
                                            </td>
                                            <td>
                                                Account Address:
                                            </td>
                                            <td>
                                                Repair Description:
                                            </td>
                                            <td>
                                                Priority:
                                            </td>
                                            <td>
                                                Tag(s):
                                            </td>
                                            <td>
                                                Status:
                                            </td>
                                            { !this.props.is_not_admin &&
                                                <Fragment>
                                                    <td>
                                                        Attachments Sent:
                                                    </td>
                                                    <td>
                                                        Invoice No.:
                                                    </td>
                                                    <td>
                                                        Grand Total:
                                                    </td>
                                                </Fragment>
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { this.renderRepairs() }
                                    </tbody>
                                </table>
                                <LoadCollection
                                    collectionName="repairs"
                                    fetchCollection={this.fetchRepairs}
                                    loadOnMount={false}
                                    collectionLength={this.state.repairs.length}
                                    currentPage={this.state.currentPage}
                                    totalPages={this.state.totalPages}
                                />
                            </div>
                        }
                        { !this.state.loading && this.state.repairs.length === 0 &&
                            <h4 className="text-center">
                                No { `${Utils.humanize(this.state.selectedStatus)}` } Repairs to show
                            </h4>
                        }
                    </div>
                </div>
                <RepairAction
                    action={this.state.repairAction}
                    timezone={this.props.timezone}
                    statuses={this.props.statuses}
                    repairs={this.state.selectedRepairs.map(id => this.state.repairs.find(r => r.id === id))}
                    callback={this.repairActionCallback}
                    destroyRepairs={this.destroyRepairs}
                />
                <EditTemplatesModal
                    company={this.props.company}
                />
            </div>
        )
    }
}
