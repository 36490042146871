import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

export default class Toggle extends React.Component {
    static propTypes = {
        subscription: PropTypes.object.isRequired,
        action: PropTypes.string.isRequired,
        callback: PropTypes.func.isRequired
    }

    constructor(props) {
        super(props);

        this.baseState = {
            baseCancellation: null,
            cancellationReason: null,
            validations: {},
            loading: false
        }

        this.state = this.baseState;
    }

    componentDidMount() {
        $(this.modalEl).on("show.bs.modal", (e) => {
            $(e.target).addClass("center-screen-modal");
        });

        $(this.modalEl).on("hide.bs.modal", (e) => {
            $(e.target).removeClass("center-screen-modal");
        });
    }

    showModal() {
        $(this.modalEl).modal("show");
    }

    hideModal = () => {
        $(this.modalEl).modal("hide");
    };

    validateCancellation = () => {
        const validations = {};

        if (this.state.baseCancellation === "other software") {
            if (!this.state.cancellationReason) {
                validations.otherSoftware = true;
            }
        } else if (this.state.baseCancellation === "other") {
            if (!this.state.cancellationReason) {
                validations.other = true;
            }
        } else if (!this.state.baseCancellation || this.state.baseCancellation.length < 1) {
            validations.base = true;
        }

        this.setState({ validations });

        if (!Object.keys(validations).length > 0) {
            this.toggleSubscription("off");
        }
    };

    toggleSubscription = (toggle) => {
        this.setState({ loading: true });

        let cancellation_reason = "";
        if (this.props.action === "cancel") {
            if (this.state.baseCancellation === "other software" || this.state.baseCancellation === "other") {
                cancellation_reason = `${this.state.baseCancellation}: ${this.state.cancellationReason}`;
            } else {
                cancellation_reason = this.state.baseCancellation;
            }
        }

        $.ajax({
            method: "PUT",
            url: "/subscriptions/toggle",
            data: {
                toggle: toggle,
                cancellation_reason
            }
        }).done(subscription => {
            this.props.callback(subscription);
            this.setState({ ...this.baseState });
            this.hideModal();
        }).fail(res => {
            // handle failure
        });
    };

    render() {
        return(
            <div
                id="toggle-subscription-modal"
                className="modal fade"
                ref={(e) => this.modalEl = e}
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button
                                className="close"
                                type="button"
                                data-dismiss="modal"
                            >
                                &times;
                            </button>
                            <h5 className="modal-title">
                                { this.props.action === "cancel" ? "Cancel Subscription" : "Activate Subscription" }
                            </h5>
                        </div>
                        <div className="modal-body">
                            <div className="display-flex justify-content align-items-center flex-column">
                                { this.state.loading &&
                                    <div className="display-flex justify-content-center margin-10-top">
                                        <i className="fa fa-spinner fa-pulse fa-3x fa-fw align-self-center flex-1"></i>
                                    </div>
                                }
                                { this.props.action === "cancel" &&
                                    <Fragment>
                                        { !this.state.loading &&
                                            <Fragment>
                                                <h4 className="text-center">
                                                    Subscription will be canceled after { moment.unix(this.props.subscription.current_period_end).format("MM-DD-YYYY") }
                                                </h4>
                                                <div className="col-xs-12">
                                                    <div className="form-group">
                                                        <label>
                                                            Reason for cancelling:
                                                        </label>
                                                        <select className="form-control" onChange={(e) => this.setState({ baseCancellation: e.target.value })}>
                                                            <option value="">Select reason...</option>
                                                            <option value="too expensive">AquaSuite is too expensive</option>
                                                            <option value="other software">Switching to another software</option>
                                                            <option value="hard to use">AquaSuite is too hard to use</option>
                                                            <option value="other">Other</option>
                                                        </select>
                                                    </div>
                                                    { this.state.baseCancellation === "other software" &&
                                                        <Fragment>
                                                            <div className="form-group">
                                                                <label>Software you are switching to:</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    onChange={(e) => this.setState({ cancellationReason: e.target.value })}
                                                                />
                                                            </div>
                                                            { this.state.validations.otherSoftware &&
                                                                <div className="alert alert-warning">
                                                                    <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                                                                    Please include what software you are switching to
                                                                </div>
                                                            }
                                                        </Fragment>
                                                    }
                                                    { this.state.baseCancellation === "other" &&
                                                        <Fragment>
                                                            <div className="form-group">
                                                                <label>Other Reason:</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    onChange={(e) => this.setState({ cancellationReason: e.target.value })}
                                                                />
                                                            </div>
                                                            { this.state.validations.other &&
                                                                <div className="alert alert-warning">
                                                                    <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                                                                    Please include the reason you are cancelling
                                                                </div>
                                                            }
                                                        </Fragment>
                                                    }
                                                    { this.state.validations.base &&
                                                        <div className="alert alert-warning">
                                                            <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                                                            Please include reason for cancelling
                                                        </div>
                                                    }
                                                </div>
                                                <button className="btn btn-danger" onClick={this.validateCancellation}>
                                                    Cancel Subscription
                                                </button>
                                            </Fragment>
                                        }
                                    </Fragment>
                                }
                                { this.props.action === "activate" &&
                                    <Fragment>
                                        { !this.state.loading &&
                                            <Fragment>
                                                <h4 className="text-center">
                                                    Subscription will renew automatically after { moment.unix(this.props.subscription.current_period_end).format("MM-DD-YYYY") }
                                                </h4>
                                                <div className="display-flex justify-content-center align-items-center">
                                                    <button className="btn btn-primary" onClick={(e) => this.toggleSubscription("on")}>
                                                        Confirm
                                                    </button>
                                                </div>
                                            </Fragment>
                                        }
                                    </Fragment>
                                }
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-default" data-dismiss="modal">
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
