import React from 'react';
import PropTypes from 'prop-types';

import ValidationMessage from '../ValidationMessage';

export default class BodyOfWater extends React.Component {
    static propTypes = {
        _key: PropTypes.number.isRequired,
        bodyOfWater: PropTypes.object.isRequired,
        validations: PropTypes.object.isRequired,
        updateBodyOfWater: PropTypes.func.isRequired,
        removeBodyOfWater: PropTypes.func.isRequired,
        mainSelected: PropTypes.bool.isRequired,
    }

    constructor(props) {
        super(props);

        this.baseState = {
            ...props,
            id: props.bodyOfWater.id || null,
            _destroy: props.bodyOfWater._destroy || null,
            name: props.bodyOfWater.name || "",
            gallons: props.bodyOfWater.gallons || "",
            main: props.bodyOfWater.main || false,
            validations: {
                name: false,
                gallons: false
            }
        }

        this.state = this.baseState;
    }

    componentWillReceiveProps(props) {
        // wrap in timeout so checkInvalid will setState and this won't override it
        setTimeout(() => {
            // the validation check in Step 2 can get called when the component is unmounted
            if (this.mounted) {
                this.setState({ ...this.state, ...props.bodyOfWater });
            }
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const results = ["id", "name", "gallons", "main"].map(prop => {
            return prevState[prop] === this.state[prop];
        });

        if (results.indexOf(false) > -1) {
            this.props.updateBodyOfWater(this.state._key);
        }
    }

    getBodyOfWater() {
        let { id, name, gallons, main, _destroy } = this.state;

        return { id, name, gallons, main, _destroy }
    }

    checkInvalid() {
        const name = (this.state.name || "").length < 1
        const gallons = (this.state.gallons || "").length < 1

        this.setState({ validations: { name, gallons } });

        return [name, gallons].includes(true);
    }

    render() {
        const klass = this.props.bodyOfWater._destroy ? "display-none" : "display-flex";
        return(
            <div className={`${klass} margin-10-bottom`}>
                <div className="width-80">
                    <div className="row">
                        <div className="col-md-11 col-xs-10">
                            <div className="form-group no-margin">
                                <input
                                    type="text"
                                    className="margin-10-bottom form-control"
                                    value={this.state.name}
                                    onChange={(e) => this.setState({ name: e.target.value })}
                                    placeholder="Name (e.g. Spa)"
                                />
                                <ValidationMessage condition={this.state.validations.name} message="Name can't be blank" bottomMargin={true} />
                                <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.gallons}
                                    onChange={(e) => this.setState({ gallons: e.target.value })}
                                    placeholder="Gallons (e.g. 10000)"
                                />
                                <ValidationMessage condition={this.state.validations.gallons} message="Gallons can't be blank" bottomMargin={true} />
                                { (this.state.main || !this.props.mainSelected) &&
                                    <div>
                                        <label htmlFor={`${this.state._key}-main-bow`}>
                                            Main body of water:
                                            &nbsp;
                                        </label>
                                        <input type="checkbox"
                                            id={`${this.state._key}-main-bow`}
                                            checked={this.state.main || false}
                                            value={this.state.main || ""}
                                            onChange={(e) => this.setState({ main: !this.state.main })}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="width-20 display-flex align-items-center justify-content-center">
                    <a
                        className="text-center"
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            this.props.removeBodyOfWater(this.state._key);
                        }}
                    >
                        Remove Body of Water
                    </a>
                </div>
            </div>
        )
    }
}
