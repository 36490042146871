import React from 'react';
import PropTypes from 'prop-types';

import { toast } from 'react-toastify';
import Utils from '../../Utils';

export default class LoadTemplateModal extends React.Component {
    static propTypes = {
        loadCallback: PropTypes.func.isRequired
    }

    constructor(props) {
        super(props);

        this.baseState = {
            templates: [],
            displayableTemplates: [],
        }

        this.state = this.baseState;
    }

    componentDidMount() {
        $.ajax({
            method: "GET",
            url: "/repair_templates.json",
        }).done(templates => {
            this.setState({ templates, displayableTemplates: templates });
        }).fail(res => {
            toast.error("Something went wrong...", {
                position: toast.POSITION.TOP_CENTER
            });
        });

        $("#load-template-modal").on("show.bs.modal", (e) => {
            $(e.target).addClass("center-screen-modal");
        });

        $("#load-template-modal").on("hide.bs.modal", (e) => {
            $(e.target).removeClass("center-screen-modal");
        });
    }

    searchTemplates = (e) => {
        const term = e.target.value;

        const displayableTemplates = this.state.templates.filter(template => template.name.toLowerCase().search(term.toLowerCase()) !== -1);

        this.setState({ displayableTemplates });
    };

    render() {
        return(
            <div
                id="load-template-modal"
                className="modal fade"
                tabIndex="-1"
                role="dialog"
            >
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title float-left">
                                Load Repair Template
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                 <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body overflow-y-scroll">
                            <div className="form-group">
                                <div className="input-group">
                                    <input
                                        className="form-control"
                                        type="search"
                                        placeholder="Search for templates by name"
                                        onChange={this.searchTemplates}
                                    />
                                    <div className="input-group-addon">
                                        <i className="fa fa-search"></i>
                                    </div>
                                </div>
                            </div>
                            <hr/>
                            { this.state.displayableTemplates.length < 1 &&
                                <h4 className="text-center">
                                    No Templates found
                                </h4>
                            }
                            { this.state.displayableTemplates.length > 0 &&
                                <div>
                                    <div className="display-flex padding-15-left padding-15-right margin-10-bottom">
                                        <div className="flex-1">
                                            Name:
                                        </div>
                                        <div className="flex-2 margin-20-right">
                                            Description:
                                        </div>
                                    </div>
                                    <ul className="list-group">
                                        {
                                            this.state.displayableTemplates.map((template, index) => (
                                                <li className="list-group-item" key={index}>
                                                    <div className="display-flex align-items-center">
                                                        <div className="flex-2">
                                                            { template.name }
                                                        </div>
                                                        <div className="flex-3 margin-20-right">
                                                            { Utils.truncate(template.description, 75) }
                                                        </div>
                                                        <div className="flex-1 display-flex justify-content-end">
                                                            <button className="btn btn-default" onClick={() => this.props.loadCallback(template)}>
                                                                <i className="fa fa-download"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            }
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-default"
                                data-dismiss="modal"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
