import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

export default class ScheduledForm extends React.Component {
    static propTypes = {
        account_id: PropTypes.number.isRequired,
        reminders: PropTypes.array.isRequired,
        reminder: PropTypes.object,
        nextServiceDate: PropTypes.string.isRequired
    }

    constructor(props) {
        super(props);

        const reminder = this.props.reminder || {
            notify: false,
            occurrence_options: null,
            description: "",
            occurrences: "",
            start: "",
            infinite: null
        };

        const validations = {
            description: false,
            numberOfReminders: false,
            dateToShow: false
        }

        this.baseState = {
            reminder,
            loading: false,
            validations
        };

        this.state = this.baseState;
    }

    componentDidMount() {
        $(".scheduled-form .datetime-picker").datetimepicker({
            format: "MM/DD/YYYY",
            ignoreReadonly: true,
            allowInputToggle: true,
            showClose: true,
            widgetPositioning: {
                horizontal: "left",
                vertical: "bottom"
            },
            icons: {
                time: "glyphicon dt-picker-icon-time",
                close: "glyphicon dt-picker-icon-close",
                date: "glyphicon dt-picker-icon-date"
            }
        });

        $(".scheduled-form .datetime-picker").on("dp.change", (e) => {
            this.setState({ reminder: { ...this.state.reminder, start: e.target.value } });
        });

        $(".scheduled-form .datetime-picker").on("dp.show", (e) => {
            $(".bootstrap-datetimepicker-widget.dropdown-menu")[0].scrollIntoView();
        });

        if (this.state.reminder.start.length > 0) {
            this.startInput.value = moment(this.state.reminder.start).format("MM/DD/YYYY")
        }
    }

    updateSetAmount = (e) => {
        let occurrences, occurrence_options;

        if (parseInt(e.target.value) === 1) {
            occurrences = 1;
            occurrence_options = null;
        } else if (parseInt(e.target.value) > 1) {
            occurrences = e.target.value;
            occurrence_options = this.state.reminder.occurrence_options;
        } else if (e.target.value === "") {
            occurrences = e.target.value;
            occurrence_options = null;
        }

        this.setState({ reminder: { ...this.state.reminder, occurrences, occurrence_options } });
    };

    setNextServiceStop = () => {
        this.setState({ reminder: { ...this.state.reminder, start: this.props.nextServiceDate } }, () => {
            this.startInput.value = this.state.reminder.start;
        });
    };

    isValidated = () => {
        const validations = {
            description: this.state.reminder.description.length < 1,
            numberOfReminders: !(this.state.reminder.infinite || this.state.reminder.occurrences),
            dateToShow: this.state.reminder.start.length < 1
        }

        this.setState({ validations });

        return !Object.values(validations).includes(true);
    };

    saveReminder = () => {
        if (this.isValidated()) {
            this.setState({ reminder: { ...this.state.reminder, start: moment(this.state.reminder.start).format("YYYY-MM-DD") }, loading: true }, () => {
                let url = "/route_reminders/scheduled";

                url += this.state.reminder.id ? `/${this.state.reminder.id}.json` : ".json"

                const method = this.state.reminder.id ? "PUT" : "POST";

                $.ajax({
                    url,
                    method,
                    data: {
                        account_id: this.props.account_id,
                        reminder: {
                            ...this.state.reminder
                        }
                    }
                }).done((reminder) => {
                    let reminders;
                    if (this.state.reminder.id) {
                        reminders = this.props.reminders.map(_reminder => {
                            if (reminder.id === _reminder.id) {
                                return reminder
                            } else {
                                return _reminder
                            }
                        });
                    } else {
                        reminders = [...this.props.reminders, reminder];
                    }

                    this.props.updateModalState({ reminders, success: "Reminder successfully saved!", action: "list"  })
                }).fail(() => {
                    // handle failure
                });
            });
        }
    };

    render() {
        const occurrence_options = this.state.reminder.occurrence_options || {};
        return(
            <div className="scheduled-form modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title">
                            <h5 className="modal-title float-left">
                                { this.state.reminder.id && "Edit Route Reminder" }
                                { !this.state.reminder.id && "Add Route Reminder" }
                            </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="display-flex flex-column">
                            <div className="header-icon schedule">
                                <i className="fa fa-bell-o"></i>
                            </div>
                            <h4 className="text-center">
                                { `${this.state.reminder.id ? "Edit" : "Schedule a"} reminder to show on this customer's Service Ticket` }
                            </h4>
                        </div>

                        <hr/>

                        { !this.state.loading &&
                            <Fragment>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <h4>
                                                Description:
                                            </h4>
                                            <textarea
                                                className="form-control"
                                                onChange={(e) => this.setState({ reminder: { ...this.state.reminder, description: e.target.value } })}
                                                value={this.state.reminder.description}
                                            >
                                            </textarea>
                                            { this.state.validations.description &&
                                                <div className="validation-wrapper">
                                                    <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                                                    Description is required
                                                </div>
                                            }
                                        </div>
                                        <div className="form-group no-margin-bottom">
                                            <input
                                                id="notify"
                                                type="checkbox"
                                                checked={this.state.reminder.notify || false}
                                                onChange={() => this.setState({ reminder: { ...this.state.reminder, notify: !this.state.reminder.notify } })}
                                            />
                                            &nbsp;
                                            <label htmlFor="notify">
                                                Include reminder in Service Stop Notification to customer
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group no-margin-bottom">
                                            <h4>
                                                Number of reminders:
                                            </h4>
                                            { this.state.validations.numberOfReminders &&
                                                <div className="validation-wrapper margin-10-bottom">
                                                    <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                                                    Number of Reminders are required
                                                </div>
                                            }
                                            <input
                                                name="occurrence-type"
                                                type="radio"
                                                id="infinite"
                                                onChange={() => this.setState({ reminder: { ...this.state.reminder, occurrences: null, infinite: true, occurrence_options: null } })}
                                                checked={(this.state.reminder.infinite && !this.state.reminder.occurrences) || false}
                                            />
                                            &nbsp;
                                            <label htmlFor="infinite">Infinite</label>

                                            <br/>

                                            <input
                                                name="occurrence-type"
                                                type="radio"
                                                id="set-amount"
                                                onChange={() => this.setState({ reminder: { ...this.state.reminder, infinite: false, occurrences: "1" } })}
                                                checked={(this.state.reminder.occurrences && !this.state.reminder.infinite) || false}
                                            />
                                            &nbsp;
                                            <label htmlFor="set-amount">Set amount</label>
                                            { !this.state.reminder.infinite && this.state.reminder.occurrences &&
                                                <div className="form-group">
                                                    <div className="col-md-6 col-xs-6">
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            min="1"
                                                            onChange={(e) => this.updateSetAmount(e)}
                                                            value={this.state.reminder.occurrences}
                                                        />
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-5 col-xs-12">
                                        <div className="form-group no-margin-bottom">
                                            <h4>
                                                { (parseInt(this.state.reminder.occurrences) > 1 || this.state.reminder.infinite) &&
                                                    <span>
                                                        Date for reminders to start:
                                                    </span>
                                                }
                                                { (parseInt(this.state.reminder.occurrences) === 1 || (!this.state.reminder.occurrences && !this.state.reminder.infinite)) &&
                                                    <span>
                                                        Date for reminder to show:
                                                    </span>
                                                }
                                            </h4>
                                            <div className="row">
                                                <div className="col-xs-6 col-md-5">
                                                    <input
                                                        type="text"
                                                        className="form-control datetime-picker"
                                                        ref={(i) => this.startInput = i}
                                                    />
                                                </div>
                                                <div className="next-service-stop-badge col-xs-6 margin-5-top">
                                                    <span className="label label-primary cursor-pointer" onClick={this.setNextServiceStop}>
                                                        Next Service Stop
                                                    </span>
                                                    { moment(this.state.reminder.start).format("MM/DD/YYYY") === this.props.nextServiceDate &&
                                                        <span className="glyphicon glyphicon-ok margin-5-left"></span>
                                                    }
                                                </div>
                                            </div>
                                            { this.state.validations.dateToShow &&
                                                <div className="validation-wrapper">
                                                    <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                                                    { parseInt(this.state.reminder.occurrences) > 1 ? "Date for Reminders to start is required" : "Date for reminder to show is required" }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>

                                { (parseInt(this.state.reminder.occurrences) > 1 || this.state.reminder.infinite) &&
                                    <div className="row">
                                        <div className="col-md-4">
                                            <h4>
                                                Remind Every:
                                            </h4>
                                            <div className="form-group">
                                                <input
                                                    name="occurrence"
                                                    type="radio"
                                                    id="service-stop"
                                                    onChange={() => this.setState({ reminder: { ...this.state.reminder, occurrence_options: null } })}
                                                    checked={!this.state.reminder.occurrence_options && (this.state.reminder.infinite || this.state.reminder.occurrences)}
                                                />
                                                &nbsp;
                                                <label htmlFor="service-stop">Service Stop</label>
                                                <br/>
                                                <input
                                                    name="occurrence"
                                                    id="custom"
                                                    type="radio"
                                                    onChange={() => this.setState({ reminder: { ...this.state.reminder, occurrence_options: { reminder_interval: 2, reminder_unit: "weeks" } } })}
                                                    checked={(this.state.reminder.occurrence_options && this.state.reminder.occurrence_options.reminder_interval) || false}
                                                />
                                                &nbsp;
                                                <label htmlFor="custom">Custom</label>
                                                { this.state.reminder.occurrence_options &&
                                                    <div className="form-group">
                                                        <div className="col-md-6 col-xs-6">
                                                            <input
                                                                placeholder="#"
                                                                type="number" className="form-control"
                                                                onChange={(e) => this.setState({ reminder: { ...this.state.reminder, occurrence_options: { ...occurrence_options, reminder_interval: e.target.value } } })}
                                                                value={this.state.reminder.occurrence_options.reminder_interval || ""}
                                                                min="1"
                                                            />
                                                        </div>
                                                        <div className="col-md-6 col-xs-6">
                                                            <select
                                                                className="form-control"
                                                                onChange={(e) => this.setState({ reminder: { ...this.state.reminder, occurrence_options: { ...occurrence_options, reminder_unit: e.target.value } } })}
                                                                value={this.state.reminder.occurrence_options.reminder_unit || ""}
                                                            >
                                                                <option value="weeks">
                                                                    Weeks
                                                                </option>
                                                                <option value="months">
                                                                    Months
                                                                </option>
                                                                <option value="years">
                                                                    Years
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                            </Fragment>
                        }
                        { this.state.loading &&
                            <div className="display-flex flex-column justify-content-center align-items-center height-100 margin-10-bottom">
                                <div>
                                    <i className="fa fa-spinner fa-pulse fa-3x fa-fw align-self-center flex-1"></i>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="modal-footer">
                        <div className="float-left">
                            <button className="btn btn-default" onClick={() => this.props.updateModalState({ action: "list" })}>
                                <span className="glyphicon glyphicon-chevron-left margin-5-right"></span>
                                Reminders
                            </button>
                        </div>
                        <div className="float-right">
                            <button
                                type="button"
                                className="btn btn-default"
                                data-dismiss="modal"
                            >
                                Close
                            </button>
                            <button
                                type="button"
                                className="btn btn-success"
                                onClick={() => this.saveReminder()}
                            >
                                Save Reminder
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
