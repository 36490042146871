import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import AccountPreview from './AccountPreview';
import { ExternalMatchConsumer } from './Provider';

export default class MatchExternalCustomersModal extends React.Component {
    componentDidMount() {
        $("#match-account-modal").on("show.bs.modal", (e) => {
            $(e.target).addClass("center-screen-modal");

            this.props.fetchAccountsAndCustomers();
        });

        $("#match-account-modal").on("hide.bs.modal", (e) => {
            $(e.target).removeClass("center-screen-modal");
        });
    }

    renderAccounts = (accounts) => {
        return accounts.map((acct, index) => {
            return(
                <AccountPreview account={acct} key={index} />
            )
        })
    };

    filterAccounts = (e) => {
        const filteredAccounts = this.state.accounts.filter((acct) => {
            let string = `${acct.contact_name} ${acct.location.address}`
            return string.toLowerCase().search(e.target.value.toLowerCase()) !== -1;
        });

        this.setState({ filteredAccounts });
    };

    render() {
        return(
            <ExternalMatchConsumer>
                { (context) => (
                    <div
                        id="match-account-modal"
                        className="modal fade"
                        tabIndex="-1"
                        role="dialog"
                    >
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                    <h4 className="modal-title">Match your AquaSuite accounts with your {context.integration} customers</h4>
                                </div>
                                <div className="modal-body display-flex">
                                    <div className="match-account-wrapper display-flex flex-column height-100 width-100">
                                        <div>
                                            <div className="header-icon display-flex justify-content-center">
                                                <i className="fa fa-refresh"></i>
                                            </div>
                                            <h4 className="text-center">
                                                In order to sync Invoices you must match each AquaSuite account with the corresponding customer in { context.integration }
                                            </h4>
                                            <div className="width-100">
                                                <hr/>
                                            </div>

                                            { context.success &&
                                                <div className="alert alert-success text-center">
                                                    { context.success === "auto-match" &&
                                                        `Successfully found unique matches for each account. Please verify each AquaSuite account is matched with the correct ${context.integration} customer and save changes.`
                                                    }
                                                    { context.success === "saved" &&
                                                        "Successfully updated matched accounts!"
                                                    }
                                                </div>
                                            }

                                            { !context.loading &&
                                                <Fragment>
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <button
                                                                className="btn btn-primary"
                                                                disabled={context.loading === "matching"}
                                                                onClick={context.autoMatch}
                                                            >
                                                                Auto Match All Accounts
                                                            </button>
                                                        </div>
                                                        <div className="col-sm-6 matched-accounts">
                                                            <h4>
                                                                { context.matchedAccounts().length }/{ context.accounts.length } accounts matched
                                                            </h4>
                                                        </div>
                                                    </div>
                                                    <div className="width-100">
                                                        <hr/>
                                                    </div>
                                                </Fragment>
                                            }
                                        </div>

                                        <div className="external-account-list-wrapper display-flex flex-column">
                                            { !context.loading &&
                                                <Fragment>
                                                    <Fragment>
                                                        { this.renderAccounts(context.accounts) }
                                                    </Fragment>
                                                </Fragment>
                                            }
                                            { context.loading &&
                                                <div className="display-flex flex-column height-100 justify-content-center">
                                                    { context.loading === "initial" &&
                                                        <h4 className="text-center">
                                                            Fetching AquaSuite accounts and { context.integration } customers
                                                        </h4>
                                                    }
                                                    { context.loading === "matching" &&
                                                        <h4 className="text-center">
                                                            Attempting to automatically match each AquaSuite account with the corresponding customer in { context.integration }
                                                        </h4>
                                                    }
                                                    <div className="text-center">
                                                        <i className="fa fa-spinner fa-pulse fa-3x fa-fw align-self-center flex-1"></i>
                                                    </div>
                                                </div>
                                            }
                                            { context.error &&
                                                <div className="alert alert-warning text-center">
                                                    { context.error === "auto-match" &&
                                                        `Unable to find unique matches for all AquaSuite accounts. Please go through the accounts that aren't matched and find the customer they belong to in ${context.integration}.`
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-default"
                                        data-dismiss="modal"
                                    >
                                        Close
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-success"
                                        disabled={context.matchedAccounts().length < 1}
                                        onClick={context.updateAccounts}
                                    >
                                        Save Changes
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                ) }
            </ExternalMatchConsumer>
        )
    }
}
