const durationToString = (seconds) => {
    let string = "";

    const duration = moment.duration(seconds, "seconds");
    if (duration.days() > 0) string += `${duration.days()} Days `
    if (duration.hours() > 0) string += `${duration.hours()} Hours `;
    if (duration.minutes() > 0) string += `${duration.minutes()} Minutes `;

    return string;
};

export default {
    techColorFromIndex(index) {
        const baseColors = ["#1def16", "#0854b2", "#dd4349", "#85ae33", "#700e8a", "#d523c8", "#4a676a", "#fd7345", "#180d65", "#185bbf", "#857f3b", "#cd32e4", "#d7e344", "#00a677", "#524b3f", "#e87289", "#052dbc", "#025a79", "#b5643c", "#ccb699"];

        if (index <= (baseColors.length - 1)) {
            return baseColors[index];
        } else if ((index % baseColors.length) === 0) {
            return baseColors[0];
        } else {
            return baseColors[(index % baseColors.length)];
        }
    },
    routeToTotalStops(route) {
        if (!route) return false;

        let stops = 0;
        const userIds = Object.keys(route);
        userIds.forEach(id => {
            const days = Object.keys(route[id]).filter(d => d.length === 3);
            days.forEach(day => {
                // so we can calculate stops from metrics passed in
                if (route[id][day].distances) {
                    stops += route[id][day].distances[0].length - 1; // account for starting point
                } else {
                    stops += route[id][day].length;
                }
            });
        });

        return stops;
    },
    routeToTotalDistance(route) {
        if (!route) return false;

        let totalMeters = 0;
        const userIds = Object.keys(route);
        userIds.forEach(userId => {
            const days = Object.keys(route[userId]).filter(d => d.length === 3);
            days.forEach(day => {
                const distances = route[userId][day].distances;
                distances.forEach((matrix, index) => {
                    if ((index + 1) !== matrix.length) {
                        totalMeters += matrix[index + 1];
                    }
                });
            });
        });

        return (totalMeters / 1609).toFixed(0);
    },
    routeToTotalDuration(route) {
        if (!route) return false;

        let totalSeconds = 0;
        const userIds = Object.keys(route);
        userIds.forEach(userId => {
            const days = Object.keys(route[userId]).filter(d => d.length === 3);
            days.forEach(day => {
                const durations = route[userId][day].durations;
                durations.forEach((matrix, index) => {
                    if ((index + 1) !== matrix.length) {
                        const duration = matrix[index + 1];
                        totalSeconds += duration;
                    }
                });
            });
        });

        return durationToString(totalSeconds);
    },
    routesToAppointments(_routes, timezone) {
        const routes = Object.values(_routes);
        let appointments = [];
        routes.forEach(r => {
            const days = Object.keys(r).filter(k => k !== "starting_points");
            days.forEach(d => {
                const currentIsoDay = moment().tz(timezone).isoWeekday();
                const updatedAppointments = r[d].map(appt => {
                    const _appt = { ...appt };
                    const isoDay = moment().isoWeekday(d).isoWeekday();

                    let startTarget, endTarget;
                    const startTimeInstance = moment(_appt.start).tz(timezone);
                    const endTimeInstance = moment(_appt.end).tz(timezone);
                    const targetIso = startTimeInstance.isoWeekday();

                    const parsedStart = `${moment().isoWeekday(targetIso).format("YYYY-MM-DD")} ${startTimeInstance.format("HH:mm")}`;
                    const parsedEnd = `${moment().isoWeekday(targetIso).format("YYYY-MM-DD")} ${endTimeInstance.format("HH:mm")}`;

                    if (currentIsoDay <= isoDay) {
                        startTarget = moment(parsedStart);
                        endTarget = moment(parsedEnd);
                    } else {
                        startTarget = moment(parsedStart).add(1, "weeks").isoWeekday(isoDay);
                        endTarget = moment(parsedEnd).add(1, "weeks").isoWeekday(isoDay);
                    }

                    _appt.start = startTarget.utc().format();
                    _appt.end = endTarget.utc().format();

                    return _appt;
                });

                appointments = appointments.concat(updatedAppointments);
            });
        });

        return appointments;
    },
    durationToString,
    shorthandDayToFull(day) {
        const days = { sun: "Sunday", mon: "Monday", tue: "Tuesday", wed: "Wednesday", thu: "Thursday", fri: "Friday", sat: "Saturday" }
        return days[day]
    },
    daysOfTheWeek: ["sun", "mon", "tue", "wed", "thu", "fri", "sat"]
}
