import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import ChangeCard from '../Subscription/ChangeCard';
import Toggle from '../Subscription/Toggle';

import Utils from '../../Utils';
import SubUtils from '../Subscription/SubUtils';

export default class SubscriptionSettings extends React.Component {
    static propTypes = {
        company: PropTypes.object.isRequired,
        stripe_key: PropTypes.string.isRequired
    }

    constructor(props) {
        super(props);

        this.baseState = {
            subscription: props.company.subscription,
            loadingCardInfo: true,
            paymentSource: null
        };

        this.state = this.baseState;
    }

    componentDidMount() {
        this.fetchCardInfo();
    }

    fetchCardInfo = () => {
        this.setState({ loadingCardInfo: true });

        $.ajax({
            method: "GET",
            url: "/subscriptions/payment_source"
        }).done(res => {
            this.setState({ loadingCardInfo: false, paymentSource: res.payment_source });
        }).fail(res => {
            // handle failure
        })
    };

    showChangeCard = () => {
        this.changeCardEl.showModal();
    };

    showToggle = () => {
        this.toggleEl.showModal();
    };

    toggleCallback = (subscription) => {
        this.setState({ subscription });
    };

    render() {
        return(
            <Fragment>
                <div className="row">
                    <div className="col-md-5 col-sm-6">
                        <div className="panel panel-primary">
                            <div className="panel-heading">
                                Subscription:
                            </div>
                            <div className="panel-body">
                                <div className="plan-wrapper">
                                    <div className="display-flex justify-content-center">
                                        <img src="https://s3.amazonaws.com/superswimbros.dev/no+word+smaller+logo.png" alt="AquaSuite"/>
                                        <h4>
                                            <span className={`label ${SubUtils.labelClass(this.state.subscription)}`}>
                                                { Utils.humanize(SubUtils.basePlan(this.state.subscription)) }
                                            </span>
                                        </h4>
                                    </div>
                                    <h4 className="text-center">
                                        { SubUtils.accountLimit(this.state.subscription) } Accounts
                                    </h4>
                                    <h4 className="text-center">
                                        { SubUtils.userLimitText(SubUtils.basePlan(this.state.subscription)) }
                                    </h4>

                                    <hr/>

                                    <div>
                                        <div className="col-xs-6">
                                            <div className="text-center color-gray">
                                                Price:
                                            </div>
                                            <h4 className="text-center">
                                                { Utils.floatToCurrency(SubUtils.totalCost(this.state.subscription)) }/month
                                            </h4>
                                        </div>
                                        <div className="col-xs-6">
                                            <div className="text-center color-gray">
                                                Active Through:
                                            </div>
                                            <h4 className="text-center">
                                                { moment.unix(this.state.subscription.current_period_end).format("MM-DD-YYYY") }
                                            </h4>
                                            <div>
                                                { !this.state.subscription.cancel_at_period_end && this.state.paymentSource &&
                                                    <p className="text-success text-center">
                                                        (Will Renew Automatically)
                                                    </p>
                                                }
                                                { (this.state.subscription.cancel_at_period_end || !this.state.paymentSource) &&
                                                    <p className="text-danger text-center">
                                                        (Will NOT Renew Automatically)
                                                    </p>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <div className="text-center color-gray">
                                            Credit/Debit Card:
                                        </div>
                                        <div className="display-flex align-items-center justify-content-center">
                                            { this.state.loadingCardInfo &&
                                                <div className="display-flex justify-content-center margin-10-top">
                                                    <i className="fa fa-spinner fa-pulse fa-3x fa-fw align-self-center flex-1"></i>
                                                </div>
                                            }
                                            { !this.state.loadingCardInfo &&
                                                <Fragment>
                                                    { this.state.paymentSource &&
                                                        <Fragment>
                                                            <div>
                                                                <i className={`fa fa-cc-${this.state.paymentSource.brand.toLowerCase()} text-primary margin-10-right`}></i>
                                                            </div>
                                                            <h4>
                                                                x{this.state.paymentSource.last4} - Exp. {Utils.pad(this.state.paymentSource.exp_month, 2)}/{this.state.paymentSource.exp_year}
                                                            </h4>
                                                        </Fragment>
                                                    }
                                                    { !this.state.paymentSource &&
                                                        <h4 className="text-center">
                                                            --
                                                        </h4>
                                                    }
                                                </Fragment>
                                            }
                                        </div>
                                    </div>

                                    <hr/>

                                    <div className="display-flex align-items-center justify-content-center flex-column">
                                        { this.state.paymentSource &&
                                            <Fragment>
                                                { !this.state.subscription.cancel_at_period_end &&
                                                    <div className="margin-10-bottom">
                                                        <button className="btn btn-default" onClick={this.showChangeCard}>
                                                            <i className="fa fa-edit margin-5-right"></i>
                                                            Change Card
                                                        </button>
                                                    </div>
                                                }

                                                <div className="margin-10-bottom">
                                                    { !this.state.subscription.cancel_at_period_end &&
                                                        <button className="btn btn-danger" onClick={this.showToggle}>
                                                            <i className="fa fa-ban margin-5-right"></i>
                                                            Cancel Subscription
                                                        </button>
                                                    }
                                                    { this.state.subscription.cancel_at_period_end &&
                                                        <button className="btn btn-primary" onClick={this.showToggle}>
                                                            <i className="fa fa-toggle-on margin-5-right"></i>
                                                            Automatically Renew Subscription
                                                        </button>
                                                    }
                                                </div>
                                            </Fragment>
                                        }

                                        { !this.state.subscription.cancel_at_period_end &&
                                            <div>
                                                <a href="/subscriptions/activate" className="btn btn-success">
                                                    <i className="fa fa-chevron-up margin-5-right"></i>
                                                    { this.state.paymentSource ? "Upgrade" : "Activate" } Subscription
                                                </a>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ChangeCard
                    ref={(e) => this.changeCardEl = e}
                    stripeKey={this.props.stripe_key}
                    callback={this.fetchCardInfo}
                />
                <Toggle
                    ref={(e) => this.toggleEl = e}
                    subscription={this.state.subscription}
                    action={this.state.subscription.cancel_at_period_end ? "activate" : "cancel"}
                    callback={this.toggleCallback}
                />
            </Fragment>
        )
    }
}
