import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Select from 'react-select';
import DatePicker from '../Dates/DatePicker';
import DateHelper from '../Dates/Helper';

import { toast } from 'react-toastify';

export default class AdvancedEditAppointmentModal extends React.Component {
    static propTypes = {
        techs: PropTypes.array.isRequired,
        selectedAppointments: PropTypes.array.isRequired,
        timezone: PropTypes.string.isRequired,
        onClose: PropTypes.func.isRequired
    }

    constructor(props) {
        super(props);

        this.baseState = {
            selectedTech: this.findUser(this.testAppointment().user_id),
            originalUser: this.findOriginalUser(),
            originalStart: this.findOriginalStart(),
            options: this.testAppointment().options,
            selectedDate: moment.tz(this.testAppointment().start, props.timezone).format("MM/DD/YYYY"),
            removeOptions: null,
            loading: false
        }

        this.state = this.baseState;
        this.selectStyles = {
            dropdownIndicator: (provided, state) => ({
                color: "black",
                margin: "0 5px"
            })
        }
    }

    componentDidMount() {
        $(this.modalEl).on("hide.bs.modal", () => {
            $(this.modalEl).toggleClass("center-screen-modal");

            this.setState({ ...this.baseState });
            this.props.onClose();
        });

        $(this.modalEl).on("show.bs.modal", () => {
            $(this.modalEl).toggleClass("center-screen-modal");
        });
    }

    findUser = (id) => {
        return this.props.techs.find(t => t.id === id);
    };

    updateSelectedTech = (obj) => {
        const selectedTech = this.findUser(obj.value);

        let options = null;
        if (this.state.options) {
            options = { ...this.state.options };

            if (this.state.options.original_user !== this.testAppointment().user_id && !this.isTemporary()) {
                options.original_user = selectedTech.id;
            }
        }

        this.setState({ selectedTech, options });
    };

    updateDate = (e) => {
        this.setState({ selectedDate: e.target.value });
    };

    testAppointment = () => {
        return this.props.selectedAppointments[0];
    };

    isTemporary = () => {
        return !!this.state.options;
    };

    findOriginalUser = () => {
        if (this.testAppointment().options) {
            return this.findUser(this.testAppointment().options.original_user)
        } else {
            return this.findUser(this.testAppointment().user_id);
        }
    };

    findOriginalStart = () => {
        if (this.testAppointment().options) {
            return moment(this.testAppointment().options.original_start).utc().format();
        } else {
            return moment(this.testAppointment().start).utc().format();
        }
    };

    originalDaysMatch = () => {
        const originalDay = moment(this.state.originalStart).tz(this.props.timezone).format("dddd");
        const selectedDay = moment(this.state.selectedDate, "MM/DD/YYYY").format("dddd");

        return originalDay === selectedDay;
    };

    originalAssignmentWarning = () => {
        const parsedOriginalDate = moment(this.state.originalStart).tz(this.props.timezone);
        const parsedSelectedDate = moment(this.state.selectedDate, "MM/DD/YYYY").tz(this.props.timezone);

        if (this.state.originalUser.id !== this.state.selectedTech.id) {
            return(
                <div className="alert alert-warning margin-10-bottom">
                    <h5 className="text-center">
                        Appointment(s) originally assigned to { this.state.originalUser.name } on { parsedOriginalDate.format("dddd") }{ this.testAppointment.type === "service_stop" && "'s" }
                        { this.state.originalUser.active && this.revertBackButton() }
                    </h5>
                </div>
            )
        } else if ((this.isTemporary() && !this.originalDaysMatch()) || (this.isTemporary() && (parsedOriginalDate.format("dddd") !== parsedSelectedDate.format("dddd")))) {
            return(
                <div className="alert alert-warning margin-10-bottom">
                    <h5 className="text-center">
                        Appointment(s) originally scheduled on { parsedOriginalDate.format("dddd") }'s -
                        { this.revertBackButton() }
                    </h5>
                </div>
            )
        }
    };

    revertBackButton = () => {
        return(
            <a
                href="#"
                className="margin-5-left"
                onClick={this.revertToOriginal}
            >
                Revert Back
            </a>
        )
    };

    revertToOriginal = (e) => {
        e.preventDefault();

        const originalStart = moment(this.state.originalStart).utc();
        const nextStart = DateHelper.findNextDay(originalStart, this.props.timezone).tz(this.props.timezone);

        this.setState({ options: null, removeOptions: true, selectedTech: this.state.originalUser, selectedDate: nextStart.format("MM/DD/YYYY") }, () => {
            this.datePicker.updateInputValue(nextStart.format("MM/DD/YYYY - dddd"));
        });
    };

    updateToPresentDate = (e) => {
        e.preventDefault();
        this.setState({ selectedDate: moment.tz(this.props.timezone).format("MM/DD/YYYY") });
    };

    selectedDateInPast = () => {
        const parsedPast = moment(this.state.selectedDate, "MM/DD/YYYY").tz(this.props.timezone).endOf("day");
        return moment().tz(this.props.timezone) > parsedPast;
    };

    selectedDateDay = () => {
        return moment(this.state.selectedDate, "MM/DD/YYYY").tz(this.props.timezone).format("dddd");
    };

    saveAppointments = () => {
        this.setState({ loading: true });

        const targetDate = moment(this.state.selectedDate, "MM/DD/YYYY").tz(this.props.timezone);

        const appointments = {};
        this.props.selectedAppointments.forEach(appt => {
            const newStartDateString = `${targetDate.format("YYYY-MM-DD")}T${moment(appt.start).format("HH:mm")}`;
            const newEndDateString = `${targetDate.format("YYYY-MM-DD")}T${moment(appt.end).format("HH:mm")}`;

            appointments[appt.id] = {
                start: moment(newStartDateString).tz(this.props.timezone).utc().format(),
                end: moment(newEndDateString).tz(this.props.timezone).utc().format(),
                user_id: this.state.selectedTech.id
            }

            if (this.state.removeOptions) appointments[appt.id].remove_options = true;
            if (this.state.options && this.state.options.temp_instances && this.state.options.original_user) {
                const parsedApptStart = moment.tz(appt.start, "UTC");
                const parsedOriginalStart = moment.tz(this.state.options.original_start, "UTC");

                const original_start = `${parsedOriginalStart.format("YYYY-MM-DD")}T${parsedApptStart.format("HH:mm")}`
                appointments[appt.id].options = {
                    ...this.state.options,
                    original_start
                }
            }

        });

        $.ajax({
            method: "PUT",
            url: "/appointments/batch_update.json",
            data: {
                appointments
            }
        }).done(() => {
            $(this.modalEl).modal("hide");
            this.props.onClose();

            toast.success("Successfully updated Appointment(s)", {
                position: toast.POSITION.TOP_CENTER
            });
        }).fail(() => {

        })
    };

    render() {
        return(
            <div
                id="advanced-appointment-modal"
                className="modal fade"
                ref={(e) => this.modalEl = e}
            >
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button className="close" type="button" data-dismiss="modal">
                                <span>
                                    &times;
                                </span>
                            </button>
                            <h4 className="modal-title">
                                Advanced Appointment Edit
                            </h4>
                        </div>
                        <div className="modal-body">
                            <div className="display-flex flex-column">
                                <div className="margin-auto">
                                    <i className="fa fa-calendar font-5em color-service-stop"></i>
                                </div>
                                <h4 className="text-center">
                                    Advanced edit options for { this.props.selectedAppointments.length } selected Appointment(s)
                                </h4>
                            </div>

                            <hr/>

                            { this.originalAssignmentWarning() }

                            <div className="row margin-10-bottom">
                                <div className="col-md-4">
                                    <h4>Technician:</h4>
                                    <Select
                                        options={this.props.techs.filter(t => t.active).map(tech => ({ value: tech.id, label: tech.name }))}
                                        value={{ value: this.state.selectedTech.id, label: this.state.selectedTech.name }}
                                        onChange={this.updateSelectedTech}
                                        styles={this.selectStyles}
                                    />
                                </div>
                            </div>
                            <div className="row margin-10-bottom">
                                <div className={this.selectedDateInPast() ? "col-md-5" : "col-md-4"}>
                                    <h4>Date:</h4>
                                    { !this.selectedDateInPast() &&
                                        <DatePicker
                                            date={this.state.selectedDate}
                                            onChange={this.updateDate}
                                            options={{ minDate: moment.tz(this.props.timezone).startOf("day"), format: "MM/DD/YYYY - dddd" }}
                                            ref={(e) => this.datePicker = e}
                                        />
                                    }
                                    { this.selectedDateInPast() &&
                                        <div>
                                            <div className="alert alert-warning margin-10-bottom">
                                                <h5 className="text-center no-margin">
                                                    Appointment(s) scheduled for last { this.selectedDateDay() }
                                                </h5>
                                            </div>
                                            <a
                                                href="#"
                                                className="btn btn-success margin-10-bottom"
                                                onClick={this.revertToOriginal}
                                            >
                                                Move to next { this.selectedDateDay() }
                                            </a>

                                            <br/>

                                            <a
                                                href="#"
                                                className="btn btn-default"
                                                onClick={this.updateToPresentDate}
                                            >
                                                Move to different day
                                            </a>
                                        </div>
                                    }
                                </div>
                            </div>
                            { this.testAppointment().type === "service_stop" &&
                                <div className="row margin-10-bottom">
                                    <div className="col-md-6">
                                        <h4>Appointment(s) configuration type:</h4>

                                        <input
                                            name="type"
                                            id="permanent"
                                            type="radio"
                                            className="margin-5-right"
                                            checked={(!(this.state.options || {}).temp_instances)}
                                            onChange={() => this.setState({ options: null, removeOptions: true })}
                                        />
                                        <label htmlFor="permanent">
                                            Permanent
                                        </label>

                                        <br/>

                                        <input
                                            name="type"
                                            id="temporary"
                                            type="radio"
                                            className="margin-5-right"
                                            checked={this.isTemporary() || false}
                                            onChange={() => this.setState({ options: { ...this.state.options, temp_instances: 1, original_user: this.state.originalUser.id, original_start: this.state.originalStart }, removeOptions: null })}
                                            disabled={this.state.originalUser.id === this.state.selectedTech.id && this.originalDaysMatch()}
                                        />
                                        <label htmlFor="temporary">
                                            Temporary
                                        </label>

                                        { this.isTemporary() &&
                                            <div className="row">
                                                <div className="col-xs-12 display-flex">
                                                    <h5 className="margin-5-right">Assigned for</h5>
                                                    <input
                                                        type="number"
                                                        min="1"
                                                        className="temp-instances form-control text-center"
                                                        value={(this.state.options || this.testAppointment().options).temp_instances}
                                                        onChange={(e) => this.setState({ options: { ...this.state.options, temp_instances: parseInt(e.target.value) } })}
                                                    />
                                                    <h5 className="margin-5-left">Service Stop(s)</h5>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-default margin-5-right" data-dismiss="modal">
                                Close
                            </button>
                            <button
                                className="btn btn-success"
                                type="button"
                                onClick={this.saveAppointments}
                                disabled={this.state.loading || this.selectedDateInPast()}
                            >
                                { this.state.loading &&
                                    <i className="fa fa-spinner fa-pulse fa-fw"></i>
                                }
                                { !this.state.loading && "Save" }
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
