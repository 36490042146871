import React from 'react';

import { ZoneConsumer } from './Index';

export default class EditZone extends React.Component {
    render() {
        return(
            <ZoneConsumer>
                { (context) => {
                    return(
                        <div className="row no-margin">
                            <div className="col-xs-12">
                                <div className="display-flex align-items-center">
                                    <h3 className="margin-10-right no-margin-bottom no-margin-top">
                                        Selected Zone:
                                    </h3>
                                    { context.editZone &&
                                        <div className="form-group flex-2 no-margin-bottom margin-10-right">
                                            <input
                                                name="name"
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Zone Name"
                                                onChange={context.updateSelectedZone}
                                                value={context.aqsZone().name || ""}
                                            />
                                            { context.validations.zoneName &&
                                                <div className="validation-wrapper">
                                                    <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                                                    Name is required
                                                </div>
                                            }
                                        </div>
                                    }
                                    { !context.editZone &&
                                        <div className="flex-2">
                                            <h4 className="no-margin">
                                                { context.aqsZone().name }
                                            </h4>
                                        </div>
                                    }
                                    <div>
                                        { context.editZone &&
                                            <button className="btn btn-success margin-5-right" onClick={context.saveZone}>
                                                <i className="fa fa-save"></i>
                                            </button>
                                        }
                                        { !context.editZone &&
                                            <button className="btn btn-default margin-5-right" onClick={context.setEditZone}>
                                                <i className="fa fa-pencil"></i>
                                            </button>
                                        }
                                        <button className="btn btn-danger" onClick={context.destroyZone}>
                                            <i className="fa fa-trash"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                } }
            </ZoneConsumer>
        )
    }
}
