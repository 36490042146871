import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Switch from '@shared/components/CheckboxSwitch';
import Utils from 'Utils';

const CustomerFeedbackForm = (props) => {
    const icons = {
        yelp: "fa fa-yelp",
        google: "fa fa-google",
        facebook: "fa fa-facebook-f",
        nextdoor: "fa fa-home"
    };

    const placeholders = {
        yelp: "https://www.yelp.com/biz/desert-pools-service-and-repair",
        google: "https://google.com/map/places/Desert+Pool+Service+Repair",
        facebook: "https://facebook.com/DesertPoolServiceRepair/reviews",
        nextdoor: "https://nextdoor.com/pages/desert-pool-service-repair-az/"
    }

    return(
        <Fragment>
            <div className="form-group display-flex align-items-center">
                <Switch
                    name="customer_feedback"
                    checked={props.settings.customer_feedback_enabled}
                    onChange={props.toggleFeedback}
                />
                <label htmlFor="customer-feedback" className="no-margin-bottom">
                    Collect Customer Feedback
                </label>
            </div>
            { props.settings.customer_feedback_enabled &&
                <Fragment>
                    <h4 className="margin-20-bottom">
                        Link your Review Pages:
                    </h4>
                    { props.validations.profiles &&
                        <div className="alert alert-danger margin-10-top margin-20-bottom">
                            <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                            You must include at least one Review Page
                        </div>
                    }
                    {
                        Object.keys(icons).map((key, index) => (
                            <div className="row" key={index}>
                                <div className="col-md-6 col-sm-8">
                                    <div className="form-group">
                                        <div className="input-group">
                                            <div className="input-group-addon">
                                                <i className={`${icons[key]} margin-5-right`}></i>
                                                { Utils.humanize(key) }
                                            </div>
                                            <input
                                                name={`${key}_profile`}
                                                type="text"
                                                className="form-control"
                                                placeholder={placeholders[key]}
                                                value={props.settings[`${key}_profile`] || ""}
                                                onChange={props.updateSettings}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                    <h4 className="margin-bottom-20">
                        Negative Feedback Email
                        <small>
                            &nbsp; When a customer specifies they have had a poor experience with your company, which email address should their message be sent to?
                        </small>
                    </h4>
                    <div className="row">
                        <div className="col-md-6 col-sm-8">
                            { props.validations.email &&
                                <div className="alert alert-danger margin-10-top margin-20-bottom">
                                    <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                                    You must include an email address
                                </div>
                            }
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    name="customer_feedback_email"
                                    value={props.settings.customer_feedback_email}
                                    onChange={props.updateSettings}
                                />
                            </div>
                        </div>
                    </div>
                    { props.saveCallback &&
                        <div className="row">
                            <div className="col-md-6 col-sm-8">
                                <button
                                    className="btn btn-primary"
                                    type="button"
                                    onClick={props.saveCallback}
                                    disabled={props.saving}
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    }
                </Fragment>
            }
        </Fragment>
    )
};

CustomerFeedbackForm.propTypes = {
    settings: PropTypes.object.isRequired,
    toggleFeedback: PropTypes.func.isRequired,
    updateSettings: PropTypes.func.isRequired,
    validations: PropTypes.object.isRequired,
    saveCallback: PropTypes.func,
    saving: PropTypes.bool
}

export default CustomerFeedbackForm;
