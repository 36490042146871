import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import InvoicePreview from './InvoicePreview';
import TextMessageEditor from '../RichMediaElements/TextMessageEditor';

import { toast } from 'react-toastify';
import Utils from '../../Utils';

export default class InvoiceAction extends React.Component {
    static propTypes = {
        action: PropTypes.string.isRequired,
        invoices: PropTypes.array.isRequired,
        updateInvoices: PropTypes.func.isRequired,
        destroyInvoices: PropTypes.func.isRequired,
        integration: PropTypes.string,
        start: PropTypes.string.isRequired,
        end: PropTypes.string.isRequired,
        company: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);

        this.baseState = {
            loading: false,
            errors: false,
            completed: false,
            syncCheck: false,
            syncType: null,
            errorAccounts: [],
            deliveryTypes: [],
            textMessage: `This is ${props.company.name}. An Invoice has just been issued, please review and pay it by going to the following link: %{invoice_public_url}`,
            validations: {
                textMessage: false,
                deliveryTypes: false
            }
        }

        this.state = this.baseState;
    }

    componentDidMount() {
        $("#invoice-action-modal").on("show.bs.modal", (e) => {
            $(e.target).addClass("center-screen-modal");
        });

        $("#invoice-action-modal").on("hide.bs.modal", (e) => {
            $(e.target).removeClass("center-screen-modal");

            this.setState({ ...this.baseState });
        });
    }

    findErrorAccounts = () => {
        const accountIds = [];
        this.props.invoices.forEach(invoice => {
            if ((invoice.account.external_invoice_profile_id || "").length < 1) {
                if (!accountIds.includes(invoice.account.id)) {
                    accountIds.push(invoice.account.id);
                }
            }
        });

        return accountIds.map(id => {
            return this.props.invoices.find(invoice => invoice.account.id === id).account;
        });
    };

    syncPrepare = (syncType) => {
        this.setState({ loading: true, syncType, syncCheck: false }, () => {
            const errorAccounts = this.findErrorAccounts();

            if (errorAccounts.length > 0) {
                this.setState({ loading: false, errors: true, errorAccounts });
            } else {
                this.syncInvoices();
            }
        });
    };

    syncInvoices = () => {
        const url = "/invoices/batch_create.json";

        const sync_options = { [this.state.syncType]: true };

        $.ajax({
            method: "POST",
            url,
            data: {
                invoice_ids: this.props.invoices.map(invoice => invoice.id),
                sync_options,
                q: {
                    created_at_gteq: moment(this.props.start).startOf("day").utc().format(),
                    created_at_lteq: moment(this.props.end).endOf("day").utc().format()
                }
            }
        }).done(invoices => {
            this.props.updateInvoices(invoices);

            this.setState({ loading: false, completed: true, syncType: null });
        }).fail(res => {
            toast.error("Something went wrong...", {
                position: toast.POSITION.TOP_CENTER
            });
        });
    };

    destroyInvoices = () => {
        this.setState({ loading: true });

        $.ajax("/invoices", {
            method: "DELETE",
            data: {
                ids: this.props.invoices.map(i => i.id)
            }
        }).done(res => {
            this.props.destroyInvoices(this.props.invoices.map(i => i.id));

            this.closeModal();

            toast.success("Successfully destroyed invoices", {
                position: toast.POSITION.TOP_CENTER
            });
        }).fail(() => {
            toast.error("Something went wrong...", {
                position: toast.POSITION.TOP_CENTER
            });
        }).always(() => {
            this.setState({ loading: false });
        });
    };

    deliverInvoices = () => {
        if (this.isValid()) {
            this.setState({ loading: true });

            const data = { invoice_ids: this.props.invoices.map(i => i.id) }
            if (this.props.integration === "stripe") {
                data.delivery_types = this.state.deliveryTypes;
                data.text_message = this.state.textMessage
            }

            $.ajax({
                method: "POST",
                url: "/invoices/batch_deliver.json",
                data
            }).done(() => {
                this.setState({ completed: true });
            }).fail(() => {
                toast.error("Something went wrong...", {
                    position: toast.POSITION.TOP_CENTER
                });
            }).always(() => {
                this.setState({ loading: false });
            });
        }
    };

    isValid = () => {
        const validations = {};
        if (this.props.integration !== "stripe") {
            return true
        }

        if (this.state.deliveryTypes.includes("text")) {
            const textMessage = this.state.textMessage.length < 1;

            validations.textMessage = textMessage;
        }

        if (this.state.deliveryTypes.length < 1) {
            validations.deliveryTypes = true;
        }

        this.setState({ validations });

        return !Object.values(validations).includes(true);

        return true;
    };

    pdfUrl = (downloadKey) => {
        const data = this.props.invoices.map(i => `invoice_ids[]=${i.id}&`).join("");

        return `/invoices/pdfs.pdf?${data}download_key=${downloadKey}`;
    };

    makePdf = () => {
        this.setState({ loading: true });

        const downloadKey = moment().unix();

        $("<a>").attr("href", this.pdfUrl(downloadKey))[0].click();

        let cookieVal = Utils.getCookieValue(`breezy_pdf_downloaded_${downloadKey}`);
        const downloadInterval = setInterval(() => {
            if (cookieVal !== Utils.getCookieValue(`breezy_pdf_downloaded_${downloadKey}`)) {
                clearInterval(downloadInterval);
                this.setState({ loading: false, completed: true });
            }
        }, 1000)
    };

    toggleDeliveryType = (type) => {
        let deliveryTypes;
        if (this.state.deliveryTypes.includes(type)) {
            deliveryTypes = this.state.deliveryTypes.filter(t => t !== type);
        } else {
            deliveryTypes = [...this.state.deliveryTypes, type]
        }

        this.setState({ deliveryTypes });
    };

    renderInvoices = () => {
        return this.state.invoices.map((invoice, index) => {
            return(
                <div className="row" key={index}>
                    <div className="col-xs-2">
                        { invoice.due_date }
                    </div>
                    <div className="col-xs-2">
                        { invoice.account.contact_name }
                    </div>
                    <div className="col-xs-2">
                        { invoice.type }
                    </div>
                    <div className="col-xs-2">
                        { invoice.status }
                    </div>
                    <div className="col-xs-2">
                        { `$${invoice.total_amount.toFixed(2)}` }
                    </div>
                    <div className="col-xs-2">
                        { this.showSynced(invoice) }
                    </div>
                </div>
            )
        });
    };

    showSynced = (invoice) => {
        if (invoice.sync_error === "Not Found") {
            return(
                <span className="glyphicon glyphicon-remove"></span>
            )
        } else if (invoice.synced_at === null) {
            return "Never"
        }

        return(
            <span className="glyphicon glyphicon-ok"></span>
        )
    };

    titleizedIntegration = () => {
        return this.props.integration.charAt(0).toUpperCase() + this.props.integration.slice(1);
    };

    modalDialogClass = () => {
        const base = "modal-dialog";

        switch (this.props.action) {
            case 'sync':
                return `${base} modal-lg`
            case 'destroy':
                return base
            case 'deliver':
                return `${base} modal-lg`
            case 'pdfs':
                return `${base} modal-lg`
            case 'process':
                return `${base} modal-lg`
        }
    };

    modalTitle = () => {
        switch (this.props.action) {
            case 'sync':
                return `Review Invoices and sync to ${this.titleizedIntegration()}`
            case 'deliver':
                return `Deliver Invoices`
            case 'pdfs':
                return "Print Selected Invoice(s)"
            case 'process':
                return "Process Automatic Payments"
        }
    };

    renderBody = () => {
        switch (this.props.action) {
            case 'sync':
                return(
                    <div className="invoice-preview-wrapper display-flex flex-column width-100 height-100">
                        { !this.state.loading && !this.state.errors && !this.state.syncCheck && !this.state.completed &&
                            <Fragment>
                                <div className="header-icon review">
                                    <i className="fa fa-refresh"></i>
                                </div>
                                <h4 className="text-center margin-20-bottom">
                                    Review the following Invoice(s) to sync with { this.titleizedIntegration() }
                                </h4>
                                {
                                    this.props.invoices.map((invoice, index) => {
                                        return(
                                            <InvoicePreview invoice={invoice} key={index} />
                                        )
                                    })
                                }
                            </Fragment>
                        }
                        { this.state.syncCheck && !this.state.loading && !this.state.errors &&
                            <Fragment>
                                <div className="header-icon sync-check">
                                    <i className="fa fa-cogs"></i>
                                </div>
                                <h4 className="text-center margin-20-bottom">
                                    How would you like to sync these invoices with { this.titleizedIntegration() }?
                                </h4>
                                <div className="display-flex flex-column justify-content-center align-items-center">
                                    { this.props.integration === "freshbooks" &&
                                        <Fragment>
                                            <div className="margin-10-bottom">
                                                <button className="btn btn-lg btn-primary" onClick={() => this.syncPrepare("recurring_profiles")}>
                                                    Update Recurring Profiles
                                                </button>
                                            </div>
                                            <div>
                                                <button className="btn btn-lg btn-primary" onClick={() => this.syncPrepare("new_invoices")}>
                                                    Create New Invoices
                                                </button>
                                            </div>
                                        </Fragment>
                                    }
                                    { this.props.integration === "quickbooks" &&
                                    <button className="btn btn-lg btn-primary" onClick={() => this.syncPrepare("new")}>
                                        Create New Invoices
                                    </button>
                                    }
                                </div>
                            </Fragment>
                        }
                        { this.state.loading && !this.state.errors &&
                            <div className="display-flex flex-column justify-content-center align-items-center height-100">
                                <h3>
                                    Preparing invoices to be synced
                                </h3>
                                <div>
                                    <i className="fa fa-spinner fa-pulse fa-3x fa-fw align-self-center flex-1"></i>
                                </div>
                            </div>
                        }
                        { this.state.completed && !this.state.errors && !this.state.syncCheck &&
                            <Fragment>
                                <div className="checkmark-animation margin-20-bottom">
                                    <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/><path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/></svg>
                                </div>
                                <h3 className="text-center margin-20-bottom">
                                    Invoices have began to process with { this.titleizedIntegration() }!
                                </h3>
                                <h5 className="text-center">
                                    Selected invoices will be moved from "Processing" status to "Billed" status once they are synced successfully
                                </h5>
                            </Fragment>
                        }
                        { this.state.errors &&
                            <div className="error-wrapper display-flex flex-column height-100">
                                <div className="header-icon warning">
                                    <i className="fa fa-exclamation-triangle color-warning margin-5-right"></i>
                                </div>
                                <h4 className="text-center margin-20-bottom">
                                    The following { this.state.errorAccounts.length } accounts must be synced with { this.titleizedIntegration() } before you can continue:
                                </h4>
                                <div className="alert alert-warning text-center">
                                    Visit <a target="_blank" href="http://help.useaquasuite.com/user-guide/invoices">our help article</a> for instructions on how to sync your accounts to { this.titleizedIntegration() } and try to sync these invoices again.
                                </div>
                                {
                                    this.state.errorAccounts.map((account, index) => {
                                        return(
                                            <div className="panel panel-default margin-10-bottom" key={index}>
                                                <div className="panel-body display-flex overflow-x-scroll">
                                                    <div className="flex-1">
                                                        { account.contact_name }
                                                    </div>
                                                    <div className="flex-1">
                                                        { account.contact_email }
                                                    </div>
                                                    <div className="flex-1">
                                                        { account.location.address }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }
                    </div>
                )
            case 'destroy':
                return(
                    <div className="display-flex flex-column justify-content-center align-items-center">
                        { !this.state.loading &&
                            <Fragment>
                                <h4 className="text-center margin-20-bottom">
                                    Are you sure you want to destroy these Invoices?
                                </h4>
                                <div>
                                    <button className="btn btn-danger" onClick={this.destroyInvoices}>
                                        <span className="glyphicon glyphicon-trash margin-5-right"></span>
                                        Destroy Invoices
                                    </button>
                                </div>
                            </Fragment>
                        }
                        { this.state.loading &&
                            <div className="display-flex justify-content-center margin-20">
                                <i className="fa fa-spinner fa-pulse fa-3x fa-fw align-self-center flex-1"></i>
                            </div>
                        }
                    </div>
                )
            case 'deliver':
                return(
                    <div className="invoice-preview-wrapper display-flex flex-column width-100 height-100">
                        { !this.state.loading && !this.state.completed &&
                            <Fragment>
                                <div className="header-icon deliver">
                                    <i className="fa fa-paper-plane"></i>
                                </div>
                                <h4 className="text-center margin-20-bottom">
                                    Review the following Invoice(s) to { this.props.integration === "stripe" ? "deliver" : "email" }:
                                </h4>
                                { this.props.integration === "stripe" &&
                                    <h5 className="text-center color-gray">
                                        (Note: this will also mark all Draft Invoices as "Billed" after they have been processed)
                                    </h5>
                                }
                                { this.props.integration === "stripe" &&
                                    <Fragment>
                                        <div className="width-100">
                                            <hr/>
                                        </div>

                                        <h4 className="text-center">
                                            Select delivery type(s):
                                        </h4>

                                        <div className="well display-flex flex-column justify-content-center align-items-center">
                                            <div className="form-group">
                                                <label htmlFor="email-delivery-type" className="margin-5-right">
                                                    Email:
                                                </label>
                                                <input
                                                    id="email-delivery-type"
                                                    type="checkbox"
                                                    checked={this.state.deliveryTypes.includes("email")}
                                                    onChange={(e) => this.toggleDeliveryType("email")}
                                                />
                                            </div>
                                            <div className="form-group no-margin-bottom">
                                                <label htmlFor="text-message-delivery-type" className="margin-5-right">
                                                    Text Message:
                                                </label>
                                                <input
                                                    id="text-message-delivery-type"
                                                    type="checkbox"
                                                    disabled={!this.props.company.sms_number}
                                                    checked={this.state.deliveryTypes.includes("text")}
                                                    onChange={(e) => this.toggleDeliveryType("text")}
                                                />
                                            </div>
                                        </div>

                                        { this.state.validations.deliveryTypes &&
                                            <div className="validation-wrapper">
                                                <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                                                Must select at least 1 Delivery Type
                                            </div>
                                        }

                                        { this.state.deliveryTypes.includes("text") &&
                                            <div className="well">
                                                <h4 className="text-center">
                                                    Text Message for each Invoice:
                                                </h4>
                                                <TextMessageEditor
                                                    placeholder={this.state.textMessage}
                                                    updateCallback={(textMessage) => this.setState({ textMessage })}
                                                    specialInserts={{
                                                        "invoice_public_grand_total": "Grand Total",
                                                        "invoice_public_type": "Invoice Type",
                                                        "invoice_public_url": "Invoice Page URL"
                                                    }}
                                                />
                                                { this.state.validations.textMessage &&
                                                    <div className="validation-wrapper">
                                                        <span className="glyphicon glyphicon-exclamation-sign margin-10-right"></span>
                                                        Text Message cannot be blank
                                                    </div>
                                                }
                                            </div>
                                        }

                                        <div className="width-100">
                                            <hr/>
                                        </div>
                                    </Fragment>
                                }
                                {
                                    this.props.invoices.map((invoice, index) => {
                                        return(
                                            <InvoicePreview invoice={invoice} key={index} />
                                        )
                                    })
                                }
                            </Fragment>
                        }
                        { !this.state.completed && this.state.loading &&
                            <div className="display-flex flex-column justify-content-center align-items-center height-100">
                                <h3>
                                    Preparing to deliver Invoice(s)...
                                </h3>
                                <div>
                                    <i className="fa fa-spinner fa-pulse fa-3x fa-fw align-self-center flex-1"></i>
                                </div>
                            </div>
                        }
                        { this.state.completed && !this.state.loading &&
                            <Fragment>
                                <div className="checkmark-animation margin-20-bottom">
                                    <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/><path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/></svg>
                                </div>
                                <h3 className="text-center margin-20-bottom">
                                    { this.props.invoices.length } Invoices have been queued for delivery!
                                </h3>

                                <h5 className="text-center">
                                    All invoices will show when they have been delivered once they are done processing
                                </h5>
                            </Fragment>
                        }
                    </div>
                )
            case 'pdfs':
                return(
                    <div className="invoice-preview-wrapper display-flex flex-column width-100 height-100">
                        { !this.state.loading && !this.state.completed &&
                            <Fragment>
                                <div className="header-icon pdf">
                                    <i className="fa fa-file-pdf-o"></i>
                                </div>
                                <h4 className="text-center margin-20-bottom">
                                    Review the following Invoice(s) to generate a PDF for:
                                </h4>
                                {
                                    this.props.invoices.map((invoice, index) => {
                                        return(
                                            <InvoicePreview invoice={invoice} key={index} />
                                        )
                                    })
                                }
                            </Fragment>
                        }
                        { !this.state.completed && this.state.loading &&
                            <div className="display-flex flex-column justify-content-center align-items-center height-100">
                                <h3 className="text-center">
                                    Generating PDF of selected Invoice(s)
                                </h3>
                                <h4>
                                    (this may take a few minutes)
                                </h4>
                                <div>
                                    <i className="fa fa-spinner fa-pulse fa-3x fa-fw align-self-center flex-1"></i>
                                </div>
                            </div>
                        }
                        { this.state.completed && !this.state.loading &&
                            <Fragment>
                                <div className="checkmark-animation margin-20-bottom">
                                    <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/><path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/></svg>
                                </div>
                                <h3 className="text-center margin-20-bottom">
                                    Your PDF with { this.props.invoices.length } Invoice(s) has been generated successfully
                                </h3>
                            </Fragment>
                        }
                    </div>
                )
            case 'process':
                return(
                    <div className="invoice-preview-wrapper display-flex flex-column width-100 height-100">
                        { !this.state.loading && !this.state.completed &&
                            <Fragment>
                                <div className="header-icon process">
                                    <i className="fa fa-cogs"></i>
                                </div>
                                <h4 className="text-center margin-30-bottom">
                                    The following Invoices belong to Accounts have been flagged for Automatic Payments. AquaSuite will attempt to charge each of these Accounts' default payment source for each Invoice listed below. All Invoices that are unable to be charged automatically will be placed in the "Failed To Process" category.
                                </h4>
                                {
                                    this.props.invoices.map((invoice, index) => {
                                        return(
                                            <InvoicePreview invoice={invoice} key={index} />
                                        )
                                    })
                                }
                            </Fragment>
                        }
                        { !this.state.completed && this.state.loading &&
                            <div className="display-flex flex-column justify-content-center align-items-center height-100">
                                <h3>
                                    Queuing Selected Invoices to Process Automatic Payments
                                </h3>
                                <div>
                                    <i className="fa fa-spinner fa-pulse fa-3x fa-fw align-self-center flex-1"></i>
                                </div>
                            </div>
                        }
                        { this.state.completed && !this.state.loading &&
                            <Fragment>
                                <div className="checkmark-animation margin-20-bottom">
                                    <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/><path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/></svg>
                                </div>
                                <h3 className="text-center margin-20-bottom">
                                    The selected Invoice(s) have begun to process Automatic Payment(s)
                                </h3>
                            </Fragment>
                        }
                    </div>
                )
        }
    };

    renderFooter = () => {
        switch (this.props.action) {
            case 'sync':
                return(
                    <Fragment>
                        { !this.state.syncCheck && !this.state.errors && !this.state.completed &&
                            <button
                                type="button"
                                className="btn btn-success"
                                onClick={() => this.setState({ syncCheck: true })}
                            >
                                Sync Invoices
                            </button>
                        }
                    </Fragment>
                )
            case 'deliver':
                return(
                    <Fragment>
                        { !this.state.completed &&
                            <button
                                type="button"
                                className="btn btn-success"
                                onClick={this.deliverInvoices}
                            >
                                Deliver Invoices
                            </button>
                        }
                    </Fragment>
                )
            case 'pdfs':
                return(
                    <Fragment>
                        { !this.state.completed &&
                            <button
                                type="button"
                                className="btn btn-success"
                                onClick={this.makePdf}
                            >
                                Generate PDF
                            </button>
                        }
                    </Fragment>
                )
            case 'process':
                return(
                    <Fragment>
                        { !this.state.completed &&
                            <button
                                type="button"
                                className="btn btn-success"
                                onClick={this.syncInvoices}
                            >
                                Process Automatic Payments
                            </button>
                        }
                    </Fragment>
                )
        }
    };

    closeModal = () => {
        $("#invoice-action-modal").modal("hide");
    };

    render() {
        return(
            <div
                id="invoice-action-modal"
                className="modal fade"
                tabIndex="-1"
                role="dialog"
            >
                <div className={this.modalDialogClass()} role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                            <h4 className="modal-title">
                                { this.modalTitle() }
                            </h4>
                        </div>
                        <div className="modal-body">
                            { this.renderBody() }
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" data-dismiss="modal">
                                Close
                            </button>

                            { this.renderFooter() }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
