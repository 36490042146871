export default {
    apptIconUrl(appt) {
        switch(appt.type) {
            case "service_stop":
                return "https://s3.amazonaws.com/superswimbros.dev/swimming-pool.svg";
                break;
            case "repair":
                return "https://s3.amazonaws.com/superswimbros.dev/maintenance.svg";
                break;
            case "repair_item":
                return "https://s3.amazonaws.com/superswimbros.dev/maintenance.svg";
        }
    },
}
