import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Metrics from '@shared/components/RouteTech/Metrics/Index';
import TechWidget from '@shared/components/RouteTech/AppointmentsWidget/Index';
import OptimizeModal from './OptimizeModal';

import { toast } from 'react-toastify';
import OptimizerUtils from './Utils';
import Utils from 'Utils';
import RouteTechUtils from '@shared/components/RouteTech/Utils';

export default class RouteOptimizer extends React.Component {
    static propTypes = {
        currentCompany: PropTypes.object.isRequired,
        techs: PropTypes.array.isRequired,
        companySettings: PropTypes.object.isRequired,
        pusherKey: PropTypes.string.isRequired,
        environment: PropTypes.string.isRequired
    }

    constructor(props) {
        super(props);

        this.baseState = {
            loading: true,
            saving: false,
            routeSchedules: {},
            currentRouteSchedules: {},
            filteredTechs: this.sortedTechs(),
            mapMarkers: [],
            startingPointMarker: null,
            displayLabels: false,
            routeMetrics: null,
            metricsFetched: false,
            routesSaveable: false,
            currentRouteMetrics: null,
            selectedRouteMetrics: null,
            selectedTechnicians: [],
            showAccountSearchResults: false,
            accountSearchResults: [],
            showRoutes: true
        }

        if (!window.pusherChannel) {
            const pusher = new Pusher(this.props.pusherKey, {
                cluster: "us2",
                forceTLS: true
            });

            window.pusherChannel = pusher.subscribe(`admin-alerts-${this.props.environment}-${this.props.currentCompany.channel_id}`);
        }

        this.state = this.baseState;
    }

    componentDidMount() {
        const companyLocation = this.props.currentCompany.location;

        this.fetchAppointments().then(() => {
            this.map = new mapboxgl.Map({
                container: 'map',
                style: 'mapbox://styles/mapbox/streets-v11',
                center: [parseFloat(companyLocation.lng), parseFloat(companyLocation.lat)],
                zoom: 10
            });

            this.map.on("load", () => {
                this.addAppointmentsToMap();
                this.setState({ loading: false });

                this.map.on("zoom", (e) => {
                    const mapZoom = this.map.getZoom();

                    if (mapZoom >= 12.5) {
                        if (!this.state.displayLabels) this.setState({ displayLabels: true });
                    } else {
                        if (this.state.displayLabels) this.setState({ displayLabels: false });
                    }
                })
            });
        });
    }

    fetchAppointments = () => {
        this.setState({ loading: true });

        return new Promise((resolve, reject) => {
            $.ajax({
                method: "GET",
                url: "/reports/route/optimizer.json",
            }).done(routeSchedules => {
                this.setState({ routeSchedules, currentRouteSchedules: routeSchedules, selectedTechnicians: Object.keys(routeSchedules) }, () => {
                    resolve();
                });
            }).fail(() => {
                toast.error("Unable to load Service Stop Appointments...", {
                    position: toast.POSITION.TOP_CENTER
                });

                resolve();
            });
        });
    };

    addAppointmentsToMap = (userId = null) => {
        const mapMarkers = [];

        const userIds = userId ? [userId] : this.sortedTechs().map(t => t.id);
        userIds.forEach((userId, userIdIndex) => {
            if (!this.state.currentRouteSchedules[userId]) return false;

            const days = Object.keys(this.state.currentRouteSchedules[userId]).filter(d => d.length === 3);
            const color = RouteTechUtils.techColorFromIndex(userIdIndex)

            days.forEach(day => {
                const appointments = [...this.state.currentRouteSchedules[userId][day]];
                appointments.forEach((appt, apptIndex) => {
                    const markerEl = $(`
                        <div class="ro-marker">
                            ${OptimizerUtils.placemarkerSvg(color)}
                            <div class="label" style="background: ${color}">
                                ${appt.user.name} - ${Utils.humanize(day)} - #${apptIndex + 1}
                            </div>
                        </div>`)[0];

                    const popup = new mapboxgl.Popup({ offset: [0, -25] })
                        .setHTML(`
                            <h5 class="text-center">
                                ${appt.account.contact_name}
                            </h5>
                        `);

                    const location = appt.account.location;
                    const marker = new mapboxgl.Marker(markerEl)
                        .setLngLat([parseFloat(location.lng), parseFloat(location.lat)])
                        .setPopup(popup)
                        .addTo(this.map);

                    marker.aqsUserId = userId;

                    mapMarkers.push(marker);
                });
            });
        });

        this.setState({ mapMarkers });
    };

    removeUserMarkersFromMap = (userId = null) => {
        let remainingMarkers, markersToRemove;
        if (userId) {
            remainingMarkers = [...this.state.mapMarkers].filter(m => m.aqsUserId !== userId);
            markersToRemove = [...this.state.mapMarkers].filter(m => m.aqsUserId === userId);
        } else {
            markersToRemove = [...this.state.mapMarkers];
            remainingMarkers = [];
        }

        markersToRemove.forEach(m => m.remove());

        this.setState({ mapMarkers: remainingMarkers });
    };

    resetUserMarkers = (userId) => {
        this.removeUserMarkersFromMap(userId);
        this.addAppointmentsToMap(userId);
    };

    saveOrderCallback = (userId, route) => {
        if (this.confirmRoutesAreSaved()) {
            const routeSchedules = { ...this.state.currentRouteSchedules };
            routeSchedules[userId] = route[userId];
            this.setState({ routeSchedules, currentRouteSchedules: routeSchedules }, () => {
                this.resetUserMarkers(userId);
            });
        }
    };

    updateRouteMetrics = (routeMetrics) => {
        this.setState({ routeMetrics, currentRouteMetrics: routeMetrics, metricsFetched: true });
    };

    updateTechMetrics = (techId, day, metrics) => {
        const techMetrics = { ...this.state.currentRouteMetrics }[techId];
        techMetrics[day] = metrics

        this.setState({ metrics: { ...this.state.metrics, techMetrics } });
    };

    sortedTechs = () => {
        return this.props.techs.sort((a, b) => moment(a.created_at) - moment(b.created_at));
    };

    userMarkers = (userId) => {
        return this.state.mapMarkers.filter(m => m.aqsUserId.toString() === userId);
    };

    findSelectedRouteMetrics = (selectedTechnicians) => {
        const selectedRouteMetrics = { ...this.state.currentRouteMetrics };
        Object.keys(selectedRouteMetrics).forEach(id => {
            if (!selectedTechnicians.includes(id.toString())) {
                delete selectedRouteMetrics[id.toString()];
            }
        })

        return selectedRouteMetrics;
    };

    toggleTech = (techId) => {
        let selectedTechnicians, selectedRouteMetrics;
        if (this.state.selectedTechnicians.includes(techId.toString())) {
            selectedTechnicians = [...this.state.selectedTechnicians].filter(id => id !== techId.toString());

            this.userMarkers(techId.toString()).forEach(marker => marker.remove());
        } else {
            selectedTechnicians = [...this.state.selectedTechnicians, techId.toString()];

            this.userMarkers(techId.toString()).forEach(marker => marker.addTo(this.map));
        }

        selectedRouteMetrics = this.findSelectedRouteMetrics(selectedTechnicians);

        this.setState({ selectedTechnicians, selectedRouteMetrics });
    };

    selectAllTechnicians = (e) => {
        e.preventDefault();

        const selectedTechnicians = Object.keys(this.state.currentRouteSchedules);

        this.state.mapMarkers.forEach(marker => {
            if (!marker._map) {
                marker.addTo(this.map);
            }
        });

        const selectedRouteMetrics = this.findSelectedRouteMetrics(selectedTechnicians);

        this.setState({ selectedTechnicians, selectedRouteMetrics });
    };

    deSelectAllTechnicians = (e) => {
        e.preventDefault();

        const selectedTechnicians = [];

        this.state.mapMarkers.forEach(marker => {
            if (marker._map) {
                marker.remove();
            }
        });

        const selectedRouteMetrics = this.findSelectedRouteMetrics(selectedTechnicians);

        this.setState({ selectedTechnicians, selectedRouteMetrics });
        this.setState({ selectedTechnicians })
    };

    filterTechnicians = (e) => {
        const term = this.searchTechInputEl.value;

        if (term.length > 0) {
            const filteredTechs = this.sortedTechs().filter(t => t.name.toLowerCase().search(term) !== -1);
            this.setState({ filteredTechs });
        } else {
            this.setState({ filteredTechs: this.sortedTechs() });
        }
    };

    allAppointments = () => {
        let appointments = [];
        const userIds = Object.keys(this.state.currentRouteSchedules);
        userIds.forEach(id => {
            const days = Object.keys(this.state.currentRouteSchedules[id]).filter(d => d.length === 3);
            days.forEach(day => {
                appointments = appointments.concat(this.state.currentRouteSchedules[id][day]);
            });
        });

        return appointments;
    };

    findAccount = () => {
        const term = this.searchAccountInputEl.value.toLowerCase();

        if (term.length > 0) {
            const accountSearchResults = this.allAppointments().filter(a => a.account.contact_name.toLowerCase().search(term) !== -1)
            this.setState({ showAccountSearchResults: true, accountSearchResults });
        } else {
            this.setState({ showAccountSearchResults: false });
        }
    };

    selectAccountFromAppt = (appt) => {
        this.setState({ showAccountSearchResults: false });

        const panel = $(`.panel[data-tech-id=${appt.user_id}]`);

        if (panel) {
            const panelBody = panel.find(".panel-body");
            if (!panelBody.hasClass("expanded")) panelBody.toggleClass("expanded");
            panel[0].scrollIntoView({ behavior: "smooth" })
            setTimeout(() => {
                const apptLi = $(`li[data-appt-id=${appt.id}]`)[0]
                if (apptLi) apptLi.scrollIntoView({ behavior: "smooth" });
            }, 500)
        }
    };

    routesMismatched = () => {
        return JSON.stringify(this.state.routeSchedules) !== JSON.stringify(this.state.currentRouteSchedules)
    };

    confirmRoutesAreSaved = () => {
        if (this.routesMismatched()) {
            this.saveRoutes();
        } else {
            return true;
        }
    };

    saveRoutes = () => {
        if (confirm("This will save the current updated route(s) (this cannot be undone). Would you like to continue?")) {
            this.setState({ saving: true });

            const appointments = {};
            Object.keys(this.state.currentRouteSchedules).forEach(userId => {
                Object.keys(this.state.currentRouteSchedules[userId]).filter(k => k !== "starting_points").forEach(day => {
                    this.state.currentRouteSchedules[userId][day].forEach(appt => {
                        const filteredAppt = (({ start, end }) => ({ start, end }))(appt)
                        appointments[appt.id] = { ...filteredAppt, user_id: userId }
                    });
                });
            });

            window.pusherChannel.bind("batch-appointment-update", (data) => {
                if (data.success) {
                    toast.success("Updated Routes Successfully Saved!", {
                        position: toast.POSITION.TOP_CENTER
                    })

                    this.setState({ savable: false, saving: false });
                } else {
                    toast.error("Unable to Save Updated Routes...", {
                        position: toast.POSITION.TOP_CENTER
                    });

                    this.setState({ saveable: true, saving: false });
                }

                window.pusherChannel.unbind("batch-appointment-update");
            });

            $.ajax({
                method: "PUT",
                url: "/appointments/batch_update",
                data: { appointments, alert: true }
            }).done()
            .fail(() => {
                toast.error("Unable to Save Updated Routes...", {
                    position: toast.POSITION.TOP_CENTER
                });
            });
        }
    };

    resetRoutes = () => {
        this.setState({ showRoutes: false }, () => {
            this.updateRoutes(this.state.routeSchedules, this.state.routeMetrics);
            this.setState({ showRoutes: true });
        });
    };

    updateStartingPoint = (techId, day, startingPoint) => {
        if (this.confirmRoutesAreSaved()) {
            const routeSchedules = { ...this.state.currentRouteSchedules };
            const startingPoints = { ...routeSchedules[techId].starting_points, ...{ [day]: startingPoint } }
            routeSchedules[techId].starting_points = startingPoints;

            this.setState({ routeSchedules, currentRouteSchedules: routeSchedules });
            this.metricsEl.getMetrics(techId, false);
        }
    };

    toggleStartingPoint = (tech, day, startingPoint) => {
        if (this.state.startingPointMarker) {
            const aqsUserId = this.state.startingPointMarker.aqsUserId;
            const aqsDayName = this.state.startingPointMarker.aqsDayName;
            this.state.startingPointMarker.remove();
            this.setState({ startingPointMarker: null });

            if (aqsUserId === tech.id && aqsDayName === day) return true;
        }

        const markerEl = $(`
            <div class="ro-starting-marker">
                ${OptimizerUtils.placemarkerSvg("#e64545")}
                <div class="label" style="background: #e64545">
                    ${tech.name} - ${Utils.humanize(day)} - Starting Point
                </div>
            </div>`)[0];
        const popup = new mapboxgl.Popup({ offset: [0, -25] })
            .setText(startingPoint.location);

        const marker = new mapboxgl.Marker(markerEl)
            .setLngLat([parseFloat(startingPoint.longitude), parseFloat(startingPoint.latitude)])
            .setPopup(popup)
            .addTo(this.map);

        marker.aqsUserId = tech.id;
        marker.aqsDayName = day;

        this.setState({ startingPointMarker: marker });
    };

    techMetrics = (tech) => {
        const metrics = this.state.currentRouteMetrics || {};
        const techMetrics = metrics[tech.id];
        if (techMetrics) return { [tech.id]: { ...techMetrics } };
    };

    techRoute = (tech) => {
        const techRoute = this.state.currentRouteSchedules[tech.id];
        if (techRoute) return { [tech.id]: { ...techRoute } };
    };

    selectedRoutes = () => {
        const routes = {};
        this.state.selectedTechnicians.forEach(userId => {
            const userAppts = this.state.currentRouteSchedules[userId];
            if (userAppts) {
                routes[userId] = this.state.currentRouteSchedules[userId];
            }
        });

        return routes;
    };

    showOptimizeModal = () => {
        this.optimizeModalEl.show();
    };

    updateRoutes = (routeSchedules, routeMetrics) => {
        this.setState({ currentRouteMetrics: routeMetrics, currentRouteSchedules: routeSchedules, selectedRouteMetrics: routeMetrics }, () => {
            this.resetUserMarkers();
            this.setState({ routesSaveable: this.routesMismatched() });
        });
    };

    selectedTechs = () => {
        return (this.state.selectedTechnicians).map(id => this.props.techs.find(t => t.id.toString() === id));
    };

    render() {
        return(
            <div className="route-optimizer-report-wrapper">
                { this.state.loading &&
                    <div className="display-flex flex-column">
                        <h3 className="text-center">
                            Loading All Routes...
                        </h3>
                        <i className="fa fa-spinner fa-pulse fa-3x fa-fw align-self-center flex-1"></i>
                    </div>
                }
                { !this.state.loading &&
                    <Fragment>
                        <Metrics
                            fetchMetrics={!this.state.metricsFetched}
                            routeSchedules={this.state.currentRouteSchedules}
                            updateMetrics={this.updateRouteMetrics}
                            loadedRoutes={this.state.selectedRouteMetrics}
                            ref={(e) => this.metricsEl = e}
                        />
                        <hr/>
                    </Fragment>
                }

                <div className={`map-wrapper ${this.state.loading ? "loading" : "loaded"}`}>
                    <div id="map" className={`${this.state.displayLabels ? "display-labels" : "hide-labels"}`}></div>
                </div>

                { !this.state.loading &&
                    <div className="lower-wrapper">
                        <hr className="no-margin-top" />

                        <div className="display-flex justify-content-space-between align-items-center">
                            { !this.state.saving ?
                                <Fragment>
                                    <div>
                                        <a
                                            href="#"
                                            className="font-1-2em margin-10-right"
                                            onClick={this.selectAllTechnicians}
                                        >
                                            Select All Technicians
                                        </a>
                                        <a
                                            href="#"
                                            className="font-1-2em"
                                            onClick={this.deSelectAllTechnicians}
                                        >
                                            De-Select All Technicians
                                        </a>
                                    </div>
                                    <div>
                                        { !this.state.routesSaveable &&
                                            <button
                                                className="btn btn-success"
                                                disabled={this.state.selectedTechnicians.length < 1}
                                                onClick={this.showOptimizeModal}
                                            >
                                                Auto Optimize Selected Route(s)
                                            </button>
                                        }
                                        { this.state.routesSaveable &&
                                            <Fragment>
                                                <button
                                                    className="btn btn-success" onClick={this.saveRoutes}
                                                >
                                                    Save Updated Route(s)
                                                </button>
                                                <button className="btn btn-default margin-10-left" onClick={this.resetRoutes}>
                                                    Reset Route(s)
                                                </button>
                                            </Fragment>
                                        }
                                    </div>
                                </Fragment>
                            :
                                <Fragment>
                                    <div className="display-flex flex-column justify-content-center margin-20-bottom width-100">
                                        <h3 className="text-center">
                                            Saving Routes...
                                        </h3>
                                        <h4 className="text-center">
                                            (This may take a few minutes)
                                        </h4>
                                        <i className="fa fa-spinner fa-pulse fa-2x fa-fw align-self-center flex-1"></i>
                                    </div>
                                </Fragment>
                            }
                        </div>

                        <hr/>

                        { !this.state.saving &&
                            <Fragment>
                                <div className="search-wrapper display-flex justify-content-space-between align-items-center">
                                    <div className="form-group no-margin">
                                        <div className="display-flex">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search Technician by Name"
                                                ref={(e) => this.searchTechInputEl = e}
                                            />
                                            <button className="btn btn-default margin-10-left" onClick={this.filterTechnicians}>
                                                Search
                                            </button>
                                        </div>
                                    </div>
                                    <div className="form-group no-margin position-relative">
                                        <div className="display-flex">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search Account by Contact Name"
                                                ref={(e) => this.searchAccountInputEl = e}
                                            />
                                            <button className="btn btn-default margin-10-left" onClick={this.findAccount}>
                                                Search
                                            </button>
                                        </div>
                                        { this.state.showAccountSearchResults &&
                                            <div className="account-search-results">
                                                { this.state.accountSearchResults.length > 0 ?
                                                    this.state.accountSearchResults.map((appt, index) => (
                                                        <a
                                                            key={index}
                                                            href="#"
                                                            onClick={(e) => { e.preventDefault(); this.selectAccountFromAppt(appt) }}
                                                            className="display-block account-result-link"
                                                        >
                                                            { appt.account.contact_name } - { moment(appt.start).format("dddd") }
                                                        </a>
                                                    ))
                                                :
                                                    <h5 className="text-center color-gray">
                                                        No Results Found
                                                    </h5>
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>

                                <hr/>

                                <div className="tech-wrapper">
                                    {
                                        (this.state.currentRouteSchedules && this.state.currentRouteMetrics && this.state.showRoutes) && this.state.filteredTechs.map((tech, index) => {
                                            if (this.state.currentRouteSchedules[tech.id] && this.state.currentRouteMetrics[tech.id]) {
                                                return(
                                                    <TechWidget
                                                        key={index}
                                                        metrics={this.techMetrics(tech)}
                                                        route={this.techRoute(tech)}
                                                        techIndex={this.sortedTechs().findIndex(t => t.id === tech.id)}
                                                        tech={tech}
                                                        toggleable={true}
                                                        startingPoints={(this.state.currentRouteSchedules[tech.id] || {}).starting_points}
                                                        toggleStartingPoint={this.toggleStartingPoint}
                                                        updateStartingPoint={this.updateStartingPoint}
                                                        updateTechMetrics={this.updateTechMetrics}
                                                        startingPointMarker={this.state.startingPointMarker}
                                                        timezone={this.props.currentCompany.timezone}
                                                        toggleCallback={this.toggleTech}
                                                        toggled={this.state.selectedTechnicians.includes(tech.id.toString())}
                                                        companySettings={this.props.companySettings}
                                                        saveOrderCallback={this.saveOrderCallback}
                                                    />
                                                )
                                            }
                                        })
                                    }
                                </div>
                            </Fragment>
                        }
                    </div>
                }
                { this.state.selectedTechnicians.length > 0 &&
                    <OptimizeModal
                        ref={(e) => this.optimizeModalEl = e}
                        selectedRoutes={this.selectedRoutes()}
                        pusherKey={this.props.pusherKey}
                        environment={this.props.environment}
                        currentCompany={this.props.currentCompany}
                        callback={this.updateRoutes}
                        startTimes={this.props.companySettings.service_days}
                        selectedTechs={this.selectedTechs()}
                        toggleOptimizing={() => this.setState({ showRoutes: !this.state.showRoutes })}
                    />
                }
            </div>
        )
    }
}
