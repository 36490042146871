import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Utils from 'Utils';
import MaskedInput from 'react-text-mask';
import CreateNumberMask from '@shared/CreateNumberMask';
import { toast } from 'react-toastify';
import Switch from '@shared/components/CheckboxSwitch';

export default class ServiceItem extends React.Component {
    static propTypes = {
        serviceItem: PropTypes.object.isRequired,
        destroyServiceItem: PropTypes.func.isRequired,
        updateServiceItem: PropTypes.func.isRequired,
        accountId: PropTypes.number.isRequired,
        index: PropTypes.number.isRequired
    }

    constructor(props) {
        super(props);

        this.baseState = {
            loading: false,
            editing: !props.serviceItem.id
        }

        this.state = this.baseState;
    }

    linkUrl = () => {
        let linkUrl = "/accounts/";
        const { serviceItem } = this.props;

        if (serviceItem.service_stop) {
            linkUrl += `${serviceItem.service_stop.account_id}/service_stops/${serviceItem.service_stop.id}`;
        } else if (serviceItem.service_set) {
            linkUrl += `${serviceItem.service_set.service_stop.account_id}/service_stops/${serviceItem.service_set.service_stop.id}`;
        } else {
            return false;
        }

        return linkUrl;
    };

    bowName = () => {
        if (this.props.serviceItem.service_set) {
            return this.props.serviceItem.service_set.body_of_water.name;
        }
    };

    saveServiceItem = () => {
        this.setState({ loading: true });

        const { serviceItem } = this.props;
        let url = `/accounts/${this.props.accountId}/service_items`;
        if (serviceItem.id) url +=`/${serviceItem.id}`;
        url += ".json";

        const method = serviceItem.id ? "PUT" : "POST";

        $.ajax({
            method,
            url,
            data: {
                service_item: { ...serviceItem }
            }
        }).done((si) => {
            const serviceItem = si || { ...this.props.serviceItem };
            this.setState({ loading: false, editing: false, serviceItem });

            toast.success("Successfully saved Service Item!", {
                position: toast.POSITION.TOP_CENTER
            });

            this.props.updateServiceItem(serviceItem, this.props.index);
        }).fail(() => {
            toast.error("Unable to save Service Item... Please try again later", {
                position: toast.POSITION.TOP_CENTER
            })
        });
    };

    render() {
        const amountMask = CreateNumberMask({
            prefix: "",
            allowDecimal: true,
            allowNegative: true
        })
        const { serviceItem } = this.props;
        return(
            <tr>
                { !this.state.editing &&
                    <Fragment>
                        <td>
                            { !this.bowName() &&
                                <Fragment>
                                    { this.linkUrl() &&
                                        <a href={this.linkUrl()}>{ serviceItem.name }</a>
                                    }
                                    { !this.linkUrl() && serviceItem.name }
                                </Fragment>
                            }
                            { this.bowName() &&
                                <Fragment>
                                    { this.linkUrl() &&
                                        <a href={this.linkUrl()}>{ `${serviceItem.name} (${Utils.humanize(this.bowName())})` }</a>
                                    }
                                    { !this.linkUrl() && serviceItem.name }
                                </Fragment>
                            }
                        </td>
                        <td>
                            { Utils.floatToCurrency(parseFloat(serviceItem.price)) }
                        </td>
                        <td>
                            { serviceItem.quantity }
                        </td>
                        <td>
                            { serviceItem.recurring &&
                                <span className="glyphicon glyphicon-ok"></span>
                            }
                            { !serviceItem.recurring &&
                                <span className="glyphicon glyphicon-remove"></span>
                            }
                        </td>
                        <td>
                            <button className="btn btn-danger margin-10-right" onClick={() => this.props.destroyServiceItem(serviceItem, this.props.index)}>
                                <span className="glyphicon glyphicon-trash"></span>
                            </button>
                            <button className="btn btn-default" onClick={() => this.setState({ editing: true })}>
                                <i className="fa fa-pencil"></i>
                            </button>
                        </td>
                    </Fragment>
                }
                { this.state.editing &&
                    <Fragment>
                        <td>
                            <input
                                type="text"
                                className="form-control"
                                value={serviceItem.name || ""}
                                onChange={(e) => this.props.updateServiceItem({ ...this.props.serviceItem, name: e.target.value }, this.props.index)}
                            />
                        </td>
                        <td>
                            <div className="input-group">
                                <div className="input-group-addon">
                                    <span className="glyphicon glyphicon-usd"></span>
                                </div>
                                <MaskedInput
                                    className="form-control"
                                    value={parseFloat(serviceItem.price).toFixed(2) || ""}
                                    mask={amountMask}
                                    onChange={(e) => this.props.updateServiceItem({ ...this.props.serviceItem, price: e.target.value }, this.props.index)}
                                />
                            </div>
                        </td>
                        <td>
                            <input
                                type="number"
                                className="form-control"
                                value={serviceItem.quantity || ""}
                                step="0.5"
                                onChange={(e) => this.props.updateServiceItem({ ...this.props.serviceItem, quantity: e.target.value }, this.props.index)}
                            />
                        </td>
                        <td>
                            <Switch
                                name="recurring"
                                checked={serviceItem.recurring}
                                onText="✓"
                                offText="X"
                                onChange={() => this.props.updateServiceItem({ ...this.props.serviceItem, recurring: !this.props.serviceItem.recurring }, this.props.index)}
                            />
                        </td>
                        <td>
                            <div className="display-flex">
                                <button className="btn btn-danger margin-10-right" onClick={() => this.props.destroyServiceItem(serviceItem, this.props.index)}>
                                    <span className="glyphicon glyphicon-trash"></span>
                                </button>
                                <button className="btn btn-success" onClick={this.saveServiceItem}>
                                    { this.state.loading &&
                                        <div className="display-flex">
                                            <i className="fa fa-spinner fa-pulse fa-fw align-self-center flex-1"></i>
                                        </div>
                                    }
                                    { !this.state.loading &&
                                        <i className="fa fa-save"></i>
                                    }
                                </button>
                            </div>
                        </td>
                    </Fragment>
                }
            </tr>
        )
    }
}
