import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { toast } from 'react-toastify';
import Utils from 'Utils';

const EmailEntries = (props) => {
    const [emails, setEmails] = useState(props.emails.map(email => ({ value: email, key: btoa(Math.random()) })));
    const [saving, setSaving] = useState(false);

    const updateEmail = (index, value) => {
        const _emails = emails.map((email, _index) => {
            if (_index === index) {
                return { value, key: email.key }
            } else {
                return { ...email };
            }
        });

        setEmails(_emails);
    };

    const addEmail = (e) => {
        e.preventDefault();

        const _emails = [...emails, { value: "", key: btoa(Math.random()) }];

        setEmails(_emails);
    };

    const removeEmail = (e, index) => {
        e.preventDefault();

        const _emails = [...emails].filter((e, _index) => _index !== index);
        setEmails(_emails);
    };

    const saveEmails = () => {
        setSaving(true);

        const validEmails = emails.filter(email => email.value.length > 1);

        $.ajax({
            method: "PUT",
            url: `/accounts/${props.accountId}/update_settings.json`,
            contentType: "application/json",
            data: JSON.stringify({
                account: {
                    [`${props.type}_emails`]: validEmails.map(e => e.value)
                }
            })
        }).done(() => {
            toast.success(`${Utils.humanize(props.type)} Emails successfully saved!`, {
                position: toast.POSITION.TOP_CENTER
            });
        }).fail(() => {
            toast.error(`Unable to save ${Utils.humanize(props.type)} emails...`, {
                position: toast.POSITION.TOP_CENTER
            });
        }).always(() => {
            setSaving(false);
        })
    };

    return(
        <div>
            {
                emails.map((email, index) => (
                    <div className="form-group display-flex align-items-center" key={email.key}>
                        <input
                            className="form-control flex-2"
                            type="text"
                            onChange={(e) => updateEmail(index, e.target.value)}
                            defaultValue={email.value}
                        />
                        <a href="#" className="margin-10-left display-block flex-1" onClick={(e) => removeEmail(e, index)}>
                            <i className="fa fa-minus-circle margin-5-right"></i>
                            Remove
                        </a>
                    </div>
                ))
            }
            <a href="#" onClick={addEmail}>
                <i className="fa fa-plus-circle margin-5-right"></i>
                Add Email
            </a>
            <div className="display-flex justify-content-center">
                <button
                    className="btn btn-success"
                    disabled={saving}
                    onClick={saveEmails}
                >
                    <i className="fa fa-save margin-5-right"></i>
                    Save
                </button>
            </div>
        </div>
    )
};

EmailEntries.propTypes = {
    type: PropTypes.string.isRequired,
    emails: PropTypes.array.isRequired,
    accountId: PropTypes.number.isRequired
};

export default EmailEntries
