import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { List, AutoSizer } from 'react-virtualized';


export default class ExternalCustomers extends React.Component {
    static propTypes = {
        integration: PropTypes.string.isRequired,
        account: PropTypes.object.isRequired,
        externalCustomers: PropTypes.array.isRequired,
        updateMatched: PropTypes.func.isRequired,
        externalProp: PropTypes.func.isRequired,
        filterExternalCustomers: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.baseState = {
            filteredCustomers: props.externalCustomers
        }

        this.state = this.baseState;
    }

    renderRow = ({ index, style, key }) => {
        const customer = this.state.filteredCustomers[index];

        return(
            <div
                key={key}
                style={style}
            >
                <div className="external-row display-flex justify-content-space-between">
                    <div className="matched-desc display-flex flex-column">
                        <div className="inner-desc-wrapper">
                            <div className="header-wrapper display-flex">
                                <div className="header">
                                    Name:
                                </div>
                                <div className="header">
                                    Address:
                                </div>
                                <div className="header">
                                    Email Address:
                                </div>
                            </div>
                            <div className="display-flex">
                                <div className="header-value">
                                    { this.props.externalProp("name", customer) }
                                </div>
                                <div className="header-value">
                                    { this.props.externalProp("address", customer) }
                                </div>
                                <div className="header-value">
                                    { this.props.externalProp("email", customer) }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="match-button">
                        <button className="btn btn-info" onClick={() => this.props.updateMatched(this.props.account.id, this.props.externalProp("id", customer))}>
                            Match
                        </button>
                    </div>
                </div>
            </div>
        )
    };

    handleSearch = (e) => {
        const filteredCustomers = this.props.filterExternalCustomers(e.target.value);

        this.setState({ filteredCustomers });
    };

    render() {
        return(
            <div className="customer-list display-flex flex-column">
                <div className="row">
                    <h4 className="text-center margin-20-bottom">
                        { this.props.integration } Customers:
                    </h4>
                    <div className="form-group col-md-6 display-flex position-relative no-margin-bottom">
                        <input
                            type="text"
                            className="form-control"
                            placeholder={`Search`}
                            onChange={this.handleSearch}
                        />
                        <i className="fa fa-search"></i>
                    </div>
                </div>
                <div className="width-100">
                    <hr/>
                </div>
                <div className="margin-10-bottom">
                    <AutoSizer>
                        {({ width, height }) => (
                            <List
                                width={width}
                                height={200}
                                rowHeight={95}
                                rowRenderer={this.renderRow}
                                rowCount={this.state.filteredCustomers.length}
                                overScanRowCount={3}
                            />
                        )}
                    </AutoSizer>
                </div>
            </div>
        )
    }
}
