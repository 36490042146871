import React from 'react';
import PropTypes from 'prop-types';

import Modal from './Modal';

const ExternalMatchContext = React.createContext();

export default class ExternalMatchProvider extends React.Component {
    static propTypes = {
        integration: PropTypes.string.isRequired
    }

    constructor(props) {
        super(props);

        this.baseState = {
            loading: "initial",
            error: false,
            success: false,
            accounts: [],
            matchedAccounts: [],
            externalCustomers: [],
        }

        this.state = this.baseState;
    }

    fetchAccountsAndCustomers = () => {
        $.when(this.fetchAccounts(), this.fetchExternalCustomers()).done((accounts, externalCustomers) => {
            this.setState({ accounts: accounts[0], externalCustomers: externalCustomers[0], loading: false });
        });
    };

    fetchAccounts = () => {
        const options = {
            dataType: "json",
            method: "get",
            data: {
                external_match: true
            }
        };

        return $.ajax("/accounts", options);
    };

    updateAccounts = () => {
        const success = false, error = false, loading = "saving";

        this.setState({ success, error, loading });

        const accounts = {}
        this.state.matchedAccounts.forEach(account => {
            for (let [id, value] of Object.entries(account)) {
                accounts[id] = value;
            }
        });

        $.ajax({
            method: "PUT",
            url: "/accounts/batch_update.json",
            data: { accounts }
        }).done(res => {
            this.setState({ matchedAccounts: [], success: "saved", loading: false });
        }).fail(res => {
            // handle failure
        })
    };

    fetchExternalCustomers = () => {
        const options = {
            method: "get",
        };

        return $.ajax(`/invoice_integrations/${this.props.integration.toLowerCase()}/customers.json`);
    };

    updateMatched = (accountId, externalId) => {
        const matchedAccounts = [...this.state.matchedAccounts].filter(acct => Object.keys(acct)[0] !== accountId.toString());

        matchedAccounts.push({ [accountId]: { external_invoice_profile_id: externalId } });

        const accounts = [...this.state.accounts];
        const index = accounts.findIndex(acct => acct.id === accountId);
        const account = accounts[index];
        account.external_invoice_profile_id = externalId;

        this.setState({ matchedAccounts, accounts });
    };

    filterExternalCustomers = (term) => {
        return this.state.externalCustomers.filter(customer => {
            if (this.props.integration.toLowerCase() === "quickbooks") {
                const email = customer["PrimaryEmailAddr"] || {};
                const addr = customer["BillAddr"] || {};

                return (customer["FullyQualifiedName"].toLowerCase().search(term.toLowerCase()) !== -1 || (email["Address"] || "").toLowerCase().search(term.toLowerCase()) !== -1 || (addr["Line1"] || "").toLowerCase().search(term) !== -1)
            } else if (this.props.integration === "freshbooks") {
                return (`${customer["fname"]} ${customer["lname"]}`.toLowerCase().search(term.toLowerCase()) !== -1 || (customer["email"].toLowerCase().search(term.toLowerCase()) !== -1) || (`${customer["p_street"]}`.toLowerCase().search(term.toLowerCase) !== -1))
            }
        })
    };

    externalProp = (prop, customer) => {
        if (this.props.integration.toLowerCase() === "quickbooks") {
            if (customer) {
                switch(prop) {
                    case 'id':
                        return customer["Id"];
                    case 'name':
                        return customer["FullyQualifiedName"];
                    case 'address':
                        const addr = customer["BillAddr"];

                        if (addr) {
                            return `${addr["Line1"]} ${addr["City"]}, ${addr["CountrySubDivisionCode"]} ${addr["PostalCode"]}`
                        } else {
                            return "--";
                        }
                    case 'email':
                        const email = customer["PrimaryEmailAddr"]
                        return email ? email["Address"] : "--";
                    }
            } else {
                return "--";
            }
        } else if (this.props.integration.toLowerCase() === "freshbooks") {
            switch(prop) {
                case 'id':
                    return customer["id"];
                case 'name':
                    return `${customer["fname"]} ${customer["lname"]}`;
                case 'address':
                    return `${customer["p_street"]} ${customer["p_city"]}, ${customer["p_province"]} ${customer["p_code"]}`;
                case 'email':
                    return customer["email"];
            }
        }
    };

    findUniqueId = (props, matchedAccounts) => {
        for (let i = 0; i < props.length; i++) {
            const term = props[i];
            const result = this.filterExternalCustomers(term);
            if (result.length === 1) {
                const customer = result[0];
                const id = this.externalProp("id", customer);
                const matchedExternalIds = matchedAccounts.map(matched => Object.values(matched)[0]["external_invoice_profile_id"]);

                if (matchedExternalIds.indexOf(id) === -1) {
                    return id;
                }
            }
        }
    };

    autoMatch = () => {
        this.setState({ loading: "matching" }, () => {
            setTimeout(() => {
                const matchedAccounts = [];
                const accounts = this.state.accounts.map(account => {
                    const name = account.contact_name;
                    const address = account.full_location;
                    const email = account.contact_email;
                    const props = [name, address, email];

                    const matchedId = this.findUniqueId(props, matchedAccounts);

                    if (matchedId) {
                        matchedAccounts.push({ [account.id]: { external_invoice_profile_id: matchedId } });
                        account["external_invoice_profile_id"] = matchedId;
                    }

                    return account;
                });

                let success = false, error = false;
                if (this.matchedAccounts().length === this.state.accounts.length) {
                    success = "auto-match";
                } else {
                    error = "auto-match"
                }
                setTimeout(() => {
                    this.setState({ loading: false, matchedAccounts, accounts, success, error });
                }, 5000);
            })
        });
    };

    matchedAccounts = () => {
        return this.state.accounts.filter(account => account.external_invoice_profile_id);
    };

    render() {
        return(
            <ExternalMatchContext.Provider
                value={{
                    ...this.state,
                    integration: this.props.integration,
                    filterExternalCustomers: this.filterExternalCustomers,
                    updateMatched: this.updateMatched,
                    externalProp: this.externalProp,
                    autoMatch: this.autoMatch,
                    matchedAccounts: this.matchedAccounts,
                    updateAccounts: this.updateAccounts
                }}
            >
                <Modal fetchAccountsAndCustomers={this.fetchAccountsAndCustomers} />
            </ExternalMatchContext.Provider>
        )
    }
}

export const ExternalMatchConsumer = ExternalMatchContext.Consumer;
