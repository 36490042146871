import React from 'react';
import PropTypes from 'prop-types';

import BodyOfWater from './BodyOfWater';
import ValidationMessage from '../ValidationMessage';

export default class BodyOfWatersSelect extends React.Component {
    static propTypes = {
        getMasterState: PropTypes.func.isRequired,
        updateMasterState: PropTypes.func.isRequired,
        body_of_waters_attributes: PropTypes.array.isRequired,
        mainBowNotSelected: PropTypes.bool.isRequired
    }

    constructor(props) {
        super(props);

        this.baseState = {
            body_of_waters_attributes: props.body_of_waters_attributes,
            mainBowNotSelected: props.mainBowNotSelected
        }

        this.state = this.baseState;
    }

    componentWillReceiveProps(props) {
        let { body_of_waters_attributes, mainBowNotSelected } = { ...props };

        this.setState({ body_of_waters_attributes, mainBowNotSelected });
    }

    invalidBodyOfWaters() {
        const results = this.state.body_of_waters_attributes.map((bow, index) => {
            return this[`bodyOfWater_${index}`].checkInvalid();
        });

        return results.includes(true);
    }

    addBow = () => {
        const body_of_waters_attributes = this.props.getMasterState().body_of_waters_attributes;

        this.props.updateMasterState({ body_of_waters_attributes: [...body_of_waters_attributes, { validations: { name: false, gallons: false } }] })
    };

    updateBodyOfWater = (_key) => {
        if (this[`bodyOfWater_${_key}`]) {
            const bodyOfWater = this[`bodyOfWater_${_key}`].getBodyOfWater();

            const body_of_waters_attributes = this.props.getMasterState().body_of_waters_attributes.map((bow, index) => {
                if (index === _key) {
                    return bodyOfWater
                } else {
                    return bow
                }
            });

            this.props.updateMasterState({ body_of_waters_attributes });
        }
    };

    removeBodyOfWater = (_key) => {
        let body_of_waters_attributes = this.props.getMasterState().body_of_waters_attributes.map((bow, index) => {
            if (_key !== index) {
                return this[`bodyOfWater_${index}`].getBodyOfWater();
            } else if (_key === index && bow.id) {
                return { ...this[`bodyOfWater_${index}`].getBodyOfWater(), _destroy: true }
            }
        });

        body_of_waters_attributes = body_of_waters_attributes.filter(bow => bow); // filter out undefiend

        this.props.updateMasterState({ body_of_waters_attributes });
    };

    mainSelected = () => {
        return this.state.body_of_waters_attributes.filter(bow => bow.main).length > 0
    };

    render() {
        return(
            <div className="body-of-water-select-wrapper">
                {
                    this.state.body_of_waters_attributes.map((bow, index) => {
                        return(
                            <BodyOfWater
                                key={index}
                                _key={index}
                                getMasterState={this.props.getMasterState}
                                bodyOfWater={bow}
                                validations={bow.validations || { name: false, gallons: false } }
                                updateBodyOfWater={this.updateBodyOfWater}
                                removeBodyOfWater={this.removeBodyOfWater}
                                mainSelected={this.mainSelected()}
                                ref={(ref) => this[`bodyOfWater_${index}`] = ref}
                            />
                        )
                    })
                }
                <div>
                    <a
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            this.addBow();
                        }}
                    >
                        Add Body of Water
                    </a>
                </div>
                <ValidationMessage condition={this.state.mainBowNotSelected} message="Must have main Body of Water selected" />
            </div>
        )
    }
}
