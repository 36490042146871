import React, { Fragment } from 'react';

import AqsChart from '@shared/components/Chart';
import DateTimePicker from '@shared/components/DateTimePicker';
import Utils from 'Utils';
import DownloadCsv from '@shared/components/DownloadCsv';

import { toast } from 'react-toastify';

export default class IncomeReport extends React.Component {
    constructor(props) {
        super(props);

        this.baseState = {
            loading: false,
            start: moment().subtract(30, "days").format("MM/DD/YYYY"),
            end: moment().format("MM/DD/YYYY"),
            paymentStatuses: ["draft", "paid", "billed", "past_due", "failed_to_process"],
            paymentTypes: ["cash", "check", "credit_card"],
            reportType: "invoices",
            income: {
                repairs: 0.0,
                service: 0.0,
                service_items: 0.0,
                chemicals: 0.0,
                sales_tax: 0.0
            },
            payments: {
                cash: 0.0,
                check: 0.0,
                credit_card: 0.0
            }
        }

        this.paymentStatuses = ["draft", "paid", "billed", "past_due", "failed_to_process"];
        this.state = this.baseState;
    }

    componentDidMount() {
        this.fetchIncome();
    }

    toggleStatus = (status) => {
        let paymentStatuses;
        if (this.state.paymentStatuses.includes(status)) {
            paymentStatuses = [...this.state.paymentStatuses].filter(s => s !== status);
        } else {
            paymentStatuses = [...this.state.paymentStatuses, status];
        }

        this.setState({ paymentStatuses });
    };

    toggleType = (type) => {
        let paymentTypes;
        if (this.state.paymentTypes.includes(type)) {
            paymentTypes = [...this.state.paymentTypes].filter(t => t !== type);
        } else {
            paymentTypes = [...this.state.paymentTypes, type];
        }

        this.setState({ paymentTypes });
    };

    chartData = () => {
        const data = this.state.reportType === "invoices" ? this.state.income : this.state.payments;

        const backgroundColors = ["rgba(214, 202, 40, 0.5)", "rgba(58, 135, 173, 0.5)", "rgba(28, 70, 201, 0.5)", "rgba(255, 89, 92, 0.5)", "rgba(165, 125, 115, 0.5)"];

        const borderColors = ["rgba(214, 202, 40, 1)", "rgba(58, 135, 173, 1)", "rgba(28, 70, 201, 1)", "rgba(255, 89, 92, 1)", "rgba(165, 125, 115, 1)"];

        const datasets = Object.keys(data).map((prop, index) => (
            {
                label: Utils.humanize(prop),
                backgroundColor: backgroundColors[index],
                borderColor: borderColors[index],
                borderWidth: 1,
                data: [data[prop]]
            }
        ));

        return { datasets, labels: [] };
    };

    requestData = () => {
        const data = {
            start: moment(this.state.start, "MM/DD/YYYY").startOf("day").utc().format(),
            end: moment(this.state.end, "MM/DD/YYYY").endOf("day").utc().format(),
            report_type: this.state.reportType
        }

        if (this.state.reportType === "invoices") {
            data.statuses = this.state.paymentStatuses;
        } else {
            data.types = this.state.paymentTypes;
        }

        return data;
    };

    fetchIncome = (e) => {
        this.setState({ loading: true });
        $.ajax({
            method: "GET",
            url: "/reports/income.json",
            data: this.requestData()
        }).done(results => {
            for (const [key, value] of Object.entries(results)) {
                if (value < 1) results[key] = 0.0
            }

            if (this.state.reportType === "invoices") {
                this.setState({ income: results, loading: false });
            } else {
                this.setState({ payments: results, loading: false });
            }
        }).fail(() => {
            toast.error("Unable to fetch Income, please try again later...", {
                position: toast.POSITION.TOP_CENTER
            })
        });
    };

    totalAmount = (currencySymbol = true) => {
        const data = this.state.reportType === "invoices" ? this.state.income : this.state.payments;
        const sum = Object.values(data).reduce((a,b) => a + b);

        if (currencySymbol) return Utils.floatToCurrency(sum);
        return sum;
    };

    render() {
        return(
            <Fragment>
                <h4>
                    Billing Period:
                </h4>
                <div className="display-flex">
                    <div className="form-group display-flex align-items-center">
                        <label className="margin-5-right no-margin-bottom">Between</label>
                        <DateTimePicker
                            inputName="start"
                            callback={(e) => this.setState({ start: e.target.value })}
                            value={this.state.start}
                            options={{
                                format: "MM/DD/YYYY"
                            }}
                        />
                    </div>
                    <div className="form-group display-flex align-items-center margin-5-left">
                        <label className="margin-5-right no-margin-bottom">And</label>
                        <DateTimePicker
                            inputName="end"
                            callback={(e) => this.setState({ end: e.target.value })}
                            value={this.state.end}
                            options={{
                                format: "MM/DD/YYYY"
                            }}
                        />
                    </div>
                </div>

                <hr/>

                <h4>
                    Report Type:
                </h4>
                <div className="display-flex">
                    <div className="form-group margin-10-right no-margin-bottom">
                        <div className="display-flex flex-column align-items-center">
                            <label htmlFor="invoices">
                                Invoices:
                            </label>
                            <input
                                id="invoices"
                                name="type"
                                type="radio"
                                checked={this.state.reportType === "invoices"}
                                onChange={() => this.setState({ reportType: "invoices" })}
                            />
                        </div>
                    </div>
                    <div className="form-group no-margin-bottom">
                        <div className="display-flex flex-column align-items-center">
                            <label htmlFor="payments">
                                Payments:
                            </label>
                            <input
                                id="payments"
                                name="type"
                                type="radio"
                                checked={this.state.reportType === "payments"}
                                onChange={() => this.setState({ reportType: "payments" })}
                            />
                        </div>
                    </div>
                </div>

                <hr/>

                { this.state.reportType === "invoices" &&
                    <Fragment>
                        <h4>
                            Invoices to Include:
                        </h4>
                        <div className="display-flex invoice-types-wrapper">
                            {
                                this.paymentStatuses.map((status, index) => (
                                    <div className="form-group display-flex margin-10-right" key={index}>
                                        <input
                                            id={status}
                                            className="margin-5-right"
                                            type="checkbox"
                                            checked={this.state.paymentStatuses.includes(status)}
                                            onChange={() => this.toggleStatus(status)}
                                        />
                                        <label htmlFor={status}>{ Utils.humanize(status) }</label>
                                    </div>
                                ))
                            }
                        </div>
                    </Fragment>
                }

                { this.state.reportType === "payments" &&
                    <Fragment>
                        <h4>
                            Payment Types to Include:
                        </h4>
                        <div className="display-flex payment-types-wrapper">
                            {
                                this.state.paymentTypes.map((type, index) => (
                                    <div className="form-group display-flex margin-10-right" key={index}>
                                        <input
                                            id={type}
                                            className="margin-5-right"
                                            type="checkbox"
                                            checked={this.state.paymentTypes.includes(type)}
                                            onChange={() => toggleType(type)}
                                        />
                                        <label htmlFor={type}>{ Utils.humanize(type) }</label>
                                    </div>
                                ))
                            }
                        </div>
                    </Fragment>
                }

                <div className="display-flex margin-10-top justify-content-space-between align-items-center">
                    <div>
                        <button
                            className="btn btn-success"
                            onClick={this.fetchIncome}
                            disabled={this.state.paymentStatuses.length < 1}
                        >
                            Load Results
                        </button>
                    </div>
                    { this.totalAmount(false) > 0.01 &&
                        <DownloadCsv
                            csvUrl={`/reports/income.csv?${$.param(this.requestData())}`}
                            cookieName="income_report_downloaded"
                        />
                    }
                </div>

                <hr/>

                { this.state.loading &&
                    <div className="display-flex flex-column">
                        <h3 className="text-center">
                            Loading Income Report...
                        </h3>
                        <i className="fa fa-spinner fa-pulse fa-3x fa-fw align-self-center flex-1"></i>
                    </div>
                }

                { (!this.state.loading && this.totalAmount(false) < 0.01) &&
                    <h3 className="text-center">
                        No { Utils.humanize(this.state.reportType) } Found
                    </h3>
                }

                { (!this.state.loading && this.totalAmount(false) >= 0.01) &&
                    <Fragment>
                        <h4>
                            Total { this.state.reportType === "invoices" ? "Invoices Amount" : "Payments Collected" }: <span className="color-gray">{ this.totalAmount() }</span>
                        </h4>

                        <div className="chart-container">
                            <AqsChart
                                chartType="bar"
                                canvasWidth={500}
                                canvasHeight={500}
                                displayLegend={true}
                                chartData={this.chartData()}
                                options={{
                                    tooltips: {
                                        callbacks: {
                                            label(tooltipItems, data) {
                                                return Utils.floatToCurrency(parseFloat(tooltipItems.yLabel.toString()));
                                            }
                                        }
                                    },
                                    scales: {
                                        yAxes: [{
                                            ticks: {
                                                callback(value, index, values) {
                                                    return Utils.floatToCurrency(parseFloat(value));
                                                },
                                                beginAtZero: true
                                            }
                                        }]
                                    },
                                    maintainAspectRatio: false,
                                    responsive: true
                                }}
                            />
                        </div>
                    </Fragment>
                }
            </Fragment>
        )
    }
}
