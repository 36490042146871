import React from 'react';

const ActivateMapbox = (props) => {
    return(
        <div className="panel panel-default">
            <div className="panel-body display-flex flex-column justify-content-center align-items-center">
                <div className="alert alert-warning">
                    <h4 className="text-center no-margin">
                        <i className="fa fa-exclamation-triangle margin-5-right"></i>
                        You must activate Mapbox before you can use this feature
                    </h4>
                </div>
                <h4>
                    <a href="http://help.useaquasuite.com/en/articles/3985795-activating-mapbox" target="_blank">Click Here</a> for a step-by-step guide on how to activate Mapbox
                </h4>
                <h5 className="text-center">
                    Mapbox is the service that AquaSuite uses to put together suggested scheduling, route optimization, route metrics, and more. You must sign up for a FREE account and link it with AquaSuite before you can use this feature.
                </h5>
            </div>
        </div>
    )
};

export default ActivateMapbox;
