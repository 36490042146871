import React from 'react';
import PropTypes from 'prop-types';

export default class AqsModal extends React.Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        headerText: PropTypes.string,
        headerIcon: PropTypes.string,
        footerButtonText: PropTypes.string,
        footerButtonAction: PropTypes.func,
        footerButtonDisabled: PropTypes.bool,
        footerButtonClass: PropTypes.string,
        leftFooterButtonText: PropTypes.string,
        leftFooterButtonAction: PropTypes.func,
        leftFooterButtonDisabled: PropTypes.bool,
        leftFooterButtonClass: PropTypes.string,
        large: PropTypes.bool,
        closeCallback: PropTypes.func,
        hideCloseButton: PropTypes.bool,
        showCallback: PropTypes.func
    }

    componentDidMount() {
        $(this.modalEl).on("show.bs.modal", (e) => {
            $(e.target).addClass("center-screen-modal");

            if (this.props.showCallback) {
                this.props.showCallback();
            }
        });

        $(this.modalEl).on("hide.bs.modal", (e) => {
            $(e.target).removeClass("center-screen-modal");

            if (this.props.closeCallback) {
                this.props.closeCallback();
            }
        });
    }

    show = () => {
        $(this.modalEl).modal({ show: true, backdrop: $(".modal-backdrop").length === 0 });
    };

    hide = () => {
        $(this.modalEl).modal("hide");
    };

    render() {
        return(
            <div
                ref={(e) => this.modalEl = e}
                id={this.props.id}
                className="modal fade"
            >
                <div className={`modal-dialog ${this.props.large && "modal-lg"}`}>
                    <div className="modal-content">
                        <div className="modal-header">
                            { !this.props.hideCloseButton &&
                                <button
                                    type="button"
                                    className="close"
                                    onClick={this.hide}
                                >
                                    <span>&times;</span>
                                </button>
                            }
                            <h4 className="modal-title">
                                { this.props.headerIcon &&
                                    <span className="header-icon">
                                        <img src={this.props.headerIcon} />
                                    </span>
                                }
                                { this.props.headerText }
                            </h4>
                        </div>
                        <div className="modal-body">
                            { this.props.children }
                        </div>
                        <div className="modal-footer">
                            { this.props.leftFooterButtonText &&
                                <div className="pull-left">
                                    <button
                                        type="button"
                                        className={`btn ${this.props.leftFooterButtonClass || "btn-success"}`}
                                        onClick={this.props.leftFooterButtonAction}
                                        disabled={this.props.leftFooterButtonDisabled}
                                    >
                                        { this.props.leftFooterButtonText }
                                    </button>
                                </div>
                            }
                            { !this.props.hideCloseButton &&
                                <button
                                    className="btn btn-default"
                                    onClick={this.hide}
                                >
                                    Close
                                </button>
                            }
                            { this.props.footerButtonText &&
                                <button
                                    type="button"
                                    className={`btn ${this.props.footerButtonClass || "btn-success"}`}
                                    onClick={this.props.footerButtonAction}
                                    disabled={this.props.footerButtonDisabled}
                                >
                                    { this.props.footerButtonText }
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
