import PropTypes from 'prop-types';
import React from 'react';

export default class ImageThumbnail extends React.Component {
    static propTypes = {
        image: PropTypes.object.isRequired,
        index: PropTypes.number.isRequired,
        processImage: PropTypes.func.isRequired,
        updateImage: PropTypes.func.isRequired,
        preventRetry: PropTypes.bool.isRequired
    }

    constructor(props) {
        super(props);

        this.baseState = {
            fetchingImage: true,
            retrying: false
        }

        this.state = this.baseState;
    }

    showModal = (e) => {
        e.preventDefault();

        if (e.target.nodeName === "BUTTON") return false; // for retry button

        const modal = $("#image-modal")
        const active = modal.find(".active");
        if (active) {
            active.toggleClass("active");
        }

        modal.find(`[data-index=${this.props.index}]`).toggleClass("active");

        modal.modal({ show: true, backdrop: (!$(".modal-backdrop").length > 1) });
    };

    handleOnLoad = () => {
        if (!this.props.image.loading) {
            this.setState({ fetchingImage: false });
        }
    };

    processImage = () => {
        this.setState({ retrying: true }, () => {
            this.props.processImage(this.props.image, this.props.index, () => {
                this.setState({ retrying: false })
            })
        });
    };

    isLoading = () => {
        if (this.state.retrying) {
            return true
        } else if (this.props.image.loading || this.state.fetchingImage) {
            return !this.props.image.timeout
        }
    };

    render() {
        let klass = "thumbnail";
        if (this.isLoading()) klass += " loading";
        return(
            <a
                href="#"
                className={klass}
                onClick={(e) => this.showModal(e)}
            >
                <img
                    src={this.props.image.full_url}
                    onLoad={this.handleOnLoad}
                />
                { (!this.props.preventRetry && !this.props.image.loading && this.props.image.timeout) &&
                    <div className="failed">
                        <button className="btn btn-warning" onClick={this.processImage}>
                            Retry
                        </button>
                    </div>
                }
            </a>
        )
    }
}
