import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import Select from 'react-select';

const ServiceItem = (props) => {
    const [newItem, setNewItem] = useState(false);

    const selectStyles = {
        dropdownIndicator: (provided, state) => ({
            color: "black",
            margin: "0 5px"
        })
    };

    const updateSelect = (obj) => {
        const msi = props.masterServiceItems.find(msi => msi.name === obj.value)

        if (msi) {
            props.update({ price: msi.default_price, name: msi.name, cost: msi.default_cost }, props.index);
        } else if (obj.value === "New Service Item") {
            setNewItem(true);
        }
    };

    const selectValue = () => {
        if (props.serviceItem.name) {
            return { value: props.serviceItem.name, label: props.serviceItem.name }
        } else {
            return { label: "Select a Service Item...", value: "" }
        }
    };

    return(
        <Fragment>
            <div className="form-group">
                <label>
                    Name:
                </label>
                { !newItem &&
                    <Select
                        options={[...props.masterServiceItems.map(msi => ({ value: msi.name, label: msi.name })), { value: "New Service Item", label: "New Service Item" }]}
                        value={selectValue()}
                        onChange={updateSelect}
                        styles={selectStyles}
                    />
                }
                { newItem &&
                    <input
                        type="text"
                        className="form-control"
                        value={props.serviceItem.name || ""}
                        onChange={(e) => props.update({ ...props.serviceItem, name: e.target.value }, props.index)}
                        placeholder="Enter New Service Item Name"
                    />
                }
            </div>
            { !props.isRouteTech &&
                <Fragment>
                    <div className="form-group">
                        <label>Cost to Company:</label>
                        <input
                            type="text"
                            className="form-control"
                            onChange={(e) => props.update({ ...props.serviceItem, cost: e.target.value }, props.index)}
                            value={props.serviceItem.cost || ""}
                            placeholder="Set Cost to Company"
                        />
                    </div>
                    <div className="form-group">
                        <label>
                            Price:
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            step="0.5"
                            value={props.serviceItem.price || ""}
                            onChange={(e) => props.update({ ...props.serviceItem, price: e.target.value }, props.index)}
                            placeholder="Set price of this Service Item"
                        />
                    </div>
                </Fragment>
            }

            <div className="form-group">
                <label>
                    Quantity:
                </label>
                <input
                    type="number"
                    min=".01"
                    className="form-control"
                    defaultValue={props.serviceItem.quantity || 1}
                    onChange={(e) => props.update({ ...props.serviceItem, quantity: e.target.value }, props.index)}
                />
            </div>
        </Fragment>
    )
}

ServiceItem.propTypes = {
    index: PropTypes.number.isRequired,
    update: PropTypes.func.isRequired,
    serviceItem: PropTypes.object.isRequired,
    masterServiceItems: PropTypes.array.isRequired,
    isRouteTech: PropTypes.bool.isRequired
}

export default ServiceItem;
